import React, {Component} from "react";
import {
  Modal,
  FormSelect,
  FormInput,
} from "@frostbyte-technologies/frostbyte-tailwind";
import {Formik} from "formik";
import {
  decimalToDollars,
  toDollars,
} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import {request} from "../../../utils/request";
import * as Yup from "yup";

class SupplementalModal extends Component {
  state = {
    payrollId: null,
    employeeId: null,
    name: null,
    employeeLocations: null,
    supplementalTypeValue: "BONUS",
  };

  openNew(payrollId, employeeId, name, supplementalTypeValue) {
    this.setState(
      {
        payrollId,
        employeeId,
        name,
        supplemental: {AMOUNT: ""},
        supplementalTypeValue,
      },
      this.modal.open()
    );
  }

  open(payrollId, employeeId, name, supplemental, supplementalTypeValue) {
    this.setState(
      {payrollId, employeeId, name, supplemental, supplementalTypeValue},
      this.modal.open()
    );
  }

  delete = async () => {
    await this.submitSupplemental(null, 0);
    await this.props.refresh();

    this.modal.close();
  };

  handleSubmit = async (values) => {
    let {type, amount, location} = values;

    if (this.submitted) {
      return;
    }

    this.submitted = true;

    await this.submitSupplemental(type, amount, location);
    await this.props.refresh();

    this.modal.close();

    setTimeout(() => (this.submitted = false), 1000);
  };

  submitSupplemental = async (type, amount) => {
    let {payrollId, employeeId, supplemental, supplementalTypeValue} =
      this.state;

    let payload = {
      CHECK_PAYROLL_ID: payrollId,
      EMPLOYEE_ID: employeeId,
      TIP_TYPE: type,
      AMOUNT: decimalToDollars(amount),
      ID: supplemental?.ID,
      TYPE: SUPPLEMENTAL_TYPES[supplementalTypeValue].type,
    };

    await request("payroll/run-payroll/set-supplemental", "POST", payload);
  };

  render() {
    let {name, supplemental, supplementalTypeValue} = this.state;

    return (
      <Modal
        label={`${SUPPLEMENTAL_TYPES[supplementalTypeValue].label} for ${name}`}
        buttonLabel="Save"
        deleteLabel="Delete"
        deleteOnClick={() => this.delete()}
        ref={(e) => (this.modal = e)}
        formikOnClick={() => this.formikRef}
      >
        <Formik
          enableReinitialize={true}
          initialValues={{
            amount: supplemental ? toDollars(supplemental.AMOUNT) : "",
          }}
          validationSchema={Yup.object().shape({
            amount: Yup.string()
              .required()
              .matches(/[0-9,\.]+/, "Must be a valid dollar amount"),
          })}
          onSubmit={this.handleSubmit}
          innerRef={(e) => (this.formikRef = e)}
        >
          {(formikOptions) => {
            const {handleSubmit, values} = formikOptions;

            return (
              <form onSubmit={handleSubmit}>
                <FormInput
                  label={"Amount (in $)"}
                  name={"amount"}
                  options={formikOptions}
                />
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

export const SUPPLEMENTAL_TYPES = {
  BONUS: {
    label: "Bonus",
    value: "BONUS",
    type: "bonus",
  },
  COMMISSION: {
    label: "Commission",
    value: "COMMISSION",
    type: "commission",
  },
};

export default SupplementalModal;
