import React, {Component} from "react";
import {
  FormInput,
  FormSelect,
  Modal,
} from "@frostbyte-technologies/frostbyte-tailwind";
import {Formik} from "formik";
import {request} from "../../utils/request";
import PropTypes from "prop-types";
import ComboBox from "../../components/combobox";
import {setupReduxConnection} from "../../redux";
import {withRouter} from "../../utils/navigation";
import {PAGE_ROUTES} from "../../settings/routes";

export const PATH_REDIRECTS = {
  device: "devices",
  "company-structure/company": "company-structure/companies",
  category: "categories",
  product: "products",
  tax: "taxes",
  fee: "fees",
  check: "checks",
  ticket: "tickets",
  "flow/ticket": "flow/tickets",
  "flow/payment": "flow/payments",
  prompt: "prompts",
  receipt: "receipts",
  discount: "discounts",
  tag: "tags",
  center: "centers",
  role: "roles",
  departments: "departments",
  employee: "employees",
  policy: "policies",
  memo: "memos",
  "onboarding/process/create": "onboarding/processes",
  "onboarding/company-document": "onboarding/company-documents",
  patron: "patrons",
  new: "news",
  gift: "gifts",
  fundraiser: "fundraisers",
  "subscriber-list": "subscriber-lists",
  "campaign/texts/instances": "campaigns",
  "campaigns/email": "campaigns",
  coupon: "coupons",
  task: "tasks",
  promotion: "promotions",
  drawer: "drawers",
  bank: "banks",
  "bank/templates": "banks",
  ingredient: "ingredients",
  label: "labels",
  checklist: "checklists",
  inventory: "inventories",
  chat: "chats",
  invoice: "invoices",
  contact: "contacts",
};

class SwitchLocationModal extends Component {
  state = {data: []};

  open() {
    this.formikRef && this.formikRef.resetForm();
    this.modal.open();
  }

  componentDidMount() {
    this.syncData();
  }

  async syncData() {
    const serverLocations = await request("employees/locations", "GET");

    this.setState({data: serverLocations});
  }

  async switchLocation({location}) {
    const {data: locations} = this.state;

    const sessionPath = this.fetchSessionPath();

    localStorage.setItem("CURRENT_LOCATION", "" + location);

    localStorage.removeItem("SCHEDULE_LOCATION_ARR");

    const targetLocation = locations.find(
      ({UNIQUE_ID}) => UNIQUE_ID === location
    );

    if (targetLocation?.TYPE !== "PAYROLL") {
      window.location.href = "/" + sessionPath;
    } else {
      window.location.href = "/payroll";
    }
  }

  isValidRoute(route) {
    let validRoutesArr = PAGE_ROUTES.map((nav) =>
      nav.routes.map(({path}) => path)
    );

    validRoutesArr = validRoutesArr.flat();

    return validRoutesArr.findIndex((path) => path === route) !== -1;
  }

  fetchSessionPath() {
    const path = this.props.router.params["*"];

    const segments = path.split("/");

    if (segments.length === 0) {
      return "";
    }

    for (let i = segments.length; i > 0; i--) {
      const path = segments.slice(0, i).join("/");

      if (PATH_REDIRECTS[path]) {
        return PATH_REDIRECTS[path];
      }

      if (this.isValidRoute(path)) {
        return path;
      }
    }

    return "";
  }

  render() {
    const {location} = this.props.shop;
    const {stayOpen} = this.props;
    const {data} = this.state;

    const companiesDict = data.reduce((dict, item) => {
      if (!dict[item.COMPANY_ID]) {
        dict[item.COMPANY_ID] = item;
      }

      return dict;
    }, {});

    return (
      <Modal
        {...this.props}
        ref={(e) => (this.modal = e)}
        buttonLabel={stayOpen ? "Load" : "Switch"}
        label={stayOpen ? "Select Location" : "Switch Locations"}
        formikOnClick={() => this.formikRef}
      >
        <Formik
          enableReinitialize
          onSubmit={this.switchLocation.bind(this)}
          innerRef={(e) => (this.formikRef = e)}
          initialValues={{
            company: location?.COMPANY_ID,
            location: location?.UNIQUE_ID,
          }}
        >
          {(formikOptions) => {
            const {handleSubmit, setFieldValue, values} = formikOptions;
            const {company} = values;

            const companies = Object.values(companiesDict).map((item) => {
              return {
                id: item.COMPANY_ID,
                location: item.UNIQUE_ID,
                label: item.COMPANY_NAME,
              };
            });

            const locations = data
              .filter((item) => {
                return item.COMPANY_ID === parseInt(company);
              })
              .map((item) => {
                return {id: item.UNIQUE_ID, label: item.NAME};
              });

            return (
              <form onSubmit={handleSubmit}>
                <ComboBox
                  label="Company"
                  name="company"
                  strategy="absolute"
                  options={formikOptions}
                  data={companies}
                  onChangeSoft={({location}) =>
                    setFieldValue("location", location)
                  }
                />

                <ComboBox
                  label="Locations"
                  name="location"
                  strategy="absolute"
                  options={formikOptions}
                  data={locations}
                />
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

export default setupReduxConnection(["shop"])(withRouter(SwitchLocationModal));
