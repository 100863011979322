import React, {Component} from "react";
import Modal from "@frostbyte-technologies/frostbyte-tailwind/dist/components/modals/modal";
import moment from "moment-timezone";
import {
  FormSelect,
  Table,
  Tooltip,
} from "@frostbyte-technologies/frostbyte-tailwind";
import {Formik} from "formik";
import {request} from "../../utils/request";
import {setupReduxConnection} from "../../redux";
import {withRouter} from "../../utils/navigation";
import {
  showErrorNotification,
  showSuccessNotification,
} from "../../utils/notification-helper";
import {
  getConflictString,
  isTimeOffConflict,
  isUnavailabilityConflict,
} from "../../utils/scheduler-helper";
import {deepClone} from "../../utils/util";

export const CONFLICT_TYPE = {
  TIME_OFF: "Time Off",
  UNAVAILABILITY: "Unavailability",
};

class CopyWeekScheduleModal extends Component {
  state = {
    isLoading: false,
    startWeek: null,
    conflicts: [],
    excluded: [],
  };

  async open() {
    let startWeek = await this.props.getCalendarWeekStartEpoch();

    this.setState({startWeek}, () => {
      this.formikRef && this.formikRef.resetForm();
      this.slide.open();
    });

    this.fetchConflicts(moment(startWeek).add(1, "weeks").valueOf());
  }

  close = () => {
    this.setState({startWeek: null, open: false}, this.slide.close());
  };

  handleFormSubmission = async ({copyToWeek}) => {
    const {locationIdArr} = this.props;
    const {excluded} = this.state;

    const copyToDateEpoch = moment(copyToWeek).valueOf();

    try {
      await request("scheduling/locations/copy", "POST", {
        START_EPOCH: this.state.startWeek,
        END_EPOCH: copyToDateEpoch,
        LOCATION_ID_ARR: locationIdArr,
        EXCLUDE_IDS: excluded.map(({ID}) => ID),
      });
    } catch (err) {
      return showErrorNotification(
        "Copy Week Error",
        "Error copying week. Please refresh the page and try again."
      );
    }

    this.props.afterUpdate(copyToDateEpoch);

    showSuccessNotification("Successfully Copied Shifts");

    this.setState({open: false}, () => this.slide.close());
  };

  async fetchConflictData(startWeek) {
    const {employees} = this.props.scheduling.schedule;
    const {locationIdArr} = this.props;

    const payload = {
      EMPLOYEE_ID_ARR: employees.map(({ID}) => ID),
      LOCATION_ID_ARR: locationIdArr,
      START: startWeek,
      END: moment(startWeek).add(1, "week").valueOf(),
    };

    try {
      const [unavail, timeOff] = await Promise.all([
        request("scheduling/locations/unavailabilities", "POST", payload),
        request("scheduling/locations/timeoff", "POST", payload),
      ]);

      return {timeOff, unavail};
    } catch (err) {
      showErrorNotification(
        "Error Fetching Conflicts",
        "We encountered an error while fetching conflict information. You can still submit a request to copy shift information."
      );

      return {};
    }
  }

  async fetchConflicts(startWeek) {
    this.setState({isLoading: true});

    const {timeOff = [], unavail = []} = await this.fetchConflictData(
      startWeek
    );

    const {TIMEZONE: timezone} = this.props.shop.settings;
    const {shifts} = this.props.scheduling.schedule;
    let {startWeek: firstWeek} = this.state;

    const weekDiff = moment(startWeek).diff(moment(firstWeek), "weeks");

    let conflicts = [];

    const shiftCopy = deepClone(
      shifts.filter(({EMPLOYEE_ID}) => !!EMPLOYEE_ID)
    );

    for (const shift of shiftCopy) {
      shift.DATE_START = moment(shift.DATE_START)
        .add(weekDiff, "weeks")
        .valueOf();
      shift.DATE_END = moment(shift.DATE_END).add(weekDiff, "weeks").valueOf();

      let conflict = [];

      for (let unavailability of unavail) {
        if (isUnavailabilityConflict(unavailability, shift)) {
          conflict.push({
            conflict: unavailability,
            type: CONFLICT_TYPE.UNAVAILABILITY,
          });
        }
      }

      for (let time of timeOff) {
        if (isTimeOffConflict(time, shift, timezone)) {
          // console.log(shift, time);
          conflict.push({conflict: time, type: CONFLICT_TYPE.TIME_OFF});
        }
      }

      if (conflict.length > 0) {
        conflicts.push({shift, conflicts: conflict});
      }
    }

    conflicts = conflicts.sort(
      (a, b) => a.shift.DATE_START - b.shift.DATE_START
    );

    this.setState({conflicts, isLoading: false});
  }

  onSelect(row) {
    const {excluded} = this.state;

    if (!row.shift) {
      return;
    }

    const toFindIndex = excluded.findIndex(({ID}) => ID === row.shift.ID);

    if (toFindIndex === -1) {
      excluded.push(row.shift);
    } else {
      excluded.splice(toFindIndex, 1);
    }

    this.setState({excluded});
  }

  renderConflicts() {
    const {conflicts} = this.state;

    return (
      <div className={"mt-4"}>
        <div className={"flex flex-row items-center"}>
          <label className={"block text-sm font-medium text-gray-700"}>
            Conflicts
          </label>

          <Tooltip
            className={"ml-2"}
            label="De-select shifts with conflicts to exclude them from being copied."
          />
        </div>

        <Table
          white
          verticalLines
          className="mt-2"
          data={conflicts || null}
          columns={COPY_TABLE_COLUMNS}
          ref={(e) => (this.copyTable = e)}
          selectable
          preSelectAll
          onSelect={(row) => this.onSelect(row)}
        />
      </div>
    );
  }

  render() {
    let {startWeek, conflicts} = this.state;

    let weekArr = [];
    for (let weeksAway = 1; weeksAway < 5; weeksAway++) {
      let weekStartMoment = moment(startWeek).add(weeksAway, "weeks");

      weekArr.push({
        label: `${
          weeksAway === 1 ? "Next Week" : `In ${weeksAway} Weeks`
        }: ${weekStartMoment.format("M/DD")} - ${weekStartMoment
          .add(6, "days")
          .format("M/D")}`,
        value: moment(startWeek).add(weeksAway, "week").valueOf(),
      });
    }

    return (
      <Modal
        xlarge
        buttonLabel="Copy"
        label="Copy Weekly Schedule"
        description={`${moment(startWeek).format("MMM D")} - ${moment(startWeek)
          .add(6, "days")
          .format("MMM D")}`}
        ref={(e) => (this.slide = e)}
        formikOnClick={() => this.formikRef}
      >
        <Formik
          onSubmit={this.handleFormSubmission}
          initialValues={{
            copyToWeek: moment(startWeek).add(1, "week").valueOf(),
          }}
          enableReinitialize={true}
          // validationSchema={this.validationSchema}
          innerRef={(e) => (this.formikRef = e)}
        >
          {(formikOptions) => {
            const {handleSubmit, setFieldValue} = formikOptions;

            return (
              <form
                onSubmit={handleSubmit}
                className="w-full flex justify-start"
              >
                <FormSelect
                  className={"w-1/2"}
                  label={"Copy To Week"}
                  name={"copyToWeek"}
                  data={weekArr}
                  options={formikOptions}
                  onChangeSoft={async (option) => {
                    const {value: week} = option;

                    setFieldValue("copyToWeek", week);

                    this.fetchConflicts(week);

                    this.setState({excluded: []});
                  }}
                />
              </form>
            );
          }}
        </Formik>

        {conflicts.length > 0 && this.renderConflicts()}
      </Modal>
    );
  }
}

export const COPY_TABLE_COLUMNS = [
  {
    value: "EMPLOYEE",
    label: <div className={"ml-2"}>Employee</div>,
    format: (val, row) => {
      return <div className={"ml-2"}>{row.shift.FULL_NAME}</div>;
    },
  },
  {
    value: "ROLE",
    label: "Role",
    format: (val, row) => row.shift.ROLE_NAME ?? "Archived Role",
  },
  {
    value: "SHIFT_DATES",
    label: "Shift Date",
    format: (val, row) =>
      moment(row.shift.DATE_START).format("M/D h:mma") +
      "\n - " +
      moment(row.shift.DATE_END).format("M/D h:mma"),
  },
  {
    value: "CONFLICT_ARR",
    label: "Time Off / Unavailability",
    format: (val, row) => {
      const conflictStrArr = row.conflicts?.map((_conflict) =>
        getConflictString(_conflict)
      );

      return conflictStrArr.join(", ");
    },
  },
];

export default setupReduxConnection(["scheduling", "shop"])(
  withRouter(CopyWeekScheduleModal)
);
