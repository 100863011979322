import React, {Component} from "react";
import {request} from "../../../../utils/request";
import {withRouter} from "../../../../utils/navigation";

import {
  Card,
  Loading,
  PageHeadings,
  Tab,
  Table,
  TwoColumnList,
} from "@frostbyte-technologies/frostbyte-tailwind";
import IngredientModal from "../../../../modals/operations/ingredients/ingredient-modal";
import {getObjectImage} from "@frostbyte-technologies/frostbyte-core/dist/helpers/asset-helper";
import moment from "moment";
import {showLoadingConfirmAlert} from "../../../../utils/alert-helper";

class IngredientPage extends Component {
  state = {ingredient: null, products: null, modifiers: null, logs: null};

  componentDidMount() {
    this.syncState();
  }

  async syncState() {
    const {ID: id} = this.props.router.params;

    const ingredient = await request("ingredients/" + id, "GET");

    request("ingredients/products/" + ingredient.ID, "GET").then((products) => {
      this.setState({products});
    });

    request("ingredients/modifiers/" + ingredient.ID, "GET").then((modifiers) => {
      this.setState({modifiers});
    });

    request("ingredients/" + ingredient.ID + "/logs", "GET").then((logs) => {
      logs.reverse();

      this.setState({logs});
    });

    this.setState({ingredient});
  }

  removeIngredient() {
    const {ingredient} = this.state;

    showLoadingConfirmAlert(
      "Delete Ingredient",
      "Are you sure you want to delete this ingredient?"
    ).then(async (close) => {
      await request("ingredients/" + ingredient.ID, "DELETE", {});

      close();

      this.props.router.navigate("/ingredients");
    });
  }

  render() {
    const {ingredient, products, modifiers, logs} = this.state;

    if (ingredient === null) {
      return <Loading />;
    }

    return (
      <div>
        <PageHeadings
          label={ingredient.NAME}
          buttons={[
            {
              type: "dropdown",
              label: "Actions",
              sections: [
                {
                  items: [
                    {
                      label: "Delete Ingredient",
                      onClick: this.removeIngredient.bind(this),
                    },
                  ],
                },
              ],
            },
          ]}
          breadcrumbs={[
            {label: "Operations", url: "/"},
            {label: "Ingredients Page", url: "/ingredients"},
          ]}
        />

        <IngredientModal
          updateState={(ingredient) => this.setState({ingredient})}
          ref={(e) => (this.ingredientModal = e)}
        />

        <TwoColumnList
          label="Ingredient"
          description="Details for this ingredient"
          data={[
            {label: "Name", value: ingredient.NAME},
            {label: "Enabled", value: ingredient.ENABLED, type: "bool"},
            {label: "Base Unit", value: ingredient.INGREDIENT_UNIT_NAME},
            {label: "Unit Cost", value: ingredient.COST, type: "dollars"},
            {label: "SKU", value: ingredient.SKU || "None"},
            {label: "Barcode", value: ingredient.BARCODE || "None"},
          ]}
          buttons={[
            {
              label: "Edit Information",
              onClick: () => this.ingredientModal.open(ingredient),
            },
          ]}
        />

        <Tab
          data={[
            {id: "products", label: "Products"},
            {id: "modifiers", label: "Modifiers"},
            {id: "logs", label: "Usage Log"},
          ]}
        >
          {(id) => {
            if (id === "products") {
              return (
                <Card
                  label="Ingredient Products"
                  description="All of the products that use this ingredient"
                >
                  <Table
                    pagination
                    data={products}
                    actionButtons={[
                      {
                        label: "View",
                        onClick: (row) => {
                          this.props.router.navigate(
                            "/product/" + row.UNIQUE_ID + "?tab=ingredients"
                          );
                        },
                      },
                    ]}
                    columns={[
                      {
                        value: "NAME",
                        label: "Name",
                        format: (value, row) => {
                          return (
                            <div className="flex items-center">
                              <div className="flex-shrink-0 h-10 w-10">
                                <img
                                  src={getObjectImage(row, "LOGO", "appicon.png")}
                                  className="h-10 w-10 rounded-full"
                                  alt=""
                                />
                              </div>

                              <div className="ml-4">
                                <div className="text-sm font-medium text-gray-900">
                                  {row.NAME}
                                </div>

                                <div className="text-sm text-gray-500">
                                  {row.CATEGORY_NAME}
                                </div>
                              </div>
                            </div>
                          );
                        },
                      },
                      {
                        width: 1,
                        value: "QUANTITY",
                        label: "Ingredient Quantity",
                        tooltip:
                          "The amount of the ingredient that is consumed with the sale of each product.",
                      },
                      {
                        width: 1,
                        value: "ENABLED",
                        label: "Product Ingredient Enabled",
                        format: (val) => (val ? "Yes" : "No"),
                        tooltip:
                          "States if currently the ingredient is enabled on this product",
                      },
                    ]}
                  />
                </Card>
              );
            }

            if (id === "modifiers") {
              return (
                <Card
                  label="Ingredient Modifiers"
                  description="All of the modifiers that use this ingredient"
                >
                  <Table
                    pagination
                    data={modifiers}
                    columns={[
                      {
                        value: "NAME",
                        label: "Name",
                        format: (value, row) => {
                          return (
                            <div className="flex items-center">
                              <div className="flex-shrink-0 h-10 w-10">
                                <img
                                  src={getObjectImage(row, "LOGO", "appicon.png")}
                                  className="h-10 w-10 rounded-full"
                                  alt=""
                                />
                              </div>

                              <div className="ml-4">
                                <div className="text-sm font-medium text-gray-900">
                                  {row.NAME}
                                </div>

                                <div className="text-sm text-gray-500">
                                  {row.CATEGORY_NAME}
                                </div>
                              </div>
                            </div>
                          );
                        },
                      },
                      {
                        width: 1,
                        value: "QUANTITY",
                        label: "Ingredient Quantity",
                        tooltip:
                          "The amount of the ingredient that is consumed with the sale of each modifier.",
                      },
                      {
                        width: 1,
                        value: "ENABLED",
                        label: "Product Ingredient Enabled",
                        format: (val) => (val ? "Yes" : "No"),
                        tooltip:
                          "States if currently the ingredient is enabled on this modifier",
                      },
                    ]}
                  />
                </Card>
              );
            }

            if (id === "logs") {
              return (
                <Card label="Ingredient Logs" description="When was this ingredient used">
                  <Table
                    pagination
                    data={logs}
                    columns={[
                      {
                        value: "TYPE",
                        label: "Type",
                      },
                      {
                        width: 1,
                        value: "DATE_CREATED",
                        label: "Date of Change",
                        format: (value) => moment(value).format("hh:mma MM/DD/YY"),
                      },
                      {
                        width: 1,
                        value: "STOCK",
                        label: "Stock",
                        format: (stock, {STOCK_DELTA: delta}) =>
                          stock + " (" + delta + ")",
                      },
                    ]}
                  />
                </Card>
              );
            }

            return <div>hi</div>;
          }}
        </Tab>
      </div>
    );
  }
}

export default withRouter(IngredientPage);
