import React, {Component} from "react";
import {Table} from "@frostbyte-technologies/frostbyte-tailwind";
import {withRouter} from "../../../utils/navigation";
import AttachIngredientModal from "../../../modals/operations/supply-chain/recipes/attach-ingredient-modal";

class RecipeTable extends Component {
  render() {
    const {ingredient} = this.props;

    return (
      <div>
        <AttachIngredientModal
          ref={(e) => (this.attachIngredientModal = e)}
          source={ingredient}
          syncState={() => this.props.syncState()}
        />

        <Table
          data={ingredient.CHILDREN ?? []}
          columns={[
            {
              label: "Name",
              value: "NAME",
              format: (val, row) => {
                return (
                  <div
                    className="font-semibold text-indigo-500 hover:text-indigo-700"
                    onClick={() => {
                      this.props.router.navigate("/recipes/ingredients/" + row.UNIQUE_ID);
                    }}
                  >
                    {val}
                  </div>
                );
              },
            },
            {label: "Quantity", value: "QUANTITY"},
            {label: "Stock", value: "CURRENT_STOCK"},
            {label: "Unit", value: "UNIT_NAME"},
          ]}
          actionButtons={[
            {
              label: "Edit",
              onClick: (row) => {
                this.attachIngredientModal.open(row);
              },
            },
            {
              label: "View",
              onClick: (row) => {
                this.props.router.navigate("/recipes/ingredients/" + row.UNIQUE_ID);
              },
            },
          ]}
        />
      </div>
    );
  }
}

export default withRouter(RecipeTable);
