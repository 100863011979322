import React, {Component} from "react";
import {request} from "../../../../utils/request";
import {
  Card,
  Loading,
  PageHeadings,
  Tab,
} from "@frostbyte-technologies/frostbyte-tailwind";
import ModifierModal from "../../../../modals/sales/modifiers/modifier-modal";
import {withRouter} from "../../../../utils/navigation";
import ModifierProductsTab from "./tabs/modifier-products-tab";
import Modifier from "../../../../features/product/modifier";
import {showSuccessNotification} from "../../../../utils/notification-helper";

class ModifierPage extends Component {
  state = {modifier: null};

  componentDidMount() {
    this.syncState();
  }

  syncState() {
    const {ID: id} = this.props.router.params;

    request("modifiers/" + id, "GET").then((modifier) =>
      this.setState({modifier})
    );
  }

  deleteModifier() {}

  render() {
    const {modifier} = this.state;

    if (!modifier) {
      return <Loading />;
    }

    return (
      <>
        <ModifierModal
          product={modifier.PRODUCT}
          updateState={(id, item) => {
            this.setState({
              modifier: item,
            });
          }}
          ref={(e) => (this.modifierModal = e)}
        />

        <PageHeadings
          label={modifier.NAME}
          status={{label: "Active"}}
          breadcrumbs={[
            {label: "Sales", url: "/"},
            {label: "Menu Page", url: "/menu"},
            {label: "Modifiers Page", url: "/modifiers"},
          ]}
        />

        <Card
          label="Modifier Information"
          button={{
            label: "Save Modifier",
            onClick: () => {
              this.modifierRef
                .outsideSave()
                .then(() =>
                  showSuccessNotification(
                    "Modifier saved successfully.",
                    modifier.NAME + " has been saved successfully."
                  )
                );
            },
          }}
        >
          <div className="px-6 py-4">
            <Modifier
              modifier={modifier}
              ref={(e) => (this.modifierRef = e)}
              globalMod={true}
            />
          </div>
        </Card>

        {modifier.PRESET && (
          <Tab className="mt-2" data={[{label: "Products", id: "products"}]}>
            {(id) => {
              if (id === "products") {
                return (
                  <ModifierProductsTab
                    modifier={modifier}
                    updateState={(state) => this.setState(state)}
                  />
                );
              }

              return <div />;
            }}
          </Tab>
        )}
      </>
    );
  }
}

export default withRouter(ModifierPage);
