import React, {Component} from "react";
import PageLayout from "../page/page-layout";
import SideNav from "../../components/side-nav";
import {Outlet} from "react-router-dom";
import {PERMISSIONS} from "../../utils/constants";

class ReportingSideNav extends Component {
    render() {
        return (
            <div className="p-6 height-screen">
                <PageLayout className="mt-0">
                    <div className="lg:col-start-1 lg:col-span-2">
                        <SideNav
                            title="Reporting"
                            permissions={[PERMISSIONS.REPORTS.FULL.id]}
                            navigation={[
                                {
                                    live: true,
                                    id: "dash",
                                    name: "Sales Summary",
                                    href: "/reports/sales",
                                    permissions: [PERMISSIONS.REPORTS.SALES_REPORT.id],
                                },
                                {
                                    live: true,
                                    id: "dash",
                                    name: "Compare Sales",
                                    href: "/reports/compare",
                                    permissions: [PERMISSIONS.REPORTS.SALES_REPORT.id],
                                },
                                {
                                    live: true,
                                    id: "dash",
                                    name: "Payment Methods",
                                    href: "/reports/payment-methods",
                                    permissions: [PERMISSIONS.REPORTS.PAYMENT_METHODS_REPORT.id],
                                },
                                {
                                    live: true,
                                    id: "dash",
                                    name: "All Products Sales",
                                    href: "/reports/all-products-sales",
                                    permissions: [PERMISSIONS.REPORTS.PRODUCTS_REPORT.id],
                                },
                                // {
                                //   live: true,
                                //   id: "dash",
                                //   name: "Individual Product Sales",
                                //   href: "/reports/individual-product-sales",
                                //   permissions: [PERMISSIONS.REPORTS.PRODUCTS_REPORT.id],
                                // },
                                {
                                    live: true,
                                    id: "dash",
                                    name: "Category Sales",
                                    href: "/reports/category-sales",
                                    permissions: [PERMISSIONS.REPORTS.CATEGORY_REPORT.id],
                                },
                                {
                                    live: true,
                                    id: "dash",
                                    name: "Discounts",
                                    href: "/reports/discounts",
                                    permissions: [PERMISSIONS.REPORTS.DISCOUNTS_REPORT.id],
                                },
                                {
                                    live: true,
                                    id: "dash",
                                    name: "Labor vs Sales",
                                    href: "/reports/labor-vs-sales",
                                    permissions: [PERMISSIONS.REPORTS.LABOR_VS_SALES.id],
                                },
                                {
                                    live: true,
                                    id: "dash",
                                    name: "Modifier Sales",
                                    href: "/reports/modifier-sales",
                                    permissions: [PERMISSIONS.REPORTS.MODIFIER_REPORT.id],
                                },
                                {
                                    live: true,
                                    id: "dash",
                                    name: "Ingredient Sales",
                                    href: "/reports/ingredient-sales",
                                    permissions: [PERMISSIONS.REPORTS.INGREDIENT_REPORT.id],
                                },
                                {
                                    live: true,
                                    id: "dash",
                                    name: "Inventory Sales",
                                    href: "/reports/inventory-sales",
                                    permissions: [PERMISSIONS.REPORTS.INVENTORY_REPORT.id],
                                },
                                {
                                    live: true,
                                    id: "dash",
                                    name: "Platform Sales",
                                    href: "/reports/platform-sales",
                                    permissions: [PERMISSIONS.REPORTS.PLATFORM_REPORT.id],
                                },
                                {
                                    live: true,
                                    id: "dash",
                                    name: "Ticket Type Sales",
                                    href: "/reports/order-type-sales",
                                    permissions: [PERMISSIONS.REPORTS.ORDER_TYPE_REPORT.id],
                                },
                                {
                                    live: true,
                                    id: "dash",
                                    name: "Gift Card Sales",
                                    href: "/reports/gift-card-sales",
                                    permissions: [PERMISSIONS.REPORTS.GIFT_CARDS_REPORT.id],
                                },
                                {
                                    live: true,
                                    id: "dash",
                                    name: "Taxable Sales",
                                    href: "/reports/taxable",
                                    permissions: [PERMISSIONS.REPORTS.TAXES_REPORT.id],
                                },
                                {
                                    live: true,
                                    id: "dash",
                                    name: "Tax Categories",
                                    href: "/reports/tax-categories",
                                    permissions: [PERMISSIONS.REPORTS.TAXES_REPORT.id],
                                },
                                // {
                                //   live: false,
                                //   id: "dash",
                                //   name: "Taxes",
                                //   href: "/reports/taxes",
                                //   permissions: [PERMISSIONS.REPORTS.TAXES_REPORT.id],
                                // },
                                {
                                    live: true,
                                    id: "dash",
                                    name: "Time Cards",
                                    href: "/reports/time-cards",
                                    permissions: [PERMISSIONS.REPORTS.TIME_CARDS_REPORT.id],
                                },
                                {
                                    live: true,
                                    id: "dash",
                                    name: "Team Sales",
                                    href: "/reports/team-sales",
                                    permissions: [PERMISSIONS.REPORTS.TIME_CARDS_REPORT.id],
                                },
                                {
                                    live: true,
                                    id: "dash",
                                    name: "Cash Drawers",
                                    href: "/reports/cash-drawers",
                                    permissions: [PERMISSIONS.REPORTS.CASH_DRAWERS_REPORT.id],
                                },
                                {
                                    live: true,
                                    id: "dash",
                                    name: "Cash Flow",
                                    href: "/reports/cash-flow",
                                    permissions: [PERMISSIONS.REPORTS.CASH_FLOW_REPORT.id],
                                },
                                {
                                    live: true,
                                    id: "dash",
                                    name: "Custom Payment Type Tips",
                                    href: "/reports/custom",
                                    permissions: [PERMISSIONS.REPORTS.CUSTOM_PAYMENT_TYPE_TIPS_REPORT.id],
                                },
                                {
                                    live: true,
                                    id: "dash",
                                    name: "Invoicing",
                                    href: "/reports/invoicing",
                                    permissions: [PERMISSIONS.REPORTS.INVOICING_REPORT.id],
                                },
                                {
                                    live: true,
                                    id: "dash",
                                    name: "Ticket Completion",
                                    href: "/reports/completion",
                                    permissions: [PERMISSIONS.REPORTS.INVOICING_REPORT.id],
                                },
                                {
                                    live: true,
                                    id: "dash",
                                    name: "Checklists",
                                    href: "/reports/checklists",
                                    permissions: [PERMISSIONS.REPORTS.CHECKLISTS_REPORT.id],
                                },
                                {
                                    live: true,
                                    id: "cogs",
                                    name: "Cost of Goods Sold",
                                    href: "/reports/product_sales_to_cogs",
                                    permissions: [PERMISSIONS.REPORTS.CHECKLISTS_REPORT.id],
                                },
                                {
                                    live: true,
                                    id: "cogs",
                                    name: "Ingredient Usage",
                                    href: "/reports/ingredient-usage",
                                    permissions: [PERMISSIONS.REPORTS.CHECKLISTS_REPORT.id],
                                },
                            ]}
                        />
                    </div>

                    <div className="lg:col-start-3 lg:col-span-10">
                        <Outlet/>
                    </div>
                </PageLayout>
            </div>
        );
    }
}

export default ReportingSideNav;
