import React, {Component} from "react";
import DangerBadge from "../badges/danger-badge";
import {TwoColumnList} from "@frostbyte-technologies/frostbyte-tailwind";
import BillingAddressModal from "../../modals/billing/billing-address-modal";
import PropTypes from "prop-types";

class BillingAddress extends Component {
  formatAddress(address) {
    if (!address) {
      return <DangerBadge>Add Billing Address</DangerBadge>;
    }

    const {line1 = "", line2 = "", city = "", state = "", zipcode = ""} = address;

    let addressString = line1;

    if (line2) {
      addressString += (addressString ? ", " : "") + line2;
    }

    const cityStateZip = [city, state, zipcode].filter(Boolean).join(", ");
    if (cityStateZip) {
      addressString += (addressString ? ", " : "") + cityStateZip;
    }

    return addressString;
  }

  render() {
    const {address, updateAddress} = this.props;

    return (
      <>
        <BillingAddressModal
          ref={(e) => (this.billingAddressModal = e)}
          updateAddress={(address) => this.props.updateAddress(address)}
        />

        <TwoColumnList
          label={"Billing Address"}
          description={"The billing address used for your subscription"}
          buttons={[
            {
              label: "Edit",
              onClick: () => this.billingAddressModal.open(address),
            },
          ]}
          data={[
            {
              label: "Billing Address",
              value: this.formatAddress(address),
            },
          ]}
        />
      </>
    );
  }
}

BillingAddress.propTypes = {
  address: PropTypes.object,
};

export default BillingAddress;
