import React, {Component} from "react";
import {PageHeadings} from "@frostbyte-technologies/frostbyte-tailwind";
import {
  CELL_TYPES,
  CHECKLIST_COLUMNS,
} from "../../features/reporting/reports/reporting-constants";
import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import FixedColumnReport from "../../features/reporting/reports/fixed-column-report";

class ChecklistReportPage extends Component {
  convertDataToRows(reportData) {
    const {TOTALS: totals, NUMBERS: numbers} = reportData;
    const toReturn = [];

    for (const row of numbers) {
      toReturn.push({
        label: row.NAME,
        numbers: {
          ...row,
          NAME: undefined,
        },
        rowType: CELL_TYPES.REGULAR,
      });
    }

    toReturn.push({
      label: "Totals",
      numbers: totals,
      style: [CELL_TYPES.BOLD],
    });

    return toReturn;
  }

  renderCustomTooltip = ({payload}) => {
    if (payload && payload.length > 0) {
      const sum = payload.reduce((accum, item) => accum + item.value, 0) || 1;

      return (
        <div className="bg-white border border-solid border-gray-300 p-2 rounded-sm">
          <div className="flex flex-row justify-between">
            <div className="font-semibold text-sm">Breakdown</div>
          </div>

          <div className="w-full my-2 h-0.5 bg-gray-300" />

          {payload.map((bar) => {
            return (
              <div
                style={{minWidth: 150}}
                className={"w-44 flex flex-row items-center justify-between"}
              >
                <div className={"flex flex-row items-center"}>
                  <div className={"h-3 w-3 mr-1"} style={{backgroundColor: bar.color}} />

                  <div className={"text-xs text-gray-700 font-semibold"}>{bar.name}</div>
                </div>

                <div className={"text-xs font-semibold"}>
                  {bar.value} ({((bar.value / sum) * 100).toFixed(0)}%)
                </div>
              </div>
            );
          })}
        </div>
      );
    }

    return <div />;
  };

  renderTotalGraph(reportData) {
    const {BREAKDOWN: breakdown} = reportData;
    const data = [];

    for (const key of Object.keys(breakdown)) {
      data.push({
        name: key,
        Late: breakdown[key].LATE_COUNT,
        "On Time": breakdown[key].ON_TIME_COUNT,
        Overdue: breakdown[key].OVERDUE_COUNT,
        Outstanding: breakdown[key].OUTSTANDING_COUNT,
      });
    }

    return (
      <div className={"bg-white p-4 rounded-none shadow-sm mb-6"}>
        <ResponsiveContainer width="100%" height={300} className={"mt-3 pr-5"}>
          <BarChart
            width={500}
            height={300}
            data={data}
            stackOffset="sign"
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="name"
              tick={{fontSize: "0.75rem", fontWeight: "bold"}}
              axisLine={false}
              tickMargin={10}
              tickLine={{stroke: "#cccccc"}}
              interval={"preserveStartEnd"}
            />
            <YAxis
              tick={{fontSize: "0.75rem", fontWeight: "bold"}}
              tickMargin={10}
              axisLine={false}
              tickLine={{stroke: "#cccccc"}}
            />
            <Tooltip content={this.renderCustomTooltip} />
            <Bar dataKey="Late" fill="#6366f1" stackId="stack" />
            <Bar dataKey="On Time" fill="#4f46e5" stackId="stack" />
            <Bar dataKey="Overdue" fill="#4338ca" stackId="stack" />
            <Bar dataKey="Outstanding" fill="#3730a3" stackId="stack" />
          </BarChart>
        </ResponsiveContainer>
      </div>
    );
  }

  render() {
    return (
      <div>
        <PageHeadings label="Checklists Report" />

        <div className="h-4" />

        <FixedColumnReport
          endpoint="report/checklists"
          constantColumns={CHECKLIST_COLUMNS}
          firstColLabel="Checklist Name"
          convertDataToRows={this.convertDataToRows}
          search={true}
          searchText={"Search Checklists by Name"}
          graph={(reportData) => this.renderTotalGraph(reportData)}
          locationPicker={true}
        />
      </div>
    );
  }
}

export default ChecklistReportPage;
