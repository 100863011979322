import React, {Component} from "react";
import {FormInput, Modal} from "@frostbyte-technologies/frostbyte-tailwind";
import {Formik} from "formik";
import FormDateSpecify from "../../components/form-date-specify";
import FormStateSelect from "../../components/form-state-select";
import {request} from "../../utils/request";
import {showSuccessNotification} from "../../utils/notification-helper";

class PayrollInactiveEmployeeAddModal extends Component {
  state = {payrollEmployee: null, error: false};

  open(employeeUniqueId, fullName) {
    this.setState({employeeUniqueId, fullName}, () => this.slide.open());

    this.slide.open();
  }

  handleClick = () => {
    this.formikRef.submitForm();
  };

  submitForm = async (values) => {
    let {
      firstName,
      lastName,
      line1,
      line2,
      city,
      state,
      zip,
      ssn,
      month,
      day,
      year,
    } = values;

    const {employeeUniqueId} = this.state;

    try {
      await request(`payroll/employees/${employeeUniqueId}`, "POST", {
        EMPLOYEE_ID: employeeUniqueId,
        FIRST_NAME: firstName,
        LAST_NAME: lastName,
        ADDRESS_1: line1,
        ADDRESS_2: line2,
        CITY: city,
        STATE: state,
        ZIP: zip,
        SSN: ssn,
        DOB: `${year}-${month}-${day}`,
      });

      await this.props.onSave();

      this.slide.close();

      showSuccessNotification(
        "Added",
        `${firstName} has been added to payroll as an inactive employee`
      );

      return true;
    } catch (e) {
      this.setState({error: true});
      return false;
    }
  };

  render() {
    const {fullName, error} = this.state;

    return (
      <Modal
        ref={(e) => (this.slide = e)}
        label={`${fullName}'s Payroll Information`}
        buttonLabel={"Add"}
        buttonOnClick={this.handleClick}
        disableButtonLoading={true}
      >
        {error && (
          <div className="text-red-500">
            Invalid data, please correct then re-submit
          </div>
        )}

        <Formik
          initialValues={{}}
          onSubmit={this.submitForm}
          innerRef={(e) => (this.formikRef = e)}
        >
          {(formikOptions) => {
            return (
              <div>
                <FormInput
                  name={"firstName"}
                  label={"Legal First Name"}
                  options={formikOptions}
                />

                <FormInput
                  name={"lastName"}
                  label={"Legal Last Name"}
                  options={formikOptions}
                />

                <div className="flex flex-row">
                  <FormInput
                    name={"month"}
                    className="w-14"
                    placeholder={"MM"}
                    options={formikOptions}
                  />

                  <FormInput
                    name={"day"}
                    className="w-14 ml-5"
                    placeholder={"DD"}
                    options={formikOptions}
                  />

                  <FormInput
                    name={"year"}
                    className="w-16 ml-5"
                    placeholder={"YYYY"}
                    options={formikOptions}
                  />
                </div>

                <FormInput
                  name={"line1"}
                  label={"Address Line 1"}
                  options={formikOptions}
                />

                <FormInput
                  name={"line2"}
                  label={"Address Line 2"}
                  options={formikOptions}
                />

                <div className="flex flex-row">
                  <FormInput
                    name={"city"}
                    label={"City"}
                    options={formikOptions}
                  />

                  <FormStateSelect
                    className="ml-5"
                    name="state"
                    label={"State"}
                    options={formikOptions}
                  />

                  <FormInput
                    className="ml-5 w-24"
                    name={"zip"}
                    label={"Zipcode"}
                    options={formikOptions}
                  />
                </div>

                <FormInput
                  label={"SSN"}
                  name={"ssn"}
                  options={formikOptions}
                  type={"password"}
                />
              </div>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

export default PayrollInactiveEmployeeAddModal;
