import React, {Component} from "react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Rectangle,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import {toDollars} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import SingleReportingTooltip from "../../../reporting/graphs/tooltips/single-reporting-tooltip";
import {FormSelect} from "@frostbyte-technologies/frostbyte-tailwind";
import {formatNumberCommas} from "../../../../utils/util";
import moment from "moment-timezone";

const GROUP_BY_TYPES = {
  WASTE_TYPE: "WASTE_TYPE",
  EMPLOYEE: "EMPLOYEE",
  INGREDIENT: "INGREDIENT",
};

class WasteGraph extends Component {
  state = {type: GROUP_BY_TYPES.WASTE_TYPE, graphData: []};

  componentDidMount() {
    this.parseGraphData();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state.type !== prevState.type) {
      this.parseGraphData();
    }
  }

  parseGraphData() {
    const {data} = this.props;
    const {type} = this.state;

    const allWastes = data.reduce((accum, item) => [...accum, ...item.WASTES], []);

    const graphData = allWastes.reduce((accum, item) => {
      if (moment().startOf("month").valueOf() > item.DATE_CREATED) {
        return accum;
      }

      if (type === GROUP_BY_TYPES.WASTE_TYPE) {
        if (accum[item.WASTE_TYPE_ID]) {
          accum[item.WASTE_TYPE_ID].cost += item.COST;
          accum[item.WASTE_TYPE_ID].quantity += item.QUANTITY;
        } else {
          accum[item.WASTE_TYPE_ID] = {
            cost: item.COST,
            name: item.WASTE_TYPE_NAME,
            quantity: item.QUANTITY,
          };
        }
      } else if (type === GROUP_BY_TYPES.INGREDIENT) {
        if (accum[item.INGREDIENT_ID]) {
          accum[item.INGREDIENT_ID].cost += item.COST;
          accum[item.INGREDIENT_ID].quantity += item.QUANTITY;
        } else {
          accum[item.INGREDIENT_ID] = {
            cost: item.COST,
            name: item.INGREDIENT_NAME,
            quantity: item.QUANTITY,
          };
        }
      } else if (type === GROUP_BY_TYPES.EMPLOYEE) {
        if (accum[item.EMPLOYEE_ID]) {
          accum[item.EMPLOYEE_ID].cost += item.COST;
          accum[item.EMPLOYEE_ID].quantity += item.QUANTITY;
        } else {
          accum[item.EMPLOYEE_ID] = {
            cost: item.COST,
            name: item.FULL_NAME || "Unknown Employee",
            quantity: item.QUANTITY,
          };
        }
      }

      return accum;
    }, {});

    this.setState({graphData: Object.values(graphData)});
  }

  yTickFormatter(val) {
    return toDollars(val, true);
  }

  render() {
    const {graphData = [], type} = this.state;

    return (
      <div className="bg-white rounded-md p-10 mt-4">
        <div className="font-semibold text-lg text-center">This Month's Waste</div>

        <ResponsiveContainer width="100%" height={250}>
          <BarChart
            width={500}
            height={300}
            data={graphData}
            margin={{
              top: 30,
              right: 30,
              left: 30,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />

            <XAxis
              dataKey={"name"}
              tick={{fontSize: "0.75rem", fontWeight: "bold"}}
              axisLine={{stroke: "#cccccc"}}
              tickMargin={10}
              tickLine={{stroke: "#cccccc"}}
              interval={"preserveStartEnd"}
            />

            <YAxis
              tick={{fontSize: "0.75rem", fontWeight: "bold"}}
              tickMargin={10}
              tickFormatter={this.yTickFormatter}
              axisLine={{stroke: "#cccccc"}}
              tickLine={{stroke: "#cccccc"}}
            />

            <Tooltip
              content={
                <SingleReportingTooltip
                  formatLabel={(payload) => {
                    return payload[`name`];
                  }}
                />
              }
              rows={[
                {
                  label: "Waste Cost",
                  formatValue: (payload) => {
                    return toDollars(payload["cost"], true);
                  },
                },
                {
                  label: "Quantity Wasted",
                  formatValue: (payload) => {
                    return formatNumberCommas(payload["quantity"]);
                  },
                },
              ]}
            />

            <Bar
              dataKey="cost"
              fill="#4e46e5"
              activeBar={<Rectangle fill="pink" stroke="blue" />}
            />
          </BarChart>
        </ResponsiveContainer>

        <FormSelect
          label="Group By"
          data={[
            {value: GROUP_BY_TYPES.WASTE_TYPE, label: "Waste Type"},
            {value: GROUP_BY_TYPES.EMPLOYEE, label: "Employee"},
            {value: GROUP_BY_TYPES.INGREDIENT, label: "Ingredient"},
          ]}
          value={type}
          onChange={(type) => this.setState({type})}
        />
      </div>
    );
  }
}

export default WasteGraph;
