import {Component} from "react";
import {SearchIcon} from "@heroicons/react/solid";
import {classNames} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";

class ReportingSearchBar extends Component {
  render() {
    let {className, placeholder} = this.props;

    return (
      <div className={classNames("mb-4 z-0", className)}>
        <div className="mt-1 flex rounded-md shadow-sm">
          <div className="relative flex items-stretch flex-grow">
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <SearchIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
            </div>
            <input
              type="email"
              name="email"
              id="email"
              className="focus:ring-indigo-500 focus:border-indigo-500 block w-full rounded-md pl-10 sm:text-sm border-gray-300"
              placeholder={placeholder}
              onChange={(e) => this.props.onChange(e.target.value)}
            />
          </div>
        </div>
      </div>
    );
  }
}
export default ReportingSearchBar;
