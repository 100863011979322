import React, {Component} from "react";
import {setupReduxConnection} from "../../../../redux";
import {Loading, PageHeadings, Table} from "@frostbyte-technologies/frostbyte-tailwind";
import {withRouter} from "../../../../utils/navigation";
import moment from "moment";
import {request} from "../../../../utils/request";
import CategoryDetailsModal from "../../../../modals/sales/categories/category-details-modal";
import {CATEGORY_ENABLES} from "../../../../utils/constants";

class CategoriesPage extends Component {
  state = {isLoading: true, categories: [], products: []};

  componentDidMount() {
    this.syncState();
  }

  componentDidUpdate(prevProps) {
    if (
      this.tableRef &&
      this.props.menu.products.length !== prevProps.menu.products.length
    ) {
      this.tableRef.resetTableData(this.props.menu.products);
    }
  }

  async syncState() {
    const categories = await request("categories", "GET");
    const products = await request("products", "GET");

    categories.sort((a, b) => {
      return (a.SEQ === 0 ? 1000000 : a.SEQ) - (b.SEQ === 0 ? 10000 : b.SEQ);
    });

    this.setState({isLoading: false, categories, products});
  }

  saveSequence() {
    const {categories} = this.state;

    request("categories/seq", "POST", {
      ITEMS: categories.map((item, i) => ({
        SEQ: item.SEQ,
        ID: item.ID,
      })),
    });
  }

  render() {
    const {isLoading, products, categories} = this.state;

    if (isLoading) {
      return <Loading />;
    }

    return (
      <div>
        <CategoryDetailsModal
          ref={(e) => (this.categoryModal = e)}
          addState={(item) => this.props.router.navigate("/category/" + item.UNIQUE_ID)}
          updateState={({category}) => {
            this.setState((prevState) => {
              const {categories} = prevState;

              const idx = categories.findIndex(
                (_category) => _category.ID === category.ID
              );

              if (idx !== -1) {
                categories.splice(idx, 1, category);
              }

              return {categories};
            });
          }}
        />

        <PageHeadings
          label="Categories"
          description="Create and view categories that will display on your order platforms"
          button={{
            label: "Create Category",
            onClick: () => this.categoryModal.open(),
          }}
        />

        <Table
          draggable
          onDrag={this.saveSequence.bind(this)}
          className="mt-4"
          data={categories}
          ref={(e) => {
            this.tableRef = e;
          }}
          actionButtons={[
            {
              label: "Quick Edit",
              onClick: (row) => this.categoryModal.open(row),
            },
            {
              label: "View",
              onClick: (row) => this.props.router.navigate("/category/" + row.UNIQUE_ID),
            },
          ]}
          columns={[
            {
              value: "NAME",
              label: "Name",
              format: (value, row) => {
                const categoryProducts = products.filter((item) => {
                  return item.CATEGORY_ID === row.ID;
                });

                return (
                  <div>
                    <div className="text-sm font-medium text-gray-900">{row.NAME}</div>

                    <div className="text-sm text-gray-500">
                      {categoryProducts.length} products
                    </div>
                  </div>
                );
              },
            },
            {
              value: "APP_ENABLED",
              label: "Enabled Platforms",
              format: (value, row) => {
                return Object.keys(CATEGORY_ENABLES)
                  .filter((item) => {
                    return row[item];
                  })
                  .map((item) => {
                    return CATEGORY_ENABLES[item];
                  })
                  .join(", ");
              },
            },
            {
              value: "AUTO_COMPLETE",
              label: "Auto Complete",
              format: (value) => {
                if (value) {
                  return "On";
                }
                return "Off";
              },
            },
            {
              value: "DATE_CREATED",
              label: "Created",
              mobile: "lg:table-cell",
              format: (value, row) => {
                return (
                  <div className="text-sm text-gray-500">
                    {moment(value).format("MM/DD/YY")}
                  </div>
                );
              },
            },
          ]}
        />
      </div>
    );
  }
}

export default setupReduxConnection(["menu"])(withRouter(CategoriesPage));
