import React, {Component} from "react";
import {Card, Tab} from "@frostbyte-technologies/frostbyte-tailwind";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

class CashAccountLandingPage extends Component {
  render() {
    let tabs = [
      {id: "payroll", label: "Full-Service Payroll"},
      {id: "benefits", label: "Employee Benefits"},
      {id: "onboarding", label: "Onboarding"},
    ];

    return (
      <Card className="w-full h-full p-6">
        <div>
          <div className={"lg:w-2/3 m-auto space-y-6"}>
            <div>
              <div className={"text-right w-32"}>
                <img
                  className=" h-auto rounded-lg"
                  src={require("../../../../assets/images/logo_type.png")}
                  alt="image description"
                />
                <div className="font-bold text-lg">Cash Account</div>
              </div>
            </div>

            <div className={"py-20 text-3xl font-semibold text-center"}>
              Coming Soon!
            </div>

            <div className="w-full flex flex-row items-center m-auto">
              <button
                type="button"
                className="m-auto space-x-4 inline-flex items-center px-4 py-2 border border-transparent text-sm font-bold rounded-md shadow-sm text-white bg-indigo-600"
              >
                Contact Support to Learn More!
              </button>
            </div>
          </div>
        </div>
      </Card>
    );
  }
}

export default CashAccountLandingPage;
