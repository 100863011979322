import {Component} from "react";
import PropTypes from "prop-types";
import {withRouter} from "../../utils/navigation";
import {
  getMessageContent,
  getReadReceiptStr,
  getTimeChange,
  getIsImage,
} from "../../utils/messaging/message-helper";
import MessageDate from "./message-date";
import {classNames} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";

class Message extends Component {
  state = {showReadReceipts: false};

  renderDate() {
    const {data, nextMessage} = this.props;

    const messageDelta = getTimeChange(data, nextMessage);

    return <MessageDate messageDelta={messageDelta} date={data.DATE_CREATED} />;
  }

  renderContent() {
    const {data, isSelf} = this.props;

    return (
      <button
        className={classNames(
          !!isSelf
            ? `bg-indigo-500 text-white active:bg-indigo-400`
            : `bg-gray-100 active:bg-gray-200`,
          "rounded-lg w-fit break-all",
          getIsImage(data) ? "p-1" : "px-3 py-1"
        )}
        onClick={() =>
          this.setState((prevState) => ({
            showReadReceipts: !prevState.showReadReceipts,
          }))
        }
      >
        {getMessageContent(data)}
      </button>
    );
  }

  renderReadReceipts() {
    const {readRecipients, unreadRecipients, isSelf, readEmployees, unreadEmployees} =
      this.props;

    const readReceiptStr = getReadReceiptStr(readRecipients, isSelf);

    return (
      <div
        className={`${
          isSelf ? "text-indigo-500" : "text-gray-500"
        } text-sm mb-1 hover:cursor-pointer`}
        onClick={() =>
          this.props.openReadReceipts(
            readRecipients,
            unreadRecipients,
            readEmployees,
            unreadEmployees
          )
        }
      >
        {readReceiptStr}
      </div>
    );
  }

  render() {
    const {showReadReceipts} = this.state;
    const {isSelf, data, nextMessage, isFirst} = this.props;

    const isTimeChange = getTimeChange(data, nextMessage) !== null;

    const newSender = data?.PARTICIPANT_ID !== nextMessage?.PARTICIPANT_ID;

    const showSenderName = (isTimeChange || isFirst || newSender) && !isSelf;

    return (
      <div className="flex flex-col-reverse">
        <div
          className={`${
            !!isSelf ? "items-end" : "items-start"
          } flex flex-col mx-4 mt-1.5`}
        >
          {showSenderName && (
            <div className="text-xs text-gray-500 mb-1 mt-1.5">{data.FULL_NAME}</div>
          )}

          {this.renderContent()}

          {showReadReceipts && this.renderReadReceipts()}
        </div>

        {!!isTimeChange && (
          <div
            className={`${
              isFirst && "mt-1"
            } flex justify-center items-center my-2 text-gray-500 text-sm`}
          >
            {this.renderDate()}
          </div>
        )}
      </div>
    );
  }
}

Message.propTypes = {
  data: PropTypes.string.isRequired,
};

export default withRouter(Message);
