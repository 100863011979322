import React, {Component} from "react";
import {withRouter} from "../../../utils/navigation";
import {Loading, Modal} from "@frostbyte-technologies/frostbyte-tailwind";
import {request, uploadImage} from "../../../utils/request";
import MessagePreview from "../../../components/messages/message-preview";
import {SearchIcon} from "@heroicons/react/solid";
import {
  addObjArrayElem,
  findConversation,
  getConversationImage,
  getConversationName,
  getIsUnread,
  getNewConversation,
  isNewConversation,
  removeObjArrayElem,
} from "../../../utils/messaging/message-helper";
import {setupReduxConnection} from "../../../redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ChatWindow from "../../../components/messages/chat-window";
import {showErrorNotification} from "../../../utils/notification-helper";
import DropdownSelect from "../../../components/form-elements/dropdown-select";
import {showLoadingConfirmAlert} from "../../../utils/alert-helper";
import ChatParticipantsModal from "../../../modals/chats/chat-participants-modal";
import ReadReceiptModal from "../../../modals/chats/read-receipt-modal";
import Fuse from "fuse.js";
import Guide from "../../../components/guide";
import {USER_GUIDES} from "../../../utils/constants";
import ChatFileModal from "../../../components/messages/chat-file-modal";

class MessagesPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      search: "",
      text: "",
      isLoadingMessages: true,
      selectedConversation: null,
      messages: [],
      isEditName: false,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const {pendingMessages, pendingUpserts} = this.props.chat;
    const {selectedConversation: conversation} = this.state;

    if (!conversation) {
      return;
    }

    if (pendingMessages.length > 0) {
      const chatMessages = pendingMessages.filter((item) => {
        return parseInt(item.CONVERSATION_ID) === conversation.ID;
      });

      this.setState({messages: [...chatMessages, ...this.state.messages]});

      this.props.markConversationRead(conversation.ID);
      this.props.updatePendingMessages([]);
    }

    if (pendingUpserts.length > 0) {
      const conversationIndex = pendingUpserts.findIndex((item) => {
        return parseInt(item.ID) === conversation.ID;
      });

      if (conversationIndex !== -1) {
        const upsertConversation = pendingUpserts[conversationIndex];

        this.setState({selectedConversation: upsertConversation});

        pendingUpserts.splice(conversationIndex, 1);

        this.props.updatePendingUpserts(pendingUpserts);
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const {pendingMessages, pendingUpserts} = this.props.chat;
    const {selectedConversation: conversation} = this.state;

    if (!conversation) {
      return;
    }

    if (pendingMessages.length > 0) {
      const chatMessages = pendingMessages.filter((item) => {
        return parseInt(item.CONVERSATION_ID) === conversation.ID;
      });

      this.setState({messages: [...chatMessages, ...this.state.messages]});

      this.props.markConversationRead(conversation.ID);
      this.props.updatePendingMessages([]);
      this.props.recalculatePending();
    }

    if (pendingUpserts.length > 0) {
      const conversationIndex = pendingUpserts.findIndex((item) => {
        return parseInt(item.ID) === conversation.ID;
      });

      if (conversationIndex !== -1) {
        const upsertConversation = pendingUpserts[conversationIndex];

        this.setState({selectedConversation: upsertConversation});

        pendingUpserts.splice(conversationIndex, 1);

        this.props.updatePendingUpserts(pendingUpserts);
        this.props.recalculatePending();
      }
    }
  }

  open() {
    this.modal.open();

    this.setInitialConversation();

    setTimeout(() => {
      const messageBody = document.querySelector("#messageBody");

      if (messageBody) {
        messageBody.scrollTop = messageBody.scrollHeight;
      }
    }, 150);
  }

  close() {
    this.modal.close();
  }

  setInitialConversation() {
    const {conversations} = this.props.chat;

    if (conversations.length > 0) {
      const firstConversation = conversations[0];

      this.loadMessages(firstConversation);

      if (firstConversation.ID) {
        this.props.markConversationRead(firstConversation.ID);
      }
    }

    this.setState({isLoadingMessages: false});
  }

  handleSubmitField = async (event) => {
    if (event.key === "Enter") {
      this.sendMessage();
    }
  };

  async leaveChat() {
    const {selectedConversation: conversation} = this.state;

    const toDeleteConversationId = conversation.ID;

    showLoadingConfirmAlert(
      "Leave Chat",
      "Are you sure you to leave this conversation. This action cannot be undone."
    ).then(async (close) => {
      try {
        await request("dashboard/conversation/" + toDeleteConversationId, "DELETE", {});

        close();

        const {conversations = []} = this.props.chat;

        for (let conversation of conversations) {
          if (conversation.ID !== toDeleteConversationId) {
            return this.loadMessages(conversation);
          }
        }
      } catch (err) {
        showErrorNotification(
          "Error Deleting Conversation",
          "Encountered an error deleting this conversation. Please refresh the page and try again."
        );
      } finally {
        return close();
      }

      close();
    });
  }

  async sendMessage() {
    const {text} = this.state;
    this.setState({text: ""});

    let {selectedConversation: conversation} = this.state;

    if (text.trim().length === 0) {
      return;
    }

    if (!conversation.ID) {
      const toFindConversation = findConversation(conversation);

      if (!toFindConversation) {
        const conversationPayload = {
          NAME: null,
          DEPARTMENT_IDS: conversation.DEPARTMENTS,
          EMPLOYEE_IDS: conversation.PARTICIPANTS.map(({EMPLOYEE_ID}) => EMPLOYEE_ID),
          LOCATION_IDS: conversation.LOCATIONS,
          ROLE_IDS: conversation.ROLES,
          MESSAGE: text,
        };

        let serverConversation;

        try {
          serverConversation = await request(
            "messaging/conversation/v2",
            "POST",
            conversationPayload
          );
        } catch (err) {
          return showErrorNotification(
            "Error Creating Conversation",
            "Please refresh the page and try again."
          );
        }

        conversation.ID = serverConversation.ID;

        this.props.upsertConversation(serverConversation);

        this.loadMessages(serverConversation);
      } else {
        this.loadMessages(toFindConversation);

        conversation = toFindConversation;
      }
    }

    if (conversation.ID === null) {
      return;
    }

    request("dashboard/message/" + conversation.ID, "POST", {
      TEXT: text,
    });
  }

  async loadMessages(conversation) {
    this.setState({
      isEditName: false,
      isLoadingMessages: true,
      selectedConversation: conversation,
    });

    if (!conversation.ID) {
      return;
    }

    let messages = [];

    try {
      messages = await request(
        "dashboard/messages/" + conversation.ID + "/10000000",
        "GET"
      );
    } catch (err) {
      showErrorNotification("Error Fetching Chats");

      return this.setState({isLoadingMessages: false, messages});
    }

    this.setState({isLoadingMessages: false, messages});

    let messageBody = document.querySelector("#messageBody");
    if (messageBody) {
      messageBody.scrollTop = messageBody.scrollHeight;
    }
  }

  async uploadImage(file) {
    const {selectedConversation: conversation} = this.state;

    //TODO confirm image before posting
    const endpoint = "dashboard/message/" + conversation.ID + "/link";

    try {
      await uploadImage(endpoint, file);
    } catch (err) {
      return showErrorNotification(
        "Error Uploading Image",
        "The image failed to upload. Please make sure you are selecting an image file type."
      );
    }
  }

  createMessage() {
    const {employee} = this.props.user;

    if (!employee || employee.ID === -1) {
      return showErrorNotification(
        "Create Chat Error",
        "No employee entry associated with this account. Please create an employee using your phone number before attempting to use this feature."
      );
    }

    const conversationPayload = getNewConversation();

    this.setState({selectedConversation: conversationPayload});

    this.participantsModal.open();
  }

  addConversationField(elem) {
    //TODO ADD A DICT HERE TO SIMPLIFY LOGIC, RETURN OBJECT FIELD AND USE PREV STATE TO SET
    let {selectedConversation: conversation} = this.state;

    switch (elem.type) {
      case "employees":
        conversation = addObjArrayElem(conversation, "PARTICIPANTS", elem);
        break;
      case "departments":
        conversation = addObjArrayElem(conversation, "DEPARTMENTS", elem);
        break;
      case "locations":
        conversation = addObjArrayElem(conversation, "LOCATIONS", elem);
        break;
      case "roles":
        conversation = addObjArrayElem(conversation, "ROLES", elem);
        break;
    }

    this.setState({selectedConversation: conversation});
  }

  addParticipant(employee) {
    let {selectedConversation: conversation} = this.state;

    if (
      conversation.PARTICIPANTS.find(
        ({ID, EMPLOYEE_ID}) => ID === employee.ID || EMPLOYEE_ID === employee.ID
      )
    ) {
      return;
    }

    const participantPayload = {
      EMPLOYEE_ID: employee.ID,
      CONVERSATION_ID: conversation.ID,
      DATE_CREATED: Date.now(),
      DATE_UPDATED: Date.now(),
      AUTO_ADDED: 0,
    };

    conversation.PARTICIPANTS = [...conversation.PARTICIPANTS, participantPayload];

    this.setState({selectedConversation: conversation});
  }

  removeConversationField(elem) {
    let {selectedConversation: conversation} = this.state;

    switch (elem.type) {
      case "employees":
        conversation = removeObjArrayElem(conversation, "PARTICIPANTS", elem);
        break;
      case "departments":
        conversation = removeObjArrayElem(conversation, "DEPARTMENTS", elem);
        break;
      case "locations":
        conversation = removeObjArrayElem(conversation, "LOCATIONS", elem);
        break;
      case "roles":
        conversation = removeObjArrayElem(conversation, "ROLES", elem);
        break;
    }

    this.setState({selectedConversation: conversation});
  }

  async removeParticipant(employee) {
    let {selectedConversation: conversation} = this.state;

    const toFindIndex = conversation.PARTICIPANTS.findIndex(
      ({EMPLOYEE_ID}) => EMPLOYEE_ID === employee.ID
    );

    conversation.PARTICIPANTS.splice(toFindIndex, 1);

    this.setState({selectedConversation: conversation});
  }

  renderConversationList() {
    let {conversations} = this.props.chat;
    let {search} = this.state;
    const {account} = this.props.user;
    const {NEW_MESSAGE} = this.props.user.userGuideEntries;

    if (search !== "") {
      const items = [
        ...conversations.map((_conversation) => {
          if (!_conversation) {
            return;
          }

          return {
            ..._conversation,
            name: getConversationName(account, _conversation),
          };
        }),
      ];

      const fuse = new Fuse(items, {
        keys: ["name"],
        useExtendedSearch: true,
        threshold: 0.1,
      });

      conversations = fuse.search(search).map(({item}) => item);
    }

    conversations = conversations.sort(
      (a, b) => b.DATE_LAST_MESSAGE - a.DATE_LAST_MESSAGE
    );

    return (
      <div
        style={{width: "350px", height: "65vh", "margin-left": "-5px"}}
        className="flex-none pr-2 scrollbar-hide"
      >
        <div className="flex text-md font-semibold flex-row justify-between items-center">
          <div className={"pb-3"}>Messages</div>

          <div>
            <FontAwesomeIcon
              onClick={() => this.createMessage()}
              icon={"plus-square"}
              className={"text-indigo-500 h-5 w-5 hover:cursor-pointer"}
              id={"newMessage"}
            />

            {conversations.length === 0 && !NEW_MESSAGE && (
              <Guide
                target={"#newMessage"}
                label={"Click the plus icon to get started with your first chat"}
                showCheckbox
                _key={USER_GUIDES.NEW_MESSAGE}
              />
            )}
          </div>
        </div>

        <div className="relative flex-1 bottom-0 pb-1">
          <div className="absolute top-2.5 left-0 pl-3 flex items-center pointer-events-none">
            <SearchIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
          </div>

          <input
            id="searchChat"
            type="text"
            name="searchChat"
            className="focus:ring-indigo-500 bg-gray-100 focus:border-indigo-500 focus:bg-white block w-full rounded-full pl-10 sm:text-sm border-gray-300"
            placeholder={"Search for Conversation"}
            onChange={(e) => this.setState({search: e.target.value})}
          />
        </div>

        <div style={{height: "57vh"}} className={"flex flex-1 flex-col overflow-auto"}>
          {conversations.map((_conversation) => {
            const isUnread = getIsUnread(_conversation);

            return (
              <div
                className="hover:bg-gray-200 bg-white hover:cursor-pointer rounded-sm"
                onClick={async () => {
                  this.loadMessages(_conversation);

                  if (_conversation.ID) {
                    this.props.markConversationRead(_conversation.ID);
                  }
                }}
              >
                <MessagePreview conversation={_conversation} isUnread={isUnread} />
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  renderBottom() {
    let {text, selectedConversation: conversation} = this.state;
    const {CHAT_MESSAGE} = this.props.user.userGuideEntries;

    return (
      <div className="flex flex-row bottom-0 items-center px-2">
        <FontAwesomeIcon
          icon={"plus-circle"}
          className={"text-indigo-500 h-6 w-6 px-2 hover:cursor-pointer"}
          onClick={() => this.fileModal.open()}
        />

        <div className="flex flex-1 justify-end px-2">
          <input
            className="flex flex-1 my-2 h-min rounded-full bg-gray-100 border-gray-50 focus:bg-white"
            type="text"
            placeholder={"Aa"}
            value={text}
            onChange={(e) => this.setState({text: e.target.value})}
            resize={"vertical"}
            onKeyDown={this.handleSubmitField}
            id="messageInput"
          ></input>

          {isNewConversation(conversation) && !CHAT_MESSAGE && (
            <Guide
              target={"#messageInput"}
              label={"Type a message here and press enter to start a conversation!"}
              showCheckbox
              _key={USER_GUIDES.CHAT_MESSAGE}
            />
          )}
        </div>
      </div>
    );
  }

  renderTop() {
    const {selectedConversation: conversation, isEditName} = this.state;
    const {account} = this.props.user;
    const {employees, roles, departments, locations} = this.props.shop;

    const conversationName = conversation
      ? getConversationName(account, conversation)
      : "";

    return (
      <div
        style={{height: "50px"}}
        className="flex flex-none flex-row items-center justify-between border-b-2"
      >
        <div className="flex flex-1 items-center">
          <div className="flex justify-center items-center px-2">
            {getConversationImage(account, conversation)}
          </div>

          {isEditName ? (
            <input
              ref={(e) => (this.chatNameRef = e)}
              className="flex flex-1 text-md font-medium h-min rounded-md border-transparent"
              style={{
                outline: "none",
                padding: "0px",
                "--tw-ring-color": "transparent",
              }}
              type="text"
              placeholder={"Conversation Name"}
              value={conversation.NAME}
              onChange={(e) => {
                conversation.NAME = e.target.value;

                this.setState({selectedConversation: conversation});
              }}
              resize={"vertical"}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  if (!!conversation.ID) {
                    request(
                      "messaging/conversations/" + conversation.ID + "/basic",
                      "PATCH",
                      {NAME: conversation.NAME}
                    );

                    this.setState({isEditName: false});
                  }
                }
              }}
            />
          ) : (
            <div className="text-md font-medium">{conversationName}</div>
          )}
        </div>

        <DropdownSelect
          icon={"cog"}
          label={"Options"}
          iconSize={30}
          options={[
            {
              label: "View Employees",
              func: () => this.participantsModal.open(conversation),
            },
            {
              label: "Edit Name",
              func: () =>
                this.setState({isEditName: true}, () => this.chatNameRef.select()),
            },
            {label: "Leave Chat", func: () => this.leaveChat()},
          ]}
        />
      </div>
    );
  }

  renderMessage() {
    let {selectedConversation, messages, isLoadingMessages} = this.state;
    const {employees} = this.props.shop;

    if (!selectedConversation?.ID) {
      messages = [];
    }

    return (
      <div style={{height: "65vh"}} className="flex flex-col flex-1 bg-white border">
        {this.renderTop()}

        <div
          id="messageBody"
          className="flex flex-1 justify-center items-center overflow-auto"
        >
          {!!isLoadingMessages ? (
            <Loading />
          ) : (
            <ChatWindow
              conversation={selectedConversation}
              employees={employees}
              messages={messages}
              openReadReceipts={(read, unread, readEmp, unreadEmp) =>
                this.readReceiptModal.open(read, unread, readEmp, unreadEmp)
              }
            />
          )}
        </div>

        {this.renderBottom()}
      </div>
    );
  }

  render() {
    const {selectedConversation: conversation} = this.state;
    const {employees} = this.props.shop;

    return (
      <div>
        <ChatParticipantsModal
          conversation={conversation}
          addParticipant={(employee) => this.addParticipant(employee)}
          employees={employees}
          removeParticipant={(employee) => this.removeParticipant(employee)}
          saveConversation={(conversation) => {
            this.setState({selectedConversation: conversation});
          }}
          ref={(e) => (this.participantsModal = e)}
        />

        <ReadReceiptModal
          employees={employees}
          ref={(e) => (this.readReceiptModal = e)}
        />

        <ChatFileModal conversation={conversation} ref={(e) => (this.fileModal = e)} />

        <Modal
          xlarge
          whiteBackground
          className={"bg-white"}
          closeLabel={"Close"}
          backgroundClose={false}
          ref={(e) => (this.modal = e)}
        >
          <div className="flex flex-row">
            {this.renderConversationList()}

            {this.renderMessage()}
          </div>
        </Modal>
      </div>
    );
  }
}

export default setupReduxConnection(["user", "chat", "shop"])(withRouter(MessagesPage));
