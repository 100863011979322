export const ACCOUNT_TYPES = {
  ASSET: {
    label: "Asset",
    value: "ASSET",
    tealValue: "asset",
  },
  LIABILITY: {
    label: "Liability",
    value: "LIABILITY",
    tealValue: "liability",
  },
  EQUITY: {
    label: "Equity",
    value: "EQUITY",
    tealValue: "equity",
  },
  REVENUE: {
    label: "Income",
    value: "REVENUE",
    tealValue: "revenue",
  },
  EXPENSE: {
    label: "Expense",
    value: "EXPENSE",
    tealValue: "expense",
  },
};

export const ACCOUNT_SUB_TYPES = {
  CURRENT_ASSETS: {
    label: "Current Assets",
    value: "CURRENT_ASSETS",
    tealValue: "current_assets",
    parent: ACCOUNT_TYPES.ASSET.value,
    index: 0,
  },
  NON_CURRENT_ASSETS: {
    label: "Non-Current Assets",
    value: "NON_CURRENT_ASSETS",
    tealValue: "non-current_assets",
    parent: ACCOUNT_TYPES.ASSET.value,
    index: 1,
  },
  TRANSFERS_BETWEEN_ACCOUNTS: {
    label: "Transfers Between Accounts",
    value: "TRANSFERS_BETWEEN_ACCOUNTS",
    tealValue: "transfers_between_accounts",
    index: 2,
  },
  UNCATEGORIZED_ASSETS: {
    label: "Uncategorized Assets",
    value: "UNCATEGORIZED_ASSETS",
    tealValue: "uncategorized_assets",
    parent: ACCOUNT_TYPES.ASSET.value,
    index: 3,
  },
  CURRENT_LIABILITIES: {
    label: "Current Liabilities",
    value: "CURRENT_LIABILITIES",
    tealValue: "current_liabilities",
    parent: ACCOUNT_TYPES.LIABILITY.value,
    index: 4,
  },
  NON_CURRENT_LIABILITIES: {
    label: "Non-Current Liabilities",
    value: "NON_CURRENT_LIABILITIES",
    tealValue: "non_current_liabilities",
    parent: ACCOUNT_TYPES.LIABILITY.value,
    index: 5,
  },
  EQUITY: {
    label: "Equity",
    value: "EQUITY",
    tealValue: "equity",
    parent: ACCOUNT_TYPES.EQUITY.value,
    index: 6,
  },
  OPERATING_REVENUES: {
    label: "Operating Income",
    value: "OPERATING_REVENUES",
    tealValue: "operating_revenues",
    parent: ACCOUNT_TYPES.REVENUE.value,
    index: 7,
  },
  OTHER_INCOME: {
    label: "Other Income",
    value: "OTHER_INCOME",
    tealValue: "other_income",
    parent: ACCOUNT_TYPES.REVENUE.value,
    index: 8,
  },
  COGS: {
    label: "Cost of Goods Sold",
    value: "COGS",
    tealValue: "cost_of_goods_sold",
    parent: ACCOUNT_TYPES.EXPENSE.value,
    index: 9,
  },
  OPERATING_EXPENSES: {
    label: "Operating Expenses",
    value: "OPERATING_EXPENSES",
    tealValue: "operating_expenses",
    parent: ACCOUNT_TYPES.EXPENSE.value,
    index: 10,
  },
  OTHER_EXPENSES: {
    label: "Other Expenses",
    value: "OTHER_EXPENSES",
    tealValue: "other_expenses",
    parent: ACCOUNT_TYPES.EXPENSE.value,
    index: 11,
  },
};

export const FINANCIAL_ACCOUNT_TYPES = {
  BANK_ACCOUNT: {
    label: "Bank Account",
    tealValue: "bank_account",
  },
  CREDIT_CARD: {
    label: "Credit Card",
    tealValue: "credit_card",
  },
  PAYMENTS: {
    label: "Payments",
    tealValue: "payments",
  },
  PAYROLL: {
    label: "Payroll",
    tealValue: "payroll",
  },
  LOAN: {
    label: "Loan",
    tealValue: "loan",
  },
  PREPAID_CARD: {
    label: "Prepaid Card",
    tealValue: "prepaid_card",
  },
  AR: {
    label: "Accounts Receivable",
    tealValue: "accounts_receivable",
  },
  AP: {
    label: "Accounts Payable",
    tealValue: "accounts_payable",
  },
};

export const SALES_ENTRY = {
  SALES: {
    label: "Sales",
    seq: 1,
  },
  RETURNS: {
    label: "Returns",
    seq: 2,
  },
  DISCOUNTS: {
    label: "Discounts",
    seq: 3,
  },
  GIFT_CARDS: {
    label: "Gift Cards Purchased",
    seq: 4,
  },
  TIPS_PAYABLE: {
    label: "Tips Payable",
    seq: 5,
  },
  SALES_TAX_PAYABLE: {
    label: "Sales Tax Payable",
    seq: 6,
  },
  DRIPOS_FEES: {
    label: "Dripos Merchant Processing Fees",
    seq: 7,
  },
  CASH_CLEARING: {
    label: "Cash on Hand",
    seq: 8,
  },
  DRIPOS_CLEARING: {
    label: "Dripos Clearing Account",
    seq: 9,
  },
  LOYALTY: {
    label: "Rewards",
    seq: 10,
  },
};

export const PAYROLL_ENTRY = {
  WAGES_AND_SALARIES: {
    label: "Wages and Salaries",
  },
  CONTRACTOR_PAYMENTS: {
    label: "Contractor Payments",
  },
  TIPS_PAYABLE: {
    label: "Tips Payable",
  },
  EMPLOYEE_BENEFITS: {
    label: "Employee Benefits",
  },
  REIMBURSEMENTS: {
    label: "Expense Reimbursements",
  },
  EMPLOYER_TAXES: {
    label: "Employer Taxes",
  },
};

export const DRIPOS_MONTHLY_ENTRY = {
  DRIPOS_MONTHLY_FEE: {
    label: "Dripos Monthly Fee",
  },
};
