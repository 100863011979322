import React, {Component} from "react";
import {
  Loading,
  TwoColumnList,
} from "@frostbyte-technologies/frostbyte-tailwind";
import {setupReduxConnection} from "../../../redux";
import {request} from "../../../utils/request";
import MarketingModal from "../../../modals/shop/shop-settings/marketing-modal";
import {getFullEmail} from "../../../utils/marketing-helper";
import {getFormattedPhone} from "@frostbyte-technologies/frostbyte-core/dist/helpers/phone-helper";

class MarketingTab extends Component {
  state = {companyName: null};

  componentDidMount() {
    request("location/website", "GET").then((websiteInfo) => {
      this.setState({companyName: websiteInfo.company.NAME});
    });
  }

  render() {
    const {companyName} = this.state;
    const {onlyEmail} = this.props;
    const {MARKETING_PHONE_NUMBER, EXPIRATION_DATE_FORMAT} =
      this.props.shop.companySettings;
    const {TEXT_MARKETING_OPT_IN} = this.props.shop.settings;

    const data = [
      {
        label: "Marketing Email",
        hint: "Company Setting",
        value: getFullEmail(companyName),
        tooltip: {
          data: [
            {
              label: "Marketing Email",
              data: "This is the email that your marketing emails will be sent from.",
            },
            {
              label: "Deliverability",
              data: 'There are some steps you can take with regards to your marketing email that can help your deliverability. Setting the User and the Display Names to an actual names instead of "marketing" or "info" will improve deliverability. Additionally, removing any dashes from your subdomain will also help reduce the chances your email ends up in spam.',
            },
          ],
        },
      },
    ];

    if (!onlyEmail) {
      data.push({
        label: "Marketing Phone Number",
        hint: "Company Setting",
        value: MARKETING_PHONE_NUMBER ? (
          getFormattedPhone(MARKETING_PHONE_NUMBER)
        ) : (
          <div>
            Please contact{" "}
            <a
              href={"mailto:support@dripos.com"}
              className="text-indigo-600 hover:text-indigo-900"
            >
              support@dripos.com
            </a>{" "}
            if you would like to set up a marketing phone number!
          </div>
        ),
        tooltip: {
          label: "Marketing Phone Number",
          data: "This is the phone number that you own that is set up for your account.",
        },
      });

      data.push({
        label: "Display Marketing Opt In",
        value: TEXT_MARKETING_OPT_IN,
        type: "bool",
        tooltip:
          "If set to yes, a brief message will ask patrons if they want to subscribe to marketing campaigns after checkout.",
      });

      data.push({
        label: "Show Time on Expiration Text",
        value: EXPIRATION_DATE_FORMAT === "MM/DD/YY hh:mm a" ? "1" : "0",
        type: "bool",
        tooltip:
          "If set to yes, coupons attached to text campaigns will show the expiration date and time in the text. Otherwise, only the date will be shown and not the time.",
      });
    }

    if (companyName === null) {
      return <Loading />;
    }

    return (
      <div>
        <MarketingModal
          ref={(e) => (this.marketingModal = e)}
          companyName={companyName}
          onlyEmail={onlyEmail}
        />

        <TwoColumnList
          label="Marketing"
          description="Configure settings for your marketing campaigns"
          data={data}
          buttons={[
            {
              label: "Edit",
              onClick: () => this.marketingModal.open(),
            },
          ]}
        />
      </div>
    );
  }
}

export default setupReduxConnection(["shop"])(MarketingTab);
