import React, {Component} from "react";
import {
  CELL_TYPES,
  INVENTORY_SALES_COLUMNS,
} from "../../features/reporting/reports/reporting-constants";
import FixedColumnReport from "../../features/reporting/reports/fixed-column-report";
import {PageHeadings} from "@frostbyte-technologies/frostbyte-tailwind";
import {setupReduxConnection} from "../../redux";

class InventorySalesReportPage extends Component {
  convertDataToRows = (reportData) => {
    let mappedModifiers = this.mapModifierSalesData(reportData);

    return mappedModifiers.map((entry) => ({
      label: entry.name,
      numbers: entry,
      style: entry.bold ? CELL_TYPES.BOLD : CELL_TYPES.SECONDARY,
    }));
  };

  mapModifierSalesData = (data) => {
    let finalData = [];

    data.sort((a, b) => b.LOGS.length - a.LOGS.length);

    for (let ingredients of data) {
      let {PRODUCT_NAME, LOGS} = ingredients;

      const soldAmount = LOGS.filter((item) => {
        return item.QUANTITY < 0;
      }).reduce((accum, item) => {
        return accum + Math.abs(item.QUANTITY);
      }, 0);

      const createdAmount = LOGS.filter((item) => {
        return item.QUANTITY > 0;
      }).reduce((accum, item) => {
        return accum + Math.abs(item.QUANTITY);
      }, 0);

      finalData.push({
        name: PRODUCT_NAME,
        removed: soldAmount || 0,
        added: createdAmount || 0,
        bold: true,
      });

      finalData.push(
        ...[
          "Rule Change",
          "Manual Change",
          "Button Change",
          "Product Expire",
          "Product Sold",
        ].map((item, index) => {
          const typeLogs = LOGS.filter((item) => {
            return item.TYPE === index;
          });

          const soldAmount = typeLogs
            .filter((item) => {
              return item.QUANTITY < 0;
            })
            .reduce((accum, item) => {
              return accum + Math.abs(item.QUANTITY);
            }, 0);

          const createdAmount = typeLogs
            .filter((item) => {
              return item.QUANTITY > 0;
            })
            .reduce((accum, item) => {
              return accum + Math.abs(item.QUANTITY);
            }, 0);

          return {
            name: item,
            removed: soldAmount || 0,
            added: createdAmount || 0,
          };
        })
      );
    }

    return finalData;
  };

  render() {
    const {REPORTING_SHOW_LOCATION_CSV, REPORTING_SHOW_DATE_CSV} =
      this.props.shop.settings;

    return (
      <div>
        <div className="mb-5">
          <PageHeadings label={"Inventory Sales Report"} />
        </div>

        <FixedColumnReport
          filenamePrefix="inventory-sales"
          endpoint="report/inventorysales"
          firstColLabel="Tracked Product"
          constantColumns={INVENTORY_SALES_COLUMNS}
          convertDataToRows={this.convertDataToRows}
          searchText={"Search Ingredients by Name"}
          showLocationsInDownload={REPORTING_SHOW_LOCATION_CSV === "1"}
          showDateRangeInDownload={REPORTING_SHOW_DATE_CSV === "1"}
        />
      </div>
    );
  }
}

export default setupReduxConnection(["shop"])(InventorySalesReportPage);
