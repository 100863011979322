import React, {Component} from "react";

class ActionButton extends Component {
  render() {
    const {onClick, label} = this.props;

    return (
      <button
        onClick={(event) => onClick && onClick(event)}
        className="text-indigo-600 hover:text-indigo-900 cursor-pointer"
      >
        {label}
      </button>
    );
  }
}

export default ActionButton;
