import React, {Component} from "react";
import {
  Table,
  Tab,
  PageHeadings,
  Card,
} from "@frostbyte-technologies/frostbyte-tailwind";
import {request} from "../../utils/request";
import moment from "moment";
import LoadingSpinner from "../../components/loading-spinner";
import DangerBadge from "../../components/badges/danger-badge";
import SuccessBadge from "../../components/badges/success-badge";
import GrayBadge from "../../components/badges/gray-badge";
import WarningBadge from "../../components/badges/warning-badge";
import PayrollSuccessModal from "../../modals/payroll/payroll-success-modal";
import {toDollars} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import {
  FlexibleWidthXYPlot,
  HorizontalGridLines,
  LineSeries,
  VerticalBarSeries,
  XAxis,
  YAxis,
} from "react-vis";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ImportTab from "./import-tab";
import BillingTab from "./billing-tab";
import {CheckIcon, XIcon} from "@heroicons/react/solid";
import PayrollRunErrorModal from "../../modals/payroll/terminal/payroll-run-error-modal";
import OffCyclesTab from "./off-cycles-tab";
import VoidTab from "./void-tab";
import RunLocations from "../../features/payroll/terminal/run-locations";
import ProcessingGraph from "../../features/payroll/terminal/processing-graph";

class PayrollSuccessPage extends Component {
  state = {
    holidayPaydays: null,
    totalProcessed: null,
    employeesPaidCount: null,
  };

  async componentDidMount() {
    this.refreshHolidayPaydays();
    this.refreshProcessed();
  }

  refreshHolidayPaydays = async () => {
    let holidayPaydays = await request("admin/holiday-paydays", "GET");
    this.setState({holidayPaydays});
  };

  refreshProcessed = async () => {
    let {TOTAL_PROCESSED, EMPLOYEES_PAID_TRAILING_MONTH_COUNT} = await request(
      "admin/dashboard/payroll/processed",
      "GET"
    );
    this.setState({
      totalProcessed: TOTAL_PROCESSED,
      employeesPaidCount: EMPLOYEES_PAID_TRAILING_MONTH_COUNT,
    });
  };

  render() {
    let tabs = [
      {id: "onboarding", label: "Locations"},
      {id: "off-cycle", label: "Off-Cycles"},
      {id: "billing", label: "Billing"},
      {id: "holiday", label: "Holiday Paydays"},
      {id: "void", label: "Void"},
      {id: "import", label: "Import"},
      {id: "graphs", label: "Graphs"},
    ];

    let {totalProcessed, employeesPaidCount} = this.state;

    return (
      <div className={"p-5 px-10"}>
        <div className={"flex flex-row text-bottom justify-between"}>
          <PageHeadings label={"Payroll Terminal"} />

          <div className={"flex flex-row space-x-6"}>
            <div className={"flex flex-row space-x-4"}>
              <div className={"flex flex-col text-right"}>
                <div className="text-xl font-semibold">
                  {employeesPaidCount}
                </div>
                <div className="font-bold text-sm">
                  Employees Paid (Trailing Month)
                </div>
              </div>
            </div>

            <div className={"flex flex-row space-x-4"}>
              <div className={"flex flex-col text-right"}>
                <div className="text-xl font-semibold">
                  {toDollars(totalProcessed, true)}
                </div>
                <div className="font-bold text-sm">Total Processed</div>
              </div>
            </div>
          </div>
        </div>

        <Tab data={tabs}>
          {(id) => {
            if (id === "import") {
              return <ImportTab />;
            }

            if (id === "billing") {
              return <BillingTab />;
            }

            if (id === "off-cycle") {
              return <OffCyclesTab />;
            }

            if (id === "void") {
              return <VoidTab />;
            }

            if (id === "onboarding") {
              return <RunLocations />;
            }

            if (id === "holiday") {
              let {holidayPaydays} = this.state;

              if (!holidayPaydays) {
                return <LoadingSpinner />;
              }

              holidayPaydays.sort(
                (a, b) =>
                  moment(a.payday).valueOf() - moment(b.payday).valueOf()
              );

              holidayPaydays = holidayPaydays.filter(
                (payday) => moment(payday.payday).diff(moment(), "days") < 30
              );

              return (
                <Table
                  columns={[
                    {
                      label: "Location Name",
                      value: "locationName",
                    },
                    {
                      label: "Period End",
                      format: (value, row) => {
                        return `${moment(row.period_end).format("dddd")}`;
                      },
                      width: 1,
                    },
                    {
                      label: "Approval Deadline",
                      format: (value, row) => {
                        return `${moment(row.approval_deadline).format(
                          "dddd"
                        )}`;
                      },
                      width: 1,
                    },
                    {
                      label: "Payday",
                      format: (value, row) => {
                        return `${moment(row.payday).format("dddd M/D")}`;
                      },
                      width: 1,
                    },
                    // {
                    //   label: "Handled",
                    //   format: (value, row) => {
                    //     return
                    //   },
                    //   width: 1
                    // }
                  ]}
                  data={holidayPaydays}
                />
              );
            }

            if (id === "graphs") {
              return <ProcessingGraph />;
            }
          }}
        </Tab>
      </div>
    );
  }
}

export default PayrollSuccessPage;
