import React, {Component} from "react";
import {
  FormSelect,
  Loading,
  Modal,
  Tab,
  Table,
} from "@frostbyte-technologies/frostbyte-tailwind";
import MessageDate from "../../components/messages/message-date";
import PropTypes from "prop-types";
import {withRouter} from "../../utils/navigation";
import {getObjectImage} from "@frostbyte-technologies/frostbyte-core/dist/helpers/asset-helper";
import {
  getChatEmployees,
  getParticipantActionButton,
} from "../../utils/messaging/message-helper";
import {HIT_ICON} from "../../layouts/admin/admin-search";
import Fuse from "fuse.js";
import {setupReduxConnection} from "../../redux";
import Select from "react-dropdown-select";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {classNames} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import {TYPE_LABEL} from "../../layouts/header/global-search";
import {request} from "../../utils/request";
import {
  showErrorNotification,
  showSuccessNotification,
} from "../../utils/notification-helper";
import {MILLI_DAY} from "../../utils/constants";
import LoadingSpinner from "../../components/loading-spinner";

class ChatParticipantsModal extends Component {
  state = {
    options: [],
    lastSearch: "",
    selectedLocations: [],
    selectedDepartments: [],
    selectedRoles: [],
  };

  constructor(props) {
    super(props);

    this.requestStack = [];
  }

  open(conversation = null) {
    if (conversation && conversation.ID) {
      this.setState({
        selectedLocations:
          conversation.LOCATIONS?.map(({LOCATION_ID}) => LOCATION_ID) ?? [],
        selectedDepartments:
          conversation.DEPARTMENTS?.map(({DEPARTMENT_ID}) => DEPARTMENT_ID) ??
          [],
        selectedRoles: conversation.ROLES?.map(({ROLE_ID}) => ROLE_ID) ?? [],
      });
    }

    setTimeout(() => this.modal.open(), 100);
  }

  close() {
    this.modal.close();
  }

  async addOnShift(date) {
    const {employees} = this.props.shop;

    try {
      const serverIDs = await request(
        "messaging/conversation/on-shift",
        "POST",
        {
          DATE: date,
        }
      );

      for (const employeeId of serverIDs) {
        const employee = employees.find(({ID}) => ID === employeeId);

        employee.ON_SHIFT = 1;

        this.props.addParticipant(employee);
      }

      return showSuccessNotification(
        "Added " + serverIDs.length + " On Shift Employees",
        "To keep your changes, please press the Save button"
      );
    } catch (err) {
      if (err.error === "EMPLOYEE_UNDERFLOW") {
        return showSuccessNotification("No On Shift Employees Found");
      }

      return showErrorNotification("Problem adding On Shift Employees");
    }
  }

  async handleFormSubmit() {
    const {conversation} = this.props;
    const {selectedLocations, selectedDepartments, selectedRoles} = this.state;

    let conversationPayload = {
      ...conversation,
      LOCATIONS: selectedLocations,
      DEPARTMENTS: selectedDepartments,
      ROLES: selectedRoles,
    };

    if (!conversation.ID) {
      this.props.saveConversation(conversationPayload);

      return this.modal.close();
    }

    try {
      await request("messaging/conversations/" + conversation.ID, "PATCH", {
        NAME: conversationPayload.NAME ?? null,
        DEPARTMENT_IDS: conversationPayload.DEPARTMENTS.map((ID) => ID),
        EMPLOYEE_IDS: conversationPayload.PARTICIPANTS.map(
          ({EMPLOYEE_ID}) => EMPLOYEE_ID
        ),
        LOCATION_IDS: conversationPayload.LOCATIONS.map((ID) => ID),
        ROLE_IDS: conversationPayload.ROLES.map((ID) => ID),
      });
    } catch (err) {
      this.modal.close();

      return showErrorNotification(
        "Error Updating Chat Members",
        "We encountered an error trying to update chat members. Please refresh and try again."
      );
    }

    this.modal.close();
  }

  search(search, ignoreStack) {
    let {conversation} = this.props;
    const {employees = []} = this.props.shop;
    let {lastSearch, options} = this.state;

    if (this.requestStack.length > 1) return;

    if (lastSearch === search) {
      this.requestStack.shift();

      if (this.requestStack.length > 0) {
        this.requestStack = [this.requestStack.pop()];

        this.requestStack[0]();
      }

      return options;
    }

    if (search === "") {
      return this.setState({lastSearch: search, options: []}, () => {
        this.requestStack.shift();

        if (this.requestStack.length > 0) {
          this.requestStack = [this.requestStack.pop()];

          this.requestStack[0]();
        }
      });
    }

    if (this.selectRef) {
      this.selectRef.state.cursor = 0;
    }

    const items = [
      ...employees.map((item) => {
        if (
          conversation.PARTICIPANTS.findIndex(
            ({ID, EMPLOYEE_ID}) => ID === item.ID || EMPLOYEE_ID === item.ID
          ) !== -1
        ) {
          return;
        }

        return {
          ...item,
          type: "employees",
          name: item.FULL_NAME,
          icon: HIT_ICON["employees"],
        };
      }),
    ];

    options = new Fuse(items, {
      keys: ["name"],
      useExtendedSearch: true,
      threshold: 0.1,
    })
      .search(search)
      .map(({item}) => item);

    this.setState(
      {
        lastSearch: search,
        options,
      },
      () => {
        this.requestStack.shift();

        if (this.requestStack.length > 0) {
          this.requestStack = [this.requestStack.pop()];

          this.requestStack[0]();
        }
      }
    );
  }

  renderTop() {
    const {lastSearch, options} = this.state;

    return (
      <div className={"flex flex-1 w-full mt-4"}>
        <div
          className={"flex flex-none text-sm font-semibold text-gray-500 mr-1"}
        >
          {"Add Participant:"}
        </div>

        <div className="max-w-lg w-full lg:max-w-xs">
          <label htmlFor="search" className="sr-only">
            Search
          </label>

          <Select
            noDataRenderer={() => {
              if (lastSearch === "") {
                return (
                  <div className="text-center p-6 text-sm">
                    <h3 className="mt-2 text-sm font-medium text-gray-900">
                      Type to start searching
                    </h3>

                    <p className="mt-1 text-sm text-gray-500">
                      Type the name of an employee to add a participant to the
                      conversation
                    </p>
                  </div>
                );
              }

              return (
                <div className="text-center p-6 text-sm">
                  <FontAwesomeIcon
                    icon="cloud-question"
                    className="mx-auto h-12 w-12 text-gray-400"
                  />

                  <h3 className="mt-2 text-sm font-medium text-gray-900">
                    No records found
                  </h3>

                  <p className="mt-1 text-sm text-gray-500">
                    We could not find anything with the search you inputted.
                  </p>
                </div>
              );
            }}
            className="global-search block w-full z-20"
            onDropdownClose={() => this.setState({lastSearch: ""})}
            dropdownHandleRenderer={() => {
              return <div />;
            }}
            ref={(e) => (this.selectRef = e)}
            onChange={(obj) => {
              if (obj?.length === 0) {
                return;
              }

              this.props.addParticipant(obj[0]);
            }}
            inputRenderer={({state, methods, inputRef}) => {
              return (
                <form
                  className="flex flex-1 relative w-full flex flex-row justify-start"
                  onSubmit={(e) => {
                    e.preventDefault();
                  }}
                >
                  <input
                    className="w-full border-transparent rounded-md bg-white sm:text-sm"
                    style={{
                      outline: "none",
                      padding: "0px",
                      "--tw-ring-color": "transparent",
                    }}
                    onChange={methods.setSearch}
                    value={state.search}
                    autoComplete="off"
                    type="search"
                    ref={inputRef}
                    placeholder={"Type an employee name to search"}
                    id={"participant-search"}
                  />
                </form>
              );
            }}
            itemRenderer={({item, state, itemIndex, methods}) => {
              const isSelected = state.cursor === itemIndex;
              const {name, icon, type, extra} = item;

              return (
                <div
                  onClick={() => methods.addItem(item)}
                  className={classNames(
                    isSelected && "bg-indigo-500 text-white",
                    "group px-4 py-2 hover:bg-indigo-500 hover:text-white flex flex-row items-center"
                  )}
                >
                  {icon && (
                    <div className="mr-3">
                      <FontAwesomeIcon icon={icon} className="h-6 w-6 mt-1" />
                    </div>
                  )}

                  <div className="flex flex-col flex-1 text-sm">
                    <div className="font-medium">{name}</div>

                    <div
                      className={classNames(
                        isSelected
                          ? "text-white"
                          : "text-gray-500 group-hover:text-white",
                        "text-sm"
                      )}
                    >
                      {TYPE_LABEL[type]}
                    </div>
                  </div>
                </div>
              );
            }}
            searchFn={({state}) => {
              const {search} = state;

              this.search(search);

              return options;
            }}
          />
        </div>
      </div>
    );
  }

  render() {
    const {conversation, employees} = this.props;
    const {employee} = this.props.user;
    const {locations, departments, roles} = this.props.shop;
    const {selectedLocations, selectedDepartments, selectedRoles} = this.state;

    if (!conversation) {
      return <div></div>;
    }

    const employeeData = getChatEmployees(conversation.PARTICIPANTS, employees);

    return (
      <Modal
        className={"overflow-auto"}
        large
        whiteBackground
        hideClose
        buttonLabel={"Save"}
        buttonOnClick={() => this.handleFormSubmit()}
        ref={(e) => (this.modal = e)}
      >
        <Tab
          className={"-mt-4"}
          data={[
            {label: "Participants", id: "participants"},
            {label: "Auto Rules", id: "autoRules"},
          ]}
        >
          {(id) => {
            if (id === "participants") {
              return (
                <>
                  {this.renderTop()}

                  <div
                    className={"overflow-scroll"}
                    style={{maxHeight: "55vh"}}
                  >
                    <Table
                      data={employeeData}
                      columns={[
                        {
                          value: "FULL_NAME",
                          label: "NAME",
                          format: (value, row) => {
                            return (
                              <div className="flex items-center">
                                <div className="h-10 w-10 flex-shrink-0">
                                  <img
                                    className="h-10 w-10 rounded-full"
                                    src={getObjectImage(
                                      row,
                                      "LOGO",
                                      "https://dripos-assets.s3.amazonaws.com/default-product-specials.jpg"
                                    )}
                                    alt=""
                                  />
                                </div>

                                <div className="ml-4">
                                  <div className="text-sm font-medium text-gray-900">
                                    {row.FULL_NAME}
                                  </div>

                                  <div className="text-sm text-gray-500">
                                    {row.ROLES?.map(
                                      (item) => item.ROLE_NAME
                                    ).join(", ")}
                                  </div>
                                </div>
                              </div>
                            );
                          },
                        },
                        {
                          value: "LOCATION_NAME",
                          label: "HOME LOCATION",
                        },
                        {
                          value: "ACTION_BUTTON",
                          label: "Remove",
                          format: (value, row) => {
                            const {label, disabled} =
                              getParticipantActionButton(row);

                            return (
                              <div
                                className={classNames(
                                  disabled
                                    ? "text-gray-500"
                                    : "text-indigo-600 font-semibold"
                                )}
                                onClick={(row) => {
                                  if (!disabled) {
                                    return this.props.removeParticipant(row);
                                  }
                                }}
                              >
                                {label}
                              </div>
                            );
                          },
                        },
                      ]}
                      ref={(e) => (this.tableRef = e)}
                    />
                  </div>
                </>
              );
            }

            if (id === "autoRules") {
              return (
                <div className={"text-sm"}>
                  <div className={"mt-5 font-semibold text-gray-700"}>
                    Quick Rules
                  </div>

                  <div
                    className={
                      "my-1 flex flex-row items-center justify-between"
                    }
                  >
                    <div>Everyone Working Today</div>

                    <button
                      className={
                        "flex flex-none rounded-md border border-transparent bg-indigo-600 px-3 py-1 text-sm font-medium text-white shadow-sm hover:bg-indigo-700"
                      }
                      onClick={() => {
                        this.addOnShift(Date.now());
                      }}
                    >
                      Add
                    </button>
                  </div>

                  <div
                    className={
                      "my-1 flex flex-row items-center justify-between"
                    }
                  >
                    <div>Everyone Working Tomorrow</div>

                    <button
                      type={"submit"}
                      className={
                        "rounded-md border border-transparent bg-indigo-600 px-3 py-1 text-sm font-medium text-white shadow-sm hover:bg-indigo-700"
                      }
                      onClick={() => {
                        this.addOnShift(Date.now() + MILLI_DAY);
                      }}
                    >
                      Add
                    </button>
                  </div>

                  <div className={"mt-5 mb-2 font-semibold text-gray-700"}>
                    Auto Add Rules
                  </div>

                  <FormSelect
                    multi
                    data={locations.map(({ID, NAME}) => ({
                      value: ID,
                      label: NAME,
                    }))}
                    value={selectedLocations}
                    label={"Location"}
                    onChange={(selections) => {
                      this.setState({selectedLocations: selections});
                    }}
                  />

                  <FormSelect
                    multi
                    data={departments.map(({ID, NAME}) => ({
                      value: ID,
                      label: NAME,
                    }))}
                    value={selectedDepartments}
                    label={"Department"}
                    onChange={(selections) => {
                      this.setState({selectedDepartments: selections});
                    }}
                  />

                  <FormSelect
                    multi
                    data={roles.map(({ID, NAME}) => ({
                      value: ID,
                      label: NAME,
                    }))}
                    value={selectedRoles}
                    label={"Role"}
                    onChange={(selections) => {
                      this.setState({selectedRoles: selections});
                    }}
                  />
                </div>
              );
            }
          }}
        </Tab>
      </Modal>
    );
  }
}

MessageDate.propTypes = {
  employees: PropTypes.array.isRequired,
  conversation: PropTypes.object.isRequired,
  removeParticipant: PropTypes.func.isRequired,
};

export default setupReduxConnection(["user", "shop"])(
  withRouter(ChatParticipantsModal)
);
