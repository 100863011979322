import React, {Component} from "react";
import ComboBox from "../../components/combobox";
import {request} from "../../utils/request";
import PropTypes from "prop-types";

class PresetDropdown extends Component {
  state = {data: []};

  componentDidMount() {
    this.syncData().catch((err) => {
      console.log("Problem fetching employee data", err);
    });
  }

  async syncData() {
    const {type, excludeType, exclude = []} = this.props;

    const serverModifiers = await request("modifiers/presets", "GET");
    const data = serverModifiers
      .map((item) => {
        return {
          id: item.ID,
          type: item.TYPE,
          label: item.INTERNAL_NAME
            ? `${item.NAME} - (${item.INTERNAL_NAME})`
            : item.NAME,
        };
      })
      .filter((item) => {
        if (type) {
          return item.type === type;
        }

        if (excludeType) {
          return item.type !== excludeType;
        }

        return !exclude.includes(item.ID);
      });

    data.sort((a, b) => a.label.localeCompare(b.label));

    this.setState({data});
  }

  render() {
    const {exclude = []} = this.props;
    const {data} = this.state;

    return (
      <ComboBox
        {...this.props}
        disabled={data.length === 0}
        placeholder={
          data.length === 0
            ? "No pre-made pricing modifiers have been created."
            : "Click to select modifier"
        }
        label="Modifier"
        data={data.filter((item) => {
          return !exclude.includes(item.id);
        })}
      />
    );
  }
}

PresetDropdown.propTypes = {
  type: PropTypes.string,
};

export default PresetDropdown;
