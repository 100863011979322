import React, {Component} from "react";
import {PageHeadings, Table} from "@frostbyte-technologies/frostbyte-tailwind";
import FormDateTimeSelect from "../../components/form-date-time-select";
import {request} from "../../utils/request";
import moment from "moment-timezone";
import {
  classNames,
  decimalToDollars,
  toDollars,
} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";

class BalanceSheetPage extends Component {
  state = {date: null, report: null};

  async generateReport() {
    const {date} = this.state;

    const report = await request("accounting/reports/balance-sheet", "GET", {
      END_DATE: moment(date).format("YYYY-MM-DD"),
    });

    this.setState({report});
  }

  renderRow({name, total_amount, sort_code, section}, total = false) {
    let displayStr = name;

    if (sort_code) {
      displayStr = `${sort_code} - ` + displayStr;
    }

    if (total) {
      displayStr = `Total ` + displayStr;
    }

    return (
      <div className={classNames("flex flex-row justify-between")}>
        <div className={classNames(section && "font-bold")}>{displayStr}</div>
        <div className={classNames(section && "font-bold")}>
          {toDollars(total_amount, true)}
        </div>
      </div>
    );
  }

  renderChildren(row, ignoreText = false) {
    return (
      <div className={classNames("pl-4 space-y-1", !row.sort_code && "py-1")}>
        {!ignoreText && this.renderRow(row)}

        {row.children?.map((_child) => this.renderChildren(_child))}

        {!ignoreText && !row.sort_code && this.renderRow(row, true)}
      </div>
    );
  }

  getRows({children, name, total_amount}) {
    const toReturn = [];

    for (const {name, children: childChildren, total_amount} of children) {
      toReturn.push({
        name,
        amount: total_amount,
      });

      toReturn.push(
        ...childChildren
          .filter(({total_amount}) => total_amount > 0)
          .map(({name, total_amount}) => ({
            name,
            amount: total_amount,
            type: "secondary",
          }))
      );
    }

    toReturn.push({
      name: `${name} Total`,
      amount: total_amount,
      type: "bold",
    });

    return toReturn;
  }

  renderBalanceSheetV2() {
    const {date, report} = this.state;

    return (
      <div className={"mt-5"}>
        <div className={"space-y-2"}>
          {report.records.map((_record) => (
            <div className={"bg-white rounded-sm shadow-sm"}>
              <div className={"ml-5 py-4 font-semibold text-md"}>
                {_record.name}
              </div>

              <Table
                data={this.getRows(_record)}
                columns={[
                  {
                    label: "",
                    value: "name",
                    format: (val, {type}) => (
                      <div
                        className={classNames(
                          "text-black",
                          type === "secondary" && "pl-5",
                          type === "bold" && "font-semibold"
                        )}
                      >
                        {val}
                      </div>
                    ),
                  },
                  {
                    label: `${moment(date).format("M/D")}`,
                    value: "amount",
                    width: 1,
                    format: (val, {type}) => (
                      <div
                        className={classNames(
                          "text-black float-right",
                          type === "bold" && "font-semibold"
                        )}
                      >
                        {toDollars(decimalToDollars(val + ""), true)}
                      </div>
                    ),
                  },
                ]}
              />
            </div>
          ))}
        </div>

        <div
          className={
            "bg-white rounded-sm shadow-sm mt-2 pl-5 pr-6 py-2 text-sm flex flex-row justify-between font-semibold"
          }
        >
          <div>Liability & Equity Total</div>
          <div>
            $
            {toDollars(
              Math.round(
                report.records[1].total_amount * 100 +
                  report.records[2].total_amount * 100
              )
            )}
          </div>
        </div>
      </div>
    );
  }

  render() {
    const {date, report} = this.state;

    return (
      <div>
        <PageHeadings label={"Balance Sheet"} />

        <FormDateTimeSelect
          label="As of Date"
          hideTime={true}
          value={date}
          onChange={(date) =>
            this.setState({date}, () => this.generateReport())
          }
        />

        {/*{report && this.renderBalanceSheet()}*/}
        {report && this.renderBalanceSheetV2()}
      </div>
    );
  }
}

export default BalanceSheetPage;

const LAYERS = [
  {
    className: "space-y-2 pb-2",
  },
  {
    className: "space-y-1 pt-1",
  },
];
