import React, {Component} from "react";
import {
    PageHeadings,
    Tab,
    TwoColumnList,
} from "@frostbyte-technologies/frostbyte-tailwind";
import {withRouter} from "../../../../utils/navigation";
import {showSuccessNotification} from "../../../../utils/notification-helper";
import EditWhiteLabelInformationModal from "../../../../modals/admin/tools/edit-branded-app-information-modal";
import {setupReduxConnection} from "../../../../redux";
import {getObjectImage} from "@frostbyte-technologies/frostbyte-core/dist/helpers/asset-helper";
import EditWhiteLabelImageIconModal from "../../../../modals/admin/tools/edit-branded-app-image-icon-modal";
import {showConfirmAlert} from "../../../../utils/alert-helper";
import {saveAs} from "file-saver";
import BrandedAppBadge from "../../../../features/admin/branded/branded-app-badge";
import {BrandedAppRequests} from "../../../../utils/request-helpers/admin/branded-app-requests";
import {CardEditor} from "./cardEditor";
import {Col} from "../../../operations/supply-chain/shared";

class BrandedAppPage extends Component {
    state = {
        brandedApp: {},
        cards: [],
    };

    async componentDidMount() {
        await this.syncState();
    }

    syncState = async () => {
        const brandedApp = await BrandedAppRequests.fetch(this.props.router.params.UNIQUE_ID);
        const cards = await BrandedAppRequests.fetchBrandedAppCards(this.props.router.params.UNIQUE_ID);

        this.setState({brandedApp, cards});
    };

    renderInformation() {
        const {
            APP_NAME,
            APP_SUBTITLE,
            APP_DESCRIPTION,
            KEYWORDS,
            MARKETING_URL,
            SUPPORT_URL,
        } = this.state.brandedApp;

        return (
            <TwoColumnList
                button={{
                    label: "Edit",
                    onClick: () => this.informationModal.open(this.state.brandedApp),
                }}
                label={"App Information"}
                data={[
                    {label: "Name", value: APP_NAME},
                    {label: "Subtitle", value: APP_SUBTITLE},
                    {label: "Description", value: APP_DESCRIPTION},
                    {label: "Keywords", value: KEYWORDS},
                    {label: "Marketing URL", value: MARKETING_URL},
                    {label: "Support URL", value: SUPPORT_URL},
                ]}
            />
        );
    }

    renderIcons() {
        const {brandedApp} = this.state;

        return (
            <TwoColumnList
                button={{
                    label: "Edit",
                    onClick: () => this.iconModal.open(brandedApp),
                }}
                label={"App Icons"}
                data={[
                    {
                        label: "App Icon",
                        value: (
                            <img
                                style={{borderColor: "black", border: 1, width: 200, height: 200}}
                                className={"bg-grid"}
                                src={getObjectImage(brandedApp, "APP_ICON", "appicon.png")}
                                alt=""
                            />
                        ),
                    },
                    {
                        label: "Landing Icon",
                        value: (
                            <img
                                style={{borderColor: "black", border: 1, width: 200, height: 200}}
                                src={getObjectImage(brandedApp, "LANDING_ICON", "appicon.png")}
                                alt=""
                            />
                        ),
                    },
                    {
                        label: "Landing Screen Hex (include #)",
                        value: brandedApp.LANDING_HEX,
                    },
                ]}
            />
        );
    }

    renderCards() {
        const {brandedApp, cards} = this.state;
        return (
            <Col>
                {[{ID: null, SEQ: 0},...cards].sort((a, b) => a.SEQ - b.SEQ).map((card) =>
                    <CardEditor
                        brandedAppUniqueId={brandedApp.UNIQUE_ID}
                        card={card}
                        syncState={this.syncState}
                    />)}
            </Col>
        )
    }

    getActions() {
        const {brandedApp} = this.state;

        const confirmAction = {
            label: "Confirm App Information & iOS/Android Listings",
            onClick: async () => {
                await showConfirmAlert(
                    "Confirm Listings",
                    "Are you sure App Store Connect (iOS app/Apple Pay) and Google Play Console is setup according to the Notion guide?"
                ).then(async (res) => {
                    await BrandedAppRequests.build(brandedApp.UNIQUE_ID);

                    await this.syncState();
                    showSuccessNotification(
                        `${brandedApp.APP_NAME} is building`,
                        "Everything will be ready for you in ~20 minutes, and we'll keep you updated over Slack."
                    );
                });
            },
        };

        const downloadAndroidAction = {
            label: "Download Android AAB",
            onClick: async () => {
                const signedUrl = await BrandedAppRequests.fetchAndroidDownloadList(brandedApp.UNIQUE_ID);

                saveAs(signedUrl, brandedApp.PACKAGE_IDENTIFIER);
            },
        };

        const liveAction = {
            label: "Mark as Live",
            onClick: async () => {
                await BrandedAppRequests.upsertBrandedApp(brandedApp.UNIQUE_ID, {STATUS: "LIVE"});

                await this.syncState();
            },
        };

        const actions = [];

        if (brandedApp && ["DRAFT", "FAILED"].includes(brandedApp.STATUS)) {
            return [confirmAction];
        }

        if (brandedApp && brandedApp.STATUS === "READY_FOR_SUBMIT") {
            return [downloadAndroidAction, liveAction];
        }

        return actions;
    }

    render() {
        const {brandedApp} = this.state;

        return (
            <div className={"p-6"}>
                <EditWhiteLabelInformationModal
                    syncState={this.syncState}
                    ref={(e) => (this.informationModal = e)}
                />

                <EditWhiteLabelImageIconModal
                    syncState={this.syncState}
                    ref={(e) => (this.iconModal = e)}
                />

                <PageHeadings label={"Branded App"} buttons={this.getActions()}/>

                <TwoColumnList
                    label={"App Status"}
                    data={[
                        {
                            label: "Package",
                            value: `co.frostbyte.${brandedApp?.PACKAGE_IDENTIFIER}`,
                        },
                        {
                            label: "Status",
                            value: <BrandedAppBadge status={brandedApp.STATUS}/>,
                        },
                    ]}
                />

                <Tab
                    data={[
                        {label: "Information", id: "information"},
                        {label: "Icons", id: "icons"},
                        {label: "Cards", id: "cards"},
                    ]}
                >
                    {(id) => {
                        switch (id) {
                            case "information":
                                return this.renderInformation();
                            case "icons":
                                return this.renderIcons();
                            case "cards":
                                return this.renderCards();
                            default:
                                return <></>;
                        }
                    }}
                </Tab>
            </div>
        );
    }
}

export default setupReduxConnection(["admin"])(withRouter(BrandedAppPage));
