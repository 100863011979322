import React, {Component} from "react";

class Checkbox extends Component {
  render() {
    let {
      label,
      name,
      error,
      formikOptions = null,
      onChange,
      value,
      checked,
        textColor = null,
    } = this.props;

    return (
      <div {...this.props}>
        <input
          className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-indigo-600 transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
          type="checkbox"
          checked={
            checked || (formikOptions ? formikOptions?.values[name] : value)
          }
          name={name}
          onChange={formikOptions ? formikOptions.handleChange : onChange}
          value={formikOptions ? formikOptions.values[name] : value}
        />

        <label
          className={ `form-check-label inline-block ${textColor ? "" : "text-gray-800"}` }
          style={{color: textColor ? textColor : "#4e46e5"}}
          htmlFor="flexCheckDefault"
        >
          {label}
        </label>

        <span style={{color: "red"}}>
          {formikOptions
            ? formikOptions.touched[name] && formikOptions.errors[name]
            : error}
        </span>
      </div>
    );
  }
}

export default Checkbox;
