import React, {Component, Fragment} from "react";
import {Menu, Transition} from "@headlessui/react";
import {
  CheckIcon,
  ChevronDownIcon,
  ChevronUpIcon,
} from "@heroicons/react/solid";
import {classNames} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import {ChevronUp} from "react-feather";
import moment from "moment";
import {TIME_PERIODS} from "./main-blocks/profit-loss-block";

class TimespanDropdown extends Component {
  state = {currentValue: this.props.defaultValue};

  render() {
    let {data, label, className, value, onChange} = this.props;
    let {currentValue} = this.state;

    data = Object.keys(TIME_PERIODS).map((_key) => ({
      label: TIME_PERIODS[_key].label,
      value: _key,
    }));

    return (
      <div
        className={classNames(
          className,
          "block text-sm font-medium text-gray-700"
        )}
      >
        <Menu as="div" className="relative inline-block text-left z-2">
          {({open}) => {
            return (
              <div>
                <div>
                  <Menu.Button className={"flex flex-row cursor-pointer"}>
                    <div>{TIME_PERIODS[value]?.label ?? ""}</div>
                    {!open && (
                      <ChevronDownIcon
                        className="-mr-1 ml-2 h-5 w-5"
                        aria-hidden="true"
                      />
                    )}
                    {open && (
                      <ChevronUpIcon
                        className="-mr-1 ml-2 h-5 w-5"
                        aria-hidden="true"
                      />
                    )}{" "}
                  </Menu.Button>
                </div>

                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="origin-top-left absolute right-0 mt-2 w-40 rounded-sm shadow-md bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-10">
                    <div>
                      {data.map((item) => (
                        <Menu.Item
                          className={`cursor-pointer hover:bg-indigo-500 hover:text-white flex flex-row py-1 text-sm hover:bg-gray-100 items-center ${
                            currentValue === item.value ? "bg-gray-100" : ""
                          }`}
                          onClick={() => onChange(item.value)}
                        >
                          <div>
                            <div className="w-8">
                              {currentValue === item.value && (
                                <CheckIcon className="ml-2 h-4 w-4 text-purple-600" />
                              )}
                            </div>
                            <span>{item.label}</span>
                          </div>
                        </Menu.Item>
                      ))}
                    </div>
                  </Menu.Items>
                </Transition>
              </div>
            );
          }}
        </Menu>
      </div>
    );
  }
}

export default TimespanDropdown;
