import {request} from "../../request";

export const ReportingRequests = {
  fetchCogsReport: async (params) => {
    return request("report/cogs", "POST", params);
  },
  fetchIngredientUsageReport: async (params) => {
    return request("report/ingredient-usage", "POST", params);
  },
};
