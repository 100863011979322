import {FormSelect} from "@frostbyte-technologies/frostbyte-tailwind";
import React from "react";
import {Field} from "formik";

export default function NotificationForm({formikOptions, isSingleShift}) {
  const EMPLOYEE_WITH_CHANGED_SHIFT = 4;
  const ALL_EMPLOYEES_AT_LOCATION = 3;
  const ALL_EMPLOYEES_WITH_SHIFTS = 2;
  const ALL_EMPLOYEES_WITH_CHANGED_SHIFTS = 1;
  const NO_EMPLOYEES = 0;

  const GROUP_NOTIFICATION_SETTINGS = [
    {
      label: "All employees at location",
      value: ALL_EMPLOYEES_AT_LOCATION,
    },
    {
      label: "All employees with shifts in this window",
      value: ALL_EMPLOYEES_WITH_SHIFTS,
    },
    {
      label: "All employees with changed shifts",
      value: ALL_EMPLOYEES_WITH_CHANGED_SHIFTS,
    },
    {label: "No notification", value: NO_EMPLOYEES},
  ];

  const SINGLE_NOTIFICATION_SETTINGS = [
    {label: "Employee scheduled for this shift", value: EMPLOYEE_WITH_CHANGED_SHIFT},
    ...GROUP_NOTIFICATION_SETTINGS,
  ];

  const {values} = formikOptions;
  const {notify} = values;

  return (
    <>
      <FormSelect
        className="mt-3"
        label="Notification Settings"
        name="notify"
        options={formikOptions}
        data={isSingleShift ? SINGLE_NOTIFICATION_SETTINGS : GROUP_NOTIFICATION_SETTINGS}
      />

      {!!notify && (
        <>
          <div className="mt-3">
            <label className="inline-flex items-center h-4 cursor-pointer">
              <Field
                type="checkbox"
                name="text"
                aria-describedby={"text-description"}
                className="focus:ring-primary-border h-4 w-4 text-primary-text border-neutral-border rounded"
              />
              <span className="ml-4 font-medium text-gray-700">Send Text</span>
            </label>
          </div>

          <div className="mt-3">
            <label className="inline-flex items-center h-4 cursor-pointer">
              <Field
                type="checkbox"
                name="email"
                aria-describedby={"email-description"}
                className="focus:ring-primary-border h-4 w-4 text-primary-text border-neutral-border rounded"
              />
              <span className="ml-4 font-medium text-gray-700">Send Email</span>
            </label>
          </div>
        </>
      )}
    </>
  );
}
