import React, {Component} from "react";
import {TwoColumnList} from "@frostbyte-technologies/frostbyte-tailwind";
import {setupReduxConnection} from "../../../redux";
import LoyaltyModal from "../../../modals/shop/shop-settings/loyalty/loyalty-modal";
import PatronModal from "../../../modals/shop/shop-settings/patron-modal";

class LoyaltyTab extends Component {
  render() {
    let {
      REWARDS_ENABLED,
      IGNORE_LOYALTY_WITH_GIFT_CARDS,
      IGNORE_LOYALTY_EARN_ON_GIFT_CARDS,
      PIN_PROTECT_POINTS,
      SHOW_PATRON_NOTES,
      ADVANCED_LOYALTY_ONE_RULE_PER_TICKET,
      ENABLE_CUSTOMER_BIRTHDAY_SIGNUP,
    } = this.props.shop.settings;
    let {
      LOYALTY_POINTS_VERBIAGE,
      NEW_PATRON_AWARD,
    } = this.props.shop.companySettings;

    const terminology = LOYALTY_POINTS_VERBIAGE
      ? LOYALTY_POINTS_VERBIAGE
      : "points";

    return (
      <div>
        <LoyaltyModal ref={(e) => (this.loyaltyModal = e)} />
        <PatronModal ref={(e) => (this.patronModal = e)} />

        <TwoColumnList
          label="Loyalty"
          description="Configure loyalty settings for your customers"
          tooltip={{
            label: "Loyalty Rewards",
            data: `When loyalty is enabled, your customers will earn ${terminology} for every order they are attached to. They can exchange their ${terminology} for rewards by ordering in store or online.`,
          }}
          data={[
            {
              label: "Rewards Enabled",
              value: REWARDS_ENABLED,
              type: "bool",
              tooltip:
                "If disabled, customers will not earn points and will not be able to redeem points for free items.",
            },
            {
              label: "Terminology",
              value: terminology,
              tooltip:
                'The name that we should use to refer to your "points". By default, "points" is used.',
              hint: "Company Setting",
            },
            // {
            //   label: "New Patron Sign Up Amount",
            //   value: NEW_PATRON_AWARD,
            //   tooltip:
            //     `The amount of ${terminology} that we should award to new patrons.`,
            //   hint: "Company Setting",
            // },
            {
              label: "Ignore Loyalty for Gift Cards as a Payment",
              value: IGNORE_LOYALTY_WITH_GIFT_CARDS,
              type: "bool",
              tooltip:
                "If enabled, transactions purchased with a gift card will not earn the patron any loyalty.",
            },
            {
              label: "Ignore Loyalty for Purchased Gift Cards",
              value: IGNORE_LOYALTY_EARN_ON_GIFT_CARDS,
              type: "bool",
              tooltip:
                "If enabled, purchases of gift cards will not grant loyalty points.",
            },
            {
              label: "One Earn Rule Per Payment",
              value: ADVANCED_LOYALTY_ONE_RULE_PER_TICKET,
              type: "bool",
              tooltip: {
                label: "One Rule Per Payment",
                data: `If enabled, only one earn rule will be applied per payment. The rule that generates the maximum amount of ${terminology} will be applied. Otherwise, the patron will instead earn the sum of the ${terminology} for each satisfied earn rule.`,
              },
            },
          ]}
          buttons={[
            {
              label: "Edit",
              onClick: () => this.loyaltyModal.open(),
            },
          ]}
        />

        <TwoColumnList
          label="Patrons"
          description="Configure settings for your patrons"
          data={[
            {
              label: "Pin Protect Editing Patron Points",
              value: PIN_PROTECT_POINTS,
              type: "bool",
              tooltip:
                "If enabled, editing patron points on the point of sale will be protected by an employee pin. Only employees with PATRONS_FULL, MARKETING_FULL or ADMIN permissions will be able to edit patron points.",
            },
            {
              label: "Show First Portion of Patron Notes on Checkout Screen",
              value: SHOW_PATRON_NOTES,
              type: "bool",
              tooltip: "If enabled, the first 16 characters of patron notes will be displayed on POS during checkout."
            },
            {
              label: "Enable Customer Birthday Field on Loyalty Signup",
              value: ENABLE_CUSTOMER_BIRTHDAY_SIGNUP,
              type: "bool",
              tooltip: "If enabled, customers will have the option to provide their birthday when signing up for the loyalty program."
            },
          ]}
          buttons={[
            {
              label: "Edit",
              onClick: () => this.patronModal.open(),
            },
          ]}
        />
      </div>
    );
  }
}

export default setupReduxConnection(["shop"])(LoyaltyTab);
