import React, {Component} from "react";
import {FormInput, Modal, FormSelect} from "@frostbyte-technologies/frostbyte-tailwind";
import {request} from "../../../utils/request";
import {Formik} from "formik";
import * as Yup from "yup";
import {withRouter} from "../../../utils/navigation";
import {setupReduxConnection} from "../../../redux";

class OnboardingModal extends Component {
  state = {account: null};

  open(row) {
    this.setState({account: row || null}, () => {
      console.log(row);
      this.modal.open();
    });
  }

  onboardEmployee = async ({name, phone, email, addPartner}) => {
    if (phone[0] !== "1") {
      phone = "1" + phone;
    }
    await request(`onboarding/onboard`, "POST", {
      NAME: name,
      PHONE: phone,
      EMAIL: email,
      ADD_PARTNER: addPartner,
    });

    this.props.loadRanks();
    this.modal.close();
  };

  render() {
    const {account} = this.state;
    return (
      <Modal
        buttonLabel={account ? "Save" : "Create"}
        label={account ? "Edit Employee" : "Onboard New Employee"}
        formikOnClick={() => this.formikRef}
        ref={(e) => (this.modal = e)}
      >
        {!account && (
          <p>
            Onboarding an employee will create an account (if it does not exist), add them
            as a Dripos Admin, and add them to CF and Lifted as partners. You could also
            choose to add them to all locations
          </p>
        )}
        <Formik
          onSubmit={this.onboardEmployee}
          innerRef={(e) => (this.formikRef = e)}
          enableReinitialize
          validationSchema={Yup.object({
            name: Yup.string().required("Name is required"),
            phone: Yup.string().required("Phone is required"),
            email: Yup.string().required("Email is required"),
            addPartner: Yup.number(),
          })}
          initialValues={{
            addPartner: 0,
            name: account?.FULL_NAME ?? "",
            phone: account?.PHONE ?? "",
            email: account?.EMAIL ?? "",
          }}
        >
          {(formikOptions) => {
            const {handleSubmit} = formikOptions;

            return (
              <form onSubmit={handleSubmit}>
                <FormInput label={"Name"} name={"name"} options={formikOptions} />
                <FormInput label={"Phone"} name={"phone"} options={formikOptions} />
                <FormInput label={"Email"} name={"email"} options={formikOptions} />
                <FormSelect
                  data={[
                    {
                      label: "Yes",
                      value: 1,
                    },
                    {
                      label: "No",
                      value: 0,
                    },
                  ]}
                  label={"Add as partner to all locations?"}
                  name={"addPartner"}
                  options={formikOptions}
                />
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

export default setupReduxConnection(["admin"])(withRouter(OnboardingModal));
