import {request} from "../../request";

export const AccountingRequests = {
  Bills: {
    fetchBill: async (uniqueId) => request(`accounting/bills/${uniqueId}`, "GET"),
    createBill: async (params) => request(`accounting/bills`, "POST", params),
    updateBill: async (uniqueId, params) =>
      request(`accounting/bills/${uniqueId}`, "PATCH", params),
    deleteBill: async (uniqueId) => request(`accounting/bills/${uniqueId}`, "DELETE"),
  },
};
