import React, {Component} from "react";
import {classNames} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";

class TimeSelect extends Component {
  render() {
    let {value, className, name = null, options = null} = this.props;

    let minutes = [0, 15, 30, 45];
    let times = [];
    for (let hour = 0; hour < 24; hour++) {
      for (let minute of minutes) {
        times.push({hour, minute});
      }
    }

    if (options) {
      value = options.values[name];
    }

    times.push({hour: 23, minute: 59});

    return (
      <select
        className={classNames(
          "inline-flex flex flex-row items-center pl-4 pr-2 py-2 border border-gray-300 shadow-sm text-sm rounded-md font-medium text-gray-700 bg-white hover:bg-gray-50  ",
          className
        )}
        // className="shadow-sm text-center w-full sm:text-sm border-gray-300 rounded-md"
        onChange={
          options
            ? (value) => options.setFieldValue(name, value.target.value)
            : this.props.onChange
        }
      >
        {times.map((time) => {
          let {hour, minute} = time;

          let minutes = hour * 60 + minute;
          let isAM = hour < 12;

          if (hour > 12) {
            hour -= 12;
          }

          return (
            <option value={minutes} selected={minutes === value}>
              {`${hour === 0 ? 12 : hour}:${minute === 0 ? "00" : minute} ${
                isAM ? "AM" : "PM"
              }`}
            </option>
          );
        })}
      </select>
    );
  }
}

export default TimeSelect;
