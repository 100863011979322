import React, {Component} from "react";
import {FormInput, FormSelect, Modal} from "@frostbyte-technologies/frostbyte-tailwind";

import BillLinesForm from "../../../features/accounting/bills/bill-lines-form";
import {Formik} from "formik";
import FormDateTimeSelect from "../../../components/form-date-time-select";
import {request} from "../../../utils/request";
import * as Yup from "yup";
import moment from "moment-timezone";
import {
  decimalToDollars,
  toDollars,
} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import VendorDropdown from "../../../dropdowns/accounting/vendor-dropdown";
import {AccountingRequests} from "../../../utils/request-helpers/accounting/accounting-requests";
import FormDropZone from "../../../components/form-drop-zone";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {getStore} from "../../../redux";
import {updateVendor} from "../../../redux/supply";

class BillModal extends Component {
  state = {bill: null};

  open(bill) {
    this.setState({bill}, this.modal.open());
  }

  handleSubmit = async ({vendor, lines, issueDate, dueDate, billNo, fileUrl}) => {
    const {bill} = this.state;

    const payload = {
      VENDOR_ID: vendor,
      BILL_NUMBER: billNo,
      DATE_ISSUED: moment(issueDate).format("YYYY-MM-DD"),
      DATE_DUE: moment(dueDate).format("YYYY-MM-DD"),
      LINES: lines.map(({category, description, amount}, index) => ({
        SEQ: index + 1,
        CATEGORY_ID: category,
        DESCRIPTION: description,
        AMOUNT: decimalToDollars(amount),
      })),
      FILE_URL: fileUrl,
    };

    if (!bill) {
      await AccountingRequests.Bills.createBill(payload);
    } else {
      await AccountingRequests.Bills.updateBill(bill.UNIQUE_ID, payload);
    }

    await this.props.syncState();

    this.modal.close();
  };

  render() {
    const {bill} = this.state;

    return (
      <Modal
        xlarge
        buttonLabel={bill ? "Save" : "Create"}
        label={`${bill ? "Save" : "Edit"} Bill`}
        ref={(e) => (this.modal = e)}
        formikOnClick={() => this.formikRef}
      >
        <Formik
          enableReinitialize={true}
          innerRef={(e) => (this.formikRef = e)}
          initialValues={{
            vendor: bill?.VENDOR_ID,
            issueDate: bill?.DATE_ISSUED ?? moment().startOf("day").valueOf(),
            dueDate: bill?.DATE_DUE ?? moment().startOf("day").add(1, "month").valueOf(),
            billNo: bill?.BILL_NUMBER,
            lines: bill?.LINES
              ? bill.LINES.map(({AMOUNT, DESCRIPTION, CATEGORY_ID}) => ({
                  category: CATEGORY_ID,
                  description: DESCRIPTION,
                  amount: toDollars(AMOUNT),
                }))
              : [{}],
            fileUrl: bill?.FILE_URL,
          }}
          validationSchema={Yup.object({
            vendor: Yup.number().required("Vendor is required"),
            issueDate: Yup.string().required("Issue Date is required"),
            dueDate: Yup.string().required("Due Date is required"),
            lines: Yup.array()
              .of(
                Yup.object({
                  category: Yup.number().required("Category is required"),
                  description: Yup.string(),
                  amount: Yup.string().required("Amount is required"),
                })
              )
              .required("Lines are required"),
          })}
          onSubmit={this.handleSubmit}
        >
          {(formikOptions) => {
            const {setFieldValue, values} = formikOptions;
            const {fileUrl} = values;

            return (
              <form onSubmit={this.handleSubmit}>
                <div class={"space-y-3"}>
                  <div className={"flex flex-row justify-between"}>
                    <VendorDropdown
                      name={"vendor"}
                      label={"Vendor"}
                      options={formikOptions}
                      onChangeSoft={async (vendor) => {
                          await getStore().dispatch(updateVendor(vendor.ID));
                      }}
                    />

                    <div className={"mt-2"}>
                      {!fileUrl && (
                        <FormDropZone
                          name={"fileUrl"}
                          small
                          label={""}
                          endpoint={"accounting/bills/file"}
                          options={formikOptions}
                          onChangeSoft={(file) => {
                            console.log(file, file.name);
                            setFieldValue("fileUrl", file.name);
                          }}
                        />
                      )}

                      {fileUrl && (
                        <div className={"space-y-1"}>
                          <div class={"text-indigo-500 font-semibold cursor-pointer"}>
                            <FontAwesomeIcon
                              icon={"paperclip"}
                              className={"h-3 w-3 mr-2"}
                            />
                            View Attached File
                          </div>

                          <div
                            className={
                              "text-red-500 font-semibold text-sm text-right cursor-pointer"
                            }
                            onClick={() => {
                              setFieldValue("file", null);
                            }}
                          >
                            Delete Image
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  <div class={"flex flex-row space-x-3"}>
                    <FormDateTimeSelect
                      name="issueDate"
                      label={"Issue Date"}
                      options={formikOptions}
                      hideTime={true}
                    />
                    <FormDateTimeSelect
                      name="dueDate"
                      label={"Due Date"}
                      options={formikOptions}
                      hideTime={true}
                    />
                    <FormInput
                      name="billNo"
                      label={"Bill #"}
                      options={formikOptions}
                      hint={"Optional"}
                    />
                  </div>

                  <BillLinesForm options={formikOptions} />
                </div>
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

export const BILL_MODES = {
  FULL: "FULL",
  DETAILS: "DETAILS",
  LINES: "LINES",
};

export default BillModal;
