import React, {Component} from "react";
import {
  FormBoolean,
  FormInput,
  FormSelect,
  FormTextArea,
  Modal,
} from "@frostbyte-technologies/frostbyte-tailwind";
import {request} from "../../../utils/request";
import {Formik} from "formik";
import * as Yup from "yup";
import {withRouter} from "../../../utils/navigation";
import CompanyDropdown from "../../../dropdowns/admin/company-dropdown";
import {setupReduxConnection} from "../../../redux";
import {loadBrandedApps} from "../../../redux/admin";
import ImageFormDropZone from "../../../components/image-form-drop-zone";
import FormDropZone from "../../../components/form-drop-zone";
import {BrandedAppRequests} from "../../../utils/request-helpers/admin/branded-app-requests";

class EditBrandedAppInformationModal extends Component {
  state = {brandedApp: {}};

  open(brandedApp = null) {
    this.setState({brandedApp}, () => {
      this.formikRef && this.formikRef.resetForm();
      this.modal.open();
    });
  }

  save = async ({appIcon, landingIcon, useAppAsLanding, landingHex}) => {
    const {UNIQUE_ID} = this.state.brandedApp;

    const payload = {
      APP_ICON: appIcon,
      LANDING_ICON: useAppAsLanding === "1" ? appIcon : landingIcon,
      LANDING_HEX: landingHex
    };

    await BrandedAppRequests.upsertBrandedApp(UNIQUE_ID, payload);

    await this.props.syncState();

    this.modal.close();
  };

  render() {
    const {APP_ICON, LANDING_ICON, LANDING_HEX} = this.state.brandedApp;

    return (
      <Modal
        buttonLabel={"Save"}
        label={"Edit App Icons"}
        formikOnClick={() => this.formikRef}
        ref={(e) => (this.modal = e)}
      >
        <Formik
          onSubmit={this.save}
          innerRef={(e) => (this.formikRef = e)}
          enableReinitialize
          validationSchema={Yup.object({})}
          initialValues={{
            appIcon: APP_ICON,
            landingIcon: LANDING_ICON,
            useAppAsLanding: LANDING_ICON !== APP_ICON ? "0" : "1",
            landingHex: LANDING_HEX
          }}
        >
          {(formikOptions) => {
            const {handleSubmit, values} = formikOptions;

            const {useAppAsLanding} = values;

            return (
              <form onSubmit={handleSubmit}>
                <ImageFormDropZone
                  label={"App Icon (Transparent Background)"}
                  options={formikOptions}
                  name="appIcon"
                />

                <FormBoolean
                  label={"Use same icon for landing page?"}
                  name={"useAppAsLanding"}
                  options={formikOptions}
                />

                {useAppAsLanding === "0" && (
                  <ImageFormDropZone
                    label={"Loading Icon (Transparent Background)"}
                    name={"landingIcon"}
                    options={formikOptions}
                  />
                )}

                <FormInput
                  label={"Landing Screen Hex (include #)"}
                  name={"landingHex"}
                  options={formikOptions}
                />
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

export default setupReduxConnection(["admin"])(
  withRouter(EditBrandedAppInformationModal)
);
