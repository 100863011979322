import {
  faEnvelopeOpenDollar,
  faTableColumns,
} from "@fortawesome/pro-light-svg-icons";

export const EMPLOYEE_HEADER_MENU = [
  {
    label: "Payroll",
    routes: [
      {
        live: true,
        name: "Dashboard",
        description: "Update your information and see future paydays",
        url: "/hub/payroll",
        icon: faTableColumns,
      },
      {
        live: true,
        name: "Paystubs",
        description: "View all your paystubs",
        url: "/hub/payroll/paystubs",
        icon: faEnvelopeOpenDollar,
      },
      {
        live: true,
        name: "Tax Documents",
        description: "View all your tax documents",
        url: "/hub/payroll/tax-documents",
        icon: faEnvelopeOpenDollar,
      },
    ],
  },
];
