import Joyride from "react-joyride";
import React, {Component} from "react";
import PropTypes from "prop-types";
import Checkbox from "./form-elements/checkbox";
import {setupReduxConnection} from "../redux";
import {request} from "../utils/request";

let isExecuting = false;

class Guide extends Component {
  state = {checked: false};

  handleClose = async (data, checked) => {
    if (isExecuting) {
      return;
    }

    isExecuting = true;

    const {_key} = this.props;
    const {userGuideEntries} = this.props.user;

    if (!data) {
      return;
    }

    if (data.action === "close" && checked === true) {
      if (!!_key) {
        const serverUserEntry = await request("user/" + _key, "POST", {});

        userGuideEntries[_key] = serverUserEntry;

        this.props.updateUserGuideEntries(userGuideEntries);
      }
    }

    isExecuting = false;
  };

  render() {
    const {
      target,
      label,
      placement = "right",
      showCheckbox = true,
      color = "#4f46e5",
    } = this.props;

    const {checked} = this.state;

    const content = (
      <div>
        <div>{label}</div>

        {showCheckbox && (
          <div className={"flex flex-row items-center absolute bottom-0 mb-5"}>
            <input
              className={"h-4 w-4 rounded-sm"}
              type="checkbox"
              checked={checked}
              onChange={() =>
                this.setState((prevState) => ({checked: !prevState.checked}))
              }
            />

            <label className={"ml-2"}>Do not show again</label>
          </div>
        )}
      </div>
    );

    const steps = [{target, content, placement}];

    return (
      <Joyride
        steps={steps}
        styles={{
          options: {primaryColor: color},
          tooltipContent: {
            padding: "0px 25px",
          },
          tooltip: {closeButton: {display: "none"}},
          buttonClose: {
            display: "none",
            height: 12,
            width: 12,
            padding: 20,
          },
          overlay: {
            height: "100%",
          },
        }}
        callback={(data) => this.handleClose(data, checked)}
      />
    );
  }
}

Guide.propTypes = {
  target: PropTypes.string.isRequired,
  label: PropTypes.string,
  showCheckBox: PropTypes.bool,
};

export default setupReduxConnection(["user"])(Guide);
