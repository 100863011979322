import {SearchIcon} from "@heroicons/react/solid";
import RadioDropdown from "../../../components/form-elements/radio-dropdown";
import React, {Component} from "react";

class PayrollRunTableFilter extends Component {
  render() {
    const {dbaLocations, hideEmployeeSelector, tabs} = this.props;

    return (
      <div className="flex flex-row rounded-md space-x-4 align-bottom pt-3">
        <div className="relative w-64 bottom-0">
          <div className="absolute top-2.5 left-0 pl-3 flex items-center pointer-events-none">
            <SearchIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
          </div>
          <input
            type="email"
            name="email"
            id="email"
            className="focus:ring-indigo-500 focus:border-indigo-500 block w-full rounded-md pl-10 sm:text-sm border-gray-300"
            placeholder={"Search for name"}
            onChange={(e) => this.props.setSearch(e.target.value)}
          />
        </div>

        {dbaLocations?.length > 1 && (
          <div className="align-bottom">
            <RadioDropdown
              defaultValue={"all"}
              data={tabs}
              onChange={(val) => this.props.setSelectedLocations(val)}
            />
          </div>
        )}

        {!hideEmployeeSelector && (
          <RadioDropdown
            className={"-mt-5"}
            label={"Employees"}
            defaultValue={"with-pay"}
            data={[
              {label: "All Active", value: "all"},
              {label: "With Pay", value: "with-pay"},
            ]}
            onChange={(val) => this.props.setSelectedEmployees(val)}
          />
        )}
      </div>
    );
  }
}

export default PayrollRunTableFilter;
