import React, {Component} from "react";
import {setupReduxConnection} from "../../../../redux";
import {Table} from "@frostbyte-technologies/frostbyte-tailwind";
import DangerBadge from "../../../../components/badges/danger-badge";
import PTOModal from "../../../../modals/payroll/earnings/pto-modal";
import {toDollars} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import {PAY_TYPES} from "../../../../modals/payroll/earnings/extra-pay-modal";
import PayrollRateSelectModal from "../../../../modals/payroll/payroll-rate-select-modal";
import {fetchEmployeeRequestedHours} from "../../../../utils/payroll-helper";

class PayrollTimeOffComponent extends Component {
  renderAdditionalPTO(row, checkPayrollId, type, entry) {
    if (entry) {
      const {AMOUNT, HOURS} = entry;

      return (
        <div
          className="text-left text-indigo-500 cursor-pointer font-medium bg-red"
          onClick={() => {
            this.PTOModal.open(checkPayrollId, row, type);
          }}
        >
          <div className="text-medium">
            {HOURS} Hours | {toDollars(AMOUNT, true)}
          </div>
        </div>
      );
    }

    if (row.TYPE === "SAL") {
      return <div className={"font-semibold"}>Salaried</div>;
    }

    return (
      <span
        className="text-indigo-500 font-semibold cursor-pointer"
        onClick={() => {
          this.PTOModal.open(checkPayrollId, row, type);
        }}
      >
        {"+ Add"}
      </span>
    );
  }

  getColumns() {
    const {CHECK_PAYROLL_ID: checkPayrollId} = this.props.payrollDraft;

    return [
      {
        label: "Employee Name",
        value: "name",
        format: (value, row) => {
          const amountRequested = fetchEmployeeRequestedHours(row);
          const hasRequestedPTO = !isNaN(amountRequested) && amountRequested > 0;
          const hasPTORate = !!row?.DEFAULT_RATE;
          const isHourly = row?.TYPE === "HOUR";

          const showError = hasRequestedPTO && !hasPTORate && isHourly;

          return (
            <div
              className={"flex flex-row"}
              onClick={() =>
                showError
                  ? this.payrollRateSelectModal.open(
                      row.CHECK_EMPLOYEE_ID,
                      row.ID,
                      row.RATES,
                      row.NAME
                    )
                  : undefined
              }
            >
              <div>{row.NAME}</div>

              {showError && <DangerBadge className={"ml-2"}>No PTO Rate</DangerBadge>}
            </div>
          );
        },
      },
      {
        label: "Paid Time Off",
        value: "PTO",
        format: (value, row) => {
          const hours = row?.REQUESTED_PTO?.pto ?? 0;
          const amount = row?.DEFAULT_RATE * hours;

          return (
            <>
              <div>
                {hours}
                {" Hours"}
                {amount ? "| $" + toDollars(amount) : ""}
              </div>

              {this.renderAdditionalPTO(
                row,
                checkPayrollId,
                PAY_TYPES.ADDITIONAL_PTO.CHECK_TYPE,
                row.ADDITIONAL_PTO[PAY_TYPES.ADDITIONAL_PTO.CHECK_TYPE]
              )}
            </>
          );
        },
      },
      {
        label: "Sick Leave",
        value: "SICK_LEAVE",
        format: (value, row) => {
          const hours = row?.REQUESTED_PTO?.sick ?? 0;
          const amount = row?.DEFAULT_RATE * hours;

          return (
            <>
              <div>
                {hours}
                {" Hours"}
                {amount ? " | $" + toDollars(amount) : ""}
              </div>

              {this.renderAdditionalPTO(
                row,
                checkPayrollId,
                PAY_TYPES.SICK_LEAVE.CHECK_TYPE,
                row.ADDITIONAL_PTO[PAY_TYPES.SICK_LEAVE.CHECK_TYPE]
              )}
            </>
          );
        },
      },
    ];
  }

  render() {
    const {breakdown} = this.props;

    return (
      <>
        <PTOModal
          ref={(e) => (this.PTOModal = e)}
          updateBreakdown={this.props.updateBreakdown}
          breakdown={breakdown ?? []}
        />

        <PayrollRateSelectModal
          ref={(e) => (this.payrollRateSelectModal = e)}
          updateBreakdown={this.props.updateBreakdown}
          breakdown={breakdown ?? []}
        />

        <Table columns={this.getColumns()} data={breakdown} />
      </>
    );
  }
}

export default setupReduxConnection(["payroll"])(PayrollTimeOffComponent);
