import React, {Component} from "react";
import {setupReduxConnection} from "../../redux";
import {withRouter} from "../../utils/navigation";
import {
  Card,
  Loading,
  PageHeadings,
  Tab,
  Filter,
} from "@frostbyte-technologies/frostbyte-tailwind";
import StartingPoint from "../../components/starting-point";
import {TableIcon, ViewBoardsIcon} from "@heroicons/react/outline";
import {parseIdDict} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import ImportMenuModal from "../../modals/sales/import-menu-modal";
import {request} from "../../utils/request";
import EmployeeTable from "../../tables/team/employee-table";
import EmployeeDetailsModal from "../../modals/team/employee/employee-details-modal";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

class TeamPage extends Component {
  state = {
    isLoading: true,
    filter: null,
    employees: [],
    roles: [],
    locations: [],
  };

  async componentDidMount() {
    await this.syncState();
  }

  async syncState() {
    const serverRoles = await request("employeeroles/roles", "GET");
    const serverEmployees = await request("employees/v2", "GET", {
      ROLES: true,
    });

    const serverLocations = await request("partner/franchise/locations", "GET");

    this.setState({data: serverEmployees});

    this.setState({
      employees: serverEmployees,
      roles: serverRoles,
      isLoading: false,
      locations: serverLocations,
    });
  }

  renderEmployees() {
    const {roles, employees, locations} = this.state;
    const {location} = this.props.shop;

    const roleDict = parseIdDict(roles);

    return (
      <Tab
        className="mt-2"
        data={roles
          .filter((_) => {
            return true;
          })
          .map((item) => {
            return {label: item.NAME, id: item.ID};
          })}
      >
        {(t) => {
          const role = roleDict[t];
          const filteredEmployees = employees.filter((item) => {
            const roleSet = item.ROLES.map((item) => item.ROLE_ID);

            return roleSet.includes(role?.ID);
          });

          return (
            <Filter
              csv={{
                file: "my-team",
                data: filteredEmployees,
                headers: [
                  {label: "Name", key: "FULL_NAME"},
                  {label: "Phone", key: "PHONE"},
                  {label: "Email", key: "EMAIL"},
                  {label: "Location", key: "LOCATION_NAME"},
                  {label: "Start Date", key: "DATE_STARTED", type: "date"},
                ],
              }}
              defaultFilters={[
                {filter: "location", id: location.ID, label: location.NAME},
              ]}
              selectAll
              data={[
                {
                  id: "location",
                  label: "Home Location",
                  options: locations.map((item) => {
                    return {id: item.ID, label: item.NAME};
                  }),
                  onFilter: (options, data) => {
                    if (options.length === 0) {
                      return data;
                    }

                    return data.filter((item) =>
                      options.includes(item.LOCATION_ID)
                    );
                  },
                },
              ]}
            >
              {(filters) => {
                return (
                  <Card
                    className="mt-0"
                    label={role?.NAME + "'s Employees"}
                    description={`Employees that have the "${role?.NAME}" role`}
                    buttons={[
                      {
                        label: " View Role",
                        onClick: () => {
                          this.props.router.navigate(
                            "/role/" + role.UNIQUE_ID,
                            {
                              test: "hi",
                            }
                          );
                        },
                      },
                      {
                        label: "Create Employee",
                        onClick: () => this.employeeModal.open(),
                      },
                    ]}
                  >
                    <EmployeeTable
                      data={filteredEmployees}
                      locations={locations}
                      filters={filters}
                      pagination
                      striped
                    />
                  </Card>
                );
              }}
            </Filter>
          );
        }}
      </Tab>
    );
  }

  renderEmpty() {
    return (
      <Card className="p-6">
        <StartingPoint
          label="Setup a team"
          otherClick={() => this.importMenuRef.open()}
          description="Let's get a team setup so we can create time cards and start scheduling!"
          data={[
            {
              title: "Create a Role",
              description:
                "Employees are given roles which can determine their pay and what permissions they might have. Every employee must have at least one role.",
              icon: ViewBoardsIcon,
              background: "bg-blue-500",
              onClick: () => this.props.router.navigate("/role"),
            },
            {
              title: "Create a Employee",
              description:
                "Create an employee which can take on one or more roles.",
              icon: TableIcon,
              background: "bg-indigo-500",
              onClick: () => this.employeeModal.open(),
            },
          ]}
        />
      </Card>
    );
  }

  render() {
    const {isLoading, roles} = this.state;

    if (isLoading) {
      return <Loading />;
    }

    return (
      <div>
        <ImportMenuModal ref={(e) => (this.importMenuRef = e)} />

        <EmployeeDetailsModal
          addState={(employee) =>
            this.props.router.navigate("/employee/" + employee.UNIQUE_ID)
          }
          ref={(e) => (this.employeeModal = e)}
        />

        <PageHeadings
          label="My Team"
          description="View your team as a whole"
          buttons={[
            {
              theme: "primary",
              label: "Create",
              icon: "plus",
              type: "list",
              onChange: ({id}) => {
                if (id === "role") {
                  this.props.router.navigate("/role");
                }

                if (id === "employee") {
                  this.employeeModal.open();
                }
              },
              items: [
                {
                  id: "role",
                  label: "Role",
                  description:
                    "Create a new Role that your employees can take on.",
                },
                {
                  id: "employee",
                  label: "Employee",
                  description: "Create a new employee for your shop.",
                },
              ],
            },
          ]}
        />

        {roles.length === 0 ? this.renderEmpty() : this.renderEmployees()}
      </div>
    );
  }
}

export default setupReduxConnection(["menu", "shop"])(withRouter(TeamPage));
