import React, {Fragment, Component} from "react";
import ReportingToolTip from "./reporting-tool-tip";
import {toDollars} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
class CompareReportingTooltip extends Component {
  render() {
    const {header, row, datasets, payload} = this.props;

    const rows = payload.map((_payload, index) => ({
      stroke: datasets[index].stroke,
      label: _payload.payload[`${_payload.id}Date`],
      value: toDollars(_payload.payload[`${_payload.id}Value`], true),
    }));

    return (
      <ReportingToolTip
        {...this.props}
        rows={rows}
        formatLabel={() => header}
      />
    );
  }
}

export default CompareReportingTooltip;
