import React, {useCallback, useEffect} from "react";
import ReactFlow, {
  addEdge,
  Background,
  Controls,
  MarkerType,
  MiniMap,
  useEdgesState,
  useNodesState,
} from "reactflow";

import "reactflow/dist/style.css";
import IngredientNode from "../../../../features/operations/supply-chain/graph/ingredient-node";
import {request} from "../../../../utils/request";
import {
  parseIdDict,
  toDollars,
} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import {groupBy} from "../../../../utils/util";
import {Card} from "@frostbyte-technologies/frostbyte-tailwind";

const nodeTypes = {
  ingredient: IngredientNode,
};

const GOOD_COLOR = "green";
const BAD_COLOR = "red";

const initialNodes = [];

const initialEdges = [];

function createNode(node) {
  return {
    id: "" + node.ID,
    position: {x: node.x, y: node.y},
    data: {
      label: node.INGREDIENT_NAME,
      cost: toDollars(node.COST, true),
      quantity: node.INITIAL_QUANTITY,
      good: node.T_SCORE < 2,
      unit: null,
    },
    type: "ingredient",
    draggable: true,
  };
}

function createEdge(usage, sources) {
  const source = sources[usage.SOURCE_STOCK_ID];

  return {
    id: "e-" + usage.ID,
    source: "" + usage.SOURCE_STOCK_ID,
    target: "" + usage.DESTINATION_STOCK_ID,
    label:
      usage.QUANTITY + " @ " + toDollars(Math.round(usage.COST / usage.QUANTITY), true),
    labelStyle: {
      fontWeight: "700",
      fontSize: 14,
    },
    labelBgStyle: {
      shadowOffset: {
        width: 4,
        height: -4,
      },
      shadowColor: "black",
      shadowOpacity: 1,
      shadowRadius: 12,
    },
    labelBgPadding: [6, 6],
    labelBgBorderRadius: 6,
    labelShowBg: true,
    animated: true,
    markerEnd: {
      type: MarkerType.ArrowClosed,
      color: source.T_SCORE > 2 ? BAD_COLOR : GOOD_COLOR,
    },
    style: {
      strokeWidth: 2,
      stroke: source.T_SCORE > 2 ? BAD_COLOR : GOOD_COLOR,
    },
  };
}

const NODE_HEIGHT = 100;
const NODE_WIDTH = 450;
const INITIAL_WIDTH = 100;

function placeNodes(edges, nodes) {
  const edgeDestinationDict = groupBy(edges, "DESTINATION_STOCK_ID");
  const edgeSourceDict = groupBy(edges, "SOURCE_STOCK_ID");
  const nodeDict = parseIdDict(nodes);

  let allChildren = nodes.filter((node) => !edgeDestinationDict[node.ID]); // find all children

  let currHeight = 100;
  let currWidth = INITIAL_WIDTH;

  while (allChildren.length > 0) {
    const newChildren = [];
    const pushedChildren = {};

    for (let i = 0; i < allChildren.length; i++) {
      const child = allChildren[i];
      child.x = currWidth;

      if (currWidth === INITIAL_WIDTH) {
        child.y = currHeight;
      } else {
        const sum = edgeDestinationDict[child.ID].reduce(
          (accum, edge) => nodeDict[edge.SOURCE_STOCK_ID].y + accum,
          0
        );

        child.y = Math.round(sum / edgeDestinationDict[child.ID].length);
      }

      const children = edgeSourceDict[child.ID];

      if (children) {
        newChildren.push(
          ...children
            .filter((child) => {
              return !pushedChildren[child.ID];
            })
            .map((edge) => {
              pushedChildren[child.ID] = true;
              return nodeDict[edge.DESTINATION_STOCK_ID];
            })
        );
      }

      currHeight += NODE_HEIGHT;
    }

    allChildren = newChildren;
    currWidth += NODE_WIDTH;
    currHeight = 0;
  }
}

export default function UsagePage() {
  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);

  const onConnect = useCallback(
    (params) => setEdges((eds) => addEdge(params, eds)),
    [setEdges]
  );

  useEffect(function componentDidMount() {
    request("stocks/history", "GET").then((data) => {
      const {usages = [], stocks = []} = data;

      const stockDict = parseIdDict(stocks);

      placeNodes(usages, stocks);
      const edges = usages.map((usage) => createEdge(usage, stockDict));
      const nodes = stocks.map((node) => createNode(node));

      setNodes(nodes);
      setEdges(edges);
    });
  }, []);

  return (
    <Card label="Ingredient Usages">
      <div style={{width: "100vw", height: "100vh"}}>
        <ReactFlow
          nodes={nodes}
          edges={edges}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onConnect={onConnect}
          nodeTypes={nodeTypes}
        >
          <Controls />
          <MiniMap />
          <Background variant="dots" gap={12} size={1} />
        </ReactFlow>
      </div>
    </Card>
  );
}
