import React, {Component} from "react";
import {
  FormInput,
  FormSelect,
  Modal,
} from "@frostbyte-technologies/frostbyte-tailwind";
import {Formik} from "formik";
import {request} from "../../../utils/request";
import * as Yup from "yup";
import {toDollars} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import {PAYMENT_TYPES} from "@frostbyte-technologies/frostbyte-tickets/dist/constants/ticket-constants";
import {showErrorAlert} from "../../../utils/alert-helper";
import moment from "moment-timezone";

class BillingAccountPayoffModal extends Component {
  open() {
    this.modal.open();
  }

  async payOffBillingAccount({tickets, paymentType, checkNumber = null}) {
    const {billingAccount} = this.props;
    const ticketSet = new Set(tickets);

    const paymentAmount = billingAccount.TICKETS.reduce((accum, ticket) => {
      if (ticketSet.has(ticket.ID)) {
        accum += ticket.PAYMENT_INTENT.TOTAL;
      }

      return accum;
    }, 0);

    const billingPayload = {TICKETS: tickets, SOURCES: []};

    // Cash
    if (paymentType === 0) {
      billingPayload.SOURCES.push({
        AMOUNT: paymentAmount,
        TYPE: PAYMENT_TYPES.CASH,
        CONTENT: "" + paymentAmount,
      });
    }
    // Check
    else if (paymentType === 1) {
      billingPayload.SOURCES.push({
        AMOUNT: paymentAmount,
        TYPE: PAYMENT_TYPES.CHECK,
        CONTENT: checkNumber,
      });
    } else {
      return Promise.reject(new Error("INVALID_PAYMENT_TYPE"));
    }
    try {
      await request(
        "billing/" + billingAccount.UNIQUE_ID + "/process",
        "POST",
        billingPayload
      );
    } catch (err) {
      console.log(err);

      return showErrorAlert(
        "Something went wong",
        "Something went wrong trying to payoff this billing account. Please contact support for assistance."
      );
    }

    this.props.updateState && this.props.updateState();

    this.modal.close();
  }

  render() {
    const {billingAccount} = this.props;

    return (
      <Modal
        buttonLabel="Pay Off"
        label="Pay Off Billing Account"
        ref={(e) => (this.modal = e)}
        large
        formikOnClick={() => this.formikRef}
      >
        <Formik
          onSubmit={this.payOffBillingAccount.bind(this)}
          innerRef={(e) => (this.formikRef = e)}
          validationSchema={Yup.object().shape({
            tickets: Yup.array()
              .min(1, "You must select at least one ticket")
              .required("You must select at least one ticket"),
            paymentType: Yup.number("Type must be a number.").required(
              "Type is required"
            ),
            checkNumber: Yup.string().nullable(),
          })}
          initialValues={{
            tickets: billingAccount?.TICKETS.map(({ID}) => ID),
            paymentType: 0,
            checkNumber: null,
          }}
        >
          {(formikOptions) => {
            const {handleSubmit, values} = formikOptions;

            return (
              <form onSubmit={handleSubmit}>
                <FormSelect
                  multi
                  data={billingAccount.TICKETS.map((ticket) => {
                    return {
                      label: `#${ticket.TICKET_NUMBER} ${moment(
                        ticket.DATE_SCHEDULED || ticket.DATE_CREATED
                      ).format("MMM Do hh:mm A")} ${toDollars(
                        ticket.PAYMENT_INTENT.TOTAL,
                        true
                      )} (${ticket.UNIQUE_ID})`,
                      value: ticket.ID,
                    };
                  })}
                  options={formikOptions}
                  name={"tickets"}
                  {...this.props}
                />

                <FormSelect
                  options={formikOptions}
                  label="Type"
                  name="paymentType"
                  data={[
                    {label: "Cash", value: 0},
                    {label: "Check", value: 1},
                  ]}
                />

                {values.paymentType === 1 && (
                  <FormInput
                    options={formikOptions}
                    label="Check Number"
                    hint={"Optional"}
                    name="checkNumber"
                  />
                )}
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

export default BillingAccountPayoffModal;
