import React, {Component} from "react";
import * as Yup from "yup";
import {
  FormBoolean,
  FormSelect,
  Modal,
} from "@frostbyte-technologies/frostbyte-tailwind";
import {Formik} from "formik";
import {request} from "../../../utils/request";
import PropTypes from "prop-types";
import {showConfirmAlert} from "../../../utils/alert-helper";
import FormDateTimeSelect from "../../../components/form-date-time-select";
import FormRow from "../../../components/form-row";
import TimeSelect from "../../../components/form-elements/time-select";
import FormDaySelect from "../../../components/form-day-select";

class ProductAvailabilityModal extends Component {
  state = {availability: null};

  open(availability = null) {
    this.setState({availability}, () => {
      this.formikRef && this.formikRef.resetForm();
      this.modal.open();
    });
  }

  async deleteProductAvailability() {
    const {availability} = this.state;

    showConfirmAlert(
      "Archive this Availability",
      "Are you sure you wanted to delete this product availability"
    ).then(async () => {
      await request("items/availability/" + availability.ID, "DELETE");

      this.props.updateState(availability.ID);
      this.modal.close();
    });
  }

  async addProductAvailability({start, end, type, day, hideItem}) {
    const {product} = this.props;

    if (day !== null) {
      start = parseInt(start) * 60 * 1000;
      end = parseInt(end) * 60 * 1000;
      day = parseInt(day);
    }

    let serverCategory = await request("items/availability", "POST", {
      TYPE: type,
      PRODUCT_ID: product.ID,
      HIDE_ITEM: hideItem,
      DATE_START: start ?? "0",
      DATE_END: end ?? "0",
      ISO_DAY: day,
    });

    this.props.addState(serverCategory);
    this.modal.close();
  }

  async saveProductAvailability({start, end, type, day, hideItem}) {
    const {availability} = this.state;

    if (day !== null) {
      start = parseInt(start) * 60 * 1000;
      end = parseInt(end) * 60 * 1000;
      day = parseInt(day);
    }

    let serverPaymentType = {
      TYPE: type,
      DATE_START: start,
      DATE_END: end,
      ISO_DAY: day,
      HIDE_ITEM: hideItem,
    };

    let serverCategory = await request(
      "items/availability/" + availability.ID,
      "PATCH",
      serverPaymentType
    );

    this.props.updateState(serverCategory[0].ID, serverCategory[0]);
    this.modal.close();
  }

  parseAvailability(availability) {
    if (!availability) {
      return {dateStart: null, dateEnd: null, isoDay: null, type: null};
    }

    let {
      DATE_START: dateStart,
      DATE_END: dateEnd,
      ISO_DAY: isoDay,
      TYPE: type,
    } = availability;

    if (isoDay !== null && isoDay !== undefined) {
      dateStart = dateStart / 1000 / 60;
      dateEnd = dateEnd / 1000 / 60;
    }

    return {dateStart, dateEnd, isoDay, type};
  }

  render() {
    const {availability} = this.state;

    const {dateStart, dateEnd, isoDay, type} =
      this.parseAvailability(availability);

    return (
      <Modal
        deleteOnClick={this.deleteProductAvailability.bind(this)}
        deleteLabel={availability && "Archive"}
        buttonLabel={availability ? "Save" : "Add"}
        label={
          availability
            ? "Edit Product Availability"
            : "Add Product Availability"
        }
        ref={(e) => (this.modal = e)}
        formikOnClick={() => this.formikRef}
      >
        <Formik
          onSubmit={
            availability
              ? this.saveProductAvailability.bind(this)
              : this.addProductAvailability.bind(this)
          }
          innerRef={(e) => (this.formikRef = e)}
          enableReinitialize
          initialValues={{
            type: type ?? "RANGE",
            start: dateStart,
            end: dateEnd,
            day: isoDay,
            hideItem: availability?.HIDE_ITEM ?? "0",
          }}
          validationSchema={Yup.object({
            type: Yup.string().nullable().required(),
            start: Yup.string()
              .nullable()
              .required("Please select a start date or time."),
            end: Yup.string()
              .nullable()
              .required("Please select an end date or time."),
            day: Yup.string()
              .nullable()
              .test(
                "valid day",
                "Please choose a day.",
                (value, ctx) =>
                  ctx.parent.type !== "RECUR" ||
                  (value !== null && value !== undefined)
              ),
          })}
        >
          {(formikOptions) => {
            const {handleSubmit, values} = formikOptions;
            const {type} = values;

            return (
              <form onSubmit={handleSubmit}>
                <FormSelect
                  name="type"
                  label="Type"
                  options={formikOptions}
                  data={[
                    {label: "Date Range", value: "RANGE"},
                    {label: "Daily Recurrence", value: "RECUR"},
                  ]}
                />

                {type === "RANGE" && (
                  <FormRow>
                    <FormDateTimeSelect
                      options={formikOptions}
                      label="Start"
                      name="start"
                      flex
                    />

                    <FormDateTimeSelect
                      options={formikOptions}
                      label="End"
                      name="end"
                      flex
                    />
                  </FormRow>
                )}

                {type === "RECUR" && (
                  <div className={"flex flex-col"}>
                    <FormDaySelect
                      options={formikOptions}
                      name={"day"}
                      label={"Day of the Week"}
                      className={"my-3"}
                      iso
                    />

                    <div>
                      <div className={"flex flex-col mb-2"}>
                        <div className={"text-sm font-medium text-gray-700"}>
                          Start
                        </div>

                        <TimeSelect
                          options={formikOptions}
                          name={"start"}
                          label={"Start Time"}
                          className={"w-full mr-2"}
                        />
                      </div>

                      <div className={"flex flex-col"}>
                        <div className={"text-sm font-medium text-gray-700"}>
                          End
                        </div>

                        <TimeSelect
                          options={formikOptions}
                          name={"end"}
                          label={"End Time"}
                          className={"w-full"}
                        />
                      </div>
                    </div>
                  </div>
                )}

                <FormBoolean
                  options={formikOptions}
                  label={"Hide item when unavailable"}
                  name={"hideItem"}
                  tooltip={
                    "If this is set to yes, the item will not be shown on the POS, Order App, or Order Website when it is unavailable. If this is set to no, the item will be unavailable for purchase, but will still display on all platforms."
                  }
                  flex
                />
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

ProductAvailabilityModal.propTypes = {
  updateState: PropTypes.func,
  addState: PropTypes.func,
};

export default ProductAvailabilityModal;
