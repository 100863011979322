import React, {Component} from "react";
import StepNavCard from "../../../../features/step-nav-card";
import {setupReduxConnection} from "../../../../redux";
import SingleLocationWelcomeComponent from "../../../../features/finance/cash-account/onboarding/single-location-welcome-component";
import BusinessInformationComponent from "../../../../features/finance/cash-account/onboarding/business-information-component";

class CashAccountGroupOnboardingPage extends Component {
  getSteps() {
    const {companyLocations, cashAccountGroup} = this.props.shop;

    let isMultiLocationCompany = companyLocations.length > 1;

    // TODO: Account for multi-location

    const steps = [
      {
        label: "Welcome",
        component: SingleLocationWelcomeComponent,
        hideBack: true,
        onboardingStatus: "NO_GROUP",
      },
      {
        label: "Business Information",
        component: BusinessInformationComponent,
        onboardingStatus: "ONBOARDING",
        hideBack: true,
      },
    ];

    const defaultStep = steps.findIndex(
      (_step) => _step.onboardingStatus === cashAccountGroup?.STATUS
    );

    return {steps, defaultStep};
  }

  render() {
    const {router} = this.props;

    let {steps, defaultStep} = this.getSteps();

    return (
      <div>
        <StepNavCard
          label={`Cash Account Setup`}
          router={router}
          steps={steps}
          defaultStep={defaultStep}
        />
      </div>
    );
  }
}

export default setupReduxConnection(["shop"])(CashAccountGroupOnboardingPage);
