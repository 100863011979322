import React, {Component} from "react";
import {withRouter} from "../../../../utils/navigation";
import {
    Button,
    Card,
    FormDate,
    FormInput,
    FormTextArea,
    PageHeadings,
} from "@frostbyte-technologies/frostbyte-tailwind";
import LoadingSpinner from "../../../../components/loading-spinner";
import * as Yup from "yup";
import VendorDropdown from "../../../../dropdowns/operations/vendor-dropdown";
import FormRow from "../../../../components/form-row";
import Wizard from "../../../../components/wizard";
import PurchaseOrderItemsForm from "../../../../forms/operations/supply/purchase-orders/purchase-order-items-form";
import {PurchaseOrderRequests} from "../../../../utils/request-helpers/supply-chain/supply-chain-requests";
import {getStore} from "../../../../redux";
import {updateVendor} from "../../../../redux/supply";
import {Formik} from "formik";
import {Row} from "../shared";

class CreatePurchaseOrderPage extends Component {
    state = {
        loading: false,
        allVendorItems: [],
    };

    transformPurchaseOrderItems(items) {
        return items.map((purchaseOrderItem) => {
            const {OBJECT_ID, TOTAL, QUANTITY, NAME} = purchaseOrderItem;

            return {
                UNIQUE_ID: OBJECT_ID,
                NAME,
                PRICE_PER_CASE: TOTAL,
                QUANTITY,
            };
        });
    }

    fetchExistingPurchaseOrder(id) {
        this.setState({loading: true}, async () => {
            const purchaseOrder = await PurchaseOrderRequests.fetchPurchaseOrder(id);
            purchaseOrder.ITEMS = this.transformPurchaseOrderItems(purchaseOrder.ITEMS);

            this.setState({
                purchaseOrder,
                loading: false,
            });
        });
    }

    componentDidMount() {
        const url = new URL(window.location.href);
        const id = url.searchParams.get("purchase_order");

        if (id) {
            this.fetchExistingPurchaseOrder(id);
        }
    }

    async createPurchaseOrder({vendor, vendorItems, notes, date, number}) {
        console.log('Lets go');
        const purchaseOrder = await PurchaseOrderRequests.createPurchaseOrder({
            ITEMS: vendorItems,
            VENDOR_ID: vendor,
            NOTES: notes,
            DELIVERY_DATE: date,
            PO_NUMBER: number,
        });

        this.props.router.navigate("/purchase-order/" + purchaseOrder.UNIQUE_ID);
    }

    async savePurchaseOrder({vendor, vendorItems, notes, date, number}) {
        const {purchaseOrder} = this.state;
        console.log('Lets not go');

        await PurchaseOrderRequests.updatePurchaseOrder(purchaseOrder.ID, {
            ITEMS: vendorItems,
            VENDOR_ID: vendor,
            NOTES: notes,
            DELIVERY_DATE: date,
            PO_NUMBER: number,
        });

        this.props.router.navigate("/purchase-order/" + purchaseOrder.UNIQUE_ID);
    }

    fetchInitialValues() {
        const {purchaseOrder} = this.state;

        return {
            vendor: purchaseOrder?.VENDOR?.ID ?? null,
            vendorItems: purchaseOrder?.ITEMS ?? [],
            notes: purchaseOrder?.NOTES ?? "",
            number: purchaseOrder?.PO_NUMBER ?? null,
            date: purchaseOrder?.DATE_DELIVERED ?? Date.now(),
        };
    }

    fetchValidationSchema() {
        return Yup.object({
            vendor: Yup.number().nullable().required("Please select a vendor."),
            vendorItems: Yup.array().of(
                Yup.object().shape({
                    NAME: Yup.string().nullable().required("Please enter a name for this item."),
                    QUANTITY: Yup.number()
                        .nullable()
                        .required("Please enter a quantity for this item."),
                    PRICE_PER_CASE: Yup.number()
                        .nullable()
                        .required("Please enter a price for this item."),
                    ID: Yup.number().nullable().required("Please select a vendor item."),
                })
            ),
            notes: Yup.string(),
            number: Yup.number().nullable().required("Please enter the order number."),
            date: Yup.number(),
        });
    }

    renderVendorDropdown(formikOptions) {
        const {values} = formikOptions;
        const {vendor, vendorItems} = values;

        return (
            <VendorDropdown
                options={formikOptions}
                name="vendor"
                label="Vendor"
                disabled={vendor && vendorItems.length > 0}
                onChangeSoft={async (vendor) => {
                    await getStore().dispatch(updateVendor(vendor.ID));
                }}
            />
        );
    }

    renderOrderNumberInput(formikOptions) {
        return (
            <FormInput name="number" label={"Order Number"} options={formikOptions} flex/>
        );
    }

    renderDeliveryDateInput(formikOptions) {
        return (
            <FormDate
                name="delivery"
                label="Delivery Date"
                tooltip="On what date do you expect this purchase order to be delivered. This is used to track average vendor delays."
                options={formikOptions}
                flex
                dateFormat={"P"}
                showTime={false}
            />
        );
    }

    renderNotesInput(formikOptions) {
        return (
            <FormTextArea
                name="notes"
                label={"Notes"}
                hint={"Optional"}
                options={formikOptions}
            />
        );
    }

    renderGeneralInformationCard(formikOptions) {
        return (
            <Card
                label="Purchase Order Information"
                className="p-4 mb-4"
            >
                {this.renderVendorDropdown(formikOptions)}

                <FormRow>
                    {this.renderOrderNumberInput(formikOptions)}
                    {this.renderDeliveryDateInput(formikOptions)}
                </FormRow>

                {this.renderNotesInput()}
            </Card>
        );
    }

    renderAddItemButton(formikOptions) {
        const {values} = formikOptions;
        const {vendor} = values;

        return {
            label: "Add Item",
            disabled: !vendor,
            onClick: () => this.onCreatePurchaseOrderItem(formikOptions),
        }
    }

    onCreatePurchaseOrderItem(formikOptions) {
        const {values, setFieldValue} = formikOptions;
        const {vendorItems} = values;

        const items = [
            ...vendorItems,
            {
                NAME: "New Item",
                QUANTITY: 1,
                PRICE_PER_CASE: 0,
                ID: null,
            },
        ];

        setFieldValue("vendorItems", items);
    }

    onAddPurchaseOrderItem(idx, item, formikOptions) {
        const {values, setFieldValue} = formikOptions;
        const {vendorItems} = values;

        vendorItems[idx] = {
            ...vendorItems[idx],
            ...item,
        };

        setFieldValue("vendorItems", vendorItems);
    }

    onRemovePurchaseOrderItem(idx, formikOptions) {
        const {values, setFieldValue} = formikOptions;
        const {vendorItems} = values;

        vendorItems.splice(idx, 1);
        setFieldValue("vendorItems", vendorItems);
    }

    renderPurchaseOrderItemsForm(formikOptions) {
        const {values} = formikOptions;
        const {vendor, vendorItems} = values;

        return (
            <Card
                label="Purchase Order Information"
                button={this.renderAddItemButton(formikOptions)}
            >
                <PurchaseOrderItemsForm
                    vendor={vendor}
                    vendorItems={vendorItems}
                    addItem={(idx, item) => this.onAddPurchaseOrderItem(idx, item, formikOptions)}
                    removeItem={(idx) => this.onRemovePurchaseOrderItem(idx, formikOptions)}
                />
            </Card>
        );
    }

    renderButton(formikOptions) {
        const {values, handleSubmit} = formikOptions;
        const {purchaseOrder} = this.state;
        return (
            <Row className={"mt-4 justify-end"}>
                {purchaseOrder?.ID
                    ? <Button
                        label="Save Purchase Order"
                        type="button"
                        className="text-indigo-600 hover:text-indigo-900 cursor-pointer"
                        onClick={handleSubmit}
                    />
                    : <Button
                        label="Create Purchase Order"
                        type="button"
                        className="text-indigo-600 hover:text-indigo-900 cursor-pointer"
                        onClick={() => {
                            console.log('calling submit')
                            handleSubmit()
                        }}
                    />
                }
            </Row>
        );
    }

    renderForm() {
        const {purchaseOrder} = this.state;
        return <Formik
            initialValues={this.fetchInitialValues()}
            validationSchema={this.fetchValidationSchema()}
            onSubmit={(values) =>
                purchaseOrder?.ID
                    ? this.savePurchaseOrder(values).bind(this)
                    : this.createPurchaseOrder(values).bind(this) }
        >
            {(formikOptions) => {

                return (
                    <>
                        {this.renderGeneralInformationCard(formikOptions)}
                        {this.renderPurchaseOrderItemsForm(formikOptions)}
                        {this.renderButton(formikOptions)}
                    </>
                )
            }}
        </Formik>
    }

    renderPageHeader() {
        return (
            <PageHeadings
                className="py-4"
                label="Create Purchase Order"
                description="Select the vendor items you would like to purchase"
            />
        );
    }

    render() {
        const {loading} = this.state;

        if (loading) {
            return <LoadingSpinner/>;
        }

        return (
            <>
                {this.renderPageHeader()}
                {this.renderForm()}
            </>
        );
    }
}

export default withRouter(CreatePurchaseOrderPage);
