import React, {Component} from "react";
import {PageHeadings} from "@frostbyte-technologies/frostbyte-tailwind";
import FixedColumnReport from "../../../features/reporting/reports/fixed-column-report";
import {CELL_TYPES, SALES_TO_COGS_COLUMNS,} from "../../../features/reporting/reports/reporting-constants";
import ReportingGraphContainer from "../../../features/reporting/graphs/reporting-graph-container";
import ReportingSalesGraph, {REPORTING_GRAPH_TYPES,} from "../../../features/reporting/graphs/reporting-sales-graph";
import {setupReduxConnection} from "../../../redux";
import SingleReportingTooltip from "../../../features/reporting/graphs/tooltips/single-reporting-tooltip";
import {toDollars} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import ReportingProductIngredientUsageGraph
    from "../../../features/reporting/graphs/reporting-product-ingredient-usage-graph";
import Banner from "../../../components/banner";

const TEXT_COLORS = {
    ESTIMATED: "#0d388b",
    CALCULATED: "#dcbe27",
    REAL: "#0d8b1f",
}

class ProductSalesToCogs extends Component {
    state = {
        reportData: {
            estimated: true,
            calculated: true,
            real: true,
        },
    };
    convertDataToRows = (reportData) => {
        const {NUMBERS: numbers, TOTALS: totals} = reportData;

        const rows = numbers.map((row) => {
            return {
                label: (
                    <div className="text-left">
                        <div className="font-semibold">{row.NAME} x {row.LINE_ITEM_INFORMATION.length}</div>
                    </div>
                ),
                numbers: row.TIMESPAN,
                style: CELL_TYPES.REGULAR,
                expandCell: (row) => {
                    return (
                        <ReportingProductIngredientUsageGraph
                            data={Object.values(row.numbers.INGREDIENTS)
                                .filter((ingredient) => ingredient.INGREDIENT_TYPE === "STANDARD")
                                .map((ingredient) => {
                                    return {name: ingredient.INGREDIENT_NAME, value: ingredient.COST};
                                })}
                        />
                    );
                },
            };
        });

        rows.push({
            label: "Total",
            numbers: totals.TIMESPAN,
            style: CELL_TYPES.BOLD,
        });

        return rows;
    };

    estimatedDataSet = {
        id: "estimated",
        name: "Estimated Cost",
        dataKey: "ESTIMATED_GROSS_MARGIN_PERCENTAGE",
        extraKeyValue: "ESTIMATED_COGS",
        extraKeyName: "estimatedCost",
        stroke: TEXT_COLORS.ESTIMATED,
    }
    calculatedDataSet = {
        id: "calculated",
        name: "Calculated Cost",
        dataKey: "CALCULATED_GROSS_MARGIN_PERCENTAGE",
        extraKeyValue: "CALCULATED_COGS",
        extraKeyName: "calculatedCost",
        stroke: TEXT_COLORS.CALCULATED,
    }
    realDataSet = {
        id: "real",
        name: "Real Cost",
        dataKey: "GROSS_MARGIN_PERCENTAGE",
        extraKeyValue: "COGS",
        extraKeyName: "realCost",
        stroke: TEXT_COLORS.REAL,
    }


    createDataSet = ({id, name, dataKey, extraKeyValue, extraKeyName, stroke}, reportData) => ({
        id,
        name,
        rawData: reportData.TOTALS,
        dataKey,
        countKey: "NET_SALES",
        extraKeys: [
            {
                keyValue: extraKeyValue,
                keyName: extraKeyName,
            },
        ],
        stroke,
        strokeWidth: 2,
        fill: "#FFFFFF00",
        fillOpacity: 0.7,
        stopNow: true,
        type: "monotone",
    })
    salesTooltip =
        {
            label: "Sales",
            formatValue: (payload) => {
                return toDollars(payload[`calculatedCount`], true);
            },
        }
    estimatedTooltips = [
        {
            label: "Estimated Cost",
            formatValue: (payload) => {
                return toDollars(payload[`estimatedCost`], true);
            },
        },
        {
            label: "Estimated Gross Margin",
            formatValue: (payload) => (payload[`estimatedValue`] * 100).toFixed(2) + "%",
        },
    ]
    calculatedTooltips = [
        {
            label: "Calculated Cost",
            formatValue: (payload) => {
                return toDollars(payload[`calculatedCost`], true);
            },
        },
        {
            label: "Calculated Gross Margin",
            formatValue: (payload) => (payload[`calculatedValue`] * 100).toFixed(2) + "%",
        },
    ]
    realTooltips = [
        {
            label: "Real Cost",
            formatValue: (payload) => {
                return toDollars(payload[`realCost`], true);
            },
        },
        {
            label: "Real Gross Margin",
            formatValue: (payload) => (payload[`realValue`] * 100).toFixed(2) + "%",
        },
    ]

    renderCogsGraph(reportData) {
        const datasets = [this.estimatedDataSet, this.calculatedDataSet, this.realDataSet,]
            .map(item => this.createDataSet(item, reportData))

        const tooltips = [this.salesTooltip, this.estimatedTooltips, this.calculatedTooltips, this.realTooltips]
            .flat()

        return (
            <>
                <ReportingSalesGraph
                    height={150}
                    displayAsPercentage={true}
                    showLegend={true}
                    toggleDatasets={true}
                    datasets={datasets}
                    tooltip={
                        <SingleReportingTooltip
                            className={"bg-gray-100 p-2 rounded-md"}
                            formatLabel={(payload) => {
                                return payload[`calculatedDate`];
                            }}
                            rows={tooltips}
                        />
                    }
                    type={REPORTING_GRAPH_TYPES.AREA.id}
                    dataKey={"GROSS_MARGIN"}
                    countKey={"PAYMENT_COUNT"}
                    ignoreWeekdayLabels={true}
                />
            </>
        );
    }

    renderGraph(reportData) {
        return (
            <div>
                <ReportingGraphContainer
                    className="mb-4"
                    graph={this.renderCogsGraph(reportData)}
                />
            </div>
        );
    }

    render() {
        return (
            <div>
                <Banner
                    className="my-4"
                    label="Updated Terminology"
                    description="Estimated refers to the cost of a product based on estaimted cost,
                     set on the product. Caclculated refers to the default cost of the ingredients used on the recipe for the product.
                     Real refers to the activities tracked on the ingredients used on the recipe for the product."
                />
                <div className="mb-5">
                    <PageHeadings label="Cost of Goods Report"/>
                </div>

                <FixedColumnReport
                    filenamePrefix="cost-of-goods"
                    endpoint="report/sales_to_cogs"
                    constantColumns={SALES_TO_COGS_COLUMNS}
                    convertDataToRows={this.convertDataToRows}
                    graph={(reportData) => this.renderGraph(reportData)}
                    locationPicker
                    firstColLabel="Product"
                    firstColTooltip="This column lists the names of all ingredients that have been used during the selected time period."
                />
            </div>
        );
    }
}

export default setupReduxConnection(["shop", "reporting"])(ProductSalesToCogs);
