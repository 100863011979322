import ProductsPage from "../pages/sales/menu/products/products-page";
import ProductPage from "../pages/sales/menu/products/product-page";
import TicketsPage from "../pages/sales/tickets/tickets-page";
import TicketPage from "../pages/sales/tickets/ticket-page";
import ScheduledTicketsPage from "../pages/sales/tickets/scheduled-tickets-page";
import SalesSideNav from "../layouts/side-navs/sales-side-nav";
import MenuPage from "../pages/sales/menu/menu-page";
import SalesReportPage from "../pages/reporting/sales-report-page";
import ReportingSideNav from "../layouts/side-navs/reporting-side-nav";
import PaymentMethodsReportPage from "../pages/reporting/payment-methods-report-page";
import AllProductsSalesReportPage from "../pages/reporting/all-products-sales-report";
import CategorySalesReportPage from "../pages/reporting/category-sales-report-page";
import CategoriesPage from "../pages/sales/menu/categories/categories-page";
import PlatformSalesReportPage from "../pages/reporting/platform-sales-report-page";
import GiftCardSalesReport from "../pages/reporting/gift-card-sales-report-page";
import OrderTypeSalesReport from "../pages/reporting/order-type-sales-report-page";
import IndividualProductSalesReport from "../pages/reporting/individual-product-sales-report";
import SchedulingPage from "../pages/team/scheduling/scheduling-page";
import TeamSideNav from "../layouts/side-navs/team-side-nav";
import ModifiersPage from "../pages/sales/menu/modifier-page/modifiers-page";
import EmployeesPage from "../pages/team/employees/employees-page";
import RolesPage from "../pages/team/roles/roles-page";
import PayrollHistoryPage from "../pages/finance/payroll/payroll-history-page";
import PastPayrollPage from "../pages/finance/payroll/past-payroll-page";
import PayrollCompanyTaxDocumentsPage from "../pages/finance/payroll/payroll-company-tax-documents-page";
import PayrollEmployeesPage from "../pages/finance/payroll/payroll-employees-page";
import PayrollEmployeeTaxDocumentsPage from "../pages/finance/payroll/employee-dashboard/payroll-employee-tax-documents-page";
import PayrollSettingsPage from "../pages/finance/payroll/payroll-settings-page";
import EmployeePage from "../pages/team/employees/employee-page";
import TimesheetsPage from "../pages/team/timesheets-page";
import RunPayrollPage from "../pages/finance/payroll/run-payroll-page";
import CreateProductPage from "../pages/sales/menu/products/create-product-page";
import CreateEmployeePage from "../pages/team/employees/create-employee-page";
import CreateRolePage from "../pages/team/roles/create-role-page";
import RolePage from "../pages/team/roles/role-page";
import FinanceSideNav from "../layouts/side-navs/finance-side-nav";
import PayoutsPage from "../pages/finance/payouts/payouts-page";
import PayoutSettingsPage from "../pages/finance/payouts/payout-settings-page";
import TaxWithholdingPage from "../pages/finance/payouts/tax-withholding-page";
import CategoryPage from "../pages/sales/menu/categories/category-page";
import CardsPage from "../pages/finance/banking/cards-page";
import SetupCashAccountPage from "../pages/finance/banking/setup-cash-account-page";
import CashAccountSettingsPage from "../pages/finance/banking/cash-account-settings-page";
import CashAccountPayoutsPage from "../pages/finance/banking/cash-account-payouts-page";
import TeamPage from "../pages/team/team-page";
import ApprovalCenterPage from "../pages/team/approvals/approval-center-page";
import PatronsPage from "../pages/marketing/patrons/patrons-page";
import MarketingSideNav from "../layouts/side-navs/marketing-side-nav";
import OperationsSideNav from "../layouts/side-navs/operations-side-nav";
import CashPage from "../pages/operations/cash/cash-page";
import CashDrawersPage from "../pages/operations/cash/drawer/cash-drawers-page";
import CashDrawerPage from "../pages/operations/cash/drawer/cash-drawer-page";
import TipBucketsPage from "../pages/operations/cash/buckets/tip-buckets-page";
import IngredientsPage from "../pages/operations/ingredients/ingredient/ingredients-page";
import UnitsPage from "../pages/operations/ingredients/units-page";
import StockingsPage from "../pages/operations/ingredients/stockings-page";
import LabelsPage from "../pages/operations/labels/labels-page";
import LabelCategoriesPage from "../pages/operations/labels/label-categories-page";
import ChecklistsPage from "../pages/operations/checklists/checklists-page";
import InventoriesPage from "../pages/operations/inventory/inventories-page";
import ChatsPage from "../pages/operations/chats/chats-page";
import FundraisersPage from "../pages/marketing/fundraisers/fundraisers-page";
import GiftCardsPage from "../pages/marketing/cards/gift-cards-page";
import PromotionsPage from "../pages/marketing/deals/promotions/promotions-page";
import DealsPage from "../pages/marketing/deals/deals-page";
import CouponsPage from "../pages/marketing/deals/coupons/coupons-page";
import CampaignsPage from "../pages/marketing/campaigns/campaigns-page";
import FundraiserPage from "../pages/marketing/fundraisers/fundraiser-page";
import LoyaltyPage from "../pages/marketing/loyalty/loyalty-page";
import PaymentTypesPage from "../pages/sales/flow/payment/payment-types-page";
import TicketTypesPage from "../pages/sales/flow/ticket/ticket-types-page";
import PromptsPage from "../pages/sales/flow/prompts/prompts-page";
import DiscountsPage from "../pages/sales/discounts/discounts-page";
import MemosPage from "../pages/team/memos/memos-page";
import MemoPage from "../pages/team/memos/memo-page";
import CouponPage from "../pages/marketing/deals/coupons/coupon-page";
import CreateCouponPage from "../pages/marketing/deals/coupons/create-coupon-page";
import PromotionPage from "../pages/marketing/deals/promotions/promotion-page";
import CreatePromotionPage from "../pages/marketing/deals/promotions/create-promotion-page";
import IngredientPage from "../pages/operations/ingredients/ingredient/ingredient-page";
import ChecklistPage from "../pages/operations/checklists/checklist-page";
import TicketTypePage from "../pages/sales/flow/ticket/ticket-type-page";
import PaymentTypePage from "../pages/sales/flow/payment/payment-type-page";
import PromptPage from "../pages/sales/flow/prompts/prompt-page";
import DiscountPage from "../pages/sales/discounts/discount-page";
import ShopSideNav from "../layouts/side-navs/shop-side-nav";
import ShopPage from "../pages/shop/shop-page";
import DevicesPage from "../pages/shop/devices/devices-page";
import PatronPage from "../pages/marketing/patrons/patron-page";
import LabelPage from "../pages/operations/labels/label-page";
import TimeCardsReportPage from "../pages/reporting/time-cards-report-page";
import ModifierSalesReportPage from "../pages/reporting/modifier-sales-report-page";
import InventoryPage from "../pages/operations/inventory/inventory-page";
import InventoriesLogPage from "../pages/operations/inventory/inventories-log-page";
import {PERMISSIONS} from "../utils/constants";
import TaskPage from "../pages/marketing/tasks/task-page";
import HoursPage from "../pages/shop/hours-page";
import NewPage from "../pages/marketing/news/new-page";
import WebsitesPage from "../pages/shop/websites/websites-page";
import ChatPage from "../pages/operations/chats/chat-page";
import RedemptionsPage from "../pages/marketing/loyalty/redemptions-page";
import ShopSettingsPage from "../pages/shop/shop-settings-page";
import EmailCampaignPage from "../pages/marketing/campaigns/email/email-campaign-page";
import KioskSettingsPage from "../pages/shop/devices/kiosk-settings-page";
import GiftCardPage from "../pages/marketing/cards/gift-card-page";
import CheckBenefitIntegrationPage, {
  CHECK_BENEFIT_INTEGRATIONS,
} from "../pages/finance/payroll/check-components/check-benefit-integration-page";
import PayrollJournalPage from "../pages/finance/payroll/payroll-journal-page";
import InvoicesPage from "../pages/operations/invoices/invoices-page";
import CustomersPage from "../pages/operations/invoices/contacts-page";
import CustomerPage from "../pages/operations/invoices/contact-page";
import InvoicePage from "../pages/operations/invoices/invoice-page";
import DepartmentsPage from "../pages/team/departments/departments-page";
import DepartmentPage from "../pages/team/departments/department-page";
import DevicePage from "../pages/shop/devices/device-page";
import TimeOffPoliciesPage from "../pages/team/policies/policies-page";
import CreatePolicyPage from "../pages/team/policies/create-policy-page";
import PolicyPage from "../pages/team/policies/policy-page";
import RunPolicyPage from "../pages/team/policies/run-policy-page";
import RunPoliciesPage from "../pages/team/policies/run-policies-page";
import TagsPage from "../pages/sales/tags/tags-page";
import TagPage from "../pages/sales/tags/tag-page";
import ReorderPage from "../pages/team/scheduling/reorder-page";
import TaxableReportPage from "../pages/reporting/taxable-report-page";
import CreateInvoicePage from "../pages/operations/invoices/create-invoice-page";
import PayrollOnboardingPage from "../pages/finance/payroll/main-pages/payroll-onboarding-page";
import OrderWebsiteQRCodesPage from "../pages/shop/websites/order-websites-qr-codes-page";
import InvoiceDashboardPage from "../pages/operations/invoices/invoicing-page";
import DiscountsReportPage from "../pages/reporting/discounts-report-page";
import CompanyStructurePage from "../pages/shop/company-structure/company-structure-page";
import CompanyPage from "../pages/shop/company-structure/company-page";
import HoursOverridesPage from "../pages/shop/hours-overrides-page";
import LaborVsCostReportPage from "../pages/reporting/labor-vs-cost-report-page";
import OnboardingPage from "../pages/team/onboarding/onboarding-page";
import ProcessesPage from "../pages/team/onboarding/processes-page";
import CreateProcessPage from "../pages/team/onboarding/create-process-page";
import CompanyDocumentsPage from "../pages/team/onboarding/company-documents-page";
import ConfigureDocumentPage from "../pages/team/onboarding/configure-document-page";
import PayrollContractorsPage from "../pages/finance/payroll/payroll-contractors-page";
import CompanyAdminsPage from "../pages/shop/company-structure/company-admins-page";
import ThirdPartyPage from "../pages/sales/third/third-party-page";
import RulesPage from "../pages/marketing/loyalty/rules-page";
import CashDrawerReportPage from "../pages/reporting/cash-drawer-report-page";
import TaxesPage from "../pages/sales/menu/taxes/taxes-page";
import TaxPage from "../pages/sales/menu/taxes/tax-page";
import TaxesReportPage from "../pages/reporting/tax-report-page";
import BillingPage from "../pages/finance/billing/billing-page";
import CustomPaymentTipsReport from "../pages/reporting/custom-payment-type-report-page";
import StoreBanksPage from "../pages/operations/cash/banks/store-banks-page";
import BankTemplatePage from "../pages/operations/cash/banks/bank-template-page";
import CashFlowReportPage from "../pages/reporting/cash-flow-report-page";
import InvoicingReportPage from "../pages/reporting/invoicing-report-page";
import ChecksPage from "../pages/sales/tickets/checks-page";
import CheckPage from "../pages/sales/tickets/check-page";
import ServiceFeesPage from "../pages/sales/menu/fees/service-fees-page";
import ServiceFeePage from "../pages/sales/menu/fees/service-fee-page";
import StoreBankPage from "../pages/operations/cash/banks/store-bank-page";
import PayrollContractorTaxDocumentsPage from "../pages/finance/payroll/payroll-contractor-tax-documents-page";
import CapitalsPage from "../pages/finance/capital/capitals-page";
import OfferPage from "../pages/finance/capital/offer-page";
import BreakOptionsPage from "../pages/team/breaks/break-options-page";
import TextCampaignPage from "../pages/marketing/campaigns/text/text-campaign-page";
import PayoutPage from "../pages/finance/payouts/payout-page";
import IngredientSalesReportPage from "../pages/reporting/ingredient-sales-report-page";
import InventorySalesReportPage from "../pages/reporting/inventory-sales-report-page";
import TeamSalesReportPage from "../pages/reporting/team-sales-report-page";
import ReceiptsPage from "../pages/sales/flow/receipts/receipts-page";
import ReceiptPage from "../pages/sales/flow/receipts/receipt-page";
import SubscriptionListsPage from "../pages/marketing/campaigns/subscription-lists/subscription-lists-page";
import SubscriptionListPage from "../pages/marketing/campaigns/subscription-lists/subscription-list-page";
import BlackoutDatesPage from "../pages/team/policies/blackout-dates-page";
import GratuitiesPage from "../pages/sales/flow/gratuity/gratuities-page";
import GratuityPage from "../pages/sales/flow/gratuity/gratuity-page";
import CreditsPage from "../pages/marketing/campaigns/credits/credits-page";
import BillingAccountsPage from "../pages/operations/billing-accounts/billing-accounts-page";
import BillingAccountPage from "../pages/operations/billing-accounts/billing-account-page";
import PayrollBenefitsPage from "../pages/finance/payroll/benefits/payroll-benefits-page";
import CustomBenefitsPage from "../pages/finance/payroll/benefits/payroll-custom-benefits-page";
import PayrollCustomBenefitPage from "../pages/finance/payroll/benefits/payroll-custom-benefit-page";
import TextCampaignInstancePage from "../pages/marketing/campaigns/text/text-campaign-instance-page";
import PayrollMainPage from "../pages/finance/payroll/main-pages/payroll-main-page";
import SupplyChainInventoryPage from "../pages/operations/supply-chain/recipes/supply-chain-inventory-page";
import SupplyChainUnitsPage from "../pages/operations/supply-chain/custom-units/units-page";
import RecipePage from "../pages/operations/supply-chain/recipes/recipe-page";
import PurchaseOrderPage from "../pages/operations/supply-chain/purchase-orders/purchase-order-page";
import PurchaseOrdersPage from "../pages/operations/supply-chain/purchase-orders/purchase-orders-page";
import CompareSalesReport from "../pages/reporting/compare-sales-report";
import TicketCompletionReportPage from "../pages/reporting/ticket-completion-report-page";
import ChecklistReportPage from "../pages/reporting/checklist-report-page";
import CreatePurchaseOrderPage from "../pages/operations/supply-chain/purchase-orders/create-purchase-order-page";
import UsagePage from "../pages/operations/supply-chain/recipes/usage-page";
import InventoryCountsPage from "../pages/operations/inventory-counts/inventory-counts-page";
import InventoryCountPage from "../pages/operations/inventory-counts/inventory-count-page";
import ReceivePurchaseOrderPage from "../pages/operations/supply-chain/purchase-orders/receive-purchase-order-page";
import CashAccountMainPage from "../pages/finance/cash-account/main/cash-account-main-page";
import CashAccountCardsPage from "../pages/finance/cash-account/cash-account-cards-page";
import BillingAccountPaymentPage from "../pages/operations/billing-accounts/billing-account-payment-page";
import ModifierPage from "../pages/sales/menu/modifier-page/modifier-page";
import AccountingMainPage from "../pages/accounting/accounting-main-page";
import AccountingTransactionsPage from "../pages/accounting/accounting-transactions-page";
import AccountingAccountsPage from "../pages/accounting/accounts-page";
import BankConnectionsPage from "../pages/accounting/bank-connections-page";
import AccountingAccountPage from "../pages/accounting/account-page";
import BalanceSheetPage from "../pages/accounting/balance-sheet-page";
import AutomatedEntriesPage from "../pages/accounting/automated-entries-page";
import IncomeStatementPage from "../pages/accounting/income-statement-page";
import AccountingSettingsPage from "../pages/accounting/accounting-settings-page";
import SupplyChainVendorsPage from "../pages/operations/supply-chain/vendors/vendors-page";
import SupplyChainVendorPage from "../pages/operations/supply-chain/vendors/vendor-page";
import AccountingVendorsPage from "../pages/accounting/vendors-page";
import AccountingVendorPage from "../pages/accounting/vendor-page";
import BillsPage from "../pages/accounting/bills/bills-page";
import BillPage from "../pages/accounting/bills/bill-page";
import WastesPage from "../pages/operations/supply-chain/wastes/wastes-page";
import WastePage from "../pages/operations/supply-chain/wastes/waste-page";
import IngredientUsageReportPage from "../pages/reporting/ingredient-usage-report-page";
import TransfersPage from "../pages/operations/supply-chain/transfers/transfers-page";
import CreateTransferPage from "../pages/operations/supply-chain/transfers/create-transfer-page";
import TransferPage from "../pages/operations/supply-chain/transfers/transfer-page";
import ReceiveTransferPage from "../pages/operations/supply-chain/transfers/receive-transfer-page";
import SupplyChainLandingPage from "../pages/operations/supply-chain/main/supply-chain-landing-page";
import TaxCategoryReportPage from "../pages/reporting/tax-category-report-page";
import MapPage from "../pages/map/map-page";
import InsurancePage from "../pages/finance/insurance/insurance-page";
import SupplyChainRecipesPage from "../pages/operations/supply-chain/recipes/supply-chain-recipes-page";
import ProductSalesToCogsPage from "../pages/reporting/supply-chain/product-sales-to-cogs-page";
import PostTaxDeductionsPage from "../pages/finance/payroll/post-tax-deductions";
import SupplyChainGroupsPage from "../pages/operations/supply-chain/groups/groups-page";
import SupplyChainGroupPage from "../pages/operations/supply-chain/groups/group-page";

export const PAGE_ROUTES = [
  {
    side: <ShopSideNav />,
    permissions: [PERMISSIONS.SHOP.FULL.id],
    routes: [
      {
        live: true,
        path: "shop",
        element: <ShopPage />,
        permissions: [PERMISSIONS.SHOP.MY_SHOP_FULL.id],
      },
      {
        live: true,
        path: "settings",
        element: <ShopSettingsPage />,
        permissions: [PERMISSIONS.SHOP.MY_SHOP_FULL.id],
      },
      {
        live: true,
        path: "hours",
        element: <HoursPage />,
        permissions: [PERMISSIONS.SHOP.MY_SHOP_FULL.id],
      },
      {
        live: true,
        path: "overrides",
        element: <HoursOverridesPage />,
        permissions: [PERMISSIONS.SHOP.MY_SHOP_FULL.id],
      },
      {
        live: true,
        path: "websites",
        element: <WebsitesPage />,
        permissions: [PERMISSIONS.SHOP.MY_SHOP_FULL.id],
      },
      {
        live: true,
        path: "websites/qr",
        element: <OrderWebsiteQRCodesPage />,
        permissions: [PERMISSIONS.SHOP.MY_SHOP_FULL.id],
      },
      {
        live: true,
        path: "devices",
        element: <DevicesPage />,
        permissions: [PERMISSIONS.SHOP.DEVICES_FULL.id],
      },
      {
        live: true,
        path: "device/:ID",
        element: <DevicePage />,
        permissions: [PERMISSIONS.SHOP.DEVICES_FULL.id],
      },
      {
        live: true,
        path: "kiosks",
        element: <KioskSettingsPage />,
        permissions: [PERMISSIONS.SHOP.DEVICES_FULL.id],
      },
      {
        live: false,
        path: "company-structure/companies",
        element: <CompanyStructurePage />,
        permissions: [],
      },
      {
        live: false,
        path: "company-structure/companies",
        element: <CompanyStructurePage />,
        permissions: [],
      },
      {
        live: false,
        path: "company-structure/admins",
        element: <CompanyAdminsPage />,
        permissions: [],
      },
      {
        live: false,
        path: "company-structure/company/:ID",
        element: <CompanyPage />,
        permissions: [],
      },
    ],
  },
  {
    side: <SalesSideNav />,
    permissions: [PERMISSIONS.SALES.FULL.id],
    routes: [
      {
        live: true,
        path: "categories",
        element: <CategoriesPage />,
        permissions: [PERMISSIONS.SALES.MENU_FULL.id],
      },
      {
        live: true,
        path: "category/:ID",
        element: <CategoryPage />,
        permissions: [PERMISSIONS.SALES.MENU_FULL.id],
      },
      {
        live: true,
        path: "products",
        element: <ProductsPage />,
        permissions: [PERMISSIONS.SALES.MENU_FULL.id],
      },
      {
        live: true,
        path: "product",
        element: <CreateProductPage />,
        permissions: [PERMISSIONS.SALES.MENU_FULL.id],
      },
      {
        live: true,
        path: "product/:ID",
        element: <ProductPage />,
        permissions: [PERMISSIONS.SALES.MENU_FULL.id],
      },
      {
        live: true,
        path: "taxes",
        element: <TaxesPage />,
        permissions: [PERMISSIONS.SALES.MENU_FULL.id],
      },
      {
        live: true,
        path: "tax/:ID",
        element: <TaxPage />,
        permissions: [PERMISSIONS.SALES.MENU_FULL.id],
      },
      {
        live: true,
        path: "fees",
        element: <ServiceFeesPage />,
        permissions: [PERMISSIONS.SALES.MENU_FULL.id],
      },
      {
        live: true,
        path: "fee/:ID",
        element: <ServiceFeePage />,
        permissions: [PERMISSIONS.SALES.MENU_FULL.id],
      },
      {
        live: true,
        path: "menu",
        element: <MenuPage />,
        permissions: [PERMISSIONS.SALES.MENU_FULL.id],
      },
      {
        live: true,
        path: "modifiers",
        element: <ModifiersPage />,
        permissions: [PERMISSIONS.SALES.MENU_FULL.id],
      },
      {
        live: true,
        path: "modifier/:ID",
        element: <ModifierPage />,
        permissions: [PERMISSIONS.SALES.MENU_FULL.id],
      },
      {
        live: true,
        path: "tickets",
        element: <TicketsPage />,
        permissions: [PERMISSIONS.SALES.TICKETS_FULL.id],
      },
      {
        live: true,
        path: "scheduled",
        element: <ScheduledTicketsPage />,
        permissions: [PERMISSIONS.SALES.TICKETS_FULL.id],
      },
      {
        live: true,
        path: "checks",
        element: <ChecksPage />,
        permissions: [PERMISSIONS.SALES.TICKETS_FULL.id],
      },
      {
        live: true,
        path: "check/:UNIQUE_ID",
        element: <CheckPage />,
        permissions: [PERMISSIONS.SALES.TICKETS_FULL.id],
      },
      {
        live: true,
        path: "ticket/:ID",
        element: <TicketPage />,
        permissions: [PERMISSIONS.SALES.TICKETS_FULL.id],
      },
      // {
      //   live: false,
      //   path: "orders",
      //   element: <EcommerceTicketsPage />,
      //   permissions: [PERMISSIONS.SALES.ECOMMERCE_FULL.id],
      // },
      // {
      //   live: false,
      //   path: "order/:ID",
      //   element: <EcommerceTicketPage />,
      //   permissions: [PERMISSIONS.SALES.ECOMMERCE_FULL.id],
      // },
      // {
      //   live: true,
      //   path: "boxes",
      //   element: <ShippingBoxesPage />,
      //   permissions: [PERMISSIONS.SALES.ECOMMERCE_FULL.id],
      // },
      // {
      //   live: true,
      //   path: "addresses",
      //   element: <ShippingAddressesPage />,
      //   permissions: [PERMISSIONS.SALES.ECOMMERCE_FULL.id],
      // },
      {
        live: true,
        path: "flow/tickets",
        element: <TicketTypesPage />,
        permissions: [PERMISSIONS.SALES.CHECKOUT_FLOW_FULL.id],
      },
      {
        live: true,
        path: "flow/ticket/:ID",
        element: <TicketTypePage />,
        permissions: [PERMISSIONS.SALES.CHECKOUT_FLOW_FULL.id],
      },
      {
        live: true,
        path: "flow/payments",
        element: <PaymentTypesPage />,
        permissions: [PERMISSIONS.SALES.CHECKOUT_FLOW_FULL.id],
      },
      {
        live: true,
        path: "flow/payment/:ID",
        element: <PaymentTypePage />,
        permissions: [PERMISSIONS.SALES.CHECKOUT_FLOW_FULL.id],
      },
      {
        live: true,
        path: "gratuity",
        element: <GratuitiesPage />,
        permissions: [PERMISSIONS.SALES.CHECKOUT_FLOW_FULL.id],
      },
      {
        live: true,
        path: "gratuity/:ID",
        element: <GratuityPage />,
        permissions: [PERMISSIONS.SALES.CHECKOUT_FLOW_FULL.id],
      },
      {
        live: true,
        path: "prompts",
        element: <PromptsPage />,
        permissions: [PERMISSIONS.SALES.CHECKOUT_FLOW_FULL.id],
      },
      {
        live: true,
        path: "prompt/:ID",
        element: <PromptPage />,
        permissions: [PERMISSIONS.SALES.CHECKOUT_FLOW_FULL.id],
      },
      {
        live: true,
        path: "receipts",
        element: <ReceiptsPage />,
        permissions: [PERMISSIONS.SALES.CHECKOUT_FLOW_FULL.id],
      },
      {
        live: true,
        path: "receipt/:ID",
        element: <ReceiptPage />,
        permissions: [PERMISSIONS.SALES.CHECKOUT_FLOW_FULL.id],
      },
      {
        live: true,
        path: "discounts",
        element: <DiscountsPage />,
        permissions: [PERMISSIONS.SALES.DISCOUNTS_FULL.id],
      },
      {
        live: true,
        path: "discount/:ID",
        element: <DiscountPage />,
        permissions: [PERMISSIONS.SALES.DISCOUNTS_FULL.id],
      },
      {
        live: true,
        path: "tags",
        element: <TagsPage />,
        permissions: [PERMISSIONS.SALES.TAGS_FULL.id],
      },
      {
        live: true,
        path: "tag/:ID",
        element: <TagPage />,
        permissions: [PERMISSIONS.SALES.TAGS_FULL.id],
      },
      {
        live: true,
        path: "third",
        element: <ThirdPartyPage />,
        permissions: [PERMISSIONS.SALES.MENU_FULL.id],
      },
      // {
      //   live: true,
      //   path: "tables",
      //   element: <TablesPage />,
      //   permissions: [
      //     PERMISSIONS.SALES.TABLE_FULL.id,
      //     PERMISSIONS.SALES.TABLE_EDITING.id,
      //   ],
      // },
      // {
      //   live: true,
      //   path: "centers",
      //   element: <RevenueCentersPage />,
      //   permissions: [PERMISSIONS.SALES.TABLE_FULL.id],
      // },
      // {
      //   live: true,
      //   path: "center/:ID",
      //   element: <RevenueCenterPage />,
      //   permissions: [PERMISSIONS.SALES.TABLE_FULL.id],
      // },
    ],
  },
  {
    side: <TeamSideNav />,
    permissions: [PERMISSIONS.TEAM.FULL.id],
    routes: [
      {
        live: true,
        path: "scheduling",
        element: <SchedulingPage />,
        permissions: [PERMISSIONS.TEAM.SCHEDULING_FULL.id],
      },
      {
        live: true,
        path: "scheduling/reorder",
        element: <ReorderPage />,
        permissions: [PERMISSIONS.TEAM.SCHEDULING_FULL.id],
      },
      {
        live: true,
        path: "cards",
        element: <TimesheetsPage />,
        permissions: [PERMISSIONS.TEAM.TIME_FULL.id],
      },
      {
        live: true,
        path: "breaks",
        element: <BreakOptionsPage />,
        permissions: [PERMISSIONS.TEAM.TIME_FULL.id],
      },
      {
        live: true,
        path: "approvals",
        element: <ApprovalCenterPage />,
        permissions: [
          PERMISSIONS.TEAM.APPROVALS_FULL.id,
          PERMISSIONS.TEAM.APPROVALS_TIME_CARDS.id,
          PERMISSIONS.TEAM.APPROVALS_TIME_OFF.id,
          PERMISSIONS.TEAM.APPROVALS_TRADE_REQUESTS.id,
          PERMISSIONS.TEAM.APPROVALS_OPEN_SHIFT_REQUESTS.id,
        ],
      },
      {
        live: true,
        path: "roles",
        element: <RolesPage />,
        permissions: [PERMISSIONS.TEAM.ROLES_FULL.id],
      },
      {
        live: true,
        path: "role",
        element: <CreateRolePage />,
        permissions: [PERMISSIONS.TEAM.ROLES_FULL.id],
      },
      {
        live: true,
        path: "role/:ID",
        element: <RolePage />,
        permissions: [PERMISSIONS.TEAM.ROLES_FULL.id],
      },
      {
        live: true,
        path: "team",
        element: <TeamPage />,
        permissions: [PERMISSIONS.TEAM.MY_TEAM_FULL.id],
      },
      {
        live: true,
        path: "departments",
        element: <DepartmentsPage />,
        permissions: [PERMISSIONS.TEAM.MY_TEAM_FULL.id],
      },
      {
        live: true,
        path: "department/:ID",
        element: <DepartmentPage />,
        permissions: [PERMISSIONS.TEAM.MY_TEAM_FULL.id],
      },
      {
        live: true,
        path: "employees",
        element: <EmployeesPage />,
        permissions: [PERMISSIONS.TEAM.MY_TEAM_FULL.id],
      },
      {
        live: true,
        path: "employee/:ID",
        element: <EmployeePage />,
        permissions: [PERMISSIONS.TEAM.MY_TEAM_FULL.id],
      },
      {
        live: true,
        path: "employee",
        element: <CreateEmployeePage />,
        permissions: [PERMISSIONS.TEAM.MY_TEAM_FULL.id],
      },
      {
        live: true,
        path: "policies",
        element: <TimeOffPoliciesPage />,
        permissions: [PERMISSIONS.TEAM.POLICIES_FULL.id],
      },
      {
        live: true,
        path: "blackout",
        element: <BlackoutDatesPage />,
        permissions: [PERMISSIONS.TEAM.POLICIES_FULL.id],
      },
      {
        live: true,
        path: "policy",
        element: <CreatePolicyPage />,
        permissions: [PERMISSIONS.TEAM.POLICIES_FULL.id],
      },
      {
        live: true,
        path: "policy/:ID",
        element: <PolicyPage />,
        permissions: [PERMISSIONS.TEAM.POLICIES_FULL.id],
      },
      {
        live: true,
        path: "policy/run",
        element: <RunPoliciesPage />,
        permissions: [PERMISSIONS.TEAM.POLICIES_FULL.id],
      },
      {
        live: true,
        path: "policy/:ID/run",
        element: <RunPolicyPage />,
        permissions: [PERMISSIONS.TEAM.POLICIES_FULL.id],
      },
      {
        live: true,
        path: "policy/:ID/edit",
        element: <CreatePolicyPage />,
        permissions: [PERMISSIONS.TEAM.POLICIES_FULL.id],
      },
      {
        live: true,
        path: "memos",
        element: <MemosPage />,
        permissions: [PERMISSIONS.TEAM.MEMOS_FULL.id],
      },
      {
        live: true,
        path: "memo/:ID",
        element: <MemoPage />,
        permissions: [PERMISSIONS.TEAM.MEMOS_FULL.id],
      },
      {
        live: true,
        path: "onboarding/overview",
        element: <OnboardingPage />,
        permissions: [PERMISSIONS.TEAM.ONBOARDING_FULL.id],
      },
      {
        live: true,
        path: "onboarding/processes",
        element: <ProcessesPage />,
        permissions: [PERMISSIONS.TEAM.ONBOARDING_FULL.id],
      },
      {
        live: true,
        path: "onboarding/process/create/:UNIQUE_ID",
        element: <CreateProcessPage />,
        permissions: [PERMISSIONS.TEAM.ONBOARDING_FULL.id],
      },
      {
        live: true,
        path: "onboarding/company-documents",
        element: <CompanyDocumentsPage />,
        permissions: [PERMISSIONS.TEAM.ONBOARDING_FULL.id],
      },
      {
        live: true,
        path: "onboarding/company-document/:UNIQUE_ID",
        element: <ConfigureDocumentPage />,
        permissions: [PERMISSIONS.TEAM.ONBOARDING_FULL.id],
      },
    ],
  },
  {
    side: <MarketingSideNav />,
    permissions: [PERMISSIONS.MARKETING.FULL.id],
    routes: [
      {
        live: true,
        path: "patrons",
        element: <PatronsPage />,
        permissions: [PERMISSIONS.MARKETING.PATRONS_FULL.id],
      },
      {
        live: true,
        path: "patron/:ID",
        element: <PatronPage />,
        permissions: [PERMISSIONS.MARKETING.PATRONS_FULL.id],
      },
      // {
      //   live: true,
      //   path: "events",
      //   element: <EventsPage />,
      //   permissions: [PERMISSIONS.MARKETING.EVENTS_FULL.id],
      // },
      // {
      //   live: true,
      //   path: "event/:ID",
      //   element: <EventPage />,
      //   permissions: [PERMISSIONS.MARKETING.EVENTS_FULL.id],
      // },
      {
        live: true,
        path: "new/:ID",
        element: <NewPage />,
        permissions: [PERMISSIONS.MARKETING.NEWS_FULL.id],
      },
      {
        live: true,
        path: "gifts",
        element: <GiftCardsPage />,
        permissions: [PERMISSIONS.MARKETING.GIFT_CARDS_FULL.id],
      },
      {
        live: true,
        path: "gifts/:ID",
        element: <GiftCardPage />,
        permissions: [PERMISSIONS.MARKETING.GIFT_CARDS_FULL.id],
      },
      {
        live: true,
        path: "loyalty",
        element: <LoyaltyPage />,
        permissions: [PERMISSIONS.MARKETING.LOYALTY_FULL.id],
      },
      {
        live: true,
        path: "redemptions",
        element: <RedemptionsPage />,
        permissions: [PERMISSIONS.MARKETING.LOYALTY_FULL.id],
      },
      {
        live: true,
        path: "fundraisers",
        element: <FundraisersPage />,
        permissions: [PERMISSIONS.MARKETING.FUNDRAISERS_FULL.id],
      },
      {
        live: true,
        path: "fundraiser/:ID",
        element: <FundraiserPage />,
        permissions: [PERMISSIONS.MARKETING.FUNDRAISERS_FULL.id],
      },
      {
        live: true,
        path: "campaigns",
        element: <CampaignsPage />,
        permissions: [PERMISSIONS.MARKETING.CAMPAIGNS_FULL.id],
      },
      {
        live: true,
        path: "subscriber-lists",
        element: <SubscriptionListsPage />,
        permissions: [PERMISSIONS.MARKETING.CAMPAIGNS_FULL.id],
      },
      {
        live: true,
        path: "subscriber-list/:ID",
        element: <SubscriptionListPage />,
        permissions: [PERMISSIONS.MARKETING.CAMPAIGNS_FULL.id],
      },
      {
        live: true,
        path: "credits",
        element: <CreditsPage />,
        permissions: [PERMISSIONS.MARKETING.CAMPAIGNS_FULL.id],
      },
      {
        live: true,
        path: "campaigns/text/:ID",
        element: <TextCampaignPage />,
        permissions: [PERMISSIONS.MARKETING.CAMPAIGNS_FULL.id],
      },
      {
        live: true,
        path: "campaign/texts/instances/:ID",
        element: <TextCampaignInstancePage />,
        permissions: [PERMISSIONS.MARKETING.CAMPAIGNS_FULL.id],
      },
      {
        live: true,
        path: "campaigns/email/:ID",
        element: <EmailCampaignPage />,
        permissions: [PERMISSIONS.MARKETING.CAMPAIGNS_FULL.id],
      },
      {
        live: true,
        path: "deals",
        element: <DealsPage />,
        permissions: [PERMISSIONS.MARKETING.PROMOTIONS_FULL.id],
      },
      {
        live: true,
        path: "coupon",
        element: <CreateCouponPage />,
        permissions: [PERMISSIONS.MARKETING.PROMOTIONS_FULL.id],
      },
      {
        live: true,
        path: "coupons",
        element: <CouponsPage />,
        permissions: [PERMISSIONS.MARKETING.PROMOTIONS_FULL.id],
      },
      {
        live: true,
        path: "coupon/:ID",
        element: <CouponPage />,
        permissions: [PERMISSIONS.MARKETING.PROMOTIONS_FULL.id],
      },
      {
        live: true,
        path: "promotion",
        element: <CreatePromotionPage />,
        permissions: [PERMISSIONS.MARKETING.PROMOTIONS_FULL.id],
      },
      {
        live: true,
        path: "rules",
        element: <RulesPage />,
        permissions: [PERMISSIONS.MARKETING.LOYALTY_FULL.id],
      },
      {
        live: true,
        path: "task/:ID",
        element: <TaskPage />,
        permissions: [PERMISSIONS.MARKETING.LOYALTY_FULL.id],
      },
      {
        live: true,
        path: "promotions",
        element: <PromotionsPage />,
        permissions: [PERMISSIONS.MARKETING.PROMOTIONS_FULL.id],
      },
      {
        live: true,
        path: "promotion/:ID",
        element: <PromotionPage />,
        permissions: [PERMISSIONS.MARKETING.PROMOTIONS_FULL.id],
      },
    ],
  },
  {
    side: <OperationsSideNav />,
    permissions: [PERMISSIONS.OPERATIONS.FULL.id],
    routes: [
      {
        live: true,
        path: "cash",
        element: <CashPage />,
        permissions: [PERMISSIONS.OPERATIONS.CASH_FULL.id],
      },
      {
        live: true,
        path: "drawers",
        element: <CashDrawersPage />,
        permissions: [PERMISSIONS.OPERATIONS.CASH_FULL.id],
      },
      {
        live: true,
        path: "drawer/:ID",
        element: <CashDrawerPage />,
        permissions: [PERMISSIONS.OPERATIONS.CASH_FULL.id],
      },
      {
        live: true,
        path: "buckets",
        element: <TipBucketsPage />,
        permissions: [PERMISSIONS.OPERATIONS.CASH_FULL.id],
      },
      {
        live: true,
        path: "banks",
        element: <StoreBanksPage />,
        permissions: [PERMISSIONS.OPERATIONS.CASH_FULL.id],
      },
      {
        live: true,
        path: "bank/:ID",
        element: <StoreBankPage />,
        permissions: [PERMISSIONS.OPERATIONS.CASH_FULL.id],
      },
      {
        live: true,
        path: "bank/template/:ID",
        element: <BankTemplatePage />,
        permissions: [PERMISSIONS.OPERATIONS.CASH_FULL.id],
      },
      {
        live: true,
        path: "ingredients",
        element: <IngredientsPage />,
        permissions: [PERMISSIONS.OPERATIONS.INGREDIENTS_FULL.id],
      },
      {
        live: true,
        path: "ingredient/:ID",
        element: <IngredientPage />,
        permissions: [PERMISSIONS.OPERATIONS.INGREDIENTS_FULL.id],
      },
      {
        live: true,
        path: "units",
        element: <UnitsPage />,
        permissions: [PERMISSIONS.OPERATIONS.INGREDIENTS_FULL.id],
      },
      {
        live: true,
        path: "stockings",
        element: <StockingsPage />,
        permissions: [PERMISSIONS.OPERATIONS.INGREDIENTS_FULL.id],
      },
      {
        live: true,
        path: "label/:ID",
        element: <LabelPage />,
        permissions: [PERMISSIONS.OPERATIONS.LABELS_FULL.id],
      },
      {
        live: true,
        path: "labels",
        element: <LabelsPage />,
        permissions: [PERMISSIONS.OPERATIONS.LABELS_FULL.id],
      },
      {
        live: true,
        path: "lategories",
        element: <LabelCategoriesPage />,
        permissions: [PERMISSIONS.OPERATIONS.LABELS_FULL.id],
      },
      {
        live: true,
        path: "checklists",
        element: <ChecklistsPage />,
        permissions: [PERMISSIONS.OPERATIONS.CHECKLISTS_FULL.id],
      },
      {
        live: true,
        path: "checklist/:ID",
        element: <ChecklistPage />,
        permissions: [PERMISSIONS.OPERATIONS.CHECKLISTS_FULL.id],
      },
      {
        live: true,
        path: "inventory",
        element: <InventoriesPage />,
        permissions: [PERMISSIONS.OPERATIONS.INVENTORY_FULL.id],
      },
      {
        live: true,
        path: "inventory/logs",
        element: <InventoriesLogPage />,
        permissions: [PERMISSIONS.OPERATIONS.INVENTORY_FULL.id],
      },
      {
        live: true,
        path: "inventory/:ID",
        element: <InventoryPage />,
        permissions: [PERMISSIONS.OPERATIONS.INVENTORY_FULL.id],
      },
      {
        live: true,
        path: "chats",
        element: <ChatsPage />,
        permissions: [PERMISSIONS.OPERATIONS.SUPPORT_TEXTS_FULL.id],
      },
      {
        live: true,
        path: "chat/:PHONE",
        element: <ChatPage />,
        permissions: [PERMISSIONS.OPERATIONS.SUPPORT_TEXTS_FULL.id],
      },
      {
        live: true,
        path: "invoicing",
        element: <InvoiceDashboardPage />,
        permissions: [PERMISSIONS.OPERATIONS.INVOICES_FULL.id],
      },
      {
        live: true,
        path: "invoice",
        element: <CreateInvoicePage />,
        permissions: [PERMISSIONS.OPERATIONS.INVOICES_FULL.id],
      },
      {
        live: true,
        path: "invoices",
        element: <InvoicesPage />,
        permissions: [PERMISSIONS.OPERATIONS.INVOICES_FULL.id],
      },
      {
        live: true,
        path: "billing-accounts",
        element: <BillingAccountsPage />,
        permissions: [PERMISSIONS.OPERATIONS.INVOICES_FULL.id],
      },
      {
        live: true,
        path: "billing-accounts/:ID",
        element: <BillingAccountPage />,
        permissions: [PERMISSIONS.OPERATIONS.INVOICES_FULL.id],
      },
      {
        live: true,
        path: "billing-accounts/payment/:ID",
        element: <BillingAccountPaymentPage />,
        permissions: [PERMISSIONS.OPERATIONS.INVOICES_FULL.id],
      },
      {
        live: true,
        path: "invoice/:ID",
        element: <InvoicePage />,
        permissions: [PERMISSIONS.OPERATIONS.INVOICES_FULL.id],
      },
      {
        live: true,
        path: "contacts",
        element: <CustomersPage />,
        permissions: [PERMISSIONS.OPERATIONS.INVOICES_FULL.id],
      },
      {
        live: true,
        path: "contact/:ID",
        element: <CustomerPage />,
        permissions: [PERMISSIONS.OPERATIONS.INVOICES_FULL.id],
      },
      {
        live: true,
        path: "supply",
        element: <SupplyChainLandingPage />,
        permissions: [PERMISSIONS.OPERATIONS.SUPPLY_CHAIN_FULL.id],
      },
      {
        live: true,
        path: "supply-chain-inventory",
        element: <SupplyChainInventoryPage />,
        permissions: [PERMISSIONS.OPERATIONS.SUPPLY_CHAIN_FULL.id],
      },
      {
        live: true,
        path: "supply-chain-recipes",
        element: <SupplyChainRecipesPage />,
        permissions: [PERMISSIONS.OPERATIONS.SUPPLY_CHAIN_FULL.id],
      },
      {
        live: true,
        path: "supply-chain-units",
        element: <SupplyChainUnitsPage />,
        permissions: [PERMISSIONS.OPERATIONS.SUPPLY_CHAIN_FULL.id],
      },
      {
        live: true,
        path: "supply-chain-groups",
        element: <SupplyChainGroupsPage />,
        permissions: [PERMISSIONS.OPERATIONS.SUPPLY_CHAIN_FULL.id],
      },
      {
        live: true,
        path: "supply-chain-group/:UNIQUE_ID",
        element: <SupplyChainGroupPage />,
        permissions: [PERMISSIONS.OPERATIONS.SUPPLY_CHAIN_FULL.id],
      },
      {
        live: true,
        path: "vendors",
        element: <SupplyChainVendorsPage />,
        permissions: [PERMISSIONS.OPERATIONS.SUPPLY_CHAIN_FULL.id],
      },
      {
        live: true,
        path: "vendor/:UNIQUE_ID",
        element: <SupplyChainVendorPage />,
        permissions: [PERMISSIONS.OPERATIONS.SUPPLY_CHAIN_FULL.id],
      },
      {
        live: true,
        path: "purchase-order-create",
        element: <CreatePurchaseOrderPage />,
        permissions: [PERMISSIONS.OPERATIONS.SUPPLY_CHAIN_FULL.id],
      },
      {
        live: true,
        path: "purchase-order-receive/:ID",
        element: <ReceivePurchaseOrderPage />,
        permissions: [PERMISSIONS.OPERATIONS.SUPPLY_CHAIN_FULL.id],
      },
      {
        live: true,
        path: "purchase-order/:ID",
        element: <PurchaseOrderPage />,
        permissions: [PERMISSIONS.OPERATIONS.SUPPLY_CHAIN_FULL.id],
      },
      {
        live: true,
        path: "purchase-orders",
        element: <PurchaseOrdersPage />,
        permissions: [PERMISSIONS.OPERATIONS.SUPPLY_CHAIN_FULL.id],
      },
      {
        live: true,
        path: "recipes/ingredients/:UNIQUE_ID",
        element: <RecipePage />,
        permissions: [PERMISSIONS.OPERATIONS.SUPPLY_CHAIN_FULL.id],
      },
      {
        live: true,
        path: "usage",
        element: <UsagePage />,
        permissions: [PERMISSIONS.OPERATIONS.SUPPLY_CHAIN_FULL.id],
      },
      {
        live: true,
        path: "counts",
        element: <InventoryCountsPage />,
        permissions: [PERMISSIONS.OPERATIONS.SUPPLY_CHAIN_FULL.id],
      },
      {
        live: true,
        path: "count/:UNIQUE_ID",
        element: <InventoryCountPage />,
        permissions: [PERMISSIONS.OPERATIONS.SUPPLY_CHAIN_FULL.id],
      },
      {
        live: true,
        path: "wastes",
        element: <WastesPage />,
        permissions: [PERMISSIONS.OPERATIONS.SUPPLY_CHAIN_FULL.id],
      },
      {
        live: true,
        path: "waste/:UNIQUE_ID",
        element: <WastePage />,
        permissions: [PERMISSIONS.OPERATIONS.SUPPLY_CHAIN_FULL.id],
      },
      {
        live: true,
        path: "transfers",
        element: <TransfersPage />,
        permissions: [PERMISSIONS.OPERATIONS.SUPPLY_CHAIN_FULL.id],
      },
      {
        live: true,
        path: "transfer/:UNIQUE_ID",
        element: <TransferPage />,
        permissions: [PERMISSIONS.OPERATIONS.SUPPLY_CHAIN_FULL.id],
      },
      {
        live: true,
        path: "transfer/",
        element: <CreateTransferPage />,
        permissions: [PERMISSIONS.OPERATIONS.SUPPLY_CHAIN_FULL.id],
      },
      {
        live: true,
        path: "receive-transfer/:UNIQUE_ID",
        element: <ReceiveTransferPage />,
        permissions: [PERMISSIONS.OPERATIONS.SUPPLY_CHAIN_FULL.id],
      },
    ],
  },
  {
    side: <ReportingSideNav />,
    permissions: [PERMISSIONS.REPORTS.FULL.id],
    routes: [
      {
        live: true,
        path: "reports/sales",
        element: <SalesReportPage />,
        permissions: [PERMISSIONS.REPORTS.SALES_REPORT.id],
      },
      {
        live: true,
        path: "reports/compare",
        element: <CompareSalesReport />,
        permissions: [PERMISSIONS.REPORTS.SALES_REPORT.id],
      },
      {
        live: true,
        path: "reports/payment-methods",
        element: <PaymentMethodsReportPage />,
        permissions: [PERMISSIONS.REPORTS.PAYMENT_METHODS_REPORT.id],
      },
      {
        live: true,
        path: "reports/discounts",
        element: <DiscountsReportPage />,
        permissions: [PERMISSIONS.REPORTS.PAYMENT_METHODS_REPORT.id],
      },
      {
        live: true,
        path: "reports/all-products-sales",
        element: <AllProductsSalesReportPage />,
        permissions: [PERMISSIONS.REPORTS.PRODUCTS_REPORT.id],
      },
      {
        live: true,
        path: "reports/individual-product-sales",
        element: <IndividualProductSalesReport />,
        permissions: [PERMISSIONS.REPORTS.PRODUCTS_REPORT.id],
      },
      {
        live: true,
        path: "reports/category-sales",
        element: <CategorySalesReportPage />,
        permissions: [PERMISSIONS.REPORTS.CATEGORY_REPORT.id],
      },
      {
        live: true,
        path: "reports/checklists",
        element: <ChecklistReportPage />,
        permissions: [PERMISSIONS.REPORTS.CATEGORY_REPORT.id],
      },
      {
        live: true,
        path: "reports/modifier-sales",
        element: <ModifierSalesReportPage />,
        permissions: [PERMISSIONS.REPORTS.MODIFIER_REPORT.id],
      },
      {
        live: true,
        path: "reports/ingredient-sales",
        element: <IngredientSalesReportPage />,
        permissions: [PERMISSIONS.REPORTS.INGREDIENT_REPORT.id],
      },
      {
        live: true,
        path: "reports/inventory-sales",
        element: <InventorySalesReportPage />,
        permissions: [PERMISSIONS.REPORTS.INVENTORY_REPORT.id],
      },
      {
        live: true,
        path: "reports/platform-sales",
        element: <PlatformSalesReportPage />,
        permissions: [PERMISSIONS.REPORTS.PLATFORM_REPORT.id],
      },
      {
        live: true,
        path: "reports/order-type-sales",
        element: <OrderTypeSalesReport />,
        permissions: [PERMISSIONS.REPORTS.ORDER_TYPE_REPORT.id],
      },
      {
        live: true,
        path: "reports/gift-card-sales",
        element: <GiftCardSalesReport />,
        permissions: [PERMISSIONS.REPORTS.GIFT_CARDS_REPORT.id],
      },
      {
        live: true,
        path: "reports/taxable",
        element: <TaxableReportPage />,
        permissions: [PERMISSIONS.REPORTS.TIME_CARDS_REPORT.id],
      },
      {
        live: true,
        path: "reports/tax-categories",
        element: <TaxCategoryReportPage />,
        permissions: [PERMISSIONS.REPORTS.TIME_CARDS_REPORT.id],
      },
      {
        live: true,
        path: "reports/team-sales",
        element: <TeamSalesReportPage />,
        permissions: [PERMISSIONS.REPORTS.TIME_CARDS_REPORT.id],
      },
      {
        live: true,
        path: "reports/taxes",
        element: <TaxesReportPage />,
        permissions: [PERMISSIONS.REPORTS.TIME_CARDS_REPORT.id],
      },
      {
        live: true,
        path: "reports/time-cards",
        element: <TimeCardsReportPage />,
        permissions: [PERMISSIONS.REPORTS.TIME_CARDS_REPORT.id],
      },
      {
        live: true,
        path: "reports/labor-vs-sales",
        element: <LaborVsCostReportPage />,
        permissions: [PERMISSIONS.REPORTS.LABOR_VS_SALES.id],
      },
      {
        live: true,
        path: "reports/cash-drawers",
        element: <CashDrawerReportPage />,
        permissions: [PERMISSIONS.REPORTS.CASH_DRAWERS_REPORT.id],
      },
      {
        live: true,
        path: "reports/cash-flow",
        element: <CashFlowReportPage />,
        permissions: [PERMISSIONS.REPORTS.CASH_FLOW_REPORT.id],
      },
      {
        live: true,
        path: "reports/custom",
        element: <CustomPaymentTipsReport />,
        permissions: [PERMISSIONS.REPORTS.CASH_DRAWERS_REPORT.id],
      },
      {
        live: true,
        path: "reports/invoicing",
        element: <InvoicingReportPage />,
        permissions: [PERMISSIONS.REPORTS.INVOICING_REPORT.id],
      },
      {
        live: true,
        path: "reports/completion",
        element: <TicketCompletionReportPage />,
        permissions: [PERMISSIONS.REPORTS.INVOICING_REPORT.id],
      },
      {
        live: true,
        path: "reports/product_sales_to_cogs",
        element: <ProductSalesToCogsPage />,
        permissions: [PERMISSIONS.REPORTS.INVOICING_REPORT.id],
      },
      {
        live: true,
        path: "reports/ingredient-usage",
        element: <IngredientUsageReportPage />,
        permissions: [PERMISSIONS.REPORTS.INVOICING_REPORT.id],
      },
    ],
  },
  {
    side: <FinanceSideNav />,
    permissions: [PERMISSIONS.FINANCE.FULL.id],
    routes: [
      {
        live: true,
        path: "payouts",
        element: <PayoutsPage />,
        permissions: [PERMISSIONS.FINANCE.BANKING_FULL.id],
      },
      {
        live: true,
        path: "payouts/:PAYOUT_UNIQUE_ID",
        element: <PayoutPage />,
        permissions: [PERMISSIONS.FINANCE.BANKING_FULL.id],
      },
      {
        live: true,
        path: "payouts/settings",
        element: <PayoutSettingsPage />,
        permissions: [PERMISSIONS.FINANCE.BANKING_FULL.id],
      },
      {
        live: true,
        path: "payouts/tax-withholding",
        element: <TaxWithholdingPage />,
        permissions: [PERMISSIONS.FINANCE.BANKING_FULL.id],
      },
      {
        live: true,
        path: "transactions",
        element: <AccountingTransactionsPage />,
        permissions: [PERMISSIONS.FINANCE.BANKING_FULL.id],
      },
      {
        live: true,
        path: "cards",
        element: <CardsPage />,
        permissions: [PERMISSIONS.FINANCE.BANKING_FULL.id],
      },
      {
        live: true,
        path: "cash/payouts",
        element: <CashAccountPayoutsPage />,
        permissions: [PERMISSIONS.FINANCE.BANKING_FULL.id],
      },
      {
        live: true,
        path: "cash/settings",
        element: <CashAccountSettingsPage />,
        permissions: [PERMISSIONS.FINANCE.BANKING_FULL.id],
      },
      {
        live: true,
        path: "cash/setup",
        element: <SetupCashAccountPage />,
        permissions: [PERMISSIONS.FINANCE.BANKING_FULL.id],
      },
      {
        live: true,
        path: "cash-account",
        element: <CashAccountMainPage />,
        permissions: [PERMISSIONS.FINANCE.BANKING_FULL.id],
      },
      {
        live: true,
        path: "cash-account/cards",
        element: <CashAccountCardsPage />,
        permissions: [PERMISSIONS.FINANCE.BANKING_FULL.id],
      },
      {
        live: true,
        path: "insurance",
        element: <InsurancePage />,
        permissions: [PERMISSIONS.FINANCE.FULL.id],
      },
      {
        live: true,
        path: "payrolls/:ID",
        element: <PastPayrollPage />,
        permissions: [PERMISSIONS.FINANCE.PAYROLL_FULL.id],
      },
      {
        live: true,
        path: "payroll/company-tax-documents",
        element: <PayrollCompanyTaxDocumentsPage />,
        permissions: [PERMISSIONS.FINANCE.PAYROLL_FULL.id],
      },
      {
        live: true,
        path: "payroll/post-tax",
        element: <PostTaxDeductionsPage />,
        permissions: [PERMISSIONS.FINANCE.PAYROLL_FULL.id],
      },
      {
        live: true,
        path: "payroll/employee-tax-documents",
        element: <PayrollEmployeeTaxDocumentsPage />,
        permissions: [PERMISSIONS.FINANCE.PAYROLL_FULL.id],
      },
      {
        live: true,
        path: "payroll/contractor-tax-documents",
        element: <PayrollContractorTaxDocumentsPage />,
        permissions: [PERMISSIONS.FINANCE.PAYROLL_FULL.id],
      },
      {
        live: true,
        path: "payroll/employees",
        element: <PayrollEmployeesPage />,
        permissions: [PERMISSIONS.FINANCE.PAYROLL_FULL.id],
      },
      {
        live: true,
        path: "payroll/contractors",
        element: <PayrollContractorsPage />,
        permissions: [PERMISSIONS.TEAM.MY_TEAM_FULL.id],
      },
      {
        live: true,
        path: "payroll/settings",
        element: <PayrollSettingsPage />,
        permissions: [PERMISSIONS.FINANCE.PAYROLL_FULL.id],
      },
      {
        live: true,
        path: "payroll/journal",
        element: <PayrollJournalPage />,
        permissions: [PERMISSIONS.FINANCE.PAYROLL_FULL.id],
      },
      {
        live: true,
        path: "payroll/onboard",
        element: <PayrollOnboardingPage />,
        permissions: [PERMISSIONS.FINANCE.PAYROLL_FULL.id],
      },
      {
        live: true,
        path: "payroll",
        element: <PayrollMainPage />,
        permissions: [PERMISSIONS.FINANCE.PAYROLL_FULL.id],
      },
      {
        live: true,
        path: "payroll/run",
        element: <RunPayrollPage />,
        permissions: [PERMISSIONS.FINANCE.PAYROLL_FULL.id],
      },
      {
        live: true,
        path: "payrolls",
        element: <PayrollHistoryPage />,
        permissions: [PERMISSIONS.FINANCE.PAYROLL_FULL.id],
      },
      {
        live: true,
        path: "payroll/benefits",
        element: <PayrollBenefitsPage />,
        permissions: [PERMISSIONS.FINANCE.PAYROLL_FULL.id],
      },
      {
        live: true,
        path: "payroll/benefits/health",
        element: <CheckBenefitIntegrationPage integration={CHECK_BENEFIT_INTEGRATIONS.SIMPLY_INSURED.id} />,
        permissions: [PERMISSIONS.FINANCE.PAYROLL_FULL.id],
      },
      {
        live: true,
        path: "payroll/benefits/workers",
        element: <CheckBenefitIntegrationPage integration={CHECK_BENEFIT_INTEGRATIONS.NEXT.id} />,
        permissions: [PERMISSIONS.FINANCE.PAYROLL_FULL.id],
      },
      {
        live: true,
        path: "payroll/benefits/custom",
        element: <CustomBenefitsPage integration={CHECK_BENEFIT_INTEGRATIONS.NEXT.id} />,
        permissions: [PERMISSIONS.FINANCE.PAYROLL_FULL.id],
      },
      {
        live: true,
        path: "payroll/benefits/custom/:ID",
        element: <PayrollCustomBenefitPage />,
        permissions: [PERMISSIONS.FINANCE.PAYROLL_FULL.id],
      },
      {
        live: true,
        path: "billing",
        element: <BillingPage />,
        permissions: [PERMISSIONS.FINANCE.BANKING_FULL.id],
      },
      {
        live: true,
        path: "capital",
        element: <CapitalsPage />,
        permissions: [PERMISSIONS.FINANCE.BANKING_FULL.id],
      },
      {
        live: false,
        path: "offer/:ID",
        element: <OfferPage />,
        permissions: [PERMISSIONS.FINANCE.BANKING_FULL.id],
      },
      {
        live: true,
        path: "accounting",
        element: <AccountingMainPage />,
        permissions: [PERMISSIONS.FINANCE.BANKING_FULL.id],
      },
      {
        live: true,
        path: "accounting/bank-connections",
        element: <BankConnectionsPage />,
        permissions: [PERMISSIONS.FINANCE.BANKING_FULL.id],
      },
      {
        live: true,
        path: "accounting/transactions",
        element: <AccountingTransactionsPage />,
        permissions: [PERMISSIONS.FINANCE.BANKING_FULL.id],
      },
      {
        live: true,
        path: "accounting/accounts",
        element: <AccountingAccountsPage />,
        permissions: [PERMISSIONS.FINANCE.BANKING_FULL.id],
      },
      {
        live: true,
        path: "accounting/accounts/:UNIQUE_ID",
        element: <AccountingAccountPage />,
        permissions: [PERMISSIONS.FINANCE.BANKING_FULL.id],
      },
      {
        live: true,
        path: "accounting/balance-sheet",
        element: <BalanceSheetPage />,
        permissions: [PERMISSIONS.FINANCE.BANKING_FULL.id],
      },
      {
        live: true,
        path: "accounting/income-statement",
        element: <IncomeStatementPage />,
        permissions: [PERMISSIONS.FINANCE.BANKING_FULL.id],
      },
      {
        live: true,
        path: "/accounting/auto",
        element: <AutomatedEntriesPage />,
        permissions: [PERMISSIONS.FINANCE.BANKING_FULL.id],
      },
      {
        live: true,
        path: "accounting/vendors",
        element: <AccountingVendorsPage />,
        permissions: [PERMISSIONS.FINANCE.BANKING_FULL.id],
      },
      {
        live: true,
        path: "accounting/vendors/:UNIQUE_ID",
        element: <AccountingVendorPage />,
        permissions: [PERMISSIONS.FINANCE.BANKING_FULL.id],
      },
      {
        live: true,
        path: "accounting/bills",
        element: <BillsPage />,
        permissions: [PERMISSIONS.FINANCE.BANKING_FULL.id],
      },
      {
        live: true,
        path: "accounting/bills/:UNIQUE_ID",
        element: <BillPage />,
        permissions: [PERMISSIONS.FINANCE.BANKING_FULL.id],
      },
      {
        live: true,
        path: "accounting/settings",
        element: <AccountingSettingsPage />,
        permissions: [PERMISSIONS.FINANCE.BANKING_FULL.id],
      },
    ],
  },
];
