import React, {Component} from "react";
import {Loading, PageHeadings, Table} from "@frostbyte-technologies/frostbyte-tailwind";
import {withRouter} from "../../../../utils/navigation";
import moment from "moment";
import {request} from "../../../../utils/request";
import IngredientModal from "../../../../modals/operations/ingredients/ingredient-modal";
import Banner from "../../../../components/banner";

class IngredientsPage extends Component {
    state = {
        isLoading: true,
        ingredients: [],
        units: [],
    };

    componentDidMount() {
        this.syncState();
    }

    async syncState() {
        let ingredients = await request("ingredients", "GET", null);

        this.setState({isLoading: false, ingredients});
    }

    render() {
        const {isLoading, ingredients} = this.state;

        if (isLoading) {
            return <Loading/>;
        }

        return (
            <div>
                <PageHeadings
                    label="Ingredients"
                    description="Track the current stock of ingredients your location has."
                    button={{
                        label: "Create Ingredient",
                        onClick: () => this.ingredientModal.open(),
                    }}
                />

                <Banner
                    className="my-4"
                    description="Access to (Legacy) Ingredients will be removed in January 2025. Please migrate your
                    Legacy Ingredients over to the new Supply Chain feature for an improved inventory tracking solution.
                    Check out our Guidebook to learn more!"
                />

                <IngredientModal
                    ref={(e) => (this.ingredientModal = e)}
                    addState={(ingredient) =>
                        this.props.router.navigate("/ingredient/" + ingredient.UNIQUE_ID)
                    }
                />

                <Table
                    pagination
                    className="mt-4"
                    data={ingredients}
                    ref={(e) => (this.tableRef = e)}
                    actionButtons={[
                        {
                            label: "View",
                            onClick: (row) =>
                                this.props.router.navigate("/ingredient/" + row.UNIQUE_ID),
                        },
                    ]}
                    columns={[
                        {
                            value: "NAME",
                            label: "Name",
                        },
                        {
                            value: "STOCK",
                            label: "Stock",
                        },
                        {
                            value: "DATE_CREATED",
                            label: "Created",
                            mobile: "lg:table-cell",
                            format: (value, row) => {
                                return moment(value).format("MM/DD/YY");
                            },
                        },
                        {
                            value: "DATE_UPDATED",
                            label: "Updated",
                            mobile: "lg:table-cell",
                            format: (value) => {
                                return (
                                    <div className="text-sm text-gray-500">
                                        {moment(value).format("MM/DD/YY")}
                                    </div>
                                );
                            },
                        },
                    ]}
                />
            </div>
        );
    }
}

export default withRouter(IngredientsPage);
