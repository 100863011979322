import React, {Component} from "react";
import CashAccountLandingPage from "./cash-account-landing-page";
import {setupReduxConnection} from "../../../../redux";
import CashAccountOnboardingPage from "./cash-account-group-onboarding-page";
import CashAccountDashboardPage from "./cash-account-dashboard-page";

class CashAccountMainPage extends Component {
  render() {
    const {CASH_ACCOUNT_WHITELISTED} = this.props.shop.settings;
    const {STATUS} = this.props.shop.cashAccountGroup;

    if (CASH_ACCOUNT_WHITELISTED !== "1") {
      return <CashAccountLandingPage />;
    }

    if (STATUS === "ONBOARDING") {

      return <CashAccountOnboardingPage />;
    }

    return <CashAccountDashboardPage />;
  }
}

export default setupReduxConnection(["shop"])(CashAccountMainPage);
