import {Card, PageHeadings, Table} from "@frostbyte-technologies/frostbyte-tailwind";
import {useEffect, useRef} from "react";
import {withRouter} from "../../../../utils/navigation";
import {setupReduxConnection} from "../../../../redux";
import OnboardingModal from "../../../../modals/admin/tools/onboarding-modal";
import OffboardModal from "../../../../modals/admin/tools/offboard-modal";

function OnboardingPage(props) {
  const {ranks} = props.admin;
  let onboardingModal = useRef(null);
  let offboardModal = useRef(null);

  useEffect(() => {
    props.loadRanks();
  }, []);

  return (
    <div className="p-6">
      <OnboardingModal ref={(e) => (onboardingModal = e)} />
      <OffboardModal ref={(e) => (offboardModal = e)} />

      <PageHeadings label={"Onboarding"} />

      <Card
        label={"Current Employees"}
        button={{
          label: "Onboard New Employee",
          onClick: () => onboardingModal.open(),
        }}
      >
        <Table
          data={ranks}
          actionButtons={[
            {
              label: "Edit",
              onClick: (row) => {
                onboardingModal.open(row);
              },
            },
            {
              label: "Offboard",
              onClick: (row) => {
                offboardModal.open(row);
              },
            },
          ]}
          columns={[
            {
              label: "ID",
              value: "ID",
            },
            {
              label: "Name",
              value: "FULL_NAME",
            },
            {
              label: "Phone",
              value: "PHONE",
            },

            {
              label: "Email",
              value: "EMAIL",
            },
          ]}
        />
      </Card>
    </div>
  );
}

export default setupReduxConnection(["admin"])(withRouter(OnboardingPage));
