import React, {Component} from "react";
import {
  PageHeadings,
  Tab,
  TwoColumnList,
  Loading,
} from "@frostbyte-technologies/frostbyte-tailwind";
import {request} from "../../../utils/request";
import {setupReduxConnection} from "../../../redux";
import OrderWebsiteLinksModal from "../../../modals/shop/order-website-links-modal";

class WebsitesPage extends Component {
  state = {websiteInfo: null};

  async componentDidMount() {
    let websiteInfo = await request("location/website", "GET");

    this.setState({websiteInfo});
  }

  orderWebsiteNameParse = (str) => {
    return str.replaceAll("-", "+").replaceAll(" ", "-");
  };

  renderNavigation() {
    let {NAME, SLUG} = this.props.shop.location;
    let {NAME: companyName} = this.state.websiteInfo.company;
    let {SLUG: websiteSlug, PASSCODE: passcode} =
      this.state.websiteInfo.website;

    const baseUrl = "https://order.dripos.com/";

    let locationUrl =
      baseUrl + (SLUG ? SLUG : this.orderWebsiteNameParse(NAME));

    let companyUrl =
      baseUrl +
      (websiteSlug ? websiteSlug : this.orderWebsiteNameParse(companyName));

    return (
      <TwoColumnList
        buttons={[
          {
            label: "Edit",
            onClick: () => this.linksModal.open(),
          },
        ]}
        label="Links"
        data={[
          {
            label: `${NAME}'s Page`,
            value: (
              <a className="text-indigo-600 font-semibold" href={locationUrl}>
                {locationUrl}
              </a>
            ),
          },
          {label: `${NAME}'s Slug`, value: SLUG},
          {
            label: "Company Slug",
            value: (
              <a className="text-indigo-600 font-semibold" href={companyUrl}>
                {companyUrl}
              </a>
            ),
          },
          {label: "Company Slug", value: websiteSlug ? websiteSlug : "None"},
          {label: "Passcode", value: passcode, empty: "None"},
          // {label: "Phone", value: PHONE ? PHONE : "None"},
          // {label: "Email", value: EMAIL ? EMAIL : "None"},
          // {label: "Facebook", value: FACEBOOK ? FACEBOOK : "None"},
          // {label: "Twitter", value: TWITTER ? TWITTER : "None"},
          // {label: "Instagram", value: INSTAGRAM ? INSTAGRAM : "None"},
        ]}
      />
    );
  }

  render() {
    let {websiteInfo} = this.state;

    if (!websiteInfo) {
      return <Loading />;
    }

    let {SLUG: companySlug, PASSCODE: passcode} =
      this.state.websiteInfo.website;
    let {SLUG: locationSlug} = this.props.shop.location;

    return (
      <div>
        <OrderWebsiteLinksModal
          passcode={passcode}
          slugs={{locationSlug, companySlug}}
          updateSlugs={({locationSlug, companySlug, passcode}) => {
            let {websiteInfo} = this.state;
            let {location} = this.props.shop;

            websiteInfo.website.PASSCODE = passcode;
            websiteInfo.website.SLUG = companySlug;
            location.SLUG = locationSlug;

            this.props.updateLocation(location);
            this.setState({websiteInfo});
          }}
          ref={(e) => (this.linksModal = e)}
        />

        <PageHeadings
          label={"Ordering Website"}
          description="Information about the order website for your location"
        />

        {this.renderNavigation()}
      </div>
    );
  }
}

export default setupReduxConnection(["shop"])(WebsitesPage);
