import React, {Component} from "react";
import {Card, TwoColumnList} from "@frostbyte-technologies/frostbyte-tailwind";
import {setupReduxConnection} from "../../../redux";
import LocationModal from "../../../modals/shop/location-modal";
import {TIMEZONE_LABELS} from "../../../utils/settings-constants";

class LocationTab extends Component {
  render() {
    let {NAME, LATITUDE, LONGITUDE, ADDRESS, CITY, ZIPCODE, STATE, DESCRIPTION, INSTRUCTIONS, MIN_ORDER, TAX_RATE} = this.props.shop.location;
    let {TIMEZONE} = this.props.shop.settings;

    return (
      <div>
        <Card>
          <LocationModal
            ref={(e) => (this.locationModal = e)}
          />

          <TwoColumnList
            buttons={[
              {
                label: "Edit",
                onClick: () => this.locationModal.open(),
              },
            ]}
            label="Shop Settings"
            description="Edit your location's general information"
            data={[
              {label: "Name", value: NAME},
              {label: "Address", value: `${ADDRESS}, ${CITY} ${STATE} ${ZIPCODE}`},
              {label: "Description", value: DESCRIPTION},
              {label: "Pickup Instructions", value: INSTRUCTIONS},
              {label: "Latitude", value: LATITUDE},
              {label: "Longitude", value: LONGITUDE},
              {label: "Timezone", value: TIMEZONE_LABELS[TIMEZONE]}
            ]}
          />
        </Card>
      </div>
    );
  }
}

export default setupReduxConnection(["shop"])(LocationTab);
