import React, {Component} from "react";
import {Formik} from "formik";
import TipPayoutPage from "../../team/timesheets/tip-payout-page";
import {request} from "../../../utils/request";
import {FormSelect} from "@frostbyte-technologies/frostbyte-tailwind";

class TipPayoutToolPage extends Component {
  state = {locations: [], locationId: null};

  async componentDidMount() {
    const serverLocations = await request("employees/locations", "GET");

    this.setState({locations: serverLocations});
  }

  render() {
    const {locations, locationId} = this.state;

    const locationData = locations.map(({ID, NAME}) => ({
      value: ID,
      label: NAME,
    }));

    return (
      <div className={"mx-20"}>
        <Formik
          onSubmit={({location}) =>
            this.setState({locationId: null}, () => this.setState({locationId: location}))
          }
          innerRef={(e) => (this.formikRef = e)}
          enableReinitialize
          initialValues={{location: null}}
        >
          {(formikOptions) => {
            const {handleSubmit} = formikOptions;

            return (
              <form onSubmit={handleSubmit} className="mt-4">
                <FormSelect
                  options={formikOptions}
                  label="Select Location"
                  name="location"
                  data={locationData}
                  tooltip={
                    "If you don't see a location in this drop down, you will need to add yourself as a partner first on the admin dash"
                  }
                />

                <button
                  type="submit"
                  className="mt-4 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  Submit
                </button>
              </form>
            );
          }}
        </Formik>

        {locationId && <TipPayoutPage locationId={locationId} />}
      </div>
    );
  }
}

export default TipPayoutToolPage;
