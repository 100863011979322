import React, {Component} from "react";
import StepNavCard from "../../../../features/step-nav-card";
import {withRouter} from "../../../../utils/navigation";
import {setupReduxConnection} from "../../../../redux";
import {request} from "../../../../utils/request";

class SingleLocationWelcomeComponent extends Component {
  handleClick = async () => {
    await request("cash-account/groups", "POST", {
      TYPE: "SINGLE",
    });

    return true;
  };

  render() {
    return (
      <div
        style={{height: 600}}
        className="py-20 flex flex-col justify-center  items-center"
      >
        <div className="text-2xl font-semibold">
          Welcome to Dripos Cash Account!
        </div>

        <div className="text-gray-700 font-semibold mt-2">
          Click next to begin entering your information
        </div>
      </div>
    );
  }
}

export default setupReduxConnection(["shop"])(SingleLocationWelcomeComponent);
