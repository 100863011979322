import React, {Component} from "react";
import {Tab} from "@frostbyte-technologies/frostbyte-tailwind";
import ProductAvailabilityTab from "../../pages/sales/menu/products/tabs/product-availability-tab";
import ProductIngredientsTab from "../../pages/sales/menu/products/tabs/product-ingredients-tab";
import ProductPricingTab from "../../pages/sales/menu/products/tabs/product-pricing-tab";
import ProductModifiersTab from "../../pages/sales/menu/products/tabs/product-modifiers-tab";
import ProductTaxesTab from "../../pages/sales/menu/products/tabs/product-taxes-tab";
import ProductDiscountsTab from "../../pages/sales/menu/products/tabs/product-discounts-tab";

class ProductTableExpansionComponent extends Component {
  render() {
    const tabs = [
      {id: "pricing", label: "Pricing"},
      {id: "modifiers", label: "Modifiers"},
      {id: "availability", label: "Availability"},
      {id: "ingredients", label: "Ingredients"},
      {id: "taxes", label: "Taxes"},
      {id: "discounts", label: "Discounts"},
    ];

    return (
      <div className="px-10 py-3 pb-20 text-sm">
        <Tab data={tabs}>
          {(id) => {
            if (!tabs.find(({id: _id}) => _id === id)) {
              id = "pricing";
            }

            if (id === "availability") {
              return <ProductAvailabilityTab {...this.props} />;
            }

            if (id === "ingredients") {
              return <ProductIngredientsTab {...this.props} />;
            }

            if (id === "pricing") {
              return <ProductPricingTab {...this.props} />;
            }

            if (id === "modifiers") {
              return <ProductModifiersTab {...this.props} />;
            }

            if (id === "taxes") {
              return <ProductTaxesTab {...this.props} />;
            }

            if (id === "discounts") {
              return <ProductDiscountsTab {...this.props} />;
            }
          }}
        </Tab>
      </div>
    );
  }
}

export default ProductTableExpansionComponent;
