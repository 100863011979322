import React, {Component} from "react";
import {Modal} from "@frostbyte-technologies/frostbyte-tailwind";
import {Formik} from "formik";
import {request} from "../../../utils/request";
import * as Yup from "yup";
import moment from "moment-timezone";
import FormDateTimeSelect from "../../../components/form-date-time-select";
import {showConfirmAlert} from "../../../utils/alert-helper";

class ScheduleTextModal extends Component {
  state = {campaign: null};

  open(campaign = null, bday = 0) {
    this.setState({campaign}, () => {
      this.formikRef && this.formikRef.resetForm({});
      this.modal.open();
    });
  }

  async scheduleTextCampaign(values) {
    const {campaign} = this.state;
    const {date} = values;

    const serverCampaign = await request(
      "text/marketing/campaign/" + campaign.ID,
      "PATCH",
      {
        ...campaign,
        DATE_SCHEDULED: date,
      }
    );

    this.props.updateState(serverCampaign);
    this.modal?.close();
  }

  render() {
    const {campaign} = this.state;
    const {creditsAvailable, subList} = this.props;

    return (
      <Modal
        buttonLabel="Schedule Text"
        ref={(e) => (this.modal = e)}
        formikOnClick={() => this.formikRef}
        label={"Schedule Text Campaign"}
      >
        <Formik
          onSubmit={(values) => {
            const confirmMessage =
              subList?.Patrons?.length > creditsAvailable
                ? "The number of recipients exceeds the number of available credits. Are you sure you would like to send this text campaign? If the credits are not refilled, the text campaign may fail to send."
                : "Are you sure you would like to schedule this text campaign? A text will be sent to all patrons associated with this text campaign at the scheduled time.";

            showConfirmAlert("Schedule Text Campaign?", confirmMessage)
              .then(() => {
                this.scheduleTextCampaign(values);
              })
              .catch(() => {
                this.modal.stopLoading();
              });
          }}
          innerRef={(e) => (this.formikRef = e)}
          enableReinitialize
          validationSchema={Yup.object({
            date: Yup.string()
              .required()
              .test(
                "future-time",
                "Send Date cannot be in the past.",
                (value, _) => value > Date.now()
              ),
          })}
          initialValues={{
            date:
              campaign?.DATE_SCHEDULED ??
              moment()
                .add(1, "day")
                .endOf("day")
                .subtract(5, "hours")
                .add(1, "minute")
                .valueOf(),
          }}
        >
          {(formikOptions) => {
            const {handleSubmit} = formikOptions;

            return (
              <form onSubmit={handleSubmit}>
                <FormDateTimeSelect
                  name="date"
                  label="Send Date"
                  options={formikOptions}
                  flex
                  buttonText={(epoch) =>
                    moment(epoch).format("dddd, MMMM Do, h:mm A")
                  }
                />
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

export default ScheduleTextModal;
