import React, {Component} from "react";
import {
  Card,
  Loading,
  PageHeadings,
  Tab,
  Table,
  TwoColumnList,
} from "@frostbyte-technologies/frostbyte-tailwind";
import {request} from "../../../utils/request";
import {withRouter} from "../../../utils/navigation";
import moment from "moment-timezone";
import {toDollars} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import TicketsTable from "../../../tables/sales/tickets-table";
import GiftCardModal from "../../../modals/marketing/gift-cards/gift-card-modal";
import {setupReduxConnection} from "../../../redux";
import {getFormattedPhone} from "@frostbyte-technologies/frostbyte-core/dist/helpers/phone-helper";
import {showErrorAlert, showLoadingConfirmAlert} from "../../../utils/alert-helper";
import {showSuccessNotification} from "../../../utils/notification-helper";
import {GIFT_CARD_LOG_TYPES} from "../../../utils/constants";

class GiftCardPage extends Component {
  state = {card: null};

  componentDidMount() {
    this.syncState();
  }

  async syncState() {
    const {ID} = this.props.router.params;

    const card = await request("cards/" + ID, "GET", null);
    this.setState({card});
  }

  async deleteGiftCard() {
    const {card} = this.state;
    showLoadingConfirmAlert(
      "Delete Gift Card",
      "Are you sure you want to delete this gift card? This action cannot be undone."
    ).then(async (close) => {
      await request("cards/partner/gift/" + card.ID, "DELETE");
      this.props.router.navigate("/gifts");
      close();
    });
  }

  async resendGiftCard() {
    const {card} = this.state;
    const {location} = this.props.shop;

    try {
      const close = await showLoadingConfirmAlert(
        "Resend Gift Card",
        "Are you sure you want to resend this gift card to the attached patron?"
      );

      request("cards/resend/" + card.UNIQUE_ID, "POST", {
        TYPE: card?.PHONE ? "PHONE" : "EMAIL",
        LOCATION_ID: location.ID,
      })
        .then(() => {
          close();

          showSuccessNotification("Sent Gift Card!");
        })
        .catch((err) => {
          close();

          if (err.error === "NO_PHONE") {
            showErrorAlert(
              "Oops!",
              "We could not find a phone number to send this gift card to."
            );
          } else if (err.error === "NO_EMAIL") {
            showErrorAlert(
              "Oops!",
              "We could not find an email to send this gift card to."
            );
          } else {
            showErrorAlert(
              "Oops!",
              "Something went wrong when trying to send this gift card again."
            );
          }
        });
    } catch (_) {
      return;
    }
  }

  render() {
    const {card} = this.state;
    const {permissions} = this.props.user;
    const hasEditPermission =
      permissions.indexOf("ADMIN_FULL") !== -1 ||
      permissions.indexOf("GIFT_CARDS_EDIT_FULL") !== -1;

    console.log(card);

    let buttons = [
      {
        type: "dropdown",
        label: "Actions",
        sections: [
          {
            items: [],
          },
        ],
      },
    ];

    if (!card) {
      return <Loading />;
    }

    if (card.ACCOUNT_ID) {
      buttons[0].sections[0].items.push({
        label: "Resend Gift Card",
        onClick: this.resendGiftCard.bind(this),
      });
    }

    if (hasEditPermission) {
      buttons[0].sections[0].items.push({
        label: "Delete Gift Card",
        onClick: this.deleteGiftCard.bind(this),
      });
    }

    if (buttons[0].sections[0].items.length === 0) {
      buttons = undefined;
    }

    return (
      <div>
        <GiftCardModal
          ref={(e) => (this.cardModal = e)}
          updateState={() => {
            this.syncState();
          }}
        />

        <PageHeadings
          label={"Gift Card"}
          breadcrumbs={[
            {label: "Marketing", url: "/"},
            {label: "Gift Cards", url: "/gifts"},
          ]}
          buttons={buttons}
        />

        <TwoColumnList
          label="Gift Card Information"
          button={
            hasEditPermission
              ? {
                  label: "Edit Gift Card",
                  onClick: () => this.cardModal.open(card),
                }
              : undefined
          }
          data={[
            {
              label: "Patron",
              value: card?.ACCOUNT_ID ? (
                <div
                  onClick={() => this.props.router.navigate("/patron/" + card.PATRON_ID)}
                  className="text-indigo-600 hover:cursor-pointer hover:text-indigo-900"
                >
                  <div className="text-sm font-semibold">
                    {card?.FULL_NAME ? card.FULL_NAME : "Unnamed Patron"}
                  </div>

                  {card.PHONE ? (
                    <div className="text-sm ">{getFormattedPhone(card.PHONE)}</div>
                  ) : (
                    <></>
                  )}
                </div>
              ) : (
                "-"
              ),
            },
            {
              label: "PAN ID",
              value: card.PAN_ID ?? "-",
            },
            {
              label: "Date Created",
              value: card?.DATE_CREATED
                ? moment(card.DATE_CREATED).format("MM/DD/YYYY")
                : "",
            },
            {
              label: "Last Used",
              value:
                card?.TICKETS && card.TICKETS.length > 0
                  ? moment(card.TICKETS[card.TICKETS.length - 1].DATE_CREATED).format(
                      "MM/DD/YYYY"
                    )
                  : "-",
            },
            {
              label: "Balance",
              value: `$${toDollars(card.BALANCE)}`,
            },
            {
              label: "Notes",
              value: card?.NOTES ?? "-",
            },
          ]}
        />

        <Tab
          data={[
            {id: "tickets", label: "Tickets"},
            {id: "log", label: "Activity Log"},
          ]}
        >
          {(tab) => {
            if (tab === "tickets") {
              return (
                <Card
                  className="mt-4"
                  label="Tickets"
                  description="All of the orders that have been charged to this gift card"
                >
                  <TicketsTable tickets={card.TICKETS} />
                </Card>
              );
            }

            if (tab === "log") {
              return (
                <Card
                  label="Gift Card Activity Log"
                  description="View each time this gift card was reloaded or used."
                >
                  <Table
                    data={card.LOGS}
                    columns={[
                      {
                        label: "Location Name",
                        value: "LOCATION_NAME",
                      },
                      {
                        label: "Action",
                        value: "TYPE",
                        format: (val) => GIFT_CARD_LOG_TYPES[val],
                      },
                      {
                        label: "Amount",
                        value: "AMOUNT",
                        format: (val) => toDollars(val, true),
                      },
                      {
                        label: "Ticket Number",
                        value: "TICKET_NUMBER",
                        format: (val) => val ?? "Manual",
                      },
                      {
                        label: "Timestamp",
                        value: "DATE_CREATED",
                        format: (val) => moment(val).format("hh:mma MM/DD/YYYY"),
                      },
                      {
                        label: "",
                        value: "TICKET_UNIQUE_ID",
                        format: (val, row) => {
                          if (row.TICKET_UNIQUE_ID) {
                            return (
                              <div
                                className="text-indigo-500 font-semibold hover:text-indigo-700 hover:cursor-pointer"
                                onClick={() =>
                                  this.props.router.navigate(
                                    "/ticket/" + row.TICKET_UNIQUE_ID
                                  )
                                }
                              >
                                View Ticket
                              </div>
                            );
                          }

                          return <div />;
                        },
                        width: 1,
                      },
                    ]}
                  />
                </Card>
              );
            }
          }}
        </Tab>
      </div>
    );
  }
}

export default setupReduxConnection(["user", "shop"])(withRouter(GiftCardPage));
