import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export function renderNoPermission() {
  return (
    <div className="flex flex-1 flex-col justify-center items-center text-center p-6">
      <FontAwesomeIcon
        icon="lock-alt"
        className="mx-auto h-12 w-12 text-gray-500"
      />

      <h3 className="mt-2 text-sm font-medium text-gray-900">
        Insufficient permission to view this page
      </h3>

      <p className="mt-1 text-sm text-gray-500">
        Please contact your manager if you believe this is a mistake
      </p>
    </div>
  );
}
