import {Modal} from "@frostbyte-technologies/frostbyte-tailwind";
import React, {Component} from "react";
import {showErrorNotification} from "../../utils/notification-helper";
import {request} from "../../utils/request";
import BillingAddressForm from "../../forms/billing/billing-address-form";

class BillingAddressModal extends Component {
  state = {address: {}};

  open(address = {}) {
    this.setState({address});

    this.formikRef && this.formikRef.resetForm();
    this.modal.open();
  }

  async handleSubmit({city, line1, line2, zip, state}) {
    //TODO: We only support US customers currently
    const payload = {
      city,
      country: "US",
      line1,
      line2,
      postal_code: zip,
      state,
    };

    try {
      await request("billing/address", "POST", {ADDRESS: payload});
    } catch (err) {
      showErrorNotification("", "Error saving billing address");
    }

    if (this.props.updateAddress) {
      this.props.updateAddress(payload);
    }

    this.modal.close();
  }

  render() {
    const {address} = this.state;

    return (
      <Modal
        label="Edit Billing Address"
        buttonLabel={"Save"}
        formikOnClick={() => this.formikRef}
        ref={(e) => (this.modal = e)}
      >
        <BillingAddressForm
          address={address}
          onSubmit={(values) => this.handleSubmit(values)}
          ref={(e) => (this.formikRef = e)}
        />
      </Modal>
    );
  }
}

export default BillingAddressModal;
