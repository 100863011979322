import React, {Component} from "react";
import {Modal} from "@frostbyte-technologies/frostbyte-tailwind";
import {loadVGSCollect} from "@vgs/collect-js";

class ActivateCard extends Component {
  state = {card: null, form: null};

  async componentDidMount() {
    const collect = await loadVGSCollect({
      vaultId: "tntq4dwvhri",
    }).catch((e) => console.log("pepe!"));

    this.initForm(collect);
  }

  initForm = (vgs_collect) => {
    console.log("init form");
    const form = vgs_collect.init((state) => console.log("pepe!"));
    form.field("#card-name", {
      type: "text",
      name: "card_holder",
      placeholder: "Card holder",
      validations: ["required"],
      autoComplete: "cc-name",
      css,
    });
    form.field("#card-number", {
      type: "card-number",
      name: "card_number",
      successColor: "#4F8A10",
      errorColor: "#D8000C",
      placeholder: "Card number",
      showCardIcon: true,
      validations: ["required", "validCardNumber"],
      autoComplete: "cc-number",
      css,
    });
    form.field("#card-cvc", {
      type: "card-security-code",
      name: "card_cvc",
      successColor: "#4F8A10",
      errorColor: "#D8000C",
      placeholder: "CVC",
      maxLength: 3,
      validations: ["required", "validCardSecurityCode"],
      css,
    });
    form.field("#card-expiry", {
      type: "card-expiration-date",
      name: "card_exp",
      successColor: "#4F8A10",
      errorColor: "#D8000C",
      placeholder: "MM / YY",
      validations: ["required", "validCardExpirationDate"],
      autoComplete: "cc-exp",
      css,
    });

    this.setState({form});
  };

  open(card) {
    this.setState({card}, () => this.slide.open());
  }

  handleSubmit = async ({report}) => {
    const {UNIQUE_ID} = this.state.card;

    await this.props.syncState();
    this.slide.close();
  };

  render() {
    const {form} = this.state;

    console.log(form);

    return (
      <Modal
        ref={(e) => (this.slide = e)}
        label={`Activate Card`}
        buttonLabel="Active"
        buttonOnClick={() => this.formikRef.submitForm()}
        formikOnClick={() => this.formikRef}
      >
        <form id="collect-form">
          <div className="group">
            <label>
              <span>Card number</span>
              <input id="card-number" className="text" />
            </label>
            <label>
              <span>Expiry date</span>
              <div id="card-expiry" className="field" />
            </label>
            <label>
              <span>CVC</span>
              <div id="card-cvc" className="field" />
            </label>
            <label>
              <span>Cardholder name</span>
              <div id="card-name" className="field" />
            </label>
          </div>
          <button type="submit">Submit payment</button>
        </form>
      </Modal>
    );
  }
}

const css = {
  fontFamily: '"Helvetica Neue", Helvetica',
  boxSizing: "border-box",
  lineHeight: "1.5em",
  fontSize: "14px",
  fontWeight: "200",
  border: "none",
  color: "#31325F",
  width: "100%",
  height: "100%",
  "&::placeholder": {
    color: "#CFD7E0",
  },
};

export default ActivateCard;
