import React, {Component} from "react";
import {Modal} from "@frostbyte-technologies/frostbyte-tailwind";
import {Formik} from "formik";
import PropTypes from "prop-types";
import RolesDropdown from "../../../dropdowns/team/roles-dropdown";
import {showLoadingConfirmAlert} from "../../../utils/alert-helper";
import DefaultRolesDropdown from "../../../dropdowns/team/default-roles-dropdown";
import {TeamsRequests} from "../../../utils/request-helpers/teams/teams-requests";

class EmployeeRoleModal extends Component {
  state = {role: null};
  editDefaultRole = false;

  open(role = null, editDefaultRole = false) {
    this.editDefaultRole = editDefaultRole;
    this.setState({role}, () => {
      this.formikRef && this.formikRef.resetForm();
      this.modal.open();
    });
  }

  createRole = async ({role}) => {
    const {employee} = this.props;

    const serverEmployeeRole = await TeamsRequests.EmployeeRoles.addEmployeeRoleById(
      employee.ID,
      {
        ROLE_ID: role,
      }
    );

    const serverRole = this.roleRef.fetchRole();

    this.props.addState({
      Roles: serverRole,
      Employee_Roles: serverEmployeeRole,
    });

    this.modal.close();
  };

  async handleDefaultRoleChange(defaultRoleId) {
    const {employee} = this.props;

    await TeamsRequests.Employees.updateEmployeeById(employee?.ID, {
      PIN: employee?.PIN,
      ROLE_ID: defaultRoleId,
    });

    const updatedEmployee = {
      ...employee,
      ROLE_ID: defaultRoleId,
    };

    this.props.updateEmployeeDefaultRole(updatedEmployee);
  }

  updateDefaultRole = async ({role}) => {
    await this.handleDefaultRoleChange(role);

    this.modal.close();
  };

  filterDefaultRole() {
    const {roles} = this.props;

    return roles.map(({Roles}) => ({
      id: Roles?.ID,
      label: Roles?.NAME,
    }));
  }

  async deleteRole() {
    const {role} = this.state;
    const {employee, roles} = this.props;

    const isDefaultRole = employee.ROLE_ID === role.ID;
    let replacementDefaultRole;

    if (isDefaultRole) {
      replacementDefaultRole = roles?.find(
        (employeeRole) =>
          employeeRole?.Roles?.ID !== role.ID &&
          employeeRole?.Roles?.ID !== employee.ROLE_ID
      );
    }

    const defaultRoleMsg = ` and reassign ${employee.FULL_NAME}'s default role to ${replacementDefaultRole?.Roles?.NAME}`;
    const genericDeleteMsg = `Deleting this role will convert all upcoming employee shifts with this role into open shifts${
      isDefaultRole ? defaultRoleMsg : ""
    }. Are you sure you want to continue?`;

    showLoadingConfirmAlert("Delete Role", genericDeleteMsg)
      .then(async (close) => {
        try {
          await TeamsRequests.EmployeeRoles.deleteEmployeeRoleById(employee.ID, {
            EMPLOYEE_ROLE_ID: role.EMPLOYEE_ROLE_ID,
          });

          if (isDefaultRole && replacementDefaultRole !== undefined) {
            await this.handleDefaultRoleChange(replacementDefaultRole?.Roles?.ID);
          }

          this.props.removeState(role);
        } catch (err) {
          if (err.code === 213) {
            alert("Every employee must have at least one role.");

            return this.modal.close();
          }
        }

        close();
      })
      .catch((err) => {});

    this.modal.close();
  }

  getModalConfig() {
    const {role} = this.state;

    const modalType = role ? (this.editDefaultRole ? "edit" : "delete") : "add";

    const modalConfig = {
      edit: {
        label: "Edit Default Role",
        toolTip: "Change the default role for this employee",
        buttonLabel: "Save",
        deleteLabel: null,
      },
      delete: {
        label: "Delete Employee Role",
        toolTip: "Delete this role from this employee",
        buttonLabel: null,
        deleteLabel: "Remove",
      },
      add: {
        label: "Add Employee Role",
        toolTip: "Add a new role to this employee",
        buttonLabel: "Add",
        deleteLabel: null,
      },
    };

    return modalConfig[modalType];
  }
  return;

  render() {
    const {roles} = this.props;
    const {role} = this.state;

    const exclude = roles && !role ? roles.map((item) => item.Roles.ID) : [];

    const {label, toolTip, buttonLabel, deleteLabel} = this.getModalConfig();

    return (
      <Modal
        tooltip={{data: toolTip}}
        buttonLabel={buttonLabel}
        deleteLabel={deleteLabel}
        label={label}
        ref={(e) => (this.modal = e)}
        formikOnClick={() => this.formikRef}
        deleteOnClick={() => this.deleteRole()}
      >
        <Formik
          enableReinitialize
          innerRef={(e) => (this.formikRef = e)}
          onSubmit={this.editDefaultRole ? this.updateDefaultRole : this.createRole}
          initialValues={{
            role: role ? role.ID : null,
          }}
        >
          {(formikOptions) => {
            const {handleSubmit} = formikOptions;

            return (
              <form onSubmit={handleSubmit}>
                {this.editDefaultRole ? (
                  <DefaultRolesDropdown
                    name="role"
                    options={formikOptions}
                    defaultRoleId={role.ID}
                    otherRoles={this.filterDefaultRole()}
                    ref={(e) => (this.roleRef = e)}
                  />
                ) : (
                  <RolesDropdown
                    name="role"
                    options={formikOptions}
                    exclude={exclude}
                    disabled={!!role}
                    role={role}
                    ref={(e) => (this.roleRef = e)}
                  />
                )}
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

EmployeeRoleModal.propTypes = {
  onSave: PropTypes.func,
};

export default EmployeeRoleModal;
