import {Button, Modal, Table} from "@frostbyte-technologies/frostbyte-tailwind";
import React, {Component} from "react";
import {Formik} from "formik";
import {RecipeForm} from "../../../../../forms/operations/supply/recipe-form";
import * as Yup from "yup";
import {CUSTOMIZATION_TYPES} from "@frostbyte-technologies/frostbyte-tickets/dist/constants/product-constants";
import {RecipeRequests} from "../../../../../utils/request-helpers/supply-chain/supply-chain-requests";

class optionIngredientsModal extends Component {
  state = {option: null};

  open(option = null) {
    this.setState({option}, () => this.modal.open());
  }

  fetchValidationSchema() {
    return Yup.object().shape({});
  }

  fetchInitialValues() {
    return {};
  }

  fetchAttachPayload(values, option) {
    const {ingredient, quantity, option: connectedOption} = values;

    const payload = {
      INGREDIENT_ID: ingredient,
      QUANTITY: quantity,
    };

    if (option && option.TYPE === CUSTOMIZATION_TYPES.VARIANT) {
      payload.VARIANT_OPTION_ID = option.ID;
    } else if (option) {
      payload.OPTION_ID = option.ID;
      payload.VARIANT_OPTION_ID = connectedOption;
    }

    return payload;
  }

  async attachIngredient(values) {
    this.addButton.startLoading();
    const {ingredient, quantity} = values;
    const {option} = this.state;
    const payload = this.fetchAttachPayload(values, option);
    const recipe = await RecipeRequests.attachIngredient(ingredient, payload);

    this.props.syncState &&
      this.props.syncState({
        ...option,
        RECIPES: [...option.RECIPES, {...recipe, RECIPE_COST: quantity}],
      });

    this.setState({
      option: {
        ...option,
        RECIPES: [...option.RECIPES, {...recipe, RECIPE_COST: quantity}],
      },
    });

    this.addButton.stopLoading();
  }

  async removeIngredient(recipe) {
    await RecipeRequests.deleteRecipe(recipe.RECIPE_ID);
    const {option} = this.state;

    const newOption = {
      ...option,
      RECIPES: option.RECIPES.filter((_recipe) => _recipe.RECIPE_ID !== recipe.RECIPE_ID),
    };

    this.props.syncState && this.props.syncState(newOption);
    this.setState({option: newOption});
  }

  renderFormik(option) {
    return (
      <Formik
        onSubmit={this.attachIngredient.bind(this)}
        innerRef={(e) => (this.formikRef = e)}
        initialValues={this.fetchInitialValues()}
        validationSchema={this.fetchValidationSchema()}
      >
        {(formikOptions) => {
          return (
            <RecipeForm
              formikOptions={formikOptions}
              entity={{OPTION_ID: option.ID, option}}
            />
          );
        }}
      </Formik>
    );
  }

  renderSubmitButton() {
    return (
      <div className="flex flex-row justify-end">
        <Button
          ref={(e) => (this.addButton = e)}
          className="flex mt-4"
          onClick={() => this.formikRef && this.formikRef.submitForm()}
          label="Add"
        />
      </div>
    );
  }

  fetchNameColumn() {
    return {
      label: "Name",
      value: "NAME",
    };
  }

  fetchVariantNameColumn() {
    return {
      label: "Variant",
      value: "VARIANT_OPTION_ID",
      format: (_, row) => row.VARIANT_OPTION?.NAME,
    };
  }

  fetchQuantityColumn() {
    return {
      label: "Quantity",
      value: "RECIPE_COST",
    };
  }

  fetchColumns() {
    return [
      this.fetchNameColumn(),
      this.fetchVariantNameColumn(),
      this.fetchQuantityColumn(),
    ];
  }

  fetchActionButtons() {
    return [
      {
        label: "Delete",
        onClick: (row) => this.removeIngredient(row),
      },
    ];
  }

  renderRecipeTable(option) {
    return (
      <Table
        className="mt-4"
        data={option.RECIPES}
        columns={this.fetchColumns()}
        actionButtons={this.fetchActionButtons()}
      />
    );
  }

  render() {
    const {option} = this.state;

    return (
      <Modal
        ref={(e) => (this.modal = e)}
        large
        label={"Setting up Supply Chain Recipe for " + option?.NAME}
        formikOnClick={() => this.formikRef}
        closeLabel="Close"
      >
        {option && this.renderFormik(option)}
        {option && this.renderSubmitButton()}
        {option && this.renderRecipeTable(option)}
      </Modal>
    );
  }
}

export default optionIngredientsModal;
