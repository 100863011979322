import React, {Component} from "react";
import {
  FormBoolean,
  FormInput,
  Modal,
} from "@frostbyte-technologies/frostbyte-tailwind";
import {Formik} from "formik";
import {request} from "../../../utils/request";
import PropTypes from "prop-types";
import IngredientDropdown from "../../../dropdowns/operations/ingredient-dropdown";
import {showConfirmAlert} from "../../../utils/alert-helper";

class ProductIngredientModal extends Component {
  state = {product: null, seq: 1};

  open(product = null, seq = 1) {
    this.setState({product, seq}, () => {
      this.formikRef && this.formikRef.resetForm();
      this.modal.open();
    });
  }

  async deleteProductIngredient() {
    const {product} = this.state;

    showConfirmAlert(
      "Archive this Ingredient",
      "Are you sure you wanted to delete this product ingredient"
    )
      .then(async () => {
        await request("ingredients/product/" + product.ID, "DELETE");

        this.props.updateState(product.ID);
      })
      .finally(() => this.modal.close());
  }

  async addProductIngredient({ingredient, quantity}) {
    const {product} = this.props;
    const {seq} = this.state;

    let serverCategory = await request("ingredients/product/", "POST", {
      PRODUCT_ID: product.ID,
      INGREDIENT_ID: ingredient,
      QUANTITY: parseFloat(quantity),
      SEQ: seq + 1,
    });

    this.props.addState(serverCategory);
    this.modal.close();
  }

  async saveProductIngredient({quantity, enabled}) {
    const {product} = this.state;

    let serverPaymentType = {
      QUANTITY: parseFloat(quantity),
      ENABLED: parseInt(enabled),
      SEQ: product.SEQ,
    };

    let serverCategory = await request(
      "ingredients/product/" + product.ID,
      "PATCH",
      serverPaymentType
    );

    this.props.updateState(serverCategory.ID, serverCategory);
    this.modal.close();
  }

  render() {
    const {product} = this.state;

    return (
      <Modal
        deleteOnClick={this.deleteProductIngredient.bind(this)}
        deleteLabel={product && "Archive"}
        buttonLabel={product ? "Save" : "Add"}
        label={product ? "Edit Product Ingredient" : "Add Product Ingredient"}
        ref={(e) => (this.modal = e)}
        formikOnClick={() => this.formikRef}
      >
        <Formik
          onSubmit={
            product
              ? this.saveProductIngredient.bind(this)
              : this.addProductIngredient.bind(this)
          }
          innerRef={(e) => (this.formikRef = e)}
          enableReinitialize
          initialValues={{
            ingredient: product?.INGREDIENT_ID,
            quantity: product?.QUANTITY ?? "0",
            enabled: product?.ENABLED ?? 1,
          }}
        >
          {(formikOptions) => {
            const {handleSubmit} = formikOptions;

            return (
              <form onSubmit={handleSubmit}>
                <IngredientDropdown name="ingredient" options={formikOptions} />

                <FormInput
                  options={formikOptions}
                  placeholder="2.5"
                  label="Quantity"
                  name="quantity"
                />

                {product && (
                  <FormBoolean
                    options={formikOptions}
                    label="Enabled"
                    name="enabled"
                  />
                )}
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

ProductIngredientModal.propTypes = {
  updateState: PropTypes.func,
  addState: PropTypes.func,
};

export default ProductIngredientModal;
