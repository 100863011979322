import React, {Component} from "react";
import {FormInput, Modal, Tab} from "@frostbyte-technologies/frostbyte-tailwind";
import {request} from "../../../utils/request";
import {Formik} from "formik";
import * as Yup from "yup";
import ComboBox from "../../../components/combobox";
import {setupReduxConnection} from "../../../redux";
import {SupplyChainUnitRequests} from "../../../utils/request-helpers/supply-chain/supply-chain-requests";
import {Row} from "../../../pages/operations/supply-chain/shared";

class SupplyChainUnitModal extends Component {
    state = {toUnit: null, units: [], unit: {}};

    async componentDidMount() {
        const units = await SupplyChainUnitRequests.fetchSupplyChainUnits();
        this.setState({units});
    }

    open(unit = null) {
        this.setState({unit}, () => {
            this.formikRef && this.formikRef.resetForm();
            this.modal.open();
        });
    }

    SupplyChainDropDownForUnit = ({formikOptions}) => {
        const {setFieldValue} = formikOptions;
        const {units} = this.state;

        const data = units.map(unit => ({
            unit,
            name: unit.NAME,
            id: unit.ID,
            label: unit.NAME,
        }))

        return (
            <ComboBox
                label="Linked Unit"
                tooltip="The relational unit used to convert this custom unit.
                For example, you're making a custom unit for a syrup bottle that has 750ml.
                You would make 750 the Linked Unit Quantity, and ml would be the linked unit"
                data={data}
                options={formikOptions}
                value={formikOptions.values.toUnit}
                onChangeSoft={(id) => {
                    setFieldValue('toUnit', id);
                }}
                name="toUnit"
            />
        );
    };

    async createIngredientUnit({name, abbreviation, toUnit, conversion}) {
        const {syncState} = this.props;

        const unitToCreate = {
            NAME: name,
            ABBREVIATION: abbreviation,
            CONVERSIONS: [{TO_UNIT: toUnit, RATE: parseFloat(conversion)}],
        };

        await SupplyChainUnitRequests.createSupplyChainUnit(unitToCreate);

        await syncState();
        this.modal.close();
    }

    async saveIngredientUnit({name, abbreviation, toUnit, conversion}) {
        console.log(name, toUnit, conversion);
        const {unit} = this.state;
        const {syncState} = this.props;

        const conversions = unit.CONVERSIONS.map((existingConversion) => {
            if (existingConversion.TO_UNIT === toUnit) {
                return {TO_UNIT: toUnit, RATE: parseFloat(conversion)}
            } else return existingConversion;
        })

        const unitConversion = {
            NAME: name,
            ABBREVIATION: abbreviation,
            CONVERSIONS: conversions
        };

        await SupplyChainUnitRequests.updateSupplyChainUnit(unit.ID, unitConversion);

        await syncState();
        this.modal.close();
    }

    render() {
        const {unit} = this.state;

        const validationSchema = Yup.object({
            name: Yup.string().required("Unit name is required"),
            abbreviation: Yup.string().nullable(),
            toUnit: Yup.number().required("Unit must be selected"),
            conversion: Yup.number().required("Unit must have a conversion")
        });

        const starterConversion = unit?.CONVERSIONS?.find(it => it.FROM_UNIT === unit.ID) ?? {}

        const initialValues = {
            name: unit?.NAME ?? "",
            abbreviation: unit?.ABBREVIATION ?? null,
            toUnit: starterConversion?.TO_UNIT ?? "",
            conversion: starterConversion?.RATE ?? ""
        };


        return (
            <Modal
                buttonLabel={unit ? "Save" : "Add"}
                label={unit ? "Edit Supply Chain Unit" : "Save Supply Chain Unit"}
                ref={(e) => (this.modal = e)}
                formikOnClick={() => this.formikRef}
            >
                <Formik
                    onSubmit={
                        unit
                            ? this.saveIngredientUnit.bind(this)
                            : this.createIngredientUnit.bind(this)
                    }
                    innerRef={(e) => (this.formikRef = e)}
                    validationSchema={validationSchema}
                    initialValues={initialValues}
                >
                    {(formikOptions) => {
                        const {handleSubmit} = formikOptions;

                        const data = [{id: 0, label: 'Direct Conversion'}, {id: 1, label: 'Calculated Conversions'}]

                        return (
                            <div>
                                <FormInput
                                    options={formikOptions}
                                    placeholder="Ounces"
                                    label="Custom Unit Name"
                                    name="name"
                                />
                                <FormInput
                                    label="Abbreviation"
                                    placeholder="oz"
                                    name="abbreviation"
                                    options={formikOptions}/>

                                <Row>
                                    <this.SupplyChainDropDownForUnit formikOptions={formikOptions}/>

                                    <FormInput
                                        className="ml-2"
                                        options={formikOptions}
                                        placeholder="2.5"
                                        label="Linked Unit Quantity"
                                        tooltip="The relational unit used to convert this custom unit.
                                    For example, you're making a custom unit for a syrup bottle that has 750ml.
                                    You would make 750 the Linked Unit Quantity, and ml would be the linked unit"
                                        name="conversion"
                                    />
                                </Row>

                            </div>
                        );
                    }}
                </Formik>
            </Modal>
        );
    }
}

export default setupReduxConnection(["shop"])(SupplyChainUnitModal);
