import React, {Component} from "react";
import MultiSelectCombobox from "../../../components/multi-select-combobox";
import {setupReduxConnection} from "../../../redux";

class IngredientCategoryDropdown extends Component {
  state = {data: []};

  render() {
    const {categories: reduxCategories} = this.props.supply;
    const {categories} = this.props;

    const comboData = categories ?? reduxCategories;

    return (
      <MultiSelectCombobox
        popOver
        data={[
          {
            data: comboData.map((category) => ({
              category,
              name: category.NAME,
              id: category.ID,
              label: category.NAME,
            })),
          },
        ]}
        {...this.props}
      />
    );
  }
}

export default setupReduxConnection(["supply"])(IngredientCategoryDropdown);
