import React, {Component} from "react";
import {
    Card,
    PageHeadings,
    Tab,
    Table,
    TwoColumnList,
} from "@frostbyte-technologies/frostbyte-tailwind";
import {request} from "../../utils/request";
import {withRouter} from "../../utils/navigation";
import LoadingSpinner from "../../components/loading-spinner";
import VendorModal from "../../modals/accounting/vendor-modal";
import VendorContactModal from "../../modals/accounting/vendor-contact-modal";
import {getFormattedPhone} from "@frostbyte-technologies/frostbyte-core/dist/helpers/phone-helper";
import VendorItemModal from "../../modals/operations/supply-chain/vendors/vendor-item-modal";
import {VendorRequests} from "../../utils/request-helpers/supply-chain/supply-chain-requests";

class AccountingVendorPage extends Component {
    state = {vendor: null};

    async componentDidMount() {
        this.syncState();
    }

    syncState = async () => {
        const {UNIQUE_ID: uniqueId} = this.props.router.params;
        const vendor = await VendorRequests.fetchVendor(uniqueId);
        const vendorContacts = await VendorRequests.fetchVendorContacts(vendor.ID);
        const vendorItems = await VendorRequests.fetchVendorItems(vendor.ID);
        const purchaseOrders = await VendorRequests.fetchPurchaseOrders(vendor.ID);
        this.setState({
            vendor: {
                ...vendor,
                CONTACTS: vendorContacts,
                ITEMS: vendorItems,
                PURCHASE_ORDERS: purchaseOrders
            }
        });

        this.setState({vendor});
    };

    renderDetails() {
        const {vendor} = this.state;
        const {ADDRESS, CITY, STATE, ZIPCODE, WEBSITE, NOTES, CONTACTS} = vendor;

        return (
            <div>
                <VendorContactModal
                    vendor={vendor}
                    ref={(e) => (this.vendorContactModal = e)}
                    syncState={this.syncState}
                />

                <VendorItemModal
                    ref={(e) => (this.vendorItemModal = e)}
                    syncState={() => this.syncState()}
                    vendor={vendor}
                />

                <TwoColumnList
                    label={"Vendor Details"}
                    data={[
                        {
                            label: "Address",
                            value: `${ADDRESS}, ${CITY} ${STATE}, ${ZIPCODE}`,
                        },
                        {
                            label: "Website",
                            value: WEBSITE ? (
                                <a className={"cursor-hover text-indigo-500"} href={WEBSITE}>
                                    {WEBSITE}
                                </a>
                            ) : (
                                "None"
                            ),
                        },
                        {label: "Notes", value: NOTES ?? "None"},
                    ]}
                    buttons={[{label: "Edit", onClick: () => this.vendorModal.open(vendor)}]}
                />

                <Tab
                    data={[
                        {id: "contacts", label: "Contacts"},
                        {id: "items", label: "Items"},
                    ]}
                    noQuery
                >
                    {(tab) => {
                        if (tab === "contacts") {
                            return (
                                <Card
                                    label={"Vendor Contacts"}
                                    button={{
                                        label: "+ New",
                                        onClick: () => this.vendorContactModal.open(),
                                    }}
                                >
                                    <Table
                                        data={CONTACTS}
                                        actionButtons={[
                                            {
                                                label: "Edit",
                                                onClick: (row) => this.vendorContactModal.open(row),
                                            },
                                        ]}
                                        columns={[
                                            {
                                                label: "Name",
                                                value: "FIRST_NAME",
                                                format: (val, row) => `${row.FIRST_NAME} ${row.LAST_NAME}`,
                                            },
                                            {label: "Email", value: "EMAIL"},
                                            {
                                                label: "Phone",
                                                value: "PHONE",
                                                format: (val) => getFormattedPhone(val),
                                            },
                                        ]}
                                    />
                                </Card>
                            );
                        } else if (tab === "items") {
                            return (
                                <Card
                                    label="Vendor Price List"
                                    description="View all of the items you can purchase from this vendor"
                                    button={{
                                        label: "Create Item",
                                        onClick: () => {
                                            this.vendorItemModal.open();
                                        },
                                    }}
                                >
                                    <Table
                                        data={vendor.ITEMS}
                                        columns={[
                                            {value: "NAME", label: "Name"},
                                            {
                                                value: "PRODUCT_SKU",
                                                label: "SKU",
                                                format: (sku) => {
                                                    return sku ? "#" + sku : "-";
                                                },
                                            },
                                            {value: "INGREDIENT_NAME", label: "Attached Ingredient"},
                                            {
                                                value: "PRICE_PER_CASE",
                                                label: "Vendor Item Price",
                                                type: "dollars",
                                            },
                                        ]}
                                        actionButtons={[
                                            {
                                                label: "Edit",
                                                onClick: (row) => {
                                                    this.vendorItemModal.open(row);
                                                },
                                            },
                                        ]}
                                    />
                                </Card>
                            );
                        }
                    }}
                </Tab>
            </div>
        );
    }

    render() {
        const {vendor} = this.state;

        if (!vendor) {
            return <LoadingSpinner/>;
        }

        return (
            <div>
                <VendorModal
                    ref={(e) => (this.vendorModal = e)}
                    syncState={() => this.syncState()}
                />

                <PageHeadings
                    label={vendor.NAME}
                    breadcrumbs={[
                        {label: "Accounting", url: "/accounting"},
                        {label: "Vendors", url: "/accounting/vendors"},
                    ]}
                />

                <Tab
                    data={[
                        // {id: "transactions", label: "Transactions"},
                        {id: "details", label: "Details"},
                    ]}
                >
                    {(id) => {
                        if (id === "details") {
                            return this.renderDetails();
                        }
                    }}
                </Tab>
            </div>
        );
    }
}

export default withRouter(AccountingVendorPage);
