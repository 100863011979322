import React, {Component} from "react";
import {FormSelect, Modal} from "@frostbyte-technologies/frostbyte-tailwind";
import {Formik} from "formik";
import PresetDropdown from "../../../dropdowns/sales/preset-dropdown";
import * as Yup from "yup";

class PricingModifierModal extends Component {
  open() {
    this.formikRef && this.formikRef.resetForm();
    this.modal.open();
  }

  render() {
    return (
      <Modal
        buttonLabel="Add"
        label="Select Pricing Modifier"
        description="Select a modifier to use"
        onClose={() => this.props.clearState()}
        ref={(e) => (this.modal = e)}
        formikOnClick={() => this.formikRef}
      >
        <Formik
          innerRef={(e) => (this.formikRef = e)}
          initialValues={{type: "premade", modifier: null}}
          onSubmit={(values) => {
            this.props.updateState(values, () => this.modal.close());
          }}
          validationSchema={Yup.object({
            modifier: Yup.number()
              .nullable()
              .test(
                "Modifier Invalid",
                "Please select a preset modifier, or change the modifier type to custom.",
                (value, ctx) => {
                  return ctx.parent.type === "custom" || value;
                }
              ),
          })}
        >
          {(formikOptions) => {
            const {values, handleSubmit} = formikOptions;

            return (
              <form onSubmit={handleSubmit}>
                <FormSelect
                  label="Pricing Modifier Type"
                  options={formikOptions}
                  name="type"
                  data={[
                    {
                      label: "Pre-Made Modifier",
                      value: "premade",
                    },
                    {
                      label: "Custom Modifier",
                      value: "custom",
                    },
                  ]}
                />

                {values.type === "premade" && (
                  <PresetDropdown
                    type={5}
                    label="Pricing Modifier "
                    options={formikOptions}
                    placeholder="Select a pre made modifier"
                    name="modifier"
                  />
                )}
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

export default PricingModifierModal;
