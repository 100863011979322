export function getColumn(value, label, format, tooltip) {
  return {
    value: value,
    label: label,
    ...(format && {format: (value, row) => format(value, row)}),
    ...(tooltip && {tooltip: tooltip})
  };
}

export function getNameColumn({value = "FULL_NAME", label = "NAME", format = null}) {
  return getColumn(value, label, format);
}

export function getPhoneColumn({value = "PHONE", label = "PHONE NUMBER", format = null}) {
  return getColumn(value, label, format);
}
