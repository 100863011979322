import React, {Component} from "react";
import {request} from "../../utils/request";
import ComboBox from "../../components/combobox";

class DepedencyDropdown extends Component {
  state = {data: []};

  componentDidMount() {
    request("checklists/", "GET").then((checklists) => {
      const templateData = checklists.map((template) => {
        return {id: template.UNIQUE_ID, label: template.NAME};
      });

      const data = [];

      if (templateData.length > 0) {
        data.push({
          title: "Checklists",
          data: templateData,
        });
      }

      this.setState({data});
    });
  }

  render() {
    const {data} = this.state;

    return (
      <ComboBox
        label="Dependent"
        {...this.props}
        sections={data}
        headerIcon="chevron-right"
        iconStyle="mr-1 text-sm"
      />
    );
  }
}

export default DepedencyDropdown;
