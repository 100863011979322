import {Table} from "@frostbyte-technologies/frostbyte-tailwind";
import React, {Component} from "react";
import {showConfirmAlert} from "../../utils/alert-helper";
import {request} from "../../utils/request";
import PropTypes from "prop-types";

class BillingPaymentMethodsTable extends Component {
  getActionButtons() {
    const {sources} = this.props;

    const actionButtons = [];

    if (sources && sources.length > 1) {
      actionButtons.push({
        label: "Remove Card",
        onClick: async (row) => {
          try {
            await showConfirmAlert(
              "Remove Card?",
              `Are you sure you would like to remove the card ending in ${row.last4} from your account?`
            );
          } catch (e) {
            return;
          }

          await request("partner/source/" + row.id, "DELETE");

          if (this.props.syncState) {
            this.props.syncState();
          }
        },
      });
    }

    return actionButtons;
  }

  render() {
    const {sources} = this.props;

    const actionButtons = this.getActionButtons();

    return (
      <Table
        columns={[
          {
            label: "Brand",
            value: "card",
            format: (value, _) => {
              if (!value || !value.brand) {
                return "-";
              }

              return value.brand[0].toUpperCase() + value.brand.substring(1);
            },
          },
          {
            label: "Number",
            value: "card",
            format: (value, _) => {
              if (!value) {
                return "-";
              }

              return "**** **** **** **** " + value.last4;
            },
          },
          {
            label: "Expires",
            value: "card",
            format: (value, _) => {
              if (!value || !value.exp_month || !value.exp_year) {
                return "-";
              }

              return "" + value.exp_month + "/" + value.exp_year.toString().substring(2);
            },
          },
        ]}
        placeholders={{noDataTitle: "No cards found!"}}
        actionButtons={actionButtons}
        data={sources}
      />
    );
  }
}

BillingPaymentMethodsTable.propTypes = {
  sources: PropTypes.array.isRequired,
  syncState: PropTypes.func,
};

export default BillingPaymentMethodsTable;
