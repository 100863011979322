import React, {Component} from "react";
import {Formik} from "formik";
import {Modal} from "@frostbyte-technologies/frostbyte-tailwind";
import DepedencyDropdown from "../../../dropdowns/operations/dependency-dropdown";
import {request} from "../../../utils/request";
import {showSuccessNotification} from "../../../utils/notification-helper";

class DependencyModal extends Component {
  state = {dependency: null};

  open(dependency = null) {
    this.setState({dependency}, () => this.modal.open());
  }

  createDependency({dependent}) {
    const {template} = this.props;

    request("dependencies/", "POST", {
      DEPENDER: template.UNIQUE_ID,
      DEPENDEE: dependent,
    })
      .then(() => {
        return this.props.syncState();
      })
      .then(() => {
        this.modal.close();

        showSuccessNotification("Dependency Created");
      });
  }

  render() {
    return (
      <Modal
        label="Create Dependency"
        buttonLabel="Create"
        formikOnClick={() => this.formikRef}
        ref={(e) => (this.modal = e)}
      >
        <Formik
          onSubmit={this.createDependency.bind(this)}
          innerRef={(e) => (this.formikRef = e)}
          initialValues={{
            dependent: null,
          }}
        >
          {(formikOptions) => {
            const {handleSubmit} = formikOptions;

            return (
              <form onSubmit={handleSubmit}>
                <DepedencyDropdown
                  label="Dependent"
                  name="dependent"
                  options={formikOptions}
                  tooltip="This item must wait for this checklist being completed before being completed."
                />
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

export default DependencyModal;
