import GrayBadge from "./badges/gray-badge";
import FormPartialHours from "./form-partial-hours";
import {FormSelect} from "@frostbyte-technologies/frostbyte-tailwind";
import React from "react";

export default function FormTimeOffDuration({day, formikOptions, index}) {
  const TIME_OFF_HOURS = [
    {value: 0, label: "0 Hours"},
    {value: 1, label: "1 Hour"},
    {value: 2, label: "2 Hours"},
    {value: 3, label: "3 Hours"},
    {value: 4, label: "4 Hours"},
    {value: 5, label: "5 Hours"},
    {value: 6, label: "6 Hours"},
    {value: 7, label: "7 Hours"},
    {value: 8, label: "8 Hours"},
  ];

  const TIME_OFF_MINUTES = [
    {value: 0, label: "00 Minutes"},
    {value: 0.25, label: "15 Minutes"},
    {value: 0.5, label: "30 Minutes"},
    {value: 0.75, label: "45 Minutes"},
  ];

  const getMinutesLabel = (amount) => {
    const minutes = (amount - Math.floor(amount)) * 60;
    if (!minutes) {
      return TIME_OFF_MINUTES[0].label;
    } else {
      return `${minutes} Minutes`;
    }
  };

  const getMinutesPlaceholder = () => {
    return day.AMOUNT ? getMinutesLabel(day.AMOUNT) : TIME_OFF_MINUTES[0].label;
  };

  const getHoursPlaceholder = () => {
    return day.AMOUNT ? `${Math.floor(day.AMOUNT)} Hours` : TIME_OFF_HOURS[8].label;
  };

  const handleMinuteSelection = (value) => {
    day.AMOUNT = value + Math.floor(day.AMOUNT);

    formikOptions.setFieldValue(`amount[${index}]`, day.AMOUNT);
  };

  const handleHourSelection = (value) => {
    day.AMOUNT = value + (day.AMOUNT - Math.floor(day.AMOUNT));

    formikOptions.setFieldValue(`amount[${index}]`, day.AMOUNT);
  };

  return (
    <div key={index}>
      <div className={"flex flex-row items-center mt-4"}>
        <span class="block text-sm font-medium text-gray-700">Select Duration For {day.CONTENT}</span>
        <GrayBadge
          className={
            "flex ml-2 bg-gray-200 px-2 -py-1 rounded-md text-xs !text-gray-500 items-center font-medium"
          }
        >
          optional
        </GrayBadge>
      </div>

      <div className={"flex flex-row items-center gap-x-2 mt-1.5 mb-3"}>
        <FormPartialHours label={"Hours"}>
          <FormSelect
            label=""
            name={`hours-${index}`}
            flex
            placeholder={getHoursPlaceholder()}
            onChangeSoft={(input) => handleHourSelection(input.value)}
            data={TIME_OFF_HOURS}
            options={formikOptions}
          />
        </FormPartialHours>

        <FormPartialHours label={"Minutes"}>
          <FormSelect
            label=""
            name={`minutes-${index}`}
            flex
            placeholder={getMinutesPlaceholder()}
            onChangeSoft={(input) => handleMinuteSelection(input.value)}
            data={TIME_OFF_MINUTES}
            options={formikOptions}
          />
        </FormPartialHours>

        <input type="hidden" name={`amount[${index}]`} value={day.AMOUNT} />
      </div>
    </div>
  );
}
