import React, {Component} from "react";
import AlertService from "../services/alert-service";
import NotificationService from "../services/notification-service";
import {Loading} from "@frostbyte-technologies/frostbyte-tailwind";
import {withRouter} from "../../utils/navigation";
import EmployeeHeader from "./hub-header";
import {getStore} from "../../redux";
import {updateUser} from "../../redux/user";
import {request} from "../../utils/request";
import * as Sentry from "@sentry/react";

class EmployeeLayout extends Component {
  state = {isLoading: false};

  async componentDidMount() {
    const payload = await request("dashboard/hub", "GET").catch(({error}) => {
      this.props.router.navigate("/login", {
        state: {
          returnUrl: window.location.href,
        },
      });

      return Promise.reject("LOGIN_INVALID");
    });

    const {account} = payload;

    Sentry.setUser({
      username: account.FULL_NAME,
      email: account.EMAIL,
      id: account.ID,
    });

    getStore().dispatch(updateUser({account}));
  }

  render() {
    const {isLoading} = this.state;

    if (isLoading) {
      return <Loading />;
    }

    return (
      <div>
        <EmployeeHeader />
        <AlertService />
        <NotificationService />
        <div>{this.props.children}</div>
      </div>
    );
  }
}

export default withRouter(EmployeeLayout);
