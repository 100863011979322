import React, {Component} from "react";
import Modal from "@frostbyte-technologies/frostbyte-tailwind/dist/components/modals/modal";
import {Formik} from "formik";
import {FormSelect} from "@frostbyte-technologies/frostbyte-tailwind";
import {request} from "../../utils/request";
import moment from "moment-timezone";
import {setupReduxConnection} from "../../redux";
import {VIEWS} from "../../features/scheduling/scheduler";
import {
  showErrorNotification,
  showSuccessNotification,
} from "../../utils/notification-helper";
import NotificationForm from "../../forms/scheduling/notification-form";

class PublishModal extends Component {
  state = {start: null, selectedLocations: [], day: false};

  open(start) {
    let {currentView} = this.props.scheduling;
    const {shifts} = this.props.scheduling.schedule;
    const {locationIdArr} = this.props;

    const isDayView = currentView === VIEWS.DAY;

    let end = isDayView
      ? moment(start).add(1, "day").valueOf()
      : moment(start).add(1, "week").valueOf();

    const locationSet = new Set(locationIdArr);

    let numShifts = shifts.filter((item) => {
      return (
        item.DRAFT === 1 &&
        item.DATE_START >= start &&
        end > item.DATE_START &&
        locationSet.has(item.LOCATION_ID)
      );
    }).length;

    this.setState(
      {start, day: isDayView, numShifts, selectedLocations: [...locationIdArr]},
      () => {
        this.formikRef && this.formikRef.resetForm();
        this.slide.open();
      }
    );
  }

  fetchNumShifts(locations) {
    let {shifts} = this.props.scheduling.schedule;
    const {start, day} = this.state;

    let end = day
      ? moment(start).add(1, "day").valueOf()
      : moment(start).add(1, "week").valueOf();

    const locationSet = new Set(locations);

    let numShifts = shifts.filter((item) => {
      return (
        item.DRAFT === 1 &&
        item.DATE_START >= start &&
        end > item.DATE_START &&
        locationSet.has(item.LOCATION_ID)
      );
    }).length;

    this.setState({numShifts});
  }

  handlePublishSchedule = async (values) => {
    this.setState({isLoading: true});
    let {notify, text, email} = values;
    const {start, day, selectedLocations} = this.state;
    const {isToggledLocation} = this.props;

    try {
      await request("scheduling/locations/publish/" + start, "POST", {
        SEND_ALL: notify,
        SEND_SMS: text,
        SEND_EMAIL: email,
        DAY: day,
        LOCATION_ID_ARR: selectedLocations,
      });
    } catch (err) {
      return showErrorNotification(
        "Publish Schedule Error",
        "Error publishing schedule. Please refresh and try again."
      );
    }

    if (isToggledLocation && this.props.publishShifts) {
      this.props.publishShifts(selectedLocations);
    }

    showSuccessNotification("Publish Schedule Success");

    this.slide.close();
  };

  render() {
    let {start, day, numShifts, selectedLocations} = this.state;
    let {locationIdArr, locations} = this.props;

    const description =
      numShifts > 0
        ? day
          ? `${numShifts} Shifts
    for the day: ${moment(start).format("M/D")}`
          : `${numShifts} Shifts
    for the week: ${moment(start).format("M/D")} - ${moment(start)
              .add(1, "week")
              .subtract(1, "day")
              .format("M/D")}`
        : "";

    locations = locations.map(({ID, NAME}) => ({
      value: ID,
      label: NAME,
    }));

    return (
      <Modal
        large
        buttonLabel={"Publish"}
        label="Publish Schedule"
        description={description}
        formikOnClick={() => this.formikRef}
        ref={(e) => (this.slide = e)}
        buttonDisabled={numShifts === 0}
      >
        {numShifts === 0 && (
          <div className="font-semibold text-lg py-5 text-center">
            No Shifts to Publish
          </div>
        )}

        {numShifts > 0 && (
          <Formik
            onSubmit={this.handlePublishSchedule}
            innerRef={(e) => (this.formikRef = e)}
            initialValues={{
              notify: 1,
              text: true,
              email: true,
              locations: locationIdArr,
            }}
          >
            {(formikOptions) => {
              const {handleSubmit} = formikOptions;

              return (
                <form onSubmit={handleSubmit}>
                  <FormSelect
                    name={"locations"}
                    label={"Location"}
                    data={locations}
                    value={selectedLocations}
                    onChange={(selectedLocations) => {
                      this.fetchNumShifts(selectedLocations);

                      this.setState({selectedLocations});
                    }}
                    multi
                  />

                  <NotificationForm formikOptions={formikOptions} />
                </form>
              );
            }}
          </Formik>
        )}
      </Modal>
    );
  }
}

export default setupReduxConnection(["scheduling"])(PublishModal);
