import React, {Component} from "react";
import {FormInput, Modal} from "@frostbyte-technologies/frostbyte-tailwind";
import {Formik} from "formik";
import * as Yup from "yup";
import {
  showErrorNotification,
  showSuccessNotification,
} from "../../../../utils/notification-helper";
import {IngredientCategoryRequests} from "../../../../utils/request-helpers/supply-chain/supply-chain-requests";
import {getStore} from "../../../../redux";
import {upsertIngredientCategory} from "../../../../redux/supply";

class IngredientCategoryModal extends Component {
  state = {group: null};

  open(group = null) {
    this.formikRef && this.formikRef.resetForm();
    this.setState({group}, () => this.modal.open());
  }

  createIngredientGroup({name, wasteTarget, cogsTarget}) {
    const {syncState} = this.props;
    IngredientCategoryRequests.createCategory({
      NAME: name,
      WASTE_TARGET: wasteTarget,
      COGS_TARGET: cogsTarget,
    })
      .then((category) => {
        showSuccessNotification(
          "Group Created Successfully.",
          name + " was created successfully."
        );

        return getStore().dispatch(upsertIngredientCategory(category));
      })
      .catch((err) => {
        showErrorNotification(
          "Something went wrong.",
          "Something went wrong creating the group. Please try again."
        );
      })
      .finally(() => {
        syncState();
        this.modal && this.modal.close();
      });
  }

  saveIngredientGroup(groupId, {name, wasteTarget, cogsTarget}) {
    const {syncState} = this.props;
    IngredientCategoryRequests.updateCategory(groupId,{
      NAME: name,
      WASTE_TARGET: wasteTarget,
      COGS_TARGET: cogsTarget,
    })
        .then((category) => {
          showSuccessNotification(
              "Group Edited Successfully.",
              name + " was edited successfully."
          );

          return getStore().dispatch(upsertIngredientCategory(category));
        })
        .catch((err) => {
          showErrorNotification(
              "Something went wrong.",
              "Something went wrong editing the group. Please try again."
          );
        })
        .finally(() => {
          syncState();
          this.modal && this.modal.close();
        });
  }

  render() {
    const {group} = this.state;

    return (
      <Modal
        buttonLabel={group ? "Save" : "Add"}
        label={group ? "Edit Group" : "Create Group"}
        ref={(e) => (this.modal = e)}
        formikOnClick={() => this.formikRef}
      >
        <Formik
          innerRef={(e) => (this.formikRef = e)}
          onSubmit={(values) => group ? this.saveIngredientGroup(group?.ID, values) : this.createIngredientGroup(values)}
          initialValues={{
            name: group?.NAME,
            wasteTarget: group?.WASTE_TARGET,
            cogsTarget: group?.COGS_TARGET,
          }}
          validationSchema={Yup.object().shape({
            name: Yup.string().nullable().required("Please enter a name for this group."),
            wasteTarget: Yup.number().nullable().positive("Please enter a positive number."),
            cogsTarget: Yup.number().nullable().positive("Please enter a positive number."),
          })}
        >
          {(formikOptions) => {
            return (
              <form>
                <FormInput
                  name="name"
                  label="Name"
                  tooltip="The name for this group."
                  options={formikOptions}
                />

                <FormInput
                  name="wasteTarget"
                  label="Waste Target (%)"
                  hint="Optional"
                  tooltip={{
                    data: "Your waste target is the percentage of total inventory you expect to be wasted. If your waste amounts ever exceed this target, we will notify you.",
                  }}
                  placeholder="10"
                  options={formikOptions}
                />

                <FormInput
                  name="cogsTarget"
                  label="COGS Target (%)"
                  hint="Optional"
                  tooltip={{
                    data: "Your cogs target is the percentage of total inventory you expect to be wasted. If your waste amounts ever exceed this target, we will notify you.",
                  }}
                  placeholder="33"
                  options={formikOptions}
                />
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

export default IngredientCategoryModal;
