import React, {Component} from "react";
import {
  CATEGORY_SALES_COLUMNS,
  CELL_TYPES,
} from "../../features/reporting/reports/reporting-constants";
import FixedColumnReport from "../../features/reporting/reports/fixed-column-report";
import {PageHeadings} from "@frostbyte-technologies/frostbyte-tailwind";
import {AUDIT_CATEGORY_SALES_COLUMNS} from "../../features/reporting/reports/audit-reporting-constants";
import {setupReduxConnection} from "../../redux";

class CategorySalesReportPage extends Component {
  convertDataToRows = (reportData) => {
    let rows = [];

    if (reportData) {
      let {LINE_ITEM_RECORDS, TOTALS} = reportData;

      for (let record of LINE_ITEM_RECORDS) {
        let {CATEGORY_NAME} = record;
        rows.push({
          label: CATEGORY_NAME,
          numbers: record,
          rowType: CELL_TYPES.REGULAR,
        });
      }

      rows.push({
        label: "Total",
        numbers: TOTALS,
        rowType: CELL_TYPES.REGULAR,
      });
    }

    return rows;
  };

  render() {
    let {REPORTING_MODE, REPORTING_SHOW_LOCATION_CSV, REPORTING_SHOW_DATE_CSV} =
      this.props.shop.settings;
    const {locationIdArray, groupReports} = this.props.reporting;
    const isMultiLocation = locationIdArray?.length > 1;
    const showLocationColumn = REPORTING_MODE === "AUDIT" && isMultiLocation;

    let columns = CATEGORY_SALES_COLUMNS;

    if (REPORTING_MODE === "AUDIT") {
      columns = AUDIT_CATEGORY_SALES_COLUMNS;
    }

    return (
      <div>
        <div className="mb-5">
          <PageHeadings label={"Category Sales Report"} />
        </div>

        <FixedColumnReport
          filenamePrefix="category-sales"
          endpoint="report/categorysales"
          constantColumns={
            showLocationColumn
              ? [{label: "Location", columnSelector: "LOCATION_NAME"}, ...columns]
              : columns
          }
          firstColLabel="Category Name"
          convertDataToRows={this.convertDataToRows}
          search={true}
          searchText={"Search Categories by Name"}
          locationPicker={true}
          showExcludeThird={true}
          groupbyFilter={true}
          showLocationsInDownload={REPORTING_SHOW_LOCATION_CSV === "1"}
          showDateRangeInDownload={REPORTING_SHOW_DATE_CSV === "1"}
        />
      </div>
    );
  }
}

export default setupReduxConnection(["reporting", "shop"])(CategorySalesReportPage);
