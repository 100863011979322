import React, {Component} from "react";
import {
  FormElement,
  FormInput,
  FormSelect,
  FormTextArea,
  Modal,
} from "@frostbyte-technologies/frostbyte-tailwind";
import {FieldArray, Formik} from "formik";
import FormDateTimeSelect from "../../components/form-date-time-select";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import * as Yup from "yup";
import {
  decimalToDollars,
  toDollars,
} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import {request} from "../../utils/request";
import {setupReduxConnection} from "../../redux";
import {withRouter} from "../../utils/navigation";
import {classNames} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import LedgerDropdown from "../../dropdowns/accounting/ledger-dropdown";
import moment from "moment-timezone";

const DEBIT_CREDIT_WIDTH = 95;

class JournalEntryModal extends Component {
  state = {journalEntry: null};

  open(journalEntry) {
    this.setState({journalEntry}, () => this.modal.open());
  }

  openNew() {
    this.modal.open();
  }

  handleSubmit = async ({name, date, description, lineEntries}) => {
    const {journalEntry} = this.state;

    const payload = {
      NAME: name,
      TYPE: "MANUAL",
      DESCRIPTION: description,
      DATE_OCCURRED: moment(date).format("YYYY-MM-DD"),
      LINE_ENTRIES: lineEntries.map(({uniqueId, ledgerId, debitAmount, creditAmount}) => {
        const updatedLineEntry = {
          LEDGER_ID: ledgerId,
          AMOUNT: decimalToDollars(debitAmount) + decimalToDollars(creditAmount),
          DEBIT_CREDIT: decimalToDollars(debitAmount) > 0 ? "DEBIT" : "CREDIT",
        };

        if (uniqueId) {
          updatedLineEntry.UNIQUE_ID = uniqueId;
        }

        return updatedLineEntry;
      }),
    };

    if (!journalEntry) {
      await request(`accounting/journal-entries`, "POST", payload);
    } else {
      await request(
        `accounting/journal-entries/${journalEntry.UNIQUE_ID}`,
        "PATCH",
        payload
      );
    }

    await this.props.syncState();

    this.modal.close();
  };

  delete = async () => {
    const {journalEntry} = this.state;

    await request(`accounting/journal-entries/${journalEntry.UNIQUE_ID}`, "DELETE");

    await this.props.syncState();

    this.modal.close();
  };

  getBalances(lineEntries) {
    return {
      debitTotal: lineEntries.reduce(
        (total, {debitAmount}) =>
          total + (decimalToDollars(debitAmount) > 0 ? decimalToDollars(debitAmount) : 0),
        0
      ),
      creditTotal: lineEntries.reduce(
        (total, {creditAmount}) =>
          total +
          (decimalToDollars(creditAmount) > 0 ? decimalToDollars(creditAmount) : 0),
        0
      ),
    };
  }

  render() {
    const {journalEntry} = this.state;

    let lineEntries = [{}, {}];

    if (journalEntry) {
      lineEntries = journalEntry.LINE_ENTRIES.map(
        ({UNIQUE_ID, LEDGER_ID, DEBIT_CREDIT, AMOUNT}) => ({
          uniqueId: UNIQUE_ID,
          ledgerId: LEDGER_ID,
          debitAmount: DEBIT_CREDIT === "DEBIT" ? toDollars(AMOUNT) : null,
          creditAmount: DEBIT_CREDIT === "CREDIT" ? toDollars(AMOUNT) : null,
        })
      );
    }

    const editable = !journalEntry?.TYPE || journalEntry?.TYPE === "MANUAL";

    return (
      <Modal
        label={`${journalEntry ? "Edit" : "New"} Journal Entry`}
        ref={(e) => (this.modal = e)}
        buttonLabel={journalEntry ? "Save" : "Create"}
        deleteLabel={journalEntry ? "Delete" : ""}
        deleteOnClick={this.delete}
        formikOnClick={() => this.formikRef}
      >
        <Formik
          initialValues={{
            name: journalEntry?.NAME,
            description: journalEntry?.DESCRIPTION,
            date: moment(journalEntry?.DATE_OCCURRED).valueOf(),
            lineEntries,
          }}
          validationSchema={Yup.object({
            name: Yup.string().required("Name is required"),
            date: Yup.number().required("Date is required"),
            description: Yup.string().nullable(),
            lineEntries: Yup.array(
              Yup.object({
                ledgerId: Yup.number().required("Account is required"),
              }).test(
                "debitOrCredit",
                "Debit or Credit is required",
                (value) =>
                  (value.debitAmount || value.creditAmount) &&
                  !(value.debitAmount && value.creditAmount)
              )
            ),
            total: Yup.string()
              .nullable("")
              .test("balance", "Debits and Credits must balance", function () {
                const {lineEntries} = this.parent;

                return (
                  lineEntries.reduce(
                    (total, {debitAmount}) =>
                      total +
                      (decimalToDollars(debitAmount) > 0
                        ? decimalToDollars(debitAmount)
                        : 0),
                    0
                  ) ===
                  lineEntries.reduce(
                    (total, {creditAmount}) =>
                      total +
                      (decimalToDollars(creditAmount) > 0
                        ? decimalToDollars(creditAmount)
                        : 0),
                    0
                  )
                );
              }),
          })}
          onSubmit={this.handleSubmit}
          innerRef={(e) => (this.formikRef = e)}
        >
          {(formikOptions) => {
            const {handleSubmit, values, setFieldValue} = formikOptions;
            const {lineEntries} = values;

            const {debitTotal, creditTotal} = this.getBalances(lineEntries);

            return (
              <form onSubmit={handleSubmit}>
                <div
                  className={"flex flex-row justify-between space-x-2"}
                  id={"helloooo"}
                >
                  <FormInput
                    className={"flex-1"}
                    label={"Entry Name"}
                    name={"name"}
                    options={formikOptions}
                    disabled={!editable}
                  />

                  <FormDateTimeSelect
                    label="Date"
                    name={"date"}
                    hideTime={true}
                    options={formikOptions}
                    disabled={!editable}
                  />
                </div>

                {editable && (
                  <FormTextArea
                    label={"Description"}
                    name={"description"}
                    hint={"Optional"}
                    options={formikOptions}
                  />
                )}

                <FieldArray name={"lineEntries"} options={formikOptions}>
                  {({remove}) => (
                    <div>
                      {lineEntries.map((_l, index) => (
                        <div>
                          <div className={"flex flex-row space-x-2 items-start w-full"}>
                            <LedgerDropdown
                              label={index === 0 ? "Account" : ""}
                              name={`lineEntries.${index}.ledgerId`}
                              disabled={!editable}
                              options={formikOptions}
                            />

                            <FormInput
                              name={`lineEntries.${index}.debitAmount`}
                              style={{maxWidth: DEBIT_CREDIT_WIDTH}}
                              label={index === 0 ? "Debit" : ""}
                              disabled={!editable}
                              options={formikOptions}
                            />

                            <FormInput
                              name={`lineEntries.${index}.creditAmount`}
                              style={{maxWidth: DEBIT_CREDIT_WIDTH}}
                              label={index === 0 ? "Credit" : ""}
                              disabled={!editable}
                              options={formikOptions}
                            />
                          </div>

                          <div className={"relative"}>
                            {lineEntries.length > 2 && editable && (
                              <FontAwesomeIcon
                                className={classNames(
                                  "absolute text-red-500 text-xs cursor-pointer -right-3.5",
                                  lineEntries[index].ledgerId ? "bottom-7" : " bottom-3"
                                )}
                                icon={"fa-x"}
                                onClick={() => remove(index)}
                              />
                            )}
                          </div>
                        </div>
                      ))}

                      {editable && (
                        <div
                          className={
                            "text-indigo-500 font-semibold text-sm mt-2 cursor-pointer"
                          }
                          onClick={() =>
                            setFieldValue("lineEntries", [...lineEntries, {}])
                          }
                        >
                          + Add Account
                        </div>
                      )}
                    </div>
                  )}
                </FieldArray>

                <div className={"float-right text-black font-semibold text-sm"}>
                  <FormElement className={"display-none"} name={"total"} />

                  <div className={"inline-block mr-3"}>Totals</div>

                  <div style={{width: DEBIT_CREDIT_WIDTH}} className={"inline-block"}>
                    {toDollars(debitTotal, true)}
                  </div>

                  <div
                    style={{paddingLeft: 8, width: DEBIT_CREDIT_WIDTH + 5}}
                    className={"inline-block"}
                  >
                    {toDollars(creditTotal, true)}
                  </div>
                </div>
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

export default setupReduxConnection(["accounting"])(withRouter(JournalEntryModal));
