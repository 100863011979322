import React, {Component} from "react";
import {FormInput, Modal} from "@frostbyte-technologies/frostbyte-tailwind";
import {Formik} from "formik";
import * as Yup from "yup";
import IngredientDropdown from "../../../../dropdowns/operations/recipes/ingredient-dropdown";
import {showErrorAlert} from "../../../../utils/alert-helper";
import {
  showErrorNotification,
  showSuccessNotification,
} from "../../../../utils/notification-helper";
import {RecipeRequests} from "../../../../utils/request-helpers/supply-chain/supply-chain-requests";
import {getStore} from "../../../../redux";
import {updateIngredient} from "../../../../redux/supply";

class AttachIngredientModal extends Component {
  state = {recipe: null};

  open(recipe) {
    this.setState({recipe}, () => this.modal.open());
  }

  async attachIngredient(values) {
    const {edge, quantity} = values;
    const {source} = this.props;

    const payload = {
      SOURCE_ID: source.ID,
      DESTINATION_ID: edge,
      QUANTITY: quantity,
    };

    try {
      await RecipeRequests.createRecipe(payload);
    } catch (e) {
      showErrorAlert(
        "Cannot add ingredient.",
        "Adding this ingredient would create a cyclic dependency."
      );
    }

    await getStore().dispatch(updateIngredient(source.ID));
    this.modal.close();
  }

  deleteIngredient() {
    const {recipe} = this.state;
    const {source} = this.props;

    RecipeRequests.deleteRecipe(recipe.RECIPE_ID)
      .then(() => {
        showSuccessNotification("Sub-ingredient deleted successfully.");

        return getStore().dispatch(updateIngredient(source.ID));
      })
      .catch((err) => {
        showErrorNotification("Error deleting sub-ingredient,");
      })
      .finally(() => {
        this.modal && this.modal.close();
      });
  }

  async saveIngredient(values) {
    const {quantity} = values;
    const {recipe} = this.state;
    const {source} = this.props;

    const payload = {
      QUANTITY: quantity,
    };

    try {
      await RecipeRequests.updateRecipe(recipe.RECIPE_ID, payload);
    } catch (e) {
      console.log(e);
    }

    await getStore().dispatch(updateIngredient(source.ID));
    this.modal.close();
  }

  render() {
    const {recipe} = this.state;
    const {source} = this.props;

    return (
      <Modal
        label="Attach Ingredient"
        formikOnClick={() => this.formikRef}
        buttonLabel={recipe ? "Save" : "Attach"}
        ref={(e) => (this.modal = e)}
        deleteLabel={recipe ? "Delete" : ""}
        deleteOnClick={() => this.deleteIngredient()}
      >
        <Formik
          onSubmit={(values) =>
            recipe ? this.saveIngredient(values) : this.attachIngredient(values)
          }
          enableReinitialize
          innerRef={(e) => (this.formikRef = e)}
          initialValues={{
            edge: recipe?.DESTINATION_ID,
            quantity: recipe?.QUANTITY || 1,
          }}
          validationSchema={Yup.object().shape({
            edge: Yup.number().integer().required(),
            quantity: Yup.number().required(),
          })}
        >
          {(formikOptions) => {
            const {handleSubmit, values, setFieldValue} = formikOptions;
            const {ingredient} = values;

            return (
              <form onSubmit={handleSubmit}>
                <IngredientDropdown
                  name="edge"
                  label="Ingredient"
                  options={formikOptions}
                  disabled={recipe}
                  onChangeSoft={({ingredient}) => {
                    setFieldValue("ingredient", ingredient);
                  }}
                />

                <div className="flex flex-row">
                  <FormInput
                    name="quantity"
                    label={`Quantity to produce one ${source.UNIT.NAME.toLowerCase()} of ${
                      source.NAME
                    }`}
                    options={formikOptions}
                    className="flex-[3]"
                  />

                  <div className="flex flex-row items-end mb-2 justify-center text-neutral-text text-sm font-bold mx-2">
                    {ingredient?.UNIT?.NAME || "units"}
                  </div>
                </div>
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

export default AttachIngredientModal;
