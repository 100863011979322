import moment from "moment-timezone";
import {CONFLICT_TYPE} from "../modals/scheduling/copy-week-schedule-modal";
import {MILLI_MINUTE} from "./constants";

export function nameTruncate(string) {
  if (string.includes(" ")) {
    const spaceIndex = string.indexOf(" ");
    return string.slice(0, spaceIndex + 2);
  }
  return string;
}

export function isShiftInUnavailabilityRange(
  shiftStart,
  shiftEnd,
  unavailStart,
  unavailEnd
) {
  if (unavailEnd > shiftStart && unavailEnd <= shiftEnd) {
    return true;
  }

  if (unavailStart >= shiftStart && unavailStart < shiftEnd) {
    return true;
  }

  if (unavailStart <= shiftStart && unavailEnd >= shiftEnd) {
    return true;
  }

  return false;
}

export function isShiftInTimeOffRange(
  shiftStart,
  shiftEnd,
  timeOffStart,
  timeOffEnd,
  timezone
) {
  timeOffStart = moment(timeOffStart).tz(timezone).startOf("day").valueOf();
  timeOffEnd = moment(timeOffEnd).tz(timezone).endOf("day").valueOf();

  //Because the start and end time on time off requests for partial days is ambiguous, we just do a loose check to see if coincides on the day
  return shiftStart >= timeOffStart && shiftEnd <= timeOffEnd;
}

export function isTimeOffConflict(time, shift, timezone) {
  return (
    time.EMPLOYEE_ID === shift.EMPLOYEE_ID &&
    time.STATUS === "APPROVED" &&
    isShiftInTimeOffRange(
      shift.DATE_START,
      shift.DATE_END,
      time.DATE_START,
      time.DATE_END,
      timezone
    )
  );
}

export function isUnavailabilityConflict(unavail, shift) {
  return (
    unavail.EMPLOYEE_ID === shift.EMPLOYEE_ID &&
    unavail.TYPE === 1 &&
    isShiftInUnavailabilityRange(
      shift.DATE_START,
      shift.DATE_END,
      unavail.DATE_START,
      unavail.DATE_END
    )
  );
}

export function getConflictString(row) {
  if (row.type === CONFLICT_TYPE.UNAVAILABILITY) {
    if (row.conflict.ALL_DAY === 1) {
      return "Unavailable All Day " + moment(row.conflict.DATE_START).format("M/D");
    } else {
      return (
        "Unavailable " +
        moment()
          .isoWeekday(row.conflict.ISO_DAY + 1)
          .format("dddd") +
        ", " +
        moment(row.conflict.DATE_START).format("h:mma") +
        " - " +
        moment(row.conflict.DATE_END).format("h:mma")
      );
    }
  }

  if (row.conflict.PARTIAL_DAYS === 1) {
    return (
      "Time Off " +
      moment(row.conflict.DATE_START).format("M/D h:mma") +
      " - " +
      moment(row.conflict.DATE_END).format("M/D h:mma")
    );
  } else {
    return (
      "Time Off " +
      moment(row.conflict.DATE_START).format("M/D") +
      " - " +
      moment(row.conflict.DATE_END).format("M/D")
    );
  }
}

export const SHIFT_HINT_TYPES = {
  ON_TIME: {ENABLED: false},
  LATE: {ENABLED: true, ICON: "user-clock", VALUE: "late"},
  NO_SHOW: {ENABLED: true, ICON: "user-times", VALUE: "no-show"},
};

export function getShiftLabel(
  shift,
  noShowThreshold = MILLI_MINUTE * 15,
  lateThreshold = MILLI_MINUTE * 5
) {
  if (
    shift.DATE_START + MILLI_MINUTE > Date.now() ||
    shift.EMPLOYEE_ID === null ||
    shift.DRAFT === 1
  ) {
    return SHIFT_HINT_TYPES.ON_TIME;
  }

  const shiftTimeCards = shift?.TIMESHEETS;

  if (shiftTimeCards && shiftTimeCards.length > 0) {
    const clockStart = shiftTimeCards[0].DATE_START;

    const minutesPassed = Math.max(0, (clockStart - shift.DATE_START) / MILLI_MINUTE);

    return minutesPassed > lateThreshold
      ? SHIFT_HINT_TYPES.LATE
      : SHIFT_HINT_TYPES.ON_TIME;
  }

  const minutesPassed = Math.max(0, (Date.now() - shift.DATE_START) / MILLI_MINUTE);

  return minutesPassed > noShowThreshold
    ? SHIFT_HINT_TYPES.NO_SHOW
    : minutesPassed > lateThreshold
    ? SHIFT_HINT_TYPES.LATE
    : SHIFT_HINT_TYPES.ON_TIME;
}

export function getTimeOffDescription(timeOffDay) {
  if (!timeOffDay) {
    return "";
  }

  if (timeOffDay.AMOUNT === 8) {
    return "All Day";
  }

  return (
    timeOffDay.AMOUNT +
    " Hrs" +
    (timeOffDay.DATE_START
      ? " starting " + moment(timeOffDay.DATE_START).format("H:mmA")
      : "")
  );
}
