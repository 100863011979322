import React, {Component} from "react";
import {
  FormInput,
  FormSelect,
  Modal,
} from "@frostbyte-technologies/frostbyte-tailwind";
import {Formik} from "formik";
import {
  decimalToDollars,
  toDollars,
} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import {request} from "../../../utils/request";
import Checkbox from "../../../components/form-elements/checkbox";
import LoadingSpinner from "../../../components/loading-spinner";

class PayrollAddEmployeesToBenefitModal extends Component {
  state = {
    benefitUniqueId: null,
    benefitEmployeeUniqueId: null,
    employees: [],
    initialValues: {
      active: 1,
      contributionType: "AMOUNT",
      employeeContributionAmount: 0,
      companyContributionAmount: 0,
      employeeContributionPercent: "0",
      companyContributionPercent: "0",
    },
  };

  openAdd = (benefitUniqueId = null) => {
    let initialValues = {
      active: 1,
      contributionType: "AMOUNT",
      employeeContributionAmount: 0,
      companyContributionAmount: 0,
      employeeContributionPercent: "0",
      companyContributionPercent: "0",
    };

    this.setState(
      {
        employees: [],
        benefitUniqueId,
        benefitEmployeeUniqueId: null,
        initialValues,
      },
      () => this.syncState()
    );

    this.slide.open();
  };

  openEdit = (employeeBenefit) => {
    let initialValues = {
      active: employeeBenefit.ACTIVE,
      contributionType: employeeBenefit.CONTRIBUTION_TYPE,
      employeeContributionAmount: toDollars(
        employeeBenefit.EMPLOYEE_CONTRIBUTION_AMOUNT
      ),
      companyContributionAmount: toDollars(
        employeeBenefit.COMPANY_CONTRIBUTION_AMOUNT
      ),
      employeeContributionPercent:
        employeeBenefit.EMPLOYEE_CONTRIBUTION_PERCENT,
      companyContributionPercent: employeeBenefit.COMPANY_CONTRIBUTION_PERCENT,
    };

    this.setState({
      benefitUniqueId: null,
      benefitEmployeeUniqueId: employeeBenefit.UNIQUE_ID,
      initialValues,
    });

    this.slide.open();
  };

  syncState = async () => {
    let {benefitUniqueId} = this.state;

    let employees = await request(
      `payroll/benefits/custom/${benefitUniqueId}/available-employees`,
      "GET"
    );

    this.setState({employees});
  };

  addEmployees = async ({
    active,
    contributionType,
    employeeContributionAmount,
    companyContributionAmount,
    employeeContributionPercent,
    companyContributionPercent,
    employees,
  }) => {
    let {benefitUniqueId} = this.state;

    let BENEFIT_FIELDS = this.convertBenefitFieldsForSubmission({
      active,
      contributionType,
      employeeContributionAmount,
      companyContributionAmount,
      employeeContributionPercent,
      companyContributionPercent,
    });

    let CHECK_EMPLOYEE_ID_ARRAY = Object.keys(employees).reduce(
      (arr, empId) => {
        if (employees[empId]) {
          arr.push(empId);
        }

        return arr;
      },
      []
    );

    await request(`payroll/benefits/custom/${benefitUniqueId}`, "POST", {
      BENEFIT_FIELDS,
      CHECK_EMPLOYEE_ID_ARRAY,
    });

    this.props.syncState();

    this.slide.close();
  };

  saveBenefit = async ({
    active,
    contributionType,
    employeeContributionAmount,
    companyContributionAmount,
    employeeContributionPercent,
    companyContributionPercent,
  }) => {
    let {benefitEmployeeUniqueId} = this.state;

    let BENEFIT_FIELDS = this.convertBenefitFieldsForSubmission({
      active,
      contributionType,
      employeeContributionAmount,
      companyContributionAmount,
      employeeContributionPercent,
      companyContributionPercent,
    });

    await request(
      `payroll/benefits/custom/employee/${benefitEmployeeUniqueId}`,
      "PATCH",
      BENEFIT_FIELDS
    );

    this.props.syncState();

    this.slide.close();
  };

  removeBenefitEmployee = async () => {
    let {benefitEmployeeUniqueId} = this.state;

    await request(
      `payroll/benefits/custom/employee/${benefitEmployeeUniqueId}`,
      "DELETE"
    );

    this.props.syncState();

    this.slide.close();
  };

  convertBenefitFieldsForSubmission(benefitFields) {
    if (benefitFields.contributionType === "AMOUNT") {
      return {
        ACTIVE: benefitFields.active,
        CONTRIBUTION_TYPE: benefitFields.contributionType,
        COMPANY_CONTRIBUTION_AMOUNT:
          decimalToDollars(benefitFields.companyContributionAmount) ?? 0,
        EMPLOYEE_CONTRIBUTION_AMOUNT:
          decimalToDollars(benefitFields.employeeContributionAmount) ?? 0,

        COMPANY_CONTRIBUTION_PERCENT: 0,
        EMPLOYEE_CONTRIBUTION_PERCENT: 0,
      };
    }

    return {
      ACTIVE: benefitFields.active,
      CONTRIBUTION_TYPE: benefitFields.contributionType,
      COMPANY_CONTRIBUTION_PERCENT:
        benefitFields.companyContributionPercent ?? 0,
      EMPLOYEE_CONTRIBUTION_PERCENT:
        benefitFields.employeeContributionPercent ?? 0,

      COMPANY_CONTRIBUTION_AMOUNT: 0,
      EMPLOYEE_CONTRIBUTION_AMOUNT: 0,
    };
  }

  render() {
    let {employees, benefitEmployeeUniqueId, initialValues} = this.state;

    return (
      <Modal
        ref={(e) => (this.slide = e)}
        label={
          benefitEmployeeUniqueId ? "Save Employee Benefit" : "Add Employees"
        }
        buttonLabel={benefitEmployeeUniqueId ? "Save" : "Add"}
        formikOnClick={() => this.formikRef}
        disableButtonLoading={true}
        deleteLabel={benefitEmployeeUniqueId ? "Remove" : ""}
        deleteOnClick={
          benefitEmployeeUniqueId ? this.removeBenefitEmployee : () => {}
        }
      >
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          onSubmit={
            benefitEmployeeUniqueId ? this.saveBenefit : this.addEmployees
          }
          innerRef={(e) => (this.formikRef = e)}
        >
          {(formikOptions, handleSubmit) => {
            let {contributionType} = formikOptions.values;

            return (
              <form onSubmit={handleSubmit}>
                <FormSelect
                  name={"active"}
                  label={"Status"}
                  data={[
                    {
                      label: "Active",
                      value: 1,
                    },
                    {
                      label: "Inactive",
                      value: 0,
                    },
                  ]}
                  options={formikOptions}
                />

                <FormSelect
                  name={"contributionType"}
                  label={"Contribution Type"}
                  data={[
                    {
                      label: "Amount",
                      value: "AMOUNT",
                    },
                    {
                      label: "Percentage",
                      value: "PERCENT",
                    },
                  ]}
                  options={formikOptions}
                />

                {contributionType === "AMOUNT" && (
                  <div>
                    <FormInput
                      name={"employeeContributionAmount"}
                      label={"Employee Contribution Amount (in $ per period)"}
                      options={formikOptions}
                    />

                    <FormInput
                      name={"companyContributionAmount"}
                      label={"Company Contribution Amount (in $ per period)"}
                      options={formikOptions}
                    />
                  </div>
                )}

                {contributionType === "PERCENT" && (
                  <div>
                    <FormInput
                      name={"employeeContributionPercent"}
                      label={
                        "Employee Contribution Percentage (as a % per period)"
                      }
                      options={formikOptions}
                    />

                    <FormInput
                      name={"companyContributionPercent"}
                      label={
                        "Company Contribution Percentage (as a % per period)"
                      }
                      options={formikOptions}
                    />
                  </div>
                )}

                {!benefitEmployeeUniqueId && employees.length === 0 && (
                  <div className={"mt-2"}>
                    <LoadingSpinner />
                  </div>
                )}

                {!benefitEmployeeUniqueId && (
                  <div className={"mt-5"}>
                    {employees.map(({CHECK_EMPLOYEE_ID, FULL_NAME}) => (
                      <Checkbox
                        name={`employees.${CHECK_EMPLOYEE_ID}`}
                        label={FULL_NAME}
                        formikOptions={formikOptions}
                      />
                    ))}
                  </div>
                )}
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

export default PayrollAddEmployeesToBenefitModal;
