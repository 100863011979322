import React, {Component} from "react";
import {FormBoolean, TwoColumnList} from "@frostbyte-technologies/frostbyte-tailwind";
import {setupReduxConnection} from "../../../redux";
import SchedulerTabModal from "../../../modals/shop/shop-settings/scheduler-tab-modal";
import {SHIFT_VIEWS_DATA} from "../../scheduling/scheduler";
import SchedulerHintModal from "../../../modals/shop/shop-settings/scheduler-hint-modal";

class SchedulerTab extends Component {
  render() {
    let {
      SCHEDULER_DEFAULT_VIEW,
      SCHEDULER_SHIFT_CONFIRMATION,
      SCHEDULER_DURATION_DISPLAY,
      SCHEDULER_FIRST_DAY,
      EMAIL_SCHEDULE,
      SCHEDULER_OPEN_SHIFT_APPROVAL,
      ONE_TIME_UNAVAILABILITY,
      SCHEDULER_HINTS,
      SCHEDULER_NO_SHOW_THRESHOLD = 15,
      SCHEDULER_LATE_THRESHOLD = 5,
    } = this.props.shop.settings;

    return (
      <div>
        <SchedulerTabModal ref={(e) => (this.schedulerModal = e)} />

        <SchedulerHintModal ref={(e) => (this.schedulerHintModal = e)} />

        <TwoColumnList
          label="Scheduler"
          description="Configure settings for your scheduler"
          data={[
            {
              label: "Default scheduler to Role View",
              value: SHIFT_VIEWS_DATA.find(
                (data) => data.value === SCHEDULER_DEFAULT_VIEW
              ).label,
              tooltip:
                "This allows you to set the default view of the scheduler. You can still change between these views from the scheduler with the dropdown selection.",
            },
            {
              label: "Enable Shift Confirmation",
              value: SCHEDULER_SHIFT_CONFIRMATION,
              type: "bool",
              tooltip:
                "When enabled, employees will receive a confirmation prompt when new shifts are published and will need to acknowledge these shifts. Acknowledged shifts will appear on the scheduler with a checkmark.",
            },
            {
              label: "First Day",
              value: FIRST_DAY_MAP[SCHEDULER_FIRST_DAY],
              tooltip:
                "The day that appears as the first day of the week on the scheduler.",
            },
            {
              label: "Duration Display on Shifts",
              value: SCHEDULER_DURATION_DISPLAY,
              type: "bool",
              tooltip:
                "When enabled, shifts on the scheduler will display duration in hours",
            },
            {
              value: SCHEDULER_OPEN_SHIFT_APPROVAL,
              label: "Enable Approval for Open Shifts",
              type: "bool",
              tooltip:
                "When enabled, picking up open shifts will require manager approval",
            },
            {
              label: "Weekly Schedule Emails",
              value: EMAIL_SCHEDULE,
              type: "bool",
              tooltip:
                "When enabled, employees will be sent a recurring weekly schedule email with upcoming shifts and open shifts",
            },
            {
              label: "One Time Unavailability",
              value: ONE_TIME_UNAVAILABILITY,
              type: "bool",
              tooltip:
                "When enabled, employees will be able to create unavailability and shift preferences for a specific date",
            },
          ]}
          buttons={[
            {
              label: "Edit",
              onClick: () => this.schedulerModal.open(),
            },
          ]}
        />

        <TwoColumnList
          label="Scheduler Hints"
          description="Configure schedule hints for late clock ins and no shows"
          data={[
            {
              label: "Enable Schedule Hints",
              value: SCHEDULER_HINTS,
              type: "bool",
              tooltip:
                "When enabled, scheduler shifts will display hints for late clock ins and no shows. Hover over the icon in the bottom right corner of the scheduler event to see the status.",
            },
            {
              label: "Late Threshold",
              value: `${SCHEDULER_LATE_THRESHOLD} Minutes`,
              tooltip:
                "Threshold after which a shift will be considered late. This setting is also used for clock in notifications.",
            },
            {
              label: "No Show Threshold",
              value: `${SCHEDULER_NO_SHOW_THRESHOLD} Minutes`,
              tooltip: "Threshold after which a shift will be considered a no show",
            },
          ]}
          buttons={[
            {
              label: "Edit",
              onClick: () => this.schedulerHintModal.open(),
            },
          ]}
        />
      </div>
    );
  }
}

const FIRST_DAY_MAP = {
  0: "Sunday",
  1: "Monday",
  2: "Tuesday",
  3: "Wednesday",
  4: "Thursday",
  5: "Friday",
  6: "Saturday",
};

export default setupReduxConnection(["shop"])(SchedulerTab);
