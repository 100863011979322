import {Box, Col, Row} from "../shared";

const HeaderText = ({text}) => (<div className="flex-1 pl-4 text-left text-gray-500 py-2 text-sm font-medium">{text}</div>)

const ConversionHeader = () => (
    <Row className="w-full justify-between border-b bg-gray-50 border-gray-200">
        <HeaderText text={"Linked Unit"}/>
        <HeaderText text={"Abbreviation"}/>
        <HeaderText text={"Linked Unit Quantity"}/>
    </Row>
)

const Line = ({text, isLast = false}) => (
    <div className={`flex-1 pl-4 text-left py-2 text-sm ${!isLast ? "border-r border-gray-300" : ""}`}>{text}</div>)

const ConversionLine = ({conversion}) => (
    <Row className="w-full border-b">
        <Line text={conversion.UNIT.NAME}/>
        <Line text={conversion.UNIT.ABBREVIATION}/>
        <Line isLast={true} text={conversion.RATE}/>
    </Row>
)

export const ConversionsTable = ({unit}) => {
    return (
        <Box className="bg-white">
            <Col className="m-2 w-full border border-gray-200 rounded">
                <ConversionHeader/>
                {unit.CONVERSIONS.map((conversion) => (<ConversionLine conversion={conversion}/>))}
            </Col>
        </Box>
    )
}
