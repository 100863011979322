import React, {Component} from "react";
import {Download} from "react-feather";
import {request} from "../../../utils/request";
import LoadingSpinner from "../../../components/loading-spinner";
import {Card, Table, PageHeadings} from "@frostbyte-technologies/frostbyte-tailwind";
import b64toBlob from "b64-to-blob";
import PayrollWhitelistWrapper from "../../../features/payroll/payroll-whitelist-wrapper";
import {withRouter} from "../../../utils/navigation";

class PayrollCompanyTaxDocumentsPage extends Component {
  state = {isLoading: true, docData: null};

  async componentDidMount() {
    let docData = await request("payroll/company-tax-documents");

    this.setState({isLoading: false, docData});
  }

  downloadTaxDocument = async (checkDocumentId) => {
    let pdf = await request("payroll/company-tax-documents/" + checkDocumentId);

    let blob = b64toBlob(pdf, "application/pdf");
    let blobUrl = URL.createObjectURL(blob);
    window.open(blobUrl, "_blank");
  };

  render() {
    let {isLoading, docData} = this.state;

    const COLUMNS = [
      {
        label: "Name",
        value: "label",
      },
      {
        label: "Jurisdiction",
        value: "jurisdiction",
      },
      {
        label: "Year",
        value: "year",
      },
      {
        label: "Filed On",
        value: "filed_on",
      },
      {
        label: "Download",
        value: "id",
        format: (value, row) => {
          return (
            <div
              style={{cursor: "pointer"}}
              onClick={() => this.downloadTaxDocument(row.id)}
            >
              <Download />
            </div>
          );
        },
      },
    ];

    return (
      <PayrollWhitelistWrapper router={this.props.router}>
        <PageHeadings label={"Company Tax Documents"} />
        <Card>
          {isLoading && (
            <div className={"py-10"}>
              <LoadingSpinner />
            </div>
          )}

          {!isLoading && (
            <div>
              <Table pagination columns={COLUMNS} data={docData} />
            </div>
          )}
        </Card>
      </PayrollWhitelistWrapper>
    );
  }
}

export default withRouter(PayrollCompanyTaxDocumentsPage);
