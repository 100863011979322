export const GUSTO_HARD_CODED_MAPS = {
  "Employee Name": (row) => `${row[1]} ${row[0]}`,
};

export const DO_NOT_MAP_KEYS = [
  "Employee Name",
  "Period Start",
  "Period End",
  "Payday",
];

export function iterateOverGusto(exportData, pastProvider, func) {
  if (pastProvider === "gusto-summary") {
    return _iterateOverGustoSummary(exportData, func);
  }

  return _iterateOverGustoPaystubs(exportData, func);
}

function _iterateOverGustoPaystubs(exportData, func) {
  let toReturn = [];

  let insidePayroll = false;

  for (let row = 0; row < exportData.length; row++) {
    if (exportData[row][0] === "Pay day") {
      insidePayroll = true;
      row++;
    } else if (["Payroll Totals", ""].includes(exportData[row][0])) {
      insidePayroll = false;
    } else if (insidePayroll) {
      toReturn.push(func(exportData[row]));
    }
  }

  return toReturn;
}

function _iterateOverGustoSummary(exportData, func) {
  let toReturn = [];

  for (let row = 0; row < exportData.length; row++) {
    if (
      exportData[row][3] &&
      exportData[row][3].length > 0 &&
      exportData[row][3] !== "Work Address"
    ) {
      toReturn.push(func(exportData[row]));
    }
  }

  return toReturn;
}

export function getEmployeeNames(
  {exportData, paycheckIncrementer, firstPaycheckStartRow},
  pastProvider
) {
  if (pastProvider.startsWith("gusto")) {
    return getGustoEmployeeNames(exportData, pastProvider);
  }

  if (pastProvider === "square") {
    return getSquareEmployeeNames({
      exportData,
      paycheckIncrementer,
      firstPaycheckStartRow,
    });
  }

  if (pastProvider === "toast") {
    return getToastEmployeeNames(exportData);
  }
}

export function getSquareEmployeeNames({
  exportData,
  paycheckIncrementer,
  firstPaycheckStartRow,
}) {
  let employeeNames = {};

  for (
    let row = firstPaycheckStartRow;
    row < exportData.length;
    row += paycheckIncrementer
  ) {
    employeeNames[exportData[row][0]] = true;
  }

  return Object.keys(employeeNames);
}

export function getToastEmployeeNames(exportData) {
  const employees = [];

  for (let row = 1; row < exportData.length; row++) {
    if (exportData[row][0] !== "name") {
      employees.push(`${exportData[row][0]}`);
    }
  }

  return Array.from(new Set(employees));
}

export function getGustoEmployeeNames(exportData, pastProvider) {
  return iterateOverGusto(
    exportData,
    pastProvider,
    (row) => `${row[0]}, ${row[1]}`
  );
}

export function getWorkplaceAddresses(
  {exportData, firstPaycheckStartRow, paycheckIncrementer},
  workplaceMapping,
  pastProvider
) {
  if (pastProvider === "square") {
    return getSquareWorkplaceAddresses(
      {exportData, firstPaycheckStartRow, paycheckIncrementer},
      workplaceMapping
    );
  }

  if (pastProvider.startsWith("gusto")) {
    return getGustoWorkplaceAddresses(exportData, pastProvider);
  }
}

function getGustoWorkplaceAddresses(exportData, pastProvider) {
  return iterateOverGusto(
    exportData,
    pastProvider,
    (row) => row[3].split(",")[0]
  );
}

function getSquareWorkplaceAddresses(
  {exportData, firstPaycheckStartRow, paycheckIncrementer},
  workplaceMapping
) {
  let workplaceAddresses = {};

  for (
    let row = firstPaycheckStartRow;
    row < exportData.length;
    row += paycheckIncrementer
  ) {
    let string =
      exportData[row + workplaceMapping.rowOffset][workplaceMapping.colOffset];

    let formattedString = string.split(":")[1].substring(1).split(",")[0];

    workplaceAddresses[formattedString] = true;
  }

  return Object.keys(workplaceAddresses);
}

export const PLATFORM_IGNORE_FIELDS = {
  gusto: [
    "Employee Name",
    "Period Start",
    "Period End",
    "Payday",
    "Workplace ID",
  ],
};

export const IGNORE_FIELDS = [
  "Supplemental Tax Calc Method",
  "Gross Piece",
  "Piece Hours",
  "Gross Piece adjustment",
  "Piece adjustment Hours",
  "Gross Non-productive",
  "Non-productive Hours",
  "Gross Rest and recovery",
  "Rest and recovery Hours",
  "Gross Weighted overtime",
  "Weighted overtime Hours",
  "Gross Weighted double overtime",
  "Weighted double overtime Hours",
  "Gross Covid sick individual",
  "Covid sick individual Hours",
  "Gross Covid sick other",
  "Covid sick other Hours",
  "Gross Covid family leave",
  "Covid family leave Hours",
  "Gross COVID-19 Supplemental Paid Sick Leave",
  "COVID-19 Supplemental Paid Sick Leave Hours",
  "Gross Severance",
  "Gross Commission",
];
