import {decimalToDollars, toDollars} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import {PageHeadings} from "@frostbyte-technologies/frostbyte-tailwind";
import React, {Component} from "react";
import LoadingSpinner from "../../../../components/loading-spinner";
import RecipeBuilder from "../../../../features/operations/supply-chain/recipe-builder/recipe-builder";
import IngredientCategoryModal from "../../../../modals/operations/supply-chain/recipes/ingredient-category-modal";
import IngredientModal from "../../../../modals/operations/supply-chain/recipes/ingredient-modal";
import QuickStockIngredientModal from "../../../../modals/operations/supply-chain/recipes/quick-stock-ingredient-modal";
import MigrateMenuModal
    from "../../../../modals/operations/supply-chain/recipes/setup-product-ingredients/migrate-menu/migrate-menu-modal";
import {getStore, setupReduxConnection} from "../../../../redux";
import {updateIngredients} from "../../../../redux/supply";
import IngredientsTable from "../../../../tables/operations/recipes/supply-chain-inventory-table";
import {toDecimalPlaces} from "../../../../utils/decimal-helper";
import {withRouter} from "../../../../utils/navigation";
import {
    IngredientRequests,
    SupplyChainUnitRequests
} from "../../../../utils/request-helpers/supply-chain/supply-chain-requests";

const VIEW_TYPES = {TABLE: "TABLE", TREE: "TREE"};

class SupplyChainInventoryPage extends Component {
    state = {view: VIEW_TYPES.TABLE, ingredientUpdates: {}};

    componentWillUnmount() {
        clearInterval(this.intervalToCancel);
    }

    includeModals() {
        return (
            <>
                <IngredientModal ref={(e) => (this.ingredientModal = e)}/>
                <IngredientCategoryModal ref={(e) => (this.ingredientCategoryModal = e)}/>
                <QuickStockIngredientModal ref={(e) => (this.quickStockIngredientModal = e)}/>
            </>
        );
    }

    fetchCreateIngredientButton() {
        return {
            label: "Create Item or Ingredient",
            onClick: () => this.ingredientModal.open(),
        };
    }

    fetchSaveIngredientsButton() {
        return {
            label: "Update Ingredient Costs",
            onClick: () => {
                const {ingredientUpdates} = this.state;
                const requests = []
                for (const entry of Object.entries(ingredientUpdates)) {
                    const [id, ingredient] = entry;
                    const cost = decimalToDollars(ingredient.COST);
                    requests.push(IngredientRequests.updateIngredient(id, {COST_PER_UNIT: cost}));
                }
                Promise.all(requests).then(() => {
                    getStore().dispatch(updateIngredients())
                    this.setState({ingredientUpdates: {}});
                });
            },
        };
    }

    fetchCreateIngredientGroupButton() {
        return {
            label: "Create Ingredient Group",
            onClick: () => this.ingredientCategoryModal.open(),
        };
    }

    fetchMoreButtons() {
        return {
            type: "dropdown",
            label: "More",
            sections: [
                {
                    items: [
                        this.fetchCreateIngredientButton(),
                        this.fetchCreateIngredientGroupButton(),
                    ],
                },
            ],
        };
    }

    fetchTableViewButton() {
        return {
            label: "Table",
            onClick: () => this.setState({view: VIEW_TYPES.TABLE}),
        };
    }

    fetchTreeViewButton() {
        return {
            label: "Tree",
            onClick: () => this.setState({view: VIEW_TYPES.TREE}),
        };
    }

    fetchPageHeadingButtons() {
        return [
            this.fetchSaveIngredientsButton(),
            this.fetchCreateIngredientButton(),
            this.fetchMoreButtons(),
        ];
    }

    renderPageHeadings() {
        return (
            <PageHeadings
                label="Items and Ingredients"
                description="Create and view items and ingredients."
                buttons={this.fetchPageHeadingButtons()}
            />
        );
    }

    fetchEditButton() {
        return {
            label: "Edit",
            onClick: (_ingredient) => this.ingredientModal.open(_ingredient),
        };
    }

    fetchViewButton() {
        return {
            label: "View",
            onClick: (row) =>
                this.props.router.navigate("/recipes/ingredients/" + row.UNIQUE_ID),
        };
    }

    fetchQuickStockButton() {
        return {
            label: "Quick Stock",
            onClick: (ingredient) => {
                return this.quickStockIngredientModal.open(ingredient);
            },
        };
    }

    fetchIngredientsTableActionButtons() {
        return [this.fetchQuickStockButton(), this.fetchEditButton(), this.fetchViewButton()];
    }

    renderTableView() {
        const {ingredients} = this.props.supply;

        for (const ingredient of ingredients) {
            ingredient.COST = ingredient.COST ?? toDollars(ingredient.COST_PER_UNIT);
        }
        const updateState = (newState) => {
            const {ingredientUpdates} = this.state;
            this.setState({ingredientUpdates: {...ingredientUpdates, ...newState}});
        };

        return (
            <IngredientsTable
                data={ingredients}
                updateState={updateState}
                actionButtons={this.fetchIngredientsTableActionButtons()}
            />
        );
    }

    renderTreeView() {
        const {ingredients} = this.props.supply;
        return <RecipeBuilder ingredients={ingredients}/>;
    }

    renderView() {
        const {view} = this.state;

        if (view === VIEW_TYPES.TABLE) {
            return this.renderTableView();
        }

        return this.renderTreeView();
    }

    render() {
        const {ingredients, categories} = this.props.supply;

        if (!ingredients || !categories) {
            return <LoadingSpinner/>;
        }

        return (
            <>
                {this.includeModals()}
                {this.renderPageHeadings()}
                {this.renderView()}
            </>
        );
    }
}

export default setupReduxConnection(["shop", "supply"])(withRouter(SupplyChainInventoryPage));
