export const INVOICE_CONTACT_PAYMENT_METHODS = {
  BANK_ACCOUNT: "us_bank_account",
  CARD: "card",
};

export function getPaymentMethodVerbiageByType(method) {
  if (method.type === INVOICE_CONTACT_PAYMENT_METHODS.CARD) {
    return getCardVerbiage(method.card);
  }

  else if (method.type === INVOICE_CONTACT_PAYMENT_METHODS.BANK_ACCOUNT) {
    return getBankAccountVerbiage(method.us_bank_account);
  }

  return "Other Payment Method";
}

export function getBankAccountVerbiage(account) {
  return account?.account_type?.capitalize() + " Account Ending With " + account?.last4;
}

export function getCardVerbiage(card) {
  return card?.brand?.capitalize() + " - Ending With " + card?.last4;
}
