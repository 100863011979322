import React, {Component} from "react";
import {FormInput, Modal} from "@frostbyte-technologies/frostbyte-tailwind";
import {request} from "../../../utils/request";
import {Formik} from "formik";
import * as Yup from "yup";
import {withRouter} from "../../../utils/navigation";
import {setupReduxConnection} from "../../../redux";

class OffboardModal extends Component {
  state = {account: null};

  open(row) {
    this.setState({account: row}, () => {
      console.log(row);
      this.formikRef && this.formikRef.resetForm();
      this.modal.open();
    });
  }

  offboardEmployee = async (name) => {
    const {account} = this.state;
    if (name !== account.FULL_NAME) {
    }
    await request(`onboarding/offboard`, "POST", {
      ID: account.ID,
    });

    this.props.loadRanks();
    this.modal.close();
  };

  render() {
    const {account} = this.state;

    return (
      <Modal
        buttonLabel={"Offboard"}
        label={"Offboard Employee"}
        formikOnClick={() => this.formikRef}
        ref={(e) => (this.modal = e)}
      >
        <Formik
          onSubmit={this.offboardEmployee}
          innerRef={(e) => (this.formikRef = e)}
          enableReinitialize
          validationSchema={Yup.object({
            name: Yup.string()
              .required("Name is required")
              .oneOf([account?.FULL_NAME], "Name must match the exact required value."),
          })}
          initialValues={{
            name: "",
          }}
        >
          {(formikOptions) => {
            const {handleSubmit} = formikOptions;

            return (
              <form onSubmit={handleSubmit}>
                <FormInput
                  label={"Confirm Employee Name"}
                  placeholder={this.state.account.FULL_NAME || " "}
                  name={"name"}
                  options={formikOptions}
                />
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

export default setupReduxConnection(["admin"])(withRouter(OffboardModal));
