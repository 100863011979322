import React, {useEffect, useMemo, useRef, useState} from "react";
import {ModifierRequests} from "../../../utils/request-helpers/menu/menu-requests";
import {FormInput, FormSelect} from "@frostbyte-technologies/frostbyte-tailwind";
import {CUSTOMIZATION_TYPES} from "@frostbyte-technologies/frostbyte-tickets/dist/constants/product-constants";
import IngredientDropdown from "../../../dropdowns/operations/recipes/ingredient-dropdown";
import IngredientModal from "../../../modals/operations/supply-chain/recipes/ingredient-modal";

export function RecipeForm({formikOptions, entity}) {
  const [presets, setPresets] = useState([]);
  const [preset, setPreset] = useState(null);
  const [ingredient, setIngredient] = useState(null);
  const ingredientModalRef = useRef(null);
  const {handleSubmit} = formikOptions;

  useEffect(() => {
    ModifierRequests.fetchPresets().then((presets) => {
      return setPresets(presets);
    });
  }, []);

  function filterPresets() {
    return presets
      .filter((preset) => preset.TYPE === CUSTOMIZATION_TYPES.VARIANT)
      .map((preset) => convertToSelectData(preset));
  }

  const filteredPresets = useMemo(() => filterPresets(), [presets]);

  const options = useMemo(() => {
    return preset ? preset.OPTIONS.map((option) => convertToSelectData(option)) : [];
  }, [preset]);

  function convertToSelectData(object) {
    return {value: object.ID, label: selectDataLabel(object), object};
  }

  function selectDataLabel(object) {
    return object?.INTERNAL_NAME ? object.NAME + " - " + object.INTERNAL_NAME : object.NAME;
  }

  function includeModals() {
    return (
      <>
        <IngredientModal ref={(e) => (ingredientModalRef.current = e)} />
      </>
    );
  }

  function renderVariantSelect() {
    return (
      <FormSelect
        data={filteredPresets}
        options={formikOptions}
        onChangeSoft={({object}) => setPreset(object)}
        label="Sizing Variant"
        hint="Optional"
        name="variant"
      />
    );
  }

  function renderOptionSelect() {
    return (
      <FormSelect data={options} label="Option" name="option" options={formikOptions} />
    );
  }

  async function createIngredient(name) {
    const {setFieldValue} = formikOptions;
    const ingredient = await ingredientModalRef.current.open({NAME: name});
    setFieldValue("ingredient", ingredient.ID);
  }

  function renderIngredientDropdown() {
    return (
      <IngredientDropdown
        label="Ingredient"
        name="ingredient"
        options={formikOptions}
        onChangeSoft={({ingredient}) => setIngredient(ingredient)}
        createLabel="Create New"
        onCreate={(name) => createIngredient(name)}
      />
    );
  }

  function renderQuantityInput() {
    return (
      <FormInput
        label={`Quantity ${ingredient?.UNIT_NAME ? `(${ingredient.UNIT_NAME})` : ""}`}
        name="quantity"
        options={formikOptions}
      />
    );
  }

  function isOptionVariant(option) {
    return option && option.TYPE !== CUSTOMIZATION_TYPES.VARIANT;
  }

  function renderOptionConfiguration() {
    return (
      <div>
        {isOptionVariant(entity?.option) && renderVariantSelect()}
        {!!preset && renderOptionSelect()}
      </div>
    );
  }

  return (
    <form onSubmit={handleSubmit}>
      {includeModals()}
      {!!entity?.OPTION_ID && renderOptionConfiguration()}
      {renderIngredientDropdown()}
      {renderQuantityInput()}
    </form>
  );
}
