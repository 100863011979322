import React, {Component} from "react";
import {
  FormInput,
  FormSelect,
  FormBoolean,
  Modal,
} from "@frostbyte-technologies/frostbyte-tailwind";
import {Formik} from "formik";
import {request} from "../../../utils/request";
import * as Yup from "yup";
import FormDateTimeSelect from "../../../components/form-date-time-select";

class SubscriptionListDetailsModal extends Component {
  state = {list: null};

  open(list = null) {
    this.setState({list});
    this.modal.open();
  }

  convertToDate(prev, to) {
    return (
      (!prev ||
        SEGMENT_TYPES[prev]?.UNIT === SEGMENT_UNITS.NUMBER ||
        SEGMENT_TYPES[prev]?.UNIT === SEGMENT_UNITS.DOLLAR) &&
      SEGMENT_TYPES[to]?.UNIT === SEGMENT_UNITS.DATE
    );
  }

  convertFromDate(prev, to) {
    return (
      (SEGMENT_TYPES[to]?.UNIT === SEGMENT_UNITS.NUMBER ||
        SEGMENT_TYPES[to]?.UNIT === SEGMENT_UNITS.DOLLAR) &&
      SEGMENT_TYPES[prev]?.UNIT === SEGMENT_UNITS.DATE
    );
  }

  async saveList({name, isSegment, emailOnly, type, operation, value}) {
    const {list} = this.state;

    let segmentPayload = null;

    if (isSegment === "1") {
      segmentPayload = {
        TYPE: type,
        OPERATION: operation,
        VALUE: value,
      };
    }

    if (list) {
      await request("text/marketing/list/" + list.ID, "PATCH", {
        NAME: name,
        SEGMENT: segmentPayload,
        EMAIL_ONLY: emailOnly === "EMAIL",
      });
    } else {
      await request("text/marketing/list", "POST", {
        NAME: name,
        SEGMENT: segmentPayload,
        EMAIL_ONLY: emailOnly === "EMAIL",
      });
    }

    this.props.onSubmit && this.props.onSubmit();
    this.modal.close();
  }

  renderSegmentValue(type, formikOptions) {
    if (!type || type === "") {
      return <div></div>;
    }

    const unitType = SEGMENT_TYPES[type].UNIT;

    if (!unitType) {
      return <div></div>;
    }

    if (unitType === "date") {
      return (
        <FormDateTimeSelect
          label={"Select Value"}
          name="value"
          options={formikOptions}
          hideTime={true}
          flex
        />
      );
    }

    return (
      <FormInput
        label={"Enter Value For Comparison"}
        options={formikOptions}
        name="value"
        tooltip={"For spend criteria, please enter a value in dollars"}
        flex
      />
    );
  }

  render() {
    const {list} = this.state;

    return (
      <Modal
        buttonLabel={list ? "Save" : "Create"}
        label={list ? "Update Subscriber List" : "Create Subscriber List"}
        ref={(e) => (this.modal = e)}
        formikOnClick={() => this.formikRef}
      >
        <Formik
          onSubmit={this.saveList.bind(this)}
          innerRef={(e) => (this.formikRef = e)}
          validationSchema={Yup.object().shape({
            name: Yup.string().nullable().required(),
          })}
          initialValues={{
            name: list?.NAME ? list.NAME : null,
            isSegment: list?.SEGMENT ? "1" : "0",
            emailOnly: list?.EMAIL_ONLY ? "EMAIL" : "TEXT",
            type: list?.SEGMENT?.TYPE,
            operation: list?.SEGMENT?.OPERATION,
            value: list ? parseInt(list.SEGMENT?.VALUE) : "",
          }}
        >
          {(formikOptions) => {
            const {handleSubmit, values, setFieldValue} = formikOptions;

            return (
              <form onSubmit={handleSubmit}>
                <FormInput
                  name="name"
                  label="Name"
                  tooltip={{
                    data: "A name to describe which patrons are on this list. The patrons will not see this.",
                  }}
                  options={formikOptions}
                  placeholder={"Frequent Customers"}
                />

                <FormSelect
                  name={"emailOnly"}
                  label={"Use Case"}
                  tooltip={{
                    data: "Determines which opted in customer base to use. Some patrons have indicated a desire to opt into text but not email and vice versa.",
                  }}
                  data={[
                    {value: "EMAIL", label: "Email"},
                    {value: "TEXT", label: "Text"},
                  ]}
                  options={formikOptions}
                />

                <FormBoolean
                  name={"isSegment"}
                  label={"Enable Customer Segments"}
                  tooltip={{
                    data: "If enabled, the patrons on this list will be calculated based on the rule you set (e.g. date last seen) ",
                  }}
                  options={formikOptions}
                />

                {values.isSegment === "1" && (
                  <>
                    <FormSelect
                      label="Segment Type"
                      options={formikOptions}
                      name="type"
                      data={Object.keys(SEGMENT_TYPES).map((_key) => ({
                        value: _key,
                        label: SEGMENT_TYPES[_key].LABEL,
                      }))}
                      tooltip={"Choose the segmentation criteria"}
                      onChangeSoft={(type) => {
                        if (this.convertToDate(values.type, type.value)) {
                          setFieldValue("value", Date.now());
                        }

                        if (this.convertFromDate(values.type, type.value)) {
                          setFieldValue("value", 0);
                        }
                      }}
                      flex
                    />

                    <FormSelect
                      label="Operation"
                      options={formikOptions}
                      name="operation"
                      data={OPERATION_TYPES}
                      flex
                    />

                    {this.renderSegmentValue(values.type, formikOptions)}
                  </>
                )}
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

export const OPERATION_TYPES = [
  {label: "greater than / later than", value: ">"},
  {
    label: "less than / earlier than",
    value: "<",
  },
  {label: "equal to", value: "="},
  {
    label: "at least",
    value: ">=",
  },
  {label: "at most", value: "<="},
  {label: "not equal to", value: "!="},
];

export const SEGMENT_UNITS = {
  NUMBER: "number",
  DOLLAR: "dollar",
  DATE: "date",
};

export const SEGMENT_TYPES = {
  AGE: {LABEL: "Age", UNIT: "number"},
  FREQUENCY: {LABEL: "Number of visits ", UNIT: "number"},
  SPEND: {LABEL: "Dollar spend", UNIT: "dollar"},
  FIRST_SEEN: {LABEL: "First seen", UNIT: "date"},
  LAST_SEEN: {LABEL: "Last seen", UNIT: "date"},
  POINTS: {LABEL: "Points", UNIT: "number"},
  LIFETIME_POINTS: {LABEL: "Lifetime points", UNIT: "number"},
};

export default SubscriptionListDetailsModal;
