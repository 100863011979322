import ComboBox from "../../components/combobox";
import React, {Component} from "react";
import {setupReduxConnection} from "../../redux";
import {withRouter} from "../../utils/navigation";

class VendorDropdown extends Component {
  render() {
    const {vendors} = this.props.accounting;

    return (
      <ComboBox
        label="Vendor"
        {...this.props}
        data={vendors.map(({ID, NAME}) => ({
          label: NAME,
          id: ID,
        }))}
      />
    );
  }
}

export default setupReduxConnection(["accounting"])(VendorDropdown);
