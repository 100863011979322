import React, {Component} from "react";
import {
  Card,
  PageHeadings,
  Table,
} from "@frostbyte-technologies/frostbyte-tailwind";
import {Download} from "react-feather";
import b64toBlob from "b64-to-blob";
import {withRouter} from "../../../utils/navigation";
import {request} from "../../../utils/request";

class AccountPaystubsPage extends Component {
  state = {paystubs: null, link: ""};

  async componentDidMount() {
    const paystubs = await request("hub/payroll/paystubs");

    this.setState({paystubs});
  }

  openPaystub = async (payrollID) => {
    const pdf = await request(`hub/payroll/paystubs/${[payrollID]}`);

    let blob = b64toBlob(pdf, "application/pdf");
    let blobUrl = URL.createObjectURL(blob);

    await this.setState({link: blobUrl}, () => this.linkRef.click());
  };

  render() {
    const {paystubs, link} = this.state;

    let columns = [
      {
        label: "Company",
        value: "COMPANY_NAME",
        format: (value, row) => row.company.legal_name,
      },
      {
        label: "Payday",
        value: "payday",
      },
      {
        label: "Period Start",
        value: "period_start",
      },
      {
        label: "Period End",
        value: "period_end",
      },
      {
        name: "Open",
        value: "id",
        format: (id, row) => {
          return (
            <div
              style={{cursor: "pointer"}}
              onClick={() => this.openPaystub(row.payroll)}
            >
              <Download className={"h-4 w-4 text-indigo-500"} />
            </div>
          );
        },
      },
    ];

    return (
      <div className="p-6">
        <a ref={(e) => (this.linkRef = e)} href={link} />

        <PageHeadings
          label="Paystubs"
          description={"View all of your Dripos paystubs"}
        />

        <Card label="Your Paystubs">
          <Table columns={columns} data={paystubs} limit={10} />
        </Card>
      </div>
    );
  }
}

export default withRouter(AccountPaystubsPage);
