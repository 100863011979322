import {upsert} from "../utils/util";
import {
  IngredientCategoryRequests,
  IngredientCountRequests,
  IngredientRequests,
  UnitRequests,
  VendorRequests, WasteRequests,
} from "../utils/request-helpers/supply-chain/supply-chain-requests";

const defaultState = {
  recipes: [],
  ingredients: [],
  vendors: [],
  units: [],
  categories: [],
  counts: [],
  purchaseOrders: [],
};

const UPDATE_SUPPLY_UNITS = "UPDATE_SUPPLY_UNITS";
const UPDATE_SUPPLY_VENDORS = "UPDATE_SUPPLY_VENDORS";
const UPDATE_SUPPLY_INGREDIENTS = "UPDATE_SUPPLY_INGREDIENTS";
const UPDATE_SUPPLY_CATEGORIES = "UPDATE_SUPPLY_CATEGORIES";
const UPDATE_SUPPLY_COUNTS = "UPDATE_SUPPLY_COUNTS";
const UPDATE_SUPPLY_WASTE_TYPES = "UPDATE_SUPPLY_WASTE_TYPES";

export function setIngredients(payload) {
  return {type: UPDATE_SUPPLY_INGREDIENTS, payload};
}

export function setVendors(payload) {
  return {type: UPDATE_SUPPLY_VENDORS, payload};
}

export function setUnits(payload) {
  return {type: UPDATE_SUPPLY_UNITS, payload};
}

export function setIngredientCategories(payload) {
  return {type: UPDATE_SUPPLY_CATEGORIES, payload};
}

export function setCounts(payload) {
  return {type: UPDATE_SUPPLY_COUNTS, payload};
}

export function setWasteTypes(payload) {
  return {type: UPDATE_SUPPLY_WASTE_TYPES, payload}
}

export function loadSupply() {
  return async (dispatch) => {
    dispatch(updateIngredients());
    dispatch(updateUnits());
    dispatch(updateVendors());
    dispatch(updateCounts());
    dispatch(updateIngredientCategories());
    dispatch(updateWasteTypes());
  };
}

export function updateIngredients() {
  return async (dispatch) => {
    const ingredients = await IngredientRequests.fetchIngredients();
    dispatch(setIngredients(ingredients));
  };
}

export function updateIngredient(id) {
  return async (dispatch, getState) => {
    const serverIngredient = await IngredientRequests.fetchIngredient(id);
    dispatch(upsertIngredient(serverIngredient));
  };
}

export function upsertIngredient(ingredient) {
  return (dispatch, getState) => {
    const {ingredients} = getState().supply;
    dispatch(setIngredients(upsert(ingredients, ingredient)));
  };
}

export function removeIngredient(id) {
  return (dispatch, getState) => {
    const {ingredients} = getState().supply;
    dispatch(setIngredients(ingredients.filter((ingredient) => ingredient.ID !== id)));
  };
}

export function updateUnits() {
  return async (dispatch) => {
    const units = await UnitRequests.fetchUnits();
    dispatch(setUnits(units));
  };
}

export function updateUnit(id) {
  return async (dispatch, getState) => {
    const serverUnit = await UnitRequests.fetchUnit(id);
    dispatch(upsertUnit(serverUnit));
  };
}

export function upsertUnit(unit) {
  return (dispatch, getState) => {
    const {units} = getState().supply;
    dispatch(setUnits(upsert(units, unit)));
  };
}

export function removeUnit(id) {
  return (dispatch, getState) => {
    const {units} = getState().supply;
    dispatch(setUnits(units.filter((unit) => unit.ID !== id)));
  };
}

export function updateVendors() {
  return async (dispatch) => {
    const vendors = await VendorRequests.fetchVendors();
    for(const vendor of vendors) {
      vendor.ITEMS = await VendorRequests.fetchVendorItems(vendor.ID);
    }
    dispatch(setVendors(vendors));
  };
}

export function updateVendor(id) {
  return async (dispatch, getState) => {
    const vendor = await VendorRequests.fetchVendor(id);
    const items = await VendorRequests.fetchVendorItems(vendor.ID);
    dispatch(upsertVendor({...vendor, ITEMS: items}));
  };
}

export function upsertVendor(vendor) {
  return (dispatch, getState) => {
    const {vendors} = getState().supply;
    dispatch(setVendors(upsert(vendors, vendor)));
  };
}

export function updateIngredientCategories() {
  return async (dispatch) => {
    const categories = await IngredientCategoryRequests.fetchCategories();
    dispatch(setIngredientCategories(categories));
  };
}

export function updateIngredientCategory(id) {
  return async (dispatch) => {
    const category = await IngredientCategoryRequests.fetchCategory(id);
    dispatch(upsertIngredientCategory(category));
  };
}

export function upsertIngredientCategory(category) {
  return (dispatch, getState) => {
    const {categories} = getState().supply;
    dispatch(setIngredientCategories(upsert(categories, category)));
  };
}

export function updateCounts() {
  return async (dispatch) => {
    const counts = await IngredientCountRequests.fetchCounts();
    dispatch(setCounts(counts));
  };
}

export function updateCount(id) {
  return async (dispatch) => {
    const count = await IngredientCategoryRequests.fetchCategory(id);
    dispatch(upsertCount(count));
  };
}

export function upsertCount(count) {
  return (dispatch, getState) => {
    const {counts} = getState().supply;
    dispatch(setCounts(upsert(counts, count)));
  };
}

export function updateWasteTypes() {
  return async (dispatch) => {
    const wasteTypes = await WasteRequests.fetchWasteTypes();
    dispatch(setWasteTypes(wasteTypes));
  };
}

export const supplyReducer = (state = defaultState, action) => {
  let {type, payload} = action;

  switch (type) {
    default:
      return state;
    case UPDATE_SUPPLY_UNITS:
      return {...state, units: payload};
    case UPDATE_SUPPLY_VENDORS:
      return {...state, vendors: payload};
    case UPDATE_SUPPLY_INGREDIENTS:
      return {...state, ingredients: payload};
    case UPDATE_SUPPLY_CATEGORIES:
      return {...state, categories: payload};
    case UPDATE_SUPPLY_COUNTS:
      return {...state, counts: payload};
    case UPDATE_SUPPLY_WASTE_TYPES:
      return {...state, wasteTypes: payload};
  }
};
