import React, {Component} from "react";
class VisaBadge extends Component {
  render() {
    const {inactive, disabled} = this.props;

    let rectFill = "#1434CB";
    let letterFill = "white";

    if (inactive) {
      rectFill = "#B5C7F6";
      letterFill = "white";
    }

    if (disabled) {
      rectFill = "#EBEDEF";
      letterFill = "#ABB1BA";
    }

    return (
      <div>
        <svg
          width="48"
          height="34"
          viewBox="0 0 60 42"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          aria-label="mini-card icon"
        >
          <rect width="60" height="42" rx="6" fill={rectFill}></rect>
          <path
            d="M26.2684 15.691L22.2123 26.3281H19.5643L17.5652 17.8426C17.4436 17.3206 17.3392 17.1296 16.9684 16.9068C16.3658 16.5504 15.375 16.213 14.5 16.0029L14.5579 15.691H18.8226C19.3673 15.691 19.854 16.0856 19.9757 16.7731L21.0303 22.9288L23.6378 15.691H26.2684ZM36.6462 22.8524C36.6578 20.0451 33.1116 19.8924 33.1348 18.6383C33.1406 18.2564 33.4708 17.849 34.1951 17.7471C34.5544 17.6962 35.5452 17.658 36.6636 18.2245L37.1039 15.9711C36.5013 15.7292 35.7249 15.5 34.763 15.5C32.2888 15.5 30.5447 16.945 30.5331 19.0139C30.5157 20.5417 31.7789 21.401 32.7292 21.9103C33.7026 22.4323 34.0329 22.7633 34.0271 23.2344C34.0213 23.9473 33.2507 24.2656 32.5264 24.272C31.269 24.2975 30.5331 23.8964 29.9536 23.6036L29.5017 25.9334C30.0869 26.2263 31.1647 26.4873 32.2888 26.5C34.9136 26.4936 36.6346 25.0613 36.6462 22.8524ZM43.1822 26.3218H45.5L43.4778 15.6846H41.3396C40.8587 15.6846 40.4531 15.9902 40.2735 16.4676L36.5187 26.3218H39.1494L39.6708 24.7367H42.8809L43.1822 26.3218ZM40.3894 22.5532L41.7105 18.5556L42.4695 22.5532H40.3894ZM29.8493 15.691L27.7807 26.3281H25.2776L27.3462 15.691H29.8493Z"
            fill={letterFill}
          ></path>
        </svg>
      </div>
    );
  }
}

export default VisaBadge;
