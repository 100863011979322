import React, {Component} from "react";
import {
  FormInput,
  FormSelect,
  Modal,
} from "@frostbyte-technologies/frostbyte-tailwind";
import {request} from "../../../utils/request";
import {Formik} from "formik";
import * as Yup from "yup";
import FormRow from "../../../components/form-row";
import FormStateSelect from "../../../components/form-state-select";
import CompanyDropdown from "../../../dropdowns/admin/company-dropdown";
import {withRouter} from "../../../utils/navigation";
import FranchiseDropdown from "../../../dropdowns/admin/franchise-dropdown";
import {TIMEZONE_LABELS} from "../../../utils/settings-constants";
import CompanyModal from "./company-modal";
import FranchiseModal from "./franchise-modal";

class LocationModal extends Component {
  state = {location: null};

  open(location = null) {
    this.setState({location}, () => {
      this.formikRef && this.formikRef.resetForm();
      this.modal.open();

      setTimeout(() => {
        this.setupAutofill();
      }, 250);
    });
  }

  setupAutofill() {
    var input = document.getElementById("address");
    var options = {
      types: ["address"],
    };

    console.log(input);

    if (!input) return;

    geolocate();
    let autocomplete = new window.google.maps.places.Autocomplete(
      input,
      options
    );

    autocomplete.addListener("place_changed", async () => {
      const {setFieldValue} = this.formikRef;

      let address = await fillInAddress();

      let {
        street_number,
        route,
        locality,
        postal_code,
        administrative_area_level_1,
        latitude,
        longitude,
        sublocality,
      } = address;

      let addressPayload = {
        ID: null,
        LINE_ONE: (street_number ? street_number.short : "") + " " + route.long,
        CITY: locality ? locality.long : sublocality?.long,
        ZIPCODE: postal_code.short,
        STATE: administrative_area_level_1.short,
        LATITUDE: latitude,
        LONGITUDE: longitude,
      };

      setFieldValue("address", addressPayload.LINE_ONE);
      setFieldValue("city", addressPayload.CITY);
      setFieldValue("zipcode", addressPayload.ZIPCODE);
      setFieldValue("state", addressPayload.STATE);
      setFieldValue("latitude", addressPayload.LATITUDE);
      setFieldValue("longitude", addressPayload.LONGITUDE);

      const body = await request("admin/timezone", "POST", addressPayload);

      setFieldValue("timezone", body.timeZoneId);
    });

    function geolocate() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(function (position) {
          let geolocation = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };

          let circle = new window.google.maps.Circle({
            center: geolocation,
            radius: position.coords.accuracy,
          });

          autocomplete.setBounds(circle.getBounds());
        });
      }
    }

    async function fillInAddress() {
      let place = autocomplete.getPlace();

      if (!place.geometry) {
        return {};
      }

      let {lat: latitude, lng: longitude} = place.geometry.location;
      return place.address_components.reduce(
        (dict, item, i) => {
          let {long_name, short_name, types} = item;

          for (let type of types) {
            dict[type] = {short: short_name, long: long_name};
          }

          return dict;
        },
        {
          latitude: await latitude(),
          longitude: await longitude(),
          formatted: place.formatted_address,
        }
      );
    }
  }

  async createLocation({
    name,
    franchise,
    company,
    city,
    address,
    state,
    zipcode,
    latitude,
    longitude,
    timezone,
  }) {
    const serverLocation = await request("admin/dashboard/location", "POST", {
      NAME: name,
      TAX_RATE: 0,
      COMPANY_ID: company,
      FRANCHISE_ID: franchise,
      ADDRESS: address,
      CITY: city,
      STATE: state,
      ZIPCODE: zipcode,
      LATITUDE: latitude,
      LONGITUDE: longitude,
      SETTINGS: {
        TIMEZONE: timezone,
      },
    });

    this.props.router.navigate("/admin/location/" + serverLocation.UNIQUE_ID);
  }

  async saveLocation({
    name,
    franchise,
    company,
    city,
    address,
    state,
    zipcode,
    latitude,
    longitude,
  }) {
    const {location} = this.state;

    let serverContact = await request(
      "admin/location/" + location.ID,
      "PATCH",
      {
        NAME: name,
        TAX_RATE: 0,
        COMPANY_ID: company,
        FRANCHISE_ID: franchise,
        ADDRESS: address,
        CITY: city,
        STATE: state,
        ZIPCODE: zipcode,
        LATITUDE: latitude,
        LONGITUDE: longitude,
      }
    );

    this.props.updateState(serverContact);
    this.modal.close();
  }

  render() {
    const {location} = this.state;

    return (
      <Modal
        buttonLabel={location ? "Save" : "Add"}
        label={location ? "Edit Location" : "Create Location"}
        ref={(e) => (this.modal = e)}
        formikOnClick={() => this.formikRef}
        large
      >
        <CompanyModal
          ref={(e) => (this.companyModal = e)}
          addState={(company) => {
            this.formikRef.setFieldValue("company", company.ID);

            if (company.FRANCHISE) {
              this.formikRef.setFieldValue("franchise", company.FRANCHISE.ID);
            }

            this.companyDropdown.syncData();
            this.franchiseDropdown.syncData();
          }}
        />

        <FranchiseModal
          ref={(e) => (this.franchiseModal = e)}
          addState={(franchise) => {
            this.formikRef.setFieldValue("franchise", franchise.ID);

            this.franchiseDropdown.syncData();
          }}
        />

        <Formik
          onSubmit={
            location
              ? this.saveLocation.bind(this)
              : this.createLocation.bind(this)
          }
          innerRef={(e) => (this.formikRef = e)}
          enableReinitialize
          validationSchema={Yup.object({
            name: Yup.string().required("Name is required"),
            company: Yup.number()
              .typeError("Company is required")
              .required("Company is required"),
            franchise: Yup.number()
              .typeError("Franchise is required")
              .required("Franchise is required"),
            address: Yup.string().required("Address is required"),
            city: Yup.string().required("City is required"),
            state: Yup.string().required("State is required"),
            zipcode: Yup.string().required("Zipcode is required"),
            latitude: Yup.string().required("Latitude is required"),
            longitude: Yup.string().required("Longitude is required"),
          })}
          initialValues={{
            name: location?.NAME ?? "",
            company: location?.COMPANY_ID ?? null,
            franchise: location?.FRANCHISE_ID ?? null,
            address: location?.ADDRESS ?? "",
            city: location?.CITY ?? "",
            zipcode: location?.ZIPCODE ?? "",
            state: location?.STATE ?? "",
            latitude: location?.LATITUDE ?? "",
            longitude: location?.LONGITUDE ?? "",
            timezone: location?.SETTINGS?.TIMEZONE ?? "America/New_York",
          }}
        >
          {(formikOptions) => {
            const {handleSubmit, values} = formikOptions;

            return (
              <form onSubmit={handleSubmit}>
                <FormInput
                  options={formikOptions}
                  placeholder="Avery's Cool Location"
                  label="Name"
                  name="name"
                />

                <FormRow>
                  <CompanyDropdown
                    flex
                    name="company"
                    ref={(e) => (this.companyDropdown = e)}
                    options={formikOptions}
                    buttonHint={{
                      label: "Create New",
                      onClick: () => this.companyModal.open(),
                    }}
                  />

                  <FranchiseDropdown
                    flex
                    name="franchise"
                    options={formikOptions}
                    ref={(e) => (this.franchiseDropdown = e)}
                    buttonHint={{
                      label: "Create New",
                      onClick: () => this.franchiseModal.open(values.company),
                    }}
                  />
                </FormRow>

                <FormInput
                  name="address"
                  label="Address"
                  options={formikOptions}
                  autoComplete="off"
                />

                <FormRow>
                  <FormInput name="city" label="City" options={formikOptions} />

                  <FormStateSelect
                    name="state"
                    label="State"
                    options={formikOptions}
                    flex={1}
                  />

                  <FormInput
                    name="zipcode"
                    label="Zipcode"
                    options={formikOptions}
                    flex={1}
                  />
                </FormRow>

                <FormRow>
                  <FormInput
                    name="latitude"
                    label="Latitude"
                    options={formikOptions}
                    flex
                  />

                  <FormInput
                    name="longitude"
                    label="Longitude"
                    options={formikOptions}
                    flex
                  />
                </FormRow>

                {location === null && (
                  <FormSelect
                    name="timezone"
                    label="Timezone"
                    options={formikOptions}
                    data={Object.keys(TIMEZONE_LABELS).map((key) => ({
                      label: TIMEZONE_LABELS[key],
                      value: key,
                    }))}
                  />
                )}
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

export default withRouter(LocationModal);
