export const TIMEZONE_LABELS = {
  "America/New_York": "Eastern",
  "America/Chicago": "Central",
  "America/Denver": "Mountain",
  "America/Los_Angeles": "Pacific",
  "America/Anchorage": "Alaska",
  "America/Phoenix": "Arizona (No DST)",
  "Pacific/Honolulu": "Hawaii-Aleutian (No DST)",
};

export const TIP_CALCULATION_METHOD_TYPES = {
  TRANSACTION: "By Transaction",
  DAILY: "By Day",
  WEEKLY: "By Week",
  PAY_PERIOD: "By Pay Period",
};

export const REPORTING_DATING_LABELS = {
  DATE_CAPTURED: "Time of payment",
  DATE_REPORTED: "Date of goods given to customer",
};
