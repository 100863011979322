import {request} from "../../request";

export const PayrollRequests = {
  deletePostTaxDeduction: async (id) => {
    return request(`payroll/post-tax/${id}`, "DELETE");
  },
  createPostTaxDeduction: async (params) => {
    return request(`payroll/post-tax`, "POST", params);
  },
  editPostTaxDeduction: async (params) => {
    return request(`payroll/post-tax`, "PATCH", params);
  },
  fetchPostTaxDeductions: async () => {
    return request(`payroll/post-tax`, "GET");
  },
  fetchEmployees: async () => {
    return request(`payroll/employees`, "GET");
  },
};
