import {toDollars} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";

const formatDollar = dollars => dollars >= 0 ? toDollars(dollars, true) : `$(${toDollars(-dollars)})`;

export const moneyAndPercent = (dollars, percent) => <div className="text-right">
    <div className={`text-medium`}>{formatDollar(dollars)}</div>

    <div className={`${percent < 1 ? "text-green-800" : "text-red-800"}`}>{`${(percent * 100).toFixed(
        2
    )}%`}</div>
</div>
