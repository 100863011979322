import {IngredientRequests} from "../utils/request-helpers/supply-chain/supply-chain-requests";
import {
  setIngredients,
  updateCounts,
  updateIngredientCategories,
  updateIngredients,
  updateUnits,
  updateVendors,
  updateWasteTypes,
} from "./supply";
import {request} from "../utils/request";

const defaultState = {
  otherPayments: [],
  categories: [],
  orderTypes: [],
  products: [],
};

const UPDATE_MENU = "UPDATE_MENU";
const UPDATE_PRODUCTS = "UPDATE_PRODUCTS";
const UPDATE_ORDER_TYPES = "UPDATE_ORDER_TYPES";

export function loadMenu() {
  return async (dispatch) => {
    dispatch(updateProducts());
    dispatch(updateOrderTypes());
  };
}

export function updateMenu(payload) {
  return {type: UPDATE_MENU, payload};
}

export function updateProducts() {
  return async (dispatch) => {
    const products = await request("products/", "GET");
    dispatch(setProducts(products));
  };
}

export function updateOrderTypes() {
  return async (dispatch) => {
    const orderTypes = await request("types/order", "GET");
    dispatch(setOrderTypes(orderTypes));
  };
}

export function setProducts(payload) {
  return {type: UPDATE_PRODUCTS, payload};
}

export function setOrderTypes(payload) {
  return {type: UPDATE_ORDER_TYPES, payload};
}

export const menuReducer = (state = defaultState, action) => {
  let {type, payload} = action;

  switch (type) {
    default:
      return state;
    case UPDATE_ORDER_TYPES:
      return {...state, orderTypes: payload};
    case UPDATE_PRODUCTS:
      return {...state, products: payload};
    case UPDATE_MENU:
      return {...state, ...payload};
  }
};
