import React, {Component} from "react";
import {FormEmail, FormInput, FormPhone,} from "@frostbyte-technologies/frostbyte-tailwind";
import FormStateSelect from "../../../../components/form-state-select";
import {Form, Formik} from "formik";
import * as Yup from "yup";
import {PHONE_YUP} from "@frostbyte-technologies/frostbyte-tailwind/dist/components/input/form-phone";
import {showErrorAlert} from "../../../../utils/alert-helper";
import {request} from "../../../../utils/request";
import EmployeeDropdown from "../../../../dropdowns/team/employee-dropdown";

class PayrollCompanyFormComponent extends Component {
  async handleClick() {
    return Boolean(await this.formikRef.submitForm());
  }

  submitForm = async (values) => {
    let {
      businessName,
      businessEmail,
      businessPhone,
      line1,
      line2,
      city,
      state,
      zip,
      notificationEmployeeId,
    } = values;

    try {
      this.setState({isLoading: true});

      await request("payroll/groups/company", "POST", {
        BUSINESS_NAME: businessName,
        BUSINESS_EMAIL: businessEmail,
        BUSINESS_PHONE: businessPhone.replaceAll(" ", ""),
        BUSINESS_ADDRESS_1: line1,
        BUSINESS_ADDRESS_2: line2,
        BUSINESS_CITY: city,
        BUSINESS_STATE: state,
        BUSINESS_ZIP: zip,
        NOTIFICATION_EMPLOYEE_ID: notificationEmployeeId,
      });

      this.setState({isLoading: false});
    } catch (e) {
      let message = "Error creating company";

      if (e.error?.error?.error?.message) {
        message = e.error?.error?.error?.message;
      }
      showErrorAlert("Error", message, "Ok");

      return false;
    }

    return true;
  };

  validationSchema = Yup.object().shape({
    businessName: Yup.string().required("Legal Business Name is Required"),
    businessPhone: PHONE_YUP.required("Phone Number is Required"),
    businessEmail: Yup.string()
      .email("Must be a valid email")
      .required("A Valid Email is Required"),
    line1: Yup.string().required("A Valid Address is Required"),
    city: Yup.string().required("A Valid City is Required"),
    state: Yup.string().required("Please Select a State"),
    zip: Yup.number().required("A Valid Zipcode is Required"),
  });

  render() {
    return (
      <div className="flex justify-center">
        <Formik
          onSubmit={async (values) => {
            return await this.submitForm(values);
          }}
          initialValues={{}}
          validationSchema={this.validationSchema}
          innerRef={(e) => (this.formikRef = e)}
        >
          {(formikOptions) => {
            return (
              <div className="w-full px-6 md:w-1/2 md:px-6 py-10">
                <Form onSubmit={this.submitForm}>
                  <FormInput
                    name="businessName"
                    label="Legal Business Name"
                    options={formikOptions}
                  />

                  <FormPhone
                    name="businessPhone"
                    label="Phone Number"
                    options={formikOptions}
                  />

                  <FormEmail
                    name="businessEmail"
                    label="Business Email"
                    options={formikOptions}
                  />

                  <EmployeeDropdown
                    label="Notification Employee"
                    tooltip={
                      "Reminders to run payroll will be sent to this employee's email"
                    }
                    options={formikOptions}
                    name="notificationEmployeeId"
                  />

                  <div className="font-bold text-sm mt-8">
                    Legal Business Address Information
                  </div>

                  <FormInput
                    name="line1"
                    label="Address Line 1"
                    options={formikOptions}
                  />

                  <FormInput
                    name="line2"
                    label="Address Line 2"
                    options={formikOptions}
                  />

                  <div className="flex flex-row">
                    <FormInput
                      name="city"
                      label="City"
                      options={formikOptions}
                    />

                    <FormStateSelect
                      className="ml-5"
                      name="state"
                      label="State"
                      options={formikOptions}
                    />

                    <FormInput
                      className="ml-5 w-24"
                      name="zip"
                      label="Zipcode"
                      options={formikOptions}
                    />
                  </div>
                </Form>
              </div>
            );
          }}
        </Formik>
      </div>
    );
  }
}

export default PayrollCompanyFormComponent;
