import {Disclosure} from "@headlessui/react";
import {ChevronRightIcon} from "@heroicons/react/solid";
import PropTypes from "prop-types";
import {classNames} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import {useState} from "react";

export default function Sidebar({navigation, className, onChange, stayOpen}) {
  const [current, setCurrent] = useState(null);

  function renderChildLabel(item) {
    return (
      <>
        <div className="mr-3">{item.NAME}</div>
        {!!item.ICON && item.ICON}
      </>
    );
  }

  function renderChild(item) {
    return (
      <li key={item.NAME}>
        <Disclosure.Button
          onClick={(e) => {
            e.preventDefault();
            setCurrent(item.uid);
            onChange && onChange(item);
          }}
          className={classNames(
            current === item.uid ? "bg-gray-50" : "hover:bg-gray-50",
            "flex flex-row items-center text-left w-full justify-between rounded-md space-x-4 py-2 pr-2 mx-4 px-4 text-sm leading-6 text-gray-700"
          )}
        >
          {renderChildLabel(item)}
        </Disclosure.Button>
      </li>
    );
  }

  function renderParentLabel(item, open) {
    return (
      <>
        {item.NAME}
        {!!item.ICON && item.ICON}

        <ChevronRightIcon
          className={classNames(
            open ? "rotate-90 text-gray-500" : "text-gray-400",
            "ml-auto h-5 w-5 shrink-0"
          )}
          aria-hidden="true"
        />
      </>
    );
  }

  function renderParent(item) {
    return (
      <Disclosure defaultOpen={stayOpen} as="div">
        {({open}) => (
          <>
            <Disclosure.Button
              className={classNames(
                item.CURRENT ? "bg-gray-50" : "hover:bg-gray-50",
                "flex items-center w-full text-left rounded-md p-2 gap-x-3 text-sm leading-6 font-semibold text-gray-700"
              )}
            >
              {renderParentLabel(item, open)}
            </Disclosure.Button>

            <Disclosure.Panel as="ul" className="mt-1 px-2">
              {item.CHILDREN.map((subItem) => renderItem(subItem))}
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    );
  }

  function renderItem(item) {
    return (
      <li key={item.name}>{!item.CHILDREN ? renderChild(item) : renderParent(item)}</li>
    );
  }

  function renderHeader() {
    return (
      <div className="flex h-16 shrink-0 items-center">
        <img
          className="h-8 w-auto"
          src="https://dripos-assets.s3.amazonaws.com/dripos-banner.png"
          alt="Dripos"
        />
      </div>
    );
  }

  function renderBody() {
    return (
      <nav className="flex flex-1 flex-col">
        <ul role="list" className="flex flex-1 flex-col gap-y-7">
          <li>
            <ul role="list" className="-mx-2 space-y-1">
              {navigation.map((item) => renderItem(item))}
            </ul>
          </li>
        </ul>
      </nav>
    );
  }

  return (
    <div
      className={classNames(
        "flex-1 flex-col gap-y-5 overflow-y-scroll border-r border-gray-200 bg-white px-6 max-h-fit ",
        className
      )}
    >
      {renderHeader()}
      {renderBody()}
    </div>
  );
}

Sidebar.propTypes = {
  navigation: PropTypes.arrayOf(
    PropTypes.objectOf({
      ID: PropTypes.number.isRequired,
      NAME: PropTypes.string.isRequired,
      ICON: PropTypes.string.isRequired,
      CHILDREN: PropTypes.arrayOf(
        // this is recursive, so you can add more children to continue dropdowns
        PropTypes.objectOf({
          NAME: PropTypes.string.isRequired,
          onClick: PropTypes.string,
        })
      ),
    })
  ).isRequired,
  onChange: PropTypes.func,
  stayOpen: PropTypes.bool,
};
