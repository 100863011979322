import React, {Component} from "react";
import {
  Card,
  PageHeadings,
  TwoColumnList,
} from "@frostbyte-technologies/frostbyte-tailwind";
import {request} from "../../../utils/request";
import {toDollars} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import moment from "moment-timezone";
import LoadingSpinner from "../../../components/loading-spinner";
import {withRouter} from "../../../utils/navigation";
import {setupReduxConnection} from "../../../redux";
import ReportTable from "../../../features/reporting/reports/report-table";
import {combineArraysIfExist} from "../../../utils/util";
import ReportingValueCell from "../../../features/reporting/reports/reporting-value-cell";
import ReportingLabelCell from "../../../features/reporting/reports/reporting-label-cell";
import {
  DAILY_PAYOUT_ROWS,
  TAX_WITHHOLDING_PAYOUT_ROWS,
} from "../../../features/reporting/reports/reporting-constants";
import {CSVLink} from "react-csv";
import {DownloadIcon} from "@heroicons/react/solid";

class PayoutPage extends Component {
  state = {payoutData: null};

  async componentDidMount() {
    const {PAYOUT_UNIQUE_ID} = this.props.router.params;

    const payoutData = await request(`payouts/${PAYOUT_UNIQUE_ID}`);

    payoutData.AMOUNT_PROCESSING_WITHHELD = -payoutData.AMOUNT_PROCESSING_WITHHELD;

    if (payoutData.TYPE === "TAX") {
      payoutData.AMOUNT_WITHHELD *= -1;
      payoutData.AMOUNT_TAX_WITHHELD *= -1;
      payoutData.AMOUNT_FEES_WITHHELD *= -1;
    }

    this.setState({payoutData});
  }

  getColumns() {
    return [
      {
        label: "",
        width: 50,
        sticky: true,
        format: (value, row) => {
          return <ReportingLabelCell row={row} style={row.style} />;
        },
      },
      {
        label: "Total",
        width: 100,
        format: (value, row) => {
          return (
            <ReportingValueCell
              row={row}
              firstIndex={[0]}
              secondIndex={row.selector}
              thirdIndex={row.secondSelector}
              style={combineArraysIfExist(row.style, "")}
              dollarAmount={true}
            />
          );
        },
      },
    ];
  }

  getRows() {
    const {AMOUNT_WITHHELD, AMOUNT_WITHHOLDING_ADJUSTED, TYPE} = this.state.payoutData;

    let rows = [...DAILY_PAYOUT_ROWS];

    if (TYPE === "TAX") {
      rows = [...TAX_WITHHOLDING_PAYOUT_ROWS];
    }

    if (!AMOUNT_WITHHELD) {
      rows.splice(2, 3);
    }

    if (!AMOUNT_WITHHOLDING_ADJUSTED) {
      rows = rows.filter(({label}) => label !== "Withholding Adjustment");
    }

    return rows;
  }

  getCsvData(payoutData) {
    const {location} = this.props.shop;
    const {DATE_ARRIVED, ...data} = payoutData;
    const numbers = [data];

    const csvHeaders = [{label: "", key: "LABEL"}];
    const csvData = [{0: location.NAME, LABEL: "Location"}];

    const rows = DAILY_PAYOUT_ROWS;

    for (let entryIndex in numbers) {
      csvHeaders.push({
        label: moment(DATE_ARRIVED).format("MM/DD"),
        key: "" + entryIndex,
      });
    }

    for (let row of rows) {
      let currentRow = {LABEL: row.label};

      for (let entryIndex in numbers) {
        currentRow[entryIndex] = toDollars(numbers[entryIndex][row.selector] || 0, true);
      }

      csvData.push(currentRow);
    }

    return {csvHeaders, csvData};
  }

  getTaxWithholdingRange(startEpoch, endEpoch) {
    if (
      startEpoch === moment(startEpoch).startOf("month").valueOf() &&
      endEpoch === moment(endEpoch).endOf("month").valueOf()
    ) {
      return moment(startEpoch).format("MMMM YYYY");
    }

    return `${moment(startEpoch).format("MMM D h:mm A")} - ${moment(startEpoch).format(
      "MMM D h:mm A"
    )}`;
  }

  renderPayoutDetails() {
    let {DATE_ARRIVED, DATE_CAPTURED_START, DATE_CAPTURED_END, TYPE} =
      this.state.payoutData;

    const dateFormat = "MMM D h:mm A";

    let dateRangeString = "Card Payments Date Range";
    let dateRangeValue = `${moment(DATE_CAPTURED_START).format(dateFormat)} - ${moment(
      DATE_CAPTURED_END
    ).format(dateFormat)}`;

    if (TYPE === "TAX") {
      dateRangeString = "Tax Withholding Date Range";
      dateRangeValue = this.getTaxWithholdingRange(
        DATE_CAPTURED_START,
        DATE_CAPTURED_END
      );
    }

    return (
      <TwoColumnList
        label={"Payout Details"}
        data={[
          {
            label: "Expected Arrival",
            value: moment(DATE_ARRIVED).format("MMM Do YYYY, h:mm A"),
          },
          {
            label: dateRangeString,
            value: dateRangeValue,
          },
        ]}
      />
    );
  }

  render() {
    let {payoutData} = this.state;
    const {PAYOUT_UNIQUE_ID} = this.props.router.params;

    if (!payoutData) {
      return <LoadingSpinner />;
    }

    let {PAYOUT_TOTAL, DATE_ARRIVED, TYPE} = payoutData;
    const {csvData, csvHeaders} = this.getCsvData(payoutData);

    let title = "Daily Payout";

    let button = {
      label: "View Tickets",
      onClick: () => this.props.router.navigate(`/tickets?payout=${PAYOUT_UNIQUE_ID}`),
    };

    if (TYPE === "TAX") {
      title = "Tax Withholding Payout";
      button = null;
    }

    return (
      <div>
        <PageHeadings
          label={`${toDollars(PAYOUT_TOTAL, true)} ${title}`}
          button={{
            type: "gray",
            className: "px-0 pt-0 pb-0 border-hidden",
            label: (
              <CSVLink
                filename={`${this.props.shop.location.NAME}-payout-${moment(
                  DATE_ARRIVED
                ).format("MM/DD/YY")}.csv`}
                data={csvData}
                headers={csvHeaders}
              >
                <button
                  type="button"
                  className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                >
                  <DownloadIcon className="h-5 w-5" />
                </button>
              </CSVLink>
            ),
          }}
        />

        {this.renderPayoutDetails()}

        <Card label={"Payout Breakdown"} button={button}>
          <ReportTable
            columns={this.getColumns()}
            numbers={[payoutData]}
            constantRows={this.getRows()}
          />
        </Card>
      </div>
    );
  }
}

export default setupReduxConnection(["shop"])(withRouter(PayoutPage));
