import React, {Component, Fragment} from "react";
import {FormElement} from "@frostbyte-technologies/frostbyte-tailwind";
import {Menu} from "@headlessui/react";
import {ROLE_COLORS} from "../../utils/team-constants";
import {
  classNames,
  randomString,
} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import ReactDOM from "react-dom";
import {Manager, Popper, Reference} from "react-popper";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {ChevronDownIcon} from "@heroicons/react/solid";
import {request} from "../../utils/request";
import {setupReduxConnection} from "../../redux";

class CategoryDropdown extends Component {
  constructor(props) {
    super(props);

    this.id = "cd_" + randomString(24);
    this.state = {selectedName: null};
  }

  renderButton() {
    const {transaction} = this.props;

    return (
      <div
        onClick={this.props.onClick}
        className="inline-flex justify-center rounded border text-black shadow-sm px-2 py-0.5 bg-white text-sm font-medium hover:bg-gray-50 focus:bg-gray-50"
      >
        {transaction.CATEGORIES.length > 1
          ? "Split"
          : transaction.CATEGORIES[0]?.LEDGER_NAME}
        <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
      </div>
    );
  }

  render() {
    const {relative} = this.props;
    const {transaction} = this.props;
    const {ledgers} = this.props.accounting;

    return this.renderButton();
    // <Manager>
    //   <Menu as="div" className="relative inline-block text-left">
    //     {({open}) => {
    //       return (
    //         <>
    //           <Reference>
    //             {({ref}) => (
    //               <div className={"flex flex-row"} ref={ref}>
    //                 {this.renderButton()}
    //               </div>
    //             )}
    //           </Reference>
    //
    //           {open &&
    //             ReactDOM.createPortal(
    //               <Popper
    //                 innerRef={(e) => (this.popperRef = e)}
    //                 placement={"bottom-start"}
    //                 strategy={"absolute"}
    //               >
    //                 {({ref, style, placement, arrowProps}) => (
    //                   <Menu.Items
    //                     ref={ref}
    //                     data-placement={placement}
    //                     style={style}
    //                     unmount={false}
    //                     className={classNames(
    //                       relative ? "relative" : "absolute",
    //                       "origin top-right w-48 shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-10"
    //                     )}
    //                   >
    //                     {this.getRandomEntries(ledgers, 3).map(
    //                       ({UNIQUE_ID, NAME}) => (
    //                         <Menu.Item
    //                           as={"div"}
    //                           className={
    //                             "p-2 text-black cursor-pointer hover:bg-indigo-500 hover:text-white truncate"
    //                           }
    //                           onClick={async () => {
    //                             this.setState({selectedName: NAME});
    //
    //                             await request(
    //                               `accounting/transactions/${transaction.UNIQUE_ID}`,
    //                               "PATCH",
    //                               {CATEGORY_LEDGER_UNIQUE_ID: UNIQUE_ID}
    //                             );
    //
    //                             this.props.syncState();
    //                           }}
    //                         >
    //                           {NAME}
    //                         </Menu.Item>
    //                       )
    //                     )}
    //
    //                     <Menu.Item
    //                       as={"div"}
    //                       className="p-2 text-black cursor-pointer hover:bg-indigo-500 hover:text-white"
    //                       onClick={() => this.props.onOtherClick()}
    //                     >
    //                       Other
    //                     </Menu.Item>
    //                   </Menu.Items>
    //                 )}
    //               </Popper>,
    //               document.querySelector("#category-wrapper")
    //             )}
    //         </>
    //       );
    //     }}
    //   </Menu>
    // </Manager>
  }
}

export default setupReduxConnection(["accounting"])(CategoryDropdown);
