import React, {Component} from "react";
import {setupReduxConnection} from "../../../../redux";
import {toDollars} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import LoadingSpinner from "../../../../components/loading-spinner";
import {submitPayroll} from "../../../../utils/payroll-helper";
import {saveAs} from "file-saver";
import PayrollInformationComponent from "../../payroll-information-component";
import {showErrorAlert} from "../../../../utils/alert-helper";
import {withRouter} from "../../../../utils/navigation";

class ConfirmationComponent extends Component {
  state = {
    isLoading: true,
    approved: null,
    totals: null,
    employeeBreakdown: null,
    checksLoading: true,
  };

  componentDidMount() {
    let {runMode} = this.props.payroll;
    const {ptoBreakdown} = this.props.navState.metadata;

    submitPayroll({RUN_MODE: runMode, PTO_BREAKDOWN: ptoBreakdown})
      .then((approved) => {
        this.setState({isLoading: false, approved});
        setTimeout(() => {
          this.setState({checksLoading: false});
        }, 10000);
      })
      .catch((err) => {
        showErrorAlert(
          "Error",
          "An error occurred while submitting payroll. Please reach out to support at (781)-583-3699 for more information",
          "Ok"
        );

        this.props.router.navigate("/payroll");
      });
  }

  render() {
    let {FULL_NAME} = this.props.user.account;
    let firstName = FULL_NAME.split(" ")[0];

    let {isLoading, approved, checksLoading} = this.state;

    if (isLoading) {
      return (
        <div className="py-10">
          <LoadingSpinner />
        </div>
      );
    }

    let {TOTALS, CHECKS_LINK} = approved;
    let {AMOUNT_DEBIT, AMOUNT_CHECK} = TOTALS;
    let showCheckButton = AMOUNT_CHECK > 0;

    return (
      <div className="flex flex-col pl-6">
        <div className="text-xl font-semibold">Good work, {FULL_NAME}!</div>

        <div className="mt-2 font-normal text-sm">
          Time to relax. Dripos will debit {toDollars(AMOUNT_DEBIT, true)} from your
          account for this payroll. Please make sure you have those funds available.
        </div>

        {showCheckButton && (
          <div>
            <span style={{marginTop: 15, display: "block"}}>
              You have {toDollars(AMOUNT_CHECK, true)} worth of checks to distribute on
              payday.
            </span>

            <span style={{display: "block"}}>
              Download the checks via the button below, or on the payroll dashboard.
            </span>

            {checksLoading && (
              <button
                type="button"
                className="my-5 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                <LoadingSpinner size={15} color={"rgb(255 255 255)"} />
                <div className="ml-2">Checks Are Loading</div>
              </button>
            )}

            {!checksLoading && (
              <button
                type="button"
                className="my-5 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                onClick={() => saveAs(CHECKS_LINK, "pdf")}
              >
                <div>Download Checks</div>
              </button>
            )}
          </div>
        )}
        <div className="mt-2 text-sm">
          Need to change something? You can re-open this payroll before it's processed, if
          needed.
        </div>

        <PayrollInformationComponent payroll={approved} />
      </div>
    );
  }
}

export default setupReduxConnection(["payroll", "user"])(
  withRouter(ConfirmationComponent)
);
