import React, {Component} from "react";
import {Popover} from "@headlessui/react";
import Calendar from "../features/reporting/reporting-filter/calendar";
import moment from "moment-timezone";
import FormElement from "@frostbyte-technologies/frostbyte-tailwind/dist/components/form/form-element";
import {classNames} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import PropTypes from "prop-types";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

class FormDateTimeSelect extends Component {
  render() {
    let {options, name, hideTime, hideDate, buttonText, flex, clearable} =
      this.props;

    let setMinutes = [0, 15, 30, 45];
    let times = [];
    for (let hour = 0; hour < 24; hour++) {
      for (let minute of setMinutes) {
        times.push({hour, minute});
      }
    }

    times.push({hour: 23, minute: 59});

    let format = "M/D/YY, h:mm A";

    if (hideTime) {
      format = "M/D/YY";
    }

    if (hideDate) {
      format = "h:mm A";
    }

    return (
      <FormElement {...this.props} ignoreShadow>
        {(value, error, onChange, onBlur, extraStyle, onChangeSoft) => {
          let currentMinutes = 0;
          if (value && !hideTime) {
            currentMinutes = moment(value).diff(
              moment(value).startOf("day"),
              "minutes"
            );
          }

          return (
            <div>
              <Popover className="relative">
                {({open}) => (
                  <>
                    <div className={"flex items-center"}>
                      <Popover.Button className={classNames(flex && "w-full")}>
                        <button
                          type="button"
                          className={classNames(
                            flex && "w-full",
                            "inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                          )}
                        >
                          {buttonText
                            ? buttonText(value)
                            : value
                            ? moment(value).format(format)
                            : "Click to select time"}
                        </button>
                      </Popover.Button>

                      {!!clearable && (
                        <button
                          className={
                            "inline-flex font-bold text-center mx-2 p-2  text-red-500 hover:text-red-700 focus:text-red-700 focus:ring-1 focus:ring-red-700 rounded"
                          }
                          onClick={() => options.setFieldValue(name, null)}
                          type="button"
                        >
                          {<FontAwesomeIcon icon={"x"} />}
                        </button>
                      )}
                    </div>

                    {open && (
                      <Popover.Panel
                        className="absolute h-72 z-10 bg-white"
                        focus={false}
                        unmount={false}
                      >
                        {({close}) => (
                          <div className="flex flex-row mt-4">
                            {!hideDate && (
                              <Calendar
                                className="pt-4"
                                initialEpochs={{
                                  startEpoch: value,
                                  endEpoch: value,
                                }}
                                singleDay
                                small
                                onChange={({startEpoch}) => {
                                  let newEpoch = moment(startEpoch)
                                    .startOf("day")
                                    .add(currentMinutes, "minutes")
                                    .valueOf();

                                  onChangeSoft && onChangeSoft(newEpoch);
                                  if (options) {
                                    options.setFieldValue(name, newEpoch);
                                  } else {
                                    onChange(newEpoch);
                                  }
                                }}
                              />
                            )}

                            {!hideTime && (
                              <div className="border-l w-24">
                                <div className="mt-4 pb-2 w-full flex justify-center fixed sticky font-semibold border-b">
                                  Time
                                </div>

                                <div className="h-52 overflow-y-scroll flex flex-col cursor-pointer">
                                  {times.map((time) => {
                                    let {hour, minute} = time;
                                    let mappedMinutes = hour * 60 + minute;
                                    let isAM = hour < 12;

                                    let displayHour = hour % 12 || 12;
                                    let displayMinute = minute.toString().padStart(2, "0");

                                    return (
                                      <div
                                        key={`${hour}-${minute}`}
                                        onClick={() => {
                                          let baseMoment = value
                                            ? moment(value).startOf("day")
                                            : moment().startOf("day");

                                          baseMoment.add(mappedMinutes, "minutes");

                                          onChangeSoft &&
                                          onChangeSoft(baseMoment.valueOf());
                                          if (options) {
                                            options.setFieldValue(
                                              name,
                                              baseMoment.valueOf()
                                            );
                                          } else {
                                            onChange(baseMoment.valueOf());
                                          }

                                          close();
                                        }}
                                        className={classNames(
                                          "text-center text-sm py-0.5 px-2",
                                          currentMinutes === mappedMinutes
                                            ? "bg-indigo-500 text-white"
                                            : ""
                                        )}
                                      >
                                        {`${displayHour}:${displayMinute} ${
                                          isAM ? "AM" : "PM"
                                        }`}
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                            )}
                          </div>
                        )}
                      </Popover.Panel>
                    )}
                  </>
                )}
              </Popover>
            </div>
          );
        }}
      </FormElement>
    );
  }
}

FormDateTimeSelect.propTypes = {
  hideTime: PropTypes.bool,
};

export default FormDateTimeSelect;