import React, {Component} from "react";
import {Modal} from "@frostbyte-technologies/frostbyte-tailwind";
import {withRouter} from "../../../utils/navigation";
import {showAlert} from "../../../utils/alert-helper";
import {Formik} from "formik";
import FormDateTimeSelect from "../../../components/form-date-time-select";
import {InvoiceRequests} from "../../../utils/request-helpers/invoices/invoice-requests";

class BatchEditInvoiceModal extends Component {
  state = {invoices: null};
  constructor(props) {
    super(props);
    this.formikRef = React.createRef(null);
    this.modalRef = React.createRef(null);
    this.buttonRef = React.createRef(null);
  }

  open(invoices = null) {
    this.setState({invoices: invoices}, () => {
      this.modalRef.current.open();
    });
  }

  fetchInvoicePayload(invoice) {
    const {due} = this.formikRef.current.values;

    return {
      INVOICE_NUMBER: invoice.INVOICE_NUMBER,
      CONTACT_ID: invoice.CONTACT_ID,

      DATE_SERVICE: invoice.DATE_SERVICE,
      DATE_DUE: due || invoice.DATE_DUE,

      NOTES: invoice.DATE_SERVICE,
      REQUEST_TIP: invoice.REQUEST_TIP,
      SEND_TO_KDS: invoice.SEND_TO_KDS,
    };
  }

  async editInvoices(button) {
    const {invoices} = this.state;

    button && button.startLoading();

    const filteredInvoices = invoices.filter((invoice) => invoice.STATUS !== "PAID");

    const requests = filteredInvoices.map((invoice) =>
      InvoiceRequests.updateInvoice(invoice.ID, this.fetchInvoicePayload(invoice))
    );

    try {
      await Promise.all(requests);
      this.props.syncState();
    } catch (error) {
      showAlert({
        label: "Error",
        description: "Failed to edit all invoices",
        buttonLabel: "Close",
        type: "error",
        icon: "error",
      });
    }
    this.modalRef?.current && this.modalRef.current.close();
  }

  fetchInitialValues() {
    return {
      due: null,
    };
  }

  renderForm() {
    return (
      <Formik
        onSubmit={() => this.editInvoices()}
        innerRef={this.formikRef}
        initialValues={this.fetchInitialValues()}
      >
        {(formikOptions) => {
          const {handleSubmit} = formikOptions;

          return (
            <form onSubmit={handleSubmit}>
              <FormDateTimeSelect
                name="due"
                label="Due Date"
                options={formikOptions}
                onChangeSoft={(val) => {
                  this.props.onDueChange && this.props.onDueChange(val);
                }}
                hideTime
              />
            </form>
          );
        }}
      </Formik>
    );
  }
  render() {
    const {invoices} = this.state;

    return (
      <Modal
        hideClose
        deleteOnClick={() => this.modalRef.current.close()}
        deleteType="gray"
        deleteLabel="Cancel"
        buttons={[
          {
            label: "Save",
            ref: this.buttonRef,
            onClick: () => this.editInvoices(this.buttonRef.current),
          },
        ]}
        label="Edit Invoices"
        ref={this.modalRef}
      >
        <div className="font-medium mb-4">
          Edit
          <span className="font-bold">
            {" "}
            {invoices?.length || 0} invoice{invoices?.length > 1 && "s"}
          </span>
        </div>
        {this.renderForm()}
      </Modal>
    );
  }
}

export default withRouter(BatchEditInvoiceModal);
