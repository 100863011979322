import React, {Component} from "react";
import {request} from "../../utils/request";
import LoadingSpinner from "../../components/loading-spinner";
import {
  AdvancedFilter,
  Card,
  PageHeadings,
  Table,
  TwoColumnList,
} from "@frostbyte-technologies/frostbyte-tailwind";
import {withRouter} from "../../utils/navigation";
import {toDollars} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import AccountModal from "../../modals/accounting/account-modal";
import LedgerDescription from "../../features/accounting/ledger-description";
import moment from "moment-timezone";
import CategoryDropdown from "../../features/accounting/category-dropdown";
import TransactionModal from "../../modals/accounting/transaction-modal";
import JournalEntryModal from "../../modals/accounting/journal-entry-modal";
import DailySalesModal from "../../modals/accounting/daily-sales-modal";
import {setupReduxConnection} from "../../redux";

class AccountingAccountsPage extends Component {
  state = {account: null};

  async componentDidMount() {
    this.props.loadLedgers();

    await this.syncState();
  }

  syncState = async () => {
    const {UNIQUE_ID} = this.props.router.params;

    const account = await request(`accounting/ledgers/${UNIQUE_ID}`);

    this.setState({account});
  };

  renderCategory({JOURNAL_ENTRY}) {
    switch (JOURNAL_ENTRY.TYPE) {
      case "DAILY_SALES":
        return <div className={"text-black"}>Daily Sales</div>;
      case "TRANSACTION":
        return <CategoryDropdown transaction={JOURNAL_ENTRY.TRANSACTION} />;
      case "OPENING_BALANCES":
        return <div></div>;
      case "MANUAL":
        return <div className={"text-black"}>Manual</div>;
    }
  }

  renderLineItems() {
    const {UNIQUE_ID} = this.props.router.params;

    return (
      <div id={"category-wrapper"}>
        <Card
          label={"Entries"}
          buttons={[
            {
              label: "New Journal Entry",
              onClick: () => this.journalEntryModal.open(),
            },
          ]}
        >
          <AdvancedFilter
            data={[
              {
                label: "Search by Name",
                placeholder: "Search",
                type: "search",
                id: "search",
              },
              {label: "Start Date", type: "datetime", id: "start"},
              {label: "End Date", type: "datetime", id: "end"},
            ]}
          >
            {(filters, search) => {
              return (
                <Table
                  ref={(e) => (this.tableRef = e)}
                  pagination
                  route={`accounting/ledgers/${UNIQUE_ID}/line-entries/dumb`}
                  filters={filters}
                  columns={[
                    {
                      label: "Date",
                      value: "DATE_OCCURRED",
                      width: 1,
                      format: (value, row) => (
                        <div
                          style={{minHeight: 30}}
                          className={"flex items-center"}
                        >
                          {moment(row.JOURNAL_ENTRY.DATE_OCCURRED).format(
                            "MMM D, YYYY"
                          )}
                        </div>
                      ),
                    },
                    {
                      label: "Name",
                      value: "NAME",
                      format: (value, row) => (
                        <div
                          className={"text-black font-semibold"}
                          onClick={() => {
                            if (row.JOURNAL_ENTRY.TYPE === "DAILY_SALES") {
                              this.dailySalesModal.open(row.JOURNAL_ENTRY);
                            } else if (row.TYPE === "TRANSACTION") {
                              this.transactionModal.open(
                                row.JOURNAL_ENTRY.TRANSACTION
                              );
                            } else {
                              this.journalEntryModal.open(row.JOURNAL_ENTRY);
                            }
                          }}
                        >
                          {row.JOURNAL_ENTRY.NAME}
                        </div>
                      ),
                    },
                    {
                      label: "Category",
                      value: "CATEGORY",
                      format: (value, row) => this.renderCategory(row),
                    },
                    {
                      label: "Amount",
                      value: "AMOUNT",
                      format: (value) => (
                        <div className={"text-black"}>
                          {toDollars(value, true)}
                        </div>
                      ),
                    },
                  ]}
                />
              );
            }}
          </AdvancedFilter>
        </Card>
      </div>
    );
  }

  render() {
    const {account} = this.state;

    if (!account) {
      return <LoadingSpinner />;
    }

    return (
      <div>
        <AccountModal
          ref={(e) => (this.accountModal = e)}
          syncState={this.syncState}
        />

        <TransactionModal
          ref={(e) => (this.transactionModal = e)}
          syncState={async () => {
            await Promise.all([this.tableRef.refresh(true), this.syncState()]);
          }}
        />

        <JournalEntryModal
          ref={(e) => (this.journalEntryModal = e)}
          syncState={async () => {
            await Promise.all([this.tableRef.refresh(true), this.syncState()]);
          }}
        />

        <DailySalesModal ref={(e) => (this.dailySalesModal = e)} />

        <PageHeadings
          label={`${account?.NAME}`}
          description={<LedgerDescription ledger={account} />}
        />

        {account && (
          <div>
            <TwoColumnList
              label={"Account"}
              data={[
                {label: "Balance", value: toDollars(account?.BALANCE, true)},
              ]}
              buttons={[
                {
                  label: "Edit",
                  onClick: () => this.accountModal.open(account),
                },
              ]}
            />

            {this.renderLineItems()}
          </div>
        )}
      </div>
    );
  }
}

export default setupReduxConnection(["accounting"])(
  withRouter(AccountingAccountsPage)
);
