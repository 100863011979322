export const PURCHASE_ORDER_STATUSES = {
  DRAFT: "DRAFT",
  SENT: "SENT",
  DELIVERED: "DELIV",
  COMPLETE: "COMPLETE",
  CANCELED: "CANCELED",
};

export const PURCHASE_ORDER_STATUS_DISPLAYS = {
  [PURCHASE_ORDER_STATUSES.DRAFT]: "Draft",
  [PURCHASE_ORDER_STATUSES.SENT]: "Sent",
  [PURCHASE_ORDER_STATUSES.DELIVERED]: "Delivered",
  [PURCHASE_ORDER_STATUSES.COMPLETE]: "Complete",
  [PURCHASE_ORDER_STATUSES.CANCELED]: "Cancelled",
};
