import React, {useCallback, useEffect, useRef} from "react";
import ReactFlow, {
  Background,
  Controls,
  MiniMap,
  ReactFlowProvider,
  useEdgesState,
  useNodesState,
  useReactFlow,
} from "reactflow";

import "reactflow/dist/style.css";
import {
  parseIdDict,
  randomString,
} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import {groupBy} from "../../../../utils/util";
import {Card} from "@frostbyte-technologies/frostbyte-tailwind";
import RecipeNode from "./recipe-node";

const nodeTypes = {
  recipe: RecipeNode,
};

const GOOD_COLOR = "green";
const BAD_COLOR = "red";

const initialNodes = [];

const initialEdges = [];

function createNode(node, edges) {
  return {
    id: "" + node.ID,
    position: {x: node.x, y: node.y},
    data: {
      label: node.NAME,
      ingredient: node,
    },
    type: "recipe",
    draggable: true,
  };
}

function createEdge(usage) {
  return {
    id: "e-" + usage.ID,
    source: "" + usage.SOURCE_ID,
    target: "" + usage.DESTINATION_ID,
    style: {
      strokeWidth: 2,
      stroke: "#4F46E5",
    },
  };
}

const NODE_HEIGHT = -200;
const NODE_WIDTH = 400;
const INITIAL_HEIGHT = 300;
const INITIAL_WIDTH = 400;

function placeNodes(edges, nodes) {
  const edgeDestinationDict = groupBy(edges, "DESTINATION_ID");
  const edgeSourceDict = groupBy(edges, "SOURCE_ID");
  const nodeDict = parseIdDict(nodes);

  let allChildren = nodes.filter((node) => !edgeSourceDict[node.ID]); // find all children

  let currHeight = INITIAL_HEIGHT;
  let currWidth = INITIAL_WIDTH;

  while (allChildren.length > 0) {
    const newChildren = [];
    const pushedChildren = {};

    for (let i = 0; i < allChildren.length; i++) {
      const child = allChildren[i];
      child.y = currHeight;

      if (currHeight === INITIAL_HEIGHT) {
        child.x = currWidth;
      } else {
        const sum = edgeSourceDict[child.ID].reduce(
          (accum, edge) => nodeDict[edge.DESTINATION_ID].x + accum,
          0
        );

        child.x = Math.round(sum / edgeSourceDict[child.ID].length);
      }

      const children = edgeDestinationDict[child.ID];

      if (children) {
        newChildren.push(
          ...children
            .filter((child) => {
              return !pushedChildren[child.ID];
            })
            .map((edge) => {
              pushedChildren[child.ID] = true;
              return nodeDict[edge.SOURCE_ID];
            })
        );
      }

      currWidth += NODE_WIDTH;
    }

    allChildren = newChildren;
    currHeight += NODE_HEIGHT;
  }
}

function Flow(props) {
  const reactFlowWrapper = useRef(null);
  const connectingNodeId = useRef(null);
  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);
  const {screenToFlowPosition} = useReactFlow();

  const onConnectStart = useCallback((_, {nodeId}) => {
    connectingNodeId.current = nodeId;
  }, []);

  const onConnectEnd = useCallback(
    (event) => {
      const targetIsPane = event.target.classList.contains("react-flow__pane");

      if (targetIsPane) {
        // we need to remove the wrapper bounds, in order to get the correct position
        const id = randomString(23);

        const newNode = {
          id,
          position: screenToFlowPosition({
            x: event.clientX,
            y: event.clientY,
          }),
          type: "recipe",
          data: {label: `Node ${id}`},
          source: connectingNodeId.current,
          origin: [0.5, 0.0],
        };

        setNodes((nds) => nds.concat(newNode));
        setEdges((eds) =>
          eds.concat({
            id,
            source: connectingNodeId.current,
            target: id,
            style: {
              strokeWidth: 2,
              stroke: "#4F46E5",
            },
          })
        );
      }
    },
    [screenToFlowPosition]
  );

  useEffect(function componentDidMount() {
    const {ingredients} = props;

    const allEdges = ingredients.reduce(
      (accum, ingredient) => [...accum, ...ingredient.CHILDREN],
      []
    );

    placeNodes(allEdges, ingredients);
    const edges = allEdges.map((usage) => createEdge(usage));
    const nodes = ingredients.map((node) => createNode(node));

    setNodes(nodes);
    setEdges(edges);
  }, []);

  return (
    <Card label="Ingredient Usages">
      <div style={{width: "100%", height: "100vh"}} ref={reactFlowWrapper}>
        <ReactFlow
          nodes={nodes}
          edges={edges}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onConnectStart={onConnectStart}
          onConnectEnd={onConnectEnd}
          nodeTypes={nodeTypes}
          fitView={true}
        >
          <Controls />
          <MiniMap />
          <Background variant="dots" gap={12} size={1} />
        </ReactFlow>
      </div>
    </Card>
  );
}

export default function RecipeBuilder(props) {
  return (
    <ReactFlowProvider>
      <Flow {...props} />
    </ReactFlowProvider>
  );
}
