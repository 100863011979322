import {FormInput, FormSelect} from "@frostbyte-technologies/frostbyte-tailwind";
import FormStateSelect from "../../components/form-state-select";
import {Formik} from "formik";
import React, {Component} from "react";
import * as Yup from "yup";
import PropTypes from "prop-types";

export class BillingAddressForm extends Component {
  submitForm() {
    this.formikRef.submitForm();
  }

  async validateForm() {
    const val = await this.formikRef.validateForm();

    if (Object.keys(val).length === 0) {
      await this.formikRef.submitForm();

      return true;
    }

    for (let item of Object.keys(this.formikRef.values)) {
      this.formikRef.setFieldTouched(item, true);
    }

    return false;
  }

  render() {
    const {address, onSubmit} = this.props;

    const billingAddressSchema = Yup.object({
      line1: Yup.string().required("Address is required"),
      city: Yup.string().required("City is required"),
      state: Yup.string().required("State is required"),
      zip: Yup.string().required("Zipcode is required"),
    });

    return (
      <Formik
        enableReinitialize={true}
        onSubmit={onSubmit}
        initialValues={{...address, zip: address?.postal_code}}
        innerRef={(e) => (this.formikRef = e)}
        validationSchema={billingAddressSchema}
      >
        {(formikOptions) => {
          const {handleSubmit} = formikOptions;

          return (
            <form onSubmit={handleSubmit}>
              <FormInput label="Address Line 1" name={"line1"} options={formikOptions} />

              <FormInput
                label="Address Line 2"
                name={"line2"}
                hint={"optional"}
                options={formikOptions}
              />

              <div className="flex flex-row justify-between">
                <FormInput label="City" name="city" options={formikOptions} />

                <FormStateSelect
                  className={"mx-3"}
                  label="State"
                  name="state"
                  options={formikOptions}
                />

                <FormInput
                  className="w-24"
                  label="Zipcode"
                  name="zip"
                  options={formikOptions}
                />
              </div>

              <FormSelect
                disabled
                label={"Country"}
                placeholder={"United States"}
                data={[]}
                tooltip={
                  "Please reach out to Dripos Support regarding ex-US billing address enablement"
                }
              />
            </form>
          );
        }}
      </Formik>
    );
  }
}

BillingAddressForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  address: PropTypes.object,
};

export default BillingAddressForm;
