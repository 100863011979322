import React, {Component} from "react";
import {Card, PageHeadings} from "@frostbyte-technologies/frostbyte-tailwind";
import {setupReduxConnection} from "../../../../redux";
import {request} from "../../../../utils/request";
import LoadingSpinner from "../../../../components/loading-spinner";

class PayrollRedirectPage extends Component {
  state = {serverLocations: null};

  async componentDidMount() {
    const serverLocations = await request("employees/locations", "GET");

    this.setState({serverLocations});
  }

  switchToRunLocation = () => {
    const {companyLocations} = this.props.shop;

    const loc = companyLocations?.find((_loc) => _loc.TYPE === "PAYROLL");

    localStorage.setItem("CURRENT_LOCATION", "" + loc.UNIQUE_ID);

    window.location = `${
      window.location.protocol + "//" + window.location.host
    }/payroll`;
  };

  renderSwitch() {
    const {companyLocations} = this.props.shop;

    const {serverLocations} = this.state;
    const loc = companyLocations?.find((_loc) => _loc.TYPE === "PAYROLL");

    if (serverLocations.some(({ID}) => ID === loc.ID)) {
      return (
        <button
          type="button"
          className="m-auto block items-center px-2 py-1 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          onClick={this.switchToRunLocation}
        >
          Switch Locations
        </button>
      );
    }

    return (
      <div className={"font-semibold text-red-500"}>You don't have access</div>
    );
  }

  render() {
    const {companyLocations} = this.props.shop;
    const {serverLocations} = this.state;

    const loc = companyLocations?.find((_loc) => _loc.TYPE === "PAYROLL");

    return (
      <div>
        <PageHeadings label={"Payroll"} />

        <Card label={"Switch Locations"}>
          <div style={{height: 350}} class={"items-center justify-center flex"}>
            <div className={"text-center space-y-3"}>
              <div className="font-semibold">
                Payroll is available on {loc?.NAME}
              </div>

              {!serverLocations && <LoadingSpinner size={20} />}

              {serverLocations && this.renderSwitch()}
            </div>
          </div>
        </Card>
      </div>
    );
  }
}

export default setupReduxConnection(["shop"])(PayrollRedirectPage);
