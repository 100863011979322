import {FormBoolean, Modal} from "@frostbyte-technologies/frostbyte-tailwind";
import React, {Component} from "react";
import {Formik} from "formik";
import {setupReduxConnection} from "../../../redux";
import {request} from "../../../utils/request";

class ProcessingFeesModal extends Component {
  open() {
    this.setState({}, () => {
      this.formikRef && this.formikRef.resetForm();
      this.modal.open();
    });
  }

  handleSubmit = async (values) => {
    let {passOnline, passPos} = values;

    let payload = {
      PASS_PROCESSING_FEES_MOBILE: passOnline,
      PASS_PROCESSING_FEES_POS: passPos,
    };

    await request("partner/settings/v2", "PATCH", {SETTINGS: payload});

    let settings = await request("settings/shop", "GET");

    this.props.updateShop({settings});
    this.modal.close();
  };

  render() {
    let {PASS_PROCESSING_FEES_MOBILE, PASS_PROCESSING_FEES_POS} =
      this.props.shop.settings;

    return (
      <Modal
        label="Processing Fee Settings"
        buttonLabel={"Save"}
        formikOnClick={() => this.formikRef}
        ref={(e) => (this.modal = e)}
      >
        <Formik
          onSubmit={this.handleSubmit}
          innerRef={(e) => (this.formikRef = e)}
          initialValues={{
            passOnline: PASS_PROCESSING_FEES_MOBILE,
            passPos: PASS_PROCESSING_FEES_POS,
          }}
        >
          {(formikOptions) => {
            const {handleSubmit} = formikOptions;

            return (
              <form onSubmit={handleSubmit}>
                <FormBoolean
                  name="passOnline"
                  label="Pass Processing on Online & Mobile Ordering"
                  tooltip="If enabled, this setting will apply a surcharge labeled “Processing” to each card transaction that is not greater than your cost of acceptance. Prior to activation, please ensure full compliance with state laws and card merchant regulations. Enable at your own discretion."
                  options={formikOptions}
                />

                <FormBoolean
                  name="passPos"
                  label="Pass Processing on POS Ordering"
                  tooltip="If enabled, this setting will apply a surcharge labeled “Processing” to each card transaction that is not greater than your cost of acceptance. Prior to activation, please ensure full compliance with state laws and card merchant regulations. Enable at your own discretion."
                  options={formikOptions}
                />
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

export default setupReduxConnection(["shop"], null, {forwardRef: true})(
  ProcessingFeesModal
);
