import React, {Component} from "react";
import {withRouter} from "../../../../utils/navigation";
import {Card, PageHeadings, Table} from "@frostbyte-technologies/frostbyte-tailwind";
import LoadingSpinner from "../../../../components/loading-spinner";
import {Formik} from "formik";
import * as Yup from "yup";
import {PURCHASE_ORDER_STATUSES} from "../../../../features/operations/supply-chain/purchase-order/purchase-order-constants";
import {showConfirmAlert} from "../../../../utils/alert-helper";
import {
  decimalToDollars,
  toDollars,
} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import VendorItemsDropdown from "../../../../dropdowns/operations/recipes/vendor-items-dropdown";
import {showErrorNotification} from "../../../../utils/notification-helper";
import OcrModal from "../../../../modals/operations/supply-chain/purchase-orders/ocr-modal";
import VendorItemModal from "../../../../modals/operations/supply-chain/vendors/vendor-item-modal";
import {
  PurchaseOrderRequests,
  VendorRequests,
} from "../../../../utils/request-helpers/supply-chain/supply-chain-requests";
import {setupReduxConnection} from "../../../../redux";

class ReceivePurchaseOrderPage extends Component {
  state = {
    purchaseOrder: null,
  };

  async componentDidMount() {
    const {ID: uniqueId} = this.props.router.params;
    const purchaseOrder = await PurchaseOrderRequests.fetchPurchaseOrder(uniqueId);

    if (
      ![PURCHASE_ORDER_STATUSES.SENT, PURCHASE_ORDER_STATUSES.DELIVERED].includes(
        purchaseOrder.STATUS
      )
    ) {
      // TODO show error
      return;
    }

    purchaseOrder.ITEMS.forEach((item) => {
      item.AMOUNT = toDollars(item.AMOUNT, true);
      item.TOTAL = toDollars(item.TOTAL, true);
      item.AMOUNT_TAX = toDollars(item.AMOUNT_TAX, true);
      item.AMOUNT_FEES = toDollars(item.AMOUNT_FEES, true);
      item.AMOUNT_DISCOUNT = toDollars(item.AMOUNT_DISCOUNT, true);
      item.AMOUNT_REFUNDED = toDollars(item.AMOUNT_REFUNDED, true);
    });

    this.setState({purchaseOrder});
  }

  async receivePurchaseOrder(values) {
    const {purchaseOrder} = this.state;

    this.receiveButton && this.receiveButton.startLoading();

    values.items.forEach((val) => {
      val.AMOUNT = decimalToDollars(val.AMOUNT);
      val.AMOUNT_TAX = decimalToDollars(val.AMOUNT_TAX);
      val.TOTAL = decimalToDollars(val.TOTAL);
      val.AMOUNT_DISCOUNT = decimalToDollars(val.AMOUNT_DISCOUNT);
      val.AMOUNT_REFUNDED = decimalToDollars(val.AMOUNT_REFUNDED);
      val.AMOUNT_FEES = decimalToDollars(val.AMOUNT_FEES);
    });

    try {
      await showConfirmAlert(
        "Receive Purchase Order?",
        "Are you sure you want to mark this purchase order as received? " +
          "Make sure all of the prices and quantities are correct before marking as received."
      );
    } catch (e) {
      this.receiveButton && this.receiveButton.stopLoading();
      return;
    }

    try {
      await PurchaseOrderRequests.receivePurchaseOrder(purchaseOrder.UNIQUE_ID, {
        ITEMS: values.items,
      });

      this.props.router.navigate("/purchase-order/" + purchaseOrder.UNIQUE_ID);
    } catch (e) {
      showErrorNotification(
        "Error receiving purchase order.",
        "There was an error receiving this purchase order. Please try again."
      );
    }
  }

  removeItem(item, items, removeAll = false) {
    const newCart = [...items];

    const existingIdx = items.findIndex((_cartItem) => _cartItem.ID === item.ID);

    if (existingIdx !== -1) {
      const cartItem = newCart[existingIdx];

      if (cartItem.QUANTITY === 1 || removeAll) {
        newCart.splice(existingIdx, 1);
      } else {
        newCart[existingIdx].QUANTITY -= 1;
      }
    }

    return newCart;
  }

  fetchScannedLineItemAmount(lineItem, quantity) {
    return (
      lineItem.UNIT_PRICE?.value.replace(/[^0-9.]/g, "") ??
      toDollars(
        decimalToDollars(lineItem.PRICE?.value.replace(/[^0-9.]/g, "")) /
          parseFloat(quantity),
        true
      )
    );
  }

  fetchScannedLineItemQuantity(lineItem) {
    return lineItem.QUANTITY?.value.replace(/[^0-9.]/g, "") ?? "1";
  }

  fetchVendorVendorItems() {
    const {vendors} = this.props.supply;
    const {purchaseOrder} = this.state;
    return vendors.find((vendor) => purchaseOrder.VENDOR_ID === vendor.ID).ITEMS;
  }

  fetchScannedProductVendorItem(lineItem) {
    const vendorItems = this.fetchVendorVendorItems();
    const sku = lineItem.PRODUCT_CODE?.value;

    if (!sku) {
      return null;
    }

    return vendorItems.find((vi) => vi.PRODUCT_SKU + "" === sku + "");
  }

  fetchScannedLineItemProductInformation(lineItem) {
    const vendorItem = this.fetchScannedProductVendorItem(lineItem);
    const sku = lineItem.PRODUCT_CODE?.value;
    const objectId = vendorItem?.UNIQUE_ID;
    const name = vendorItem?.NAME;

    return {sku, objectId, name};
  }

  transformScannedLineItems(items) {
    return items.map((lineItem) => {
      const quantity = this.fetchScannedLineItemQuantity(lineItem);
      const amount = this.fetchScannedLineItemAmount(lineItem, quantity);
      const {sku, objectId, name} = this.fetchScannedLineItemProductInformation(lineItem);

      return {
        QUANTITY: parseFloat(quantity),
        AMOUNT: amount,
        NAME: name ?? lineItem.NAME?.value ?? lineItem.ITEM?.value,
        TOTAL: toDollars(decimalToDollars(amount) * parseFloat(quantity), true),
        AMOUNT_DISCOUNT: "$0.00",
        AMOUNT_FEES: "$0.00",
        AMOUNT_REFUNDED: "$0.00",
        AMOUNT_TAX: "$0.00",
        PRODUCT_SKU: sku,
        TYPE: "CUSTOM_ITEM",
        OBJECT_ID: objectId,
      };
    });
  }

  async scanInvoice() {
    const invoiceData = await this.ocrModal.open();
    const lines = this.transformScannedLineItems(invoiceData.LINE_ITEMS);
    this.formikRef.setFieldValue("items", lines);
  }

  addItem(formikOptions) {
    const {values, setFieldValue} = formikOptions;

    values.items.push({
      AMOUNT: "$0.00",
      AMOUNT_DISCOUNT: "$0.00",
      AMOUNT_FEES: "$0.00",
      AMOUNT_REFUNDED: "$0.00",
      AMOUNT_TAX: "$0.00",
      TOTAL: "$0.00",
      NAME: "",
      PRODUCT_SKU: null,
      QUANTITY: 1,
      TYPE: "CUSTOM_ITEM",
    });

    setFieldValue("items", values.items);
  }

  fetchAddItemActionText(formikOptions) {
    return {
      label: "Add Vendor Item",
      onClick: () => this.addItem(formikOptions),
    };
  }

  fetchItemsTableActionTexts(formikOptions) {
    return [this.fetchAddItemActionText(formikOptions)];
  }

  updateVendorItemProductSku(vendorItem, item) {
    const {label, id} = vendorItem;

    showConfirmAlert(
      "Add SKU to vendor Item?",
      `Would you like to set ${item.PRODUCT_SKU} as ${label}'s SKU?`
    )
      .then(() => {
        return VendorRequests.updateVendorItem(vendorItem.VENDOR_ID, id, {
          PRODUCT_SKU: item.PRODUCT_SKU,
        });
      })
      .catch(() => {});
  }

  onVendorItemChange(formikOptions, row, vendorItem) {
    const {values, setFieldValue} = formikOptions;
    const {label, id} = vendorItem;

    const item = values.items.find((_item) => _item.OBJECT_ID === row.OBJECT_ID);

    if (item.PRODUCT_SKU && vendorItem.item.PRODUCT_SKU !== row.PRODUCT_SKU) {
      this.updateVendorItemProductSku(vendorItem, item);
    }

    item.OBJECT_ID = id;
    item.NAME = label;
    setFieldValue("items", values.items);
  }

  async onVendorItemCreate(formikOptions, row, name) {
    const {setFieldValue, values} = formikOptions;
    const {items} = values;

    const item = await this.vendorItemModal.open(null, {
      NAME: name,
      PRODUCT_SKU: row.PRODUCT_SKU,
    });

    row.OBJECT_ID = item.UNIQUE_ID;
    setFieldValue("items", items);
  }

  formatVendorItemColumn(row, formikOptions) {
    const {purchaseOrder} = this.state;

    return (
      <VendorItemsDropdown
        value={row.OBJECT_ID}
        unique
        vendorId={purchaseOrder.VENDOR_ID}
        onCreate={(name) => this.onVendorItemCreate(formikOptions, row, name)}
        createLabel="Create New"
        onChange={(_, _vendorItem) => {
          return this.onVendorItemChange(formikOptions, row, _vendorItem);
        }}
      />
    );
  }

  fetchVendorItemColumn(formikOptions) {
    return {
      label: "Vendor Item",
      value: "OBJECT_ID",
      format: (_, row) => {
        return this.formatVendorItemColumn(row, formikOptions);
      },
      sortable: true,
    };
  }

  fetchProductNumberColumn() {
    return {
      label: "SKU",
      value: "PRODUCT_SKU",
    };
  }

  fetchQuantityColumn(formikOptions) {
    const {setFieldValue, values} = formikOptions;

    return {
      label: "Quantity",
      value: "QUANTITY",
      editable: true,
      onChange: (idx, val) => {
        const item = values.items[idx];
        item.QUANTITY = parseFloat(val);

        setFieldValue("items", values.items);
      },
    };
  }

  fetchPriceColumn(formikOptions) {
    const {setFieldValue, values} = formikOptions;

    return {
      label: "Cost Per Unit",
      value: "AMOUNT",
      editable: true,
      onChange: (idx, val) => {
        const item = values.items[idx];

        item.TOTAL = toDollars(
          decimalToDollars(val) + decimalToDollars(item.AMOUNT_TAX),
          true
        );

        item.AMOUNT = toDollars(decimalToDollars(val), true);

        setFieldValue("items", values.items);
      },
      sortable: true,
    };
  }

  fetchTaxColumn(formikOptions) {
    const {setFieldValue, values} = formikOptions;

    return {
      label: "Tax",
      value: "AMOUNT_TAX",
      editable: true,
      onChange: (idx, val) => {
        const item = values.items[idx];

        item.TOTAL = toDollars(
          decimalToDollars(item.AMOUNT) + decimalToDollars(val),
          true
        );

        item.AMOUNT_TAX = toDollars(decimalToDollars(val), true);

        setFieldValue("items", values.items);
      },
      sortable: true,
    };
  }

  fetchTotalColumn(formikOptions) {
    return {
      label: "Total Cost",
      value: "TOTAL",
      sortable: true,
    };
  }

  renderDeleteButton(formikOptions, row) {
    const {setFieldValue, values} = formikOptions;
    const {items} = values;

    return (
      <button
        type="button"
        className="text-indigo-600 hover:text-indigo-900 cursor-pointer"
        onClick={() => {
          const itemIndex = items.findIndex((item) => item.tid === row.tid);
          items.splice(itemIndex, 1);
          setFieldValue("items", values.items);
        }}
      >
        Delete
      </button>
    );
  }

  fetchDeleteButtonColumn(formikOptions) {
    return {
      label: "",
      value: "ID",
      format: (id, row) => {
        if (!id) {
          return this.renderDeleteButton(formikOptions, row);
        }

        return <div />;
      },
    };
  }

  fetchItemsTableColumns(formikOptions) {
    return [
      this.fetchVendorItemColumn(formikOptions),
      this.fetchProductNumberColumn(formikOptions),
      this.fetchQuantityColumn(formikOptions),
      this.fetchPriceColumn(formikOptions),
      this.fetchTaxColumn(formikOptions),
      this.fetchTotalColumn(formikOptions),
      this.fetchDeleteButtonColumn(formikOptions),
    ];
  }

  renderPurchaseOrderItemsTable(formikOptions) {
    const {values} = formikOptions;
    const {items} = values;

    return (
      <Table
        hideBorder
        className="mt-1"
        actionTexts={this.fetchItemsTableActionTexts(formikOptions)}
        data={items}
        columns={this.fetchItemsTableColumns(formikOptions)}
        pagination
      />
    );
  }

  fetchScanInvoiceButton() {
    return {
      label: "Scan",
      ref: (e) => (this.receiveButton = e),
      onClick: () => this.scanInvoice(),
    };
  }

  fetchReceiveInvoiceButton(formikOptions) {
    const {handleSubmit} = formikOptions;

    return {
      label: "Receive",
      ref: (e) => (this.receiveButton = e),
      onClick: handleSubmit,
    };
  }

  fetchReceivePurchaseOrdersButtons(formikOptions) {
    return [this.fetchScanInvoiceButton(), this.fetchReceiveInvoiceButton(formikOptions)];
  }

  renderFormBody(formikOptions) {
    const {handleSubmit} = formikOptions;

    return (
      <form onSubmit={handleSubmit}>
        <Card
          label="Receive Purchase Order"
          buttons={this.fetchReceivePurchaseOrdersButtons(formikOptions)}
        >
          {this.renderPurchaseOrderItemsTable(formikOptions)}
        </Card>
      </form>
    );
  }

  fetchInitialValues() {
    const {purchaseOrder} = this.state;

    return {
      items: purchaseOrder?.ITEMS ?? [],
      notes: "",
      invoiceNumber: null,
      invoiceDate: purchaseOrder?.DATE_DELIVERED,
    };
  }

  fetchValidationSchema() {
    return Yup.object({
      notes: Yup.string().nullable(),
      invoiceNumber: Yup.number().nullable(),
      invoiceDate: Yup.number().nullable(),
      items: Yup.array()
        .of(
          Yup.object({
            AMOUNT: Yup.string()
              .nullable()
              .required("Please enter a price for each item."),
            AMOUNT_DISCOUNT: Yup.string().nullable(),
            AMOUNT_FEES: Yup.string().nullable(),
            AMOUNT_REFUNDED: Yup.string().nullable(),
            AMOUNT_TAX: Yup.string().nullable(),
            TOTAL: Yup.string().nullable().required("Please enter in the total cost."),
            NAME: Yup.string().nullable(),
            OBJECT_ID: Yup.string().nullable().required("Please select an item."),
            QUANTITY: Yup.number().nullable().required("Please enter in a quantity."),
          })
        )
        .required("Lines are required"),
    });
  }

  renderForm() {
    // TODO add notes, discounts, taxes, document date, upload invoice picture

    return (
      <Formik
        onSubmit={(values) => this.receivePurchaseOrder(values)}
        innerRef={(e) => (this.formikRef = e)}
        validationSchema={this.fetchValidationSchema()}
        initialValues={this.fetchInitialValues()}
      >
        {(formikOptions) => {
          return this.renderFormBody(formikOptions);
        }}
      </Formik>
    );
  }

  renderPageHeading() {
    return (
      <PageHeadings
        className="py-4"
        label="Receive Purchase Order"
        description="Confirm the amounts and prices of the purchase order"
      />
    );
  }

  includeModals() {
    const {purchaseOrder} = this.state;

    return (
      <>
        <OcrModal ref={(e) => (this.ocrModal = e)} />

        <VendorItemModal
          ref={(e) => (this.vendorItemModal = e)}
          vendor={{ID: purchaseOrder.VENDOR_ID}}
        />
      </>
    );
  }

  render() {
    const {purchaseOrder} = this.state;

    if (!purchaseOrder) {
      return <LoadingSpinner />;
    }

    return (
      <div>
        {this.includeModals()}
        {this.renderPageHeading()}
        {this.renderForm()}
      </div>
    );
  }
}

export default setupReduxConnection(["supply"])(withRouter(ReceivePurchaseOrderPage));
