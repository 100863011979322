import React, {Component} from "react";
import {FormSelect, Modal} from "@frostbyte-technologies/frostbyte-tailwind";
import {Formik} from "formik";
import {toDollars} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import * as Yup from "yup";
import {showErrorAlert} from "../../utils/alert-helper";
import {request} from "../../utils/request";
import {showSuccessNotification} from "../../utils/notification-helper";
import {setupReduxConnection} from "../../redux";

class PayrollRateSelectModal extends Component {
  state = {
    checkEmployeeId: null,
    employeeId: null,
    rates: [],
    employeeName: null,
  };

  open(checkEmployeeId, employeeId, rates, employeeName) {
    this.setState(
      {
        checkEmployeeId,
        employeeId,
        rates,
        employeeName,
      },
      () => {
        this.modal.open();
      }
    );
  }

  handleSubmit = async (values) => {
    const {rate} = values;
    const {employeeId, rates} = this.state;
    const {breakdown} = this.props;

    try {
      await request("pay/rates/employee/" + employeeId + "/default", "POST", {
        EMPLOYEE_RATE_ID: rate,
      });
    } catch (err) {
      showErrorAlert(
        "Error Setting Employee Rate",
        "Encountered an error setting a default PTO rate. Please try again or contact support."
      );

      return this.modal.close();
    }

    showSuccessNotification("", "Set Employee Rate Success");

    const toFindRate = rates.find(({EMPLOYEE_RATE_ID}) => EMPLOYEE_RATE_ID === rate);

    if (this.props.updateBreakdown) {
      const employeeBreakdown = breakdown.find(({ID}) => ID === employeeId);

      employeeBreakdown.DEFAULT_RATE = toFindRate.AMOUNT;

      this.props.updateBreakdown(breakdown);
    }

    return this.modal.close();
  };

  render() {
    let {employeeName, rates} = this.state;

    const rateData = rates?.map(({EMPLOYEE_RATE_ID, NAME, AMOUNT}) => ({
      value: EMPLOYEE_RATE_ID,
      label: NAME + " ($" + toDollars(AMOUNT) + ")",
    }));

    return (
      <Modal
        label={`PTO Pay for ${employeeName}`}
        buttonLabel={"Save"}
        ref={(e) => (this.modal = e)}
        formikOnClick={() => this.formikRef}
      >
        <Formik
          initialValues={{
            rate: rates?.length > 0 ? rateData[0].value : null,
          }}
          onSubmit={this.handleSubmit}
          innerRef={(e) => (this.formikRef = e)}
          validationSchema={Yup.object({
            rate: Yup.number().required("Pay rate is required"),
          })}
        >
          {(formikOptions) => {
            const {handleSubmit} = formikOptions;

            return (
              <form onSubmit={handleSubmit}>
                <FormSelect
                  label={`Select Default Rate for ${employeeName}'s PTO`}
                  name={"rate"}
                  data={rateData ?? []}
                  options={formikOptions}
                  tooltip={`This pay rate will become the default rate used for future PTO. Once set, you can edit this rate on the pay rates tab of the employee's profile.`}
                />
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

export default setupReduxConnection(["payroll"])(PayrollRateSelectModal);
