import React, {Component} from "react";
import {
    asyncTimeout,
    decimalToDollars,
    parseIdDict,
    toDollars
} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import {request} from "../../../../utils/request";
import {showConfirmAlert, showLoadingConfirmAlert} from "../../../../utils/alert-helper";
import {showErrorNotification, showSuccessNotification} from "../../../../utils/notification-helper";
import {FormBoolean, FormInput, FormSelect, Modal} from "@frostbyte-technologies/frostbyte-tailwind";
import {Formik} from "formik";
import * as Yup from "yup";
import Banner from "../../../../components/banner";
import {MODIFIER_TYPE_FIELDS} from "../../../../utils/product-constants";
import FormRow from "../../../../components/form-row";
import ModifierOptions from "../../../../features/sales/modifier-options";
import PropTypes from "prop-types";
import {setupReduxConnection} from "../../../../redux";
import RecipeModifierOptions from "./RecipeModifierOptions";

class Div extends Component {
    render() {
        return <div>{this.props.children}</div>;
    }
}

class RecipeModifierInformation extends Component {
    state = {
        tableInvalid: false,
        rawModifier: null,
        modifier: null,
        product: null,
        invalid: true,
        create: false,
    };

    componentDidMount() {
        const {modal, modifier = null, product = null} = this.props;

        if (modal) return;

        this.setupModifier(modifier, product);
    }

    open(modifier = null, product = null, create = false) {
        this.setState({create}, () => {
            this.setupModifier(modifier, product);
        });
    }

    setupModifier(modifier, product) {
        const modifierClone = JSON.parse(JSON.stringify(modifier));
        const {modal} = this.props;

        const modifierDefaults = {};
        if (product?.DEFAULTS) {
            for (let modDefault of product?.DEFAULTS) {
                modifierDefaults[modDefault.OPTION_ID] = modDefault;
            }
        }

        for (let option of modifierClone.OPTIONS) {
            option.DEFAULT = modifierDefaults[option.ID] ? "1" : "0";
            option.DEFAULT_OBJECT = modifierDefaults[option.ID];
            option.PRICE = toDollars(option.PRICE);
            option.ESTIMATED_COST = toDollars(option.ESTIMATED_COST)
        }

        this.setState({product, modifier: modifierClone}, () => {
            this.formikRef.resetForm();

            if (modifier.ID === null) {
                this.formikRef.setTouched({});
            }

            if (modal) {
                this.slide && this.slide.open();
            }
        });
    }

    async outsideValidate() {
        const val = await this.formikRef.validateForm();
        const {tableInvalid} = this.state;

        if (Object.keys(val).length === 0 && !tableInvalid) {
            return true;
        }

        for (let item of Object.keys(this.formikRef.values)) {
            this.formikRef.setFieldTouched(item, true);
        }

        return false;
    }

    outsideSave() {
        return this.formikRef.submitForm();
    }

    outsideFetch() {
        const {modifier, product} = this.state;
        let {
            name,
            type,
            max,
            min,
            tag,
            enabled,
            required,
            dependencies,
            defaultOption: defaultOptions,
        } = this.formikRef.values;

        const modifierClone = JSON.parse(JSON.stringify(modifier));

        if (isNaN(defaultOptions) && !("" + defaultOptions).startsWith("tid")) {
            defaultOptions = null;
        }

        const defaultObj = modifierClone.OPTIONS.find((item) => {
            return (item.ID || item.tid) === defaultOptions;
        });

        if (!defaultObj) {
            defaultOptions = null;
        }

        modifierClone.OPTIONS.forEach((item) => {
            item.PRICE = decimalToDollars("" + item.PRICE);
            item.ESTIMATED_COST = decimalToDollars("" + item.ESTIMATED_COST);
        });

        let seq = 0;
        if (modifierClone.ID === null) {
            if (product) {
                const customizations = product.CUSTOMIZATIONS.map((item) => item.SEQ || 0);

                seq =
                    customizations && customizations.length > 0
                        ? Math.max(...customizations) + 1
                        : 0;
            }
        }

        const modifierPayload = {
            ...modifierClone,
            NAME: name,
            TYPE: type,
            MAX_SELECTIONS: parseInt(max),
            MIN_SELECTIONS: parseInt(min),
            DEFAULT_OPTION: defaultOptions,
            INTERNAL_NAME: tag,
            ENABLED: enabled,
            REQUIRED: required,
            PRODUCT_ID: product?.ID,
            SEQ: seq,
        };

        modifierPayload.DEPENDENCIES = dependencies.map((item) => {
            return {
                OPTION_ID: item,
                PRODUCT_ID: product.ID,
            };
        });

        return modifierPayload;
    }

    async saveModifier({
                           name,
                           type,
                           min,
                           max,
                           tag,
                           enabled,
                           required,
                           dependencies,
                           price,
                           cost,
                           defaultOption: defaultOptions,
                       }) {
        const {updateState, preset} = this.props;
        const {modifier, product} = this.state;
        const {location} = this.props.shop;

        const modifierClone = JSON.parse(JSON.stringify(modifier));

        if (isNaN(defaultOptions) && !("" + defaultOptions).startsWith("tid")) {
            defaultOptions = null;
        }

        const defaultObj = modifierClone.OPTIONS.find((item) => {
            return (item.ID || item.tid) === defaultOptions;
        });

        if (!defaultObj) {
            defaultOptions = null;
        }

        modifierClone.OPTIONS.forEach((item) => {
            item.PRICE = decimalToDollars("" + item.PRICE);
            item.ESTIMATED_COST= decimalToDollars("" + item.ESTIMATED_COST);
        });

        if (parseInt("" + type) === 4 && price !== null) {
            modifierClone.OPTIONS.forEach((option) => {
                option.PRICE = decimalToDollars("" + price);
                option.ESTIMATED_COST = decimalToDollars("" + cost);
            });
        }

        if (modifierClone.ID === null) {
            let seq = 0;
            if (product) {
                const customizations = product.CUSTOMIZATIONS.map((item) => item.SEQ || 0);

                seq =
                    customizations && customizations.length > 0
                        ? Math.max(...customizations) + 1
                        : 0;
            }

            const modifierPayload = {
                ...modifierClone,
                NAME: name,
                TYPE: type,
                MAX_SELECTIONS: parseInt(max),
                MIN_SELECTIONS: parseInt(min),
                DEFAULT_OPTION: defaultOptions,
                INTERNAL_NAME: tag,
                ENABLED: enabled,
                REQUIRED: required,
                PRODUCT_ID: product?.ID,
                DEPENDENCIES: [],
                SEQ: seq,
            };

            modifierPayload.DEPENDENCIES = dependencies.map((item) => {
                return {
                    OPTION_ID: item,
                    PRODUCT_ID: product.ID,
                };
            });

            let modifier = await request("modifiers", "POST", modifierPayload);

            for (let option of modifier.OPTIONS) {
                option.PRICE = toDollars(option.PRICE);
                option.ESTIMATED_COST = toDollars(option.ESTIMATED_COST);
            }

            this.setState({modifier}, () => {
                this.props.addState(modifier);
            });

            return this.slide && this.slide.close && this.slide.close();
        }

        const modifierPayload = {
            ...modifierClone,
            NAME: name,
            TYPE: type,
            MAX_SELECTIONS: parseInt(max),
            MIN_SELECTIONS: parseInt(min),
            DEFAULT_OPTION: defaultOptions,
            INTERNAL_NAME: tag,
            ENABLED: enabled,
            REQUIRED: required,
        };

        let serverModifier;

        if (modifierPayload.PRODUCT_ID) {
            modifierPayload.DEPENDENCIES = dependencies.map((item) => {
                return {
                    OPTION_ID: item,
                    PRODUCT_ID: product.ID,
                };
            });
        }

        serverModifier = await request(
            "modifiers/" + modifierClone.ID,
            "PATCH",
            modifierPayload
        );

        if (modifierPayload.PRESET_ID) {
            modifierPayload.DEPENDENCIES = dependencies.map((item) => {
                return {
                    OPTION_ID: item,
                    PRESET_ID: modifier.PRESET_ID,
                    PRODUCT_ID: product.ID,
                };
            });

            serverModifier.DEPENDENCIES = await request(
                "modifiers/presets/" + modifier.PRESET_ID + "/dependencies",
                "PATCH",
                {DEPENDENCIES: modifierPayload.DEPENDENCIES}
            );
        }

        if (modifierClone.PRESET_ID) {
            serverModifier.PRESET_ID = modifierClone.PRESET_ID;
        }

        updateState && updateState(serverModifier.ID, serverModifier);
        this.slide && this.slide.close && this.slide.close();

        await asyncTimeout(500);

        if (serverModifier.SYNC_ID && serverModifier.LOCATIONS?.length > 1) {
            const filteredLocations = serverModifier.LOCATIONS.filter(
                ({LOCATION_ID}) => LOCATION_ID !== location.ID
            );

            const locationsDict = parseIdDict(filteredLocations, "LOCATION_ID");
            const locations = filteredLocations.map((l) => l.LOCATION_ID);

            let locationsString = locations
                .slice(0, locations.length - 1)
                .map((id) => locationsDict[id].LOCATION_NAME)
                .join(", ");

            locationsString +=
                filteredLocations.length > 1
                    ? " and " + locationsDict[locations[locations.length - 1]].LOCATION_NAME
                    : locationsDict[locations[locations.length - 1]].LOCATION_NAME;

            showConfirmAlert(
                "Would you like to sync this modifier?",
                "Would you like to sync this modifier to " + locationsString + "?"
            )
                .then((data) => {
                    const syncPayload = {
                        LOCATIONS: locations,
                        MODIFIERS: [serverModifier.ID],
                    };

                    return request("sync/modifiers", "POST", syncPayload);
                })
                .then(() => {
                    showSuccessNotification(
                        "Product synced successfully.",
                        serverModifier.NAME + " was successfully synced to " + locationsString
                    );
                })
                .catch((e) => {
                    if (e?.error === "MENU_SYNC_IN_PROGRESS") {
                        showErrorNotification(
                            "Menu Sync in Progress.",
                            "There is already a menu sync in progress for this company. Please wait for it to finish before starting another."
                        );
                    }
                });
        }
    }

    async removeModifier() {
        const {product} = this.props;
        const {modifier} = this.state;

        let deleteLabel = "Delete Modifier";
        let deleteDescription = "Are you sure you want to delete this modifier?";

        if (modifier.PRESET_ID) {
            deleteLabel = "Remove Pre-Made Modifier";
            deleteDescription =
                "Are you sure you want to remove this pre-made modifier from this product?";
        } else if (modifier.PRESET) {
            deleteLabel = "Delete Pre-Made Modifier";
            deleteDescription =
                "Are you sure you want to delete this pre-made modifier? This will delete it from every product it's attached to";
        }

        showLoadingConfirmAlert(deleteLabel, deleteDescription)
            .then(async (close) => {
                try {
                    if (modifier.PRESET_ID) {
                        await request(`preset/${modifier.ID}/${product.ID}/remove`, "POST", {});
                    } else if (modifier.PRESET) {
                        await request("customization/" + modifier.ID + "/preset", "DELETE", {});
                    } else {
                        await request("customization/" + modifier.ID, "DELETE", {});
                    }

                    this.slide && this.slide.close && this.slide.close();
                    this.props.updateState && this.props.updateState(modifier.ID);

                    showSuccessNotification("Modifier Deleted", "The modifier has been deleted");
                } catch (e) {
                } finally {
                    close();
                }
            })
            .catch((e) => {
            });
    }

    render() {
        const {modal, variant, updateState, globalMod} = this.props;
        const {modifier, product, tableInvalid} = this.state;

        let ContainerComponent = Div;

        if (modal) {
            ContainerComponent = Modal;
        }

        const modifiers = product?.CUSTOMIZATIONS ?? [];

        return (
            <Formik
                enableReinitialize
                onSubmit={this.saveModifier.bind(this)}
                innerRef={(e) => (this.formikRef = e)}
                validationSchema={Yup.object({
                    name: Yup.string().required("Modifier name is required"),
                    type: Yup.number().required("Modifier type is required"),
                })}
                initialValues={{
                    name: modifier?.NAME,
                    type: modifier?.TYPE,
                    defaultOption: modifier?.DEFAULT_OPTION,
                    tag: modifier?.INTERNAL_NAME,
                    enabled: modifier?.ENABLED,
                    required: modifier?.REQUIRED,
                    min: modifier?.MIN_SELECTIONS,
                    max: modifier?.MAX_SELECTIONS,
                    dependencies: modifier?.DEPENDENCIES?.map((item) => item.OPTION_ID) ?? [],
                    modifier: modifier?.OPTIONS.some((item) => item.MODIFIER) ? "1" : "0",
                    price:
                        modifier?.OPTIONS && modifier?.OPTIONS[0]
                            ? modifier.OPTIONS[0].PRICE
                            : toDollars(0),
                    cost: modifier?.OPTIONS && modifier?.OPTIONS[0]
                        ? modifier.OPTIONS[0].ESTIMATED_COST
                        : toDollars(0),
                }}
            >
                {(formikOptions) => {
                    const {values, isValid, handleSubmit, setFieldValue} = formikOptions;

                    return (
                        <ContainerComponent
                            large
                            tooltip={{
                                label: "Modifiers",
                                data: "A modifier is an option that can be added as a modification to a product. Modifiers added to products will appear as selectable options on the POS, order and mobile apps.",
                            }}
                            buttonLabel="Save"
                            label="Edit Modifier"
                            description="Create a modifier that can be applied to menu items"
                            ref={(e) => (this.slide = e)}
                            buttonDisabled={tableInvalid || !isValid}
                            buttonOnClick={handleSubmit}
                            deleteLabel={
                                modifier?.ID ? (modifier.PRESET_ID ? "Remove Modifier" : "Delete") : null
                            }
                            deleteOnClick={this.removeModifier.bind(this)}
                        >
                            {modifier && (
                                <div>
                                    {modifier?.PRESET_ID && (
                                        <Banner
                                            className="mt-4"
                                            label="This modifier is a preset"
                                            description="This modifier is used across multiple products and editing the modifier here will also edit it on other products. The only thing that will not sync across is defaults modifier fields."
                                        />
                                    )}

                                    <>
                                        <FormInput
                                            label="Name"
                                            name="name"
                                            placeholder="Modifier name"
                                            options={formikOptions}
                                            tooltip={{
                                                data: 'The name of the modifier that will be displayed (e.g. "Size")',
                                                label: "Name",
                                            }}
                                            onChangeSoft={(value) => {
                                                modifier.NAME = value;
                                                this.setState({modifier});
                                            }}
                                        />

                                        <FormInput
                                            className="mt-2"
                                            placeholder="Internal tag"
                                            label="Internal Tag"
                                            hint="Optional"
                                            name="tag"
                                            options={formikOptions}
                                            tooltip={{
                                                label: "Internal Tag",
                                                data: "If two modifiers have the same name, this is used to uniquely identify them. This name is not displayed on the POS.",
                                            }}
                                            onChangeSoft={(value) => {
                                                modifier.INTERNAL_NAME = value;
                                                this.setState({modifier});
                                            }}
                                        />

                                        {parseInt(modifier.TYPE) !== 5 && (
                                            <FormSelect
                                                name="type"
                                                className="mt-2"
                                                secondaryBlock
                                                label="Modifier Type"
                                                options={formikOptions}
                                                tooltip={{
                                                    data: [
                                                        {
                                                            label: "Modifier Type",
                                                            data: "The type of add-on to a product.",
                                                        },
                                                        {
                                                            label: "Single Select",
                                                            data: "With this modifier type, you can only select one option within the modifier group.",
                                                        },
                                                        {
                                                            label: "Single Select w/ Quantity",
                                                            data: "With this modifier type, you can select multiple quantities of a single option within the modifier group.",
                                                        },
                                                        {
                                                            label: "Multi-Select",
                                                            data: "With this modifier type, you can select multiple options.",
                                                        },
                                                        {
                                                            label: "Multi-Select w/ Quantity",
                                                            data: "With this modifier type, you can select multiple quantities of one or more options.",
                                                        },
                                                        {
                                                            label: "Multi-Select w/ Fixed Price",
                                                            data: "With this modifier type, you can select as many options as you'd like for one fixed price.",
                                                        },
                                                    ],
                                                }}
                                                data={MODIFIER_TYPE_FIELDS}
                                            />
                                        )}

                                        {parseInt(values.type) === 4 && (
                                            <FormInput
                                                name="price"
                                                className="mt-2"
                                                secondaryBlock
                                                tooltip="The fixed price of this modifier."
                                                label="Modifier Price"
                                                options={formikOptions}
                                            />
                                        )}

                                        {[2, 3, 4].includes(values.type) && (
                                            <FormRow>
                                                <FormInput
                                                    label="Minimum Selections"
                                                    options={formikOptions}
                                                    tooltip="The minimum amount of options a customer needs to select. Set as 0 if disabled."
                                                    name="min"
                                                    flex
                                                />

                                                <FormInput
                                                    label="Maximum Selections"
                                                    options={formikOptions}
                                                    tooltip="The maximum amount of options a customer needs to select. Set as 0 if disabled."
                                                    name="max"
                                                    flex
                                                />
                                            </FormRow>
                                        )}

                                        {parseInt(modifier.TYPE) === 5 && (
                                            <FormBoolean
                                                secondaryBlock
                                                name="modifier"
                                                className="mt-2"
                                                options={formikOptions}
                                                tooltip="A modifier multiple will have the modifiers of this product multiplied by the amount inputted"
                                                label="Modifier Multiples"
                                                onChangeSoft={({value}) => {
                                                    if (value === "1") {
                                                        modifier.OPTIONS.forEach((item) => {
                                                            item.MODIFIER = "1";
                                                        });
                                                    } else if (value === "0") {
                                                        modifier.OPTIONS.forEach((item) => {
                                                            item.MODIFIER = null;
                                                        });
                                                    }

                                                    this.setState({modifier});
                                                }}
                                            />
                                        )}

                                        {values.type < 2 || values.type === 5 ? (
                                            <FormSelect
                                                name="defaultOption"
                                                className="mt-2"
                                                options={formikOptions}
                                                label="Default Option"
                                                data={[
                                                    {value: null, label: "No Default Option"},
                                                    ...modifier.OPTIONS.map((item) => {
                                                        return {
                                                            value: item.ID || item.tid,
                                                            label: item.NAME,
                                                        };
                                                    }),
                                                ]}
                                            />
                                        ) : (
                                            <div/>
                                        )}

                                        {!variant && (
                                            <>
                                                <FormRow>
                                                    <FormBoolean
                                                        flex
                                                        name="required"
                                                        className="mt-2"
                                                        secondaryBlock
                                                        label="Required"
                                                        options={formikOptions}
                                                        tooltip={{
                                                            label: "Required",
                                                            data: "If enabled, the modifier will be required when adding this product to a cart on all platforms.",
                                                        }}
                                                    />

                                                    <FormBoolean
                                                        flex
                                                        name="enabled"
                                                        className="mt-2"
                                                        secondaryBlock
                                                        label="Enabled"
                                                        tooltip={{
                                                            label: "Enabled",
                                                            data: "If disabled, the modifier will not display on the mobile app or order website. They will still be displayed on the POS.",
                                                        }}
                                                        options={formikOptions}
                                                    />
                                                </FormRow>

                                                {![5, 6].includes(values.type) && !globalMod ? (
                                                    <>
                                                        {modifier.PRESET_ID && (
                                                            <FormSelect
                                                                multi
                                                                className="mt-2"
                                                                placeholder="No Dependencies"
                                                                tooltip="Only display this modifier if one of the dependencies is selected"
                                                                value={modifier.DEPENDENCIES.map(
                                                                    (item) => item.OPTION_ID
                                                                )}
                                                                options={formikOptions}
                                                                name="dependencies"
                                                                label="Dependencies"
                                                                data={modifiers
                                                                    .reduce((accum, item) => {
                                                                        if (item.ID === modifier.ID) {
                                                                            return accum;
                                                                        }

                                                                        return [
                                                                            ...accum,
                                                                            ...item.OPTIONS.map((option) => {
                                                                                return {
                                                                                    ...option,
                                                                                    MODIFIER_NAME: item.NAME,
                                                                                };
                                                                            }),
                                                                        ];
                                                                    }, [])
                                                                    .map((item) => {
                                                                        return {
                                                                            value: item.ID,
                                                                            label: item.MODIFIER_NAME + " - " + item.NAME,
                                                                        };
                                                                    })}
                                                            />
                                                        )}

                                                        {!modifier.PRESET_ID && (
                                                            <FormSelect
                                                                multi
                                                                name="dependencies"
                                                                className="mt-2"
                                                                label="Dependencies"
                                                                onChangeSoftMulti={(values) => {
                                                                    modifier.DEPENDENCIES = values.map((item) => {
                                                                        return {OPTION_ID: item};
                                                                    });
                                                                }}
                                                                placeholder="No Dependencies"
                                                                tooltip="Only display this modifier if one of the dependencies is selected"
                                                                options={formikOptions}
                                                                data={modifiers
                                                                    .reduce((accum, item) => {
                                                                        if (item.ID === modifier.ID) {
                                                                            return accum;
                                                                        }

                                                                        return [
                                                                            ...accum,
                                                                            ...item.OPTIONS.map((option) => {
                                                                                return {
                                                                                    ...option,
                                                                                    MODIFIER_NAME: item.NAME,
                                                                                };
                                                                            }),
                                                                        ];
                                                                    }, [])
                                                                    .map((item) => {
                                                                        return {
                                                                            value: item.ID,
                                                                            label: item.MODIFIER_NAME + " - " + item.NAME,
                                                                        };
                                                                    })}
                                                            />
                                                        )}
                                                    </>
                                                ) : (
                                                    <div/>
                                                )}
                                            </>
                                        )}

                                        <RecipeModifierOptions
                                            showModifier={values.modifier === "1"}
                                            setState={this.setState.bind(this)}
                                            modifier={modifier}
                                            product={product}
                                        />
                                    </>
                                </div>
                            )}
                        </ContainerComponent>
                    );
                }}
            </Formik>
        );
    }
}

RecipeModifierInformation.propTypes = {
    variant: PropTypes.bool,
    preset: PropTypes.bool,
};

export default setupReduxConnection(["shop"])(RecipeModifierInformation);
