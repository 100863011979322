import React, {Component} from "react";
import {setupReduxConnection} from "../../redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {toDollars} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import {withRouter} from "../../utils/navigation";
import {TIME_PERIODS} from "../../features/accounting/timespan-dropdown";
import ProfitLossBlock from "../../features/accounting/main-blocks/profit-loss-block";
import AccountingDashboardPage from "./accounting-dashboard-page";
import AccountingGroupOnboardingPage from "./accounting-group-onboarding-page";
import PayrollLandingPage from "../finance/payroll/main-pages/payroll-landing-page";
import AccountingLandingPage from "./accounting-landing-page";

class AccountingMainPage extends Component {
  render() {
    const {accountingGroup} = this.props.shop;
    const {ACCOUNTING_WHITELISTED} = this.props.shop.settings;

    if (ACCOUNTING_WHITELISTED !== "1") {
      return <AccountingLandingPage />;
    }

    if (accountingGroup?.STATUS !== "LIVE") {
      return <AccountingGroupOnboardingPage />;
    }

    return <AccountingDashboardPage />;
  }
}

export default setupReduxConnection(["shop"])(withRouter(AccountingMainPage));
