import React, {Component} from "react";
import {Formik} from "formik";
import * as Yup from "yup";
import {
  fetchValidatedPhone,
  fetchValidatedToken,
} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import {
  Button,
  FormPhone,
  FormToken,
} from "@frostbyte-technologies/frostbyte-tailwind";
import {PHONE_YUP} from "@frostbyte-technologies/frostbyte-tailwind/dist/components/input/form-phone";
import {TOKEN_YUP} from "@frostbyte-technologies/frostbyte-tailwind/dist/components/input/form-token";
import {request} from "../../utils/request";
import {setupReduxConnection} from "../../redux";
import {withRouter} from "../../utils/navigation";

class LoginPage extends Component {
  state = {open: false, unique: null, field: "", phone: null};

  submitPhone({phone}) {
    // this.buttonRef.startLoading();

    let phoneFormatted = fetchValidatedPhone(phone);

    request("login/initiate", "POST", {PHONE: phoneFormatted})
      .then(async (unique) => {
        this.setState({unique, phone: phoneFormatted});
      })
      .catch((err) => {
        if (err.error === "PHONE_UNSUBSCRIBED") {
          return this.formikPhoneRef?.setFieldError(
            "phone",
            "Phone number unsubscribed from text messages. Please text START to 1(888)-503-3747 to continue with login."
          );
        }
        return Promise.reject("SERVER_ERROR");
      });
  }

  submitEmail(phone) {
    alert("Email Sent!");

    request("login/initiate/email", "POST", {PHONE: phone})
      .then(async (unique) => {
        if (unique) {
          this.setState({unique, phone});
        }
      })
      .catch(() => {
        return Promise.reject("SERVER_ERROR");
      });
  }

  completeLogin({token}) {
    const {unique} = this.state;
    const {state} = this.props.router.location;
    // this.buttonRef.startLoading();

    request("login/complete", "POST", {
      TOKEN: fetchValidatedToken(token),
      UNIQUE: unique,
      CLIENT: {
        NAME: "ORDER.DRIPOS.COM",
        INFO: "Website",
        TYPE: 2,
      },
    })
      .then(async (payload) => {
        localStorage.setItem("TOKEN", "" + payload);

        window.location.href = state?.returnUrl ? state?.returnUrl : "/";
      })
      .catch((err) => {
        this.formikRef.setFieldError(
          "token",
          err?.message ?? "Error validating token"
        );

        this.buttonRef.stopLoading();

        return Promise.reject("SERVER_ERROR");
      });
  }

  renderForm() {
    const {field, unique} = this.state;

    if (unique) {
      return (
        <Formik
          initialValues={{token: ""}}
          innerRef={(e) => (this.formikRef = e)}
          onSubmit={this.completeLogin.bind(this)}
          validationSchema={Yup.object({
            token: TOKEN_YUP.required(
              "Authentication token is required to login"
            ),
          })}
        >
          {(formikValues) => {
            const {handleSubmit} = formikValues;

            return (
              <form className="space-y-6" onSubmit={handleSubmit}>
                <FormToken
                  label="Validation Token"
                  options={formikValues}
                  name="token"
                />

                <div>
                  {this.state.phone && (
                    <div
                      className="mb-5 cursor-pointer text-primary-text text-sm"
                      onClick={() => {
                        this.submitEmail(this.state.phone);
                      }}
                    >
                      Didn't receive token? Send via email.
                    </div>
                  )}

                  <Button
                    flex
                    type="submit"
                    label="Complete Sign-In"
                    ref={(e) => (this.buttonRef = e)}
                  />
                </div>
              </form>
            );
          }}
        </Formik>
      );
    }

    return (
      <Formik
        initialValues={{phone: ""}}
        onSubmit={this.submitPhone.bind(this)}
        validationSchema={Yup.object({
          phone: PHONE_YUP.required("Phone number is required to login"),
        })}
        innerRef={(e) => (this.formikPhoneRef = e)}
      >
        {(formikValues) => {
          const {handleSubmit} = formikValues;

          return (
            <form className="space-y-6" onSubmit={handleSubmit}>
              <FormPhone
                placeholder="781 583 3699"
                label="Phone Number"
                options={formikValues}
                innerRef={(e) => (this.phoneField = e)}
                name="phone"
              />

              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <input
                    id="remember-me"
                    name="remember-me"
                    type="checkbox"
                    className="h-4 w-4 text-primary-text focus:ring-primary-border border-neutral-border rounded"
                  />
                  <label
                    htmlFor="remember-me"
                    className="ml-2 block text-sm text-gray-900"
                  >
                    Keep me signed in?
                  </label>
                </div>

                <div className="text-sm">
                  <a
                    target="_blank"
                    href="https://support.dripos.com/"
                    className="font-medium text-primary-text hover:text-primary-hover"
                  >
                    Phone number changed?
                  </a>
                </div>
              </div>

              <div>
                <Button
                  ref={(e) => (this.buttonRef = e)}
                  type="submit"
                  label="Sign In"
                  flex
                />

                <div className="text-center text-sm font-small px-6 pt-2 text-gray-500">
                  By clicking sign in, you opt-in to get a one-time verification
                  text sent to the provided phone
                </div>
              </div>
            </form>
          );
        }}
      </Formik>
    );
  }

  render() {
    return (
      <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <img
            src={require("./../../assets/images/logo.png")}
            className="mx-auto h-12 w-auto"
            alt="Workflow"
          />

          <h2 className="mt-2 text-center text-3xl font-extrabold text-gray-900">
            Sign in to your account
          </h2>

          <p className="mt-2 text-center text-sm text-gray-600">
            Or login for the first time
          </p>
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            {this.renderForm()}
          </div>
        </div>
      </div>
    );
  }
}

export default setupReduxConnection(["user"])(withRouter(LoginPage));
