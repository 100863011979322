import React, {Component} from "react";
import {FormInput, FormSelect, Modal} from "@frostbyte-technologies/frostbyte-tailwind";
import {Formik} from "formik";
import PropTypes from "prop-types";
import {request} from "../../../utils/request";
import * as Yup from "yup";
import EmployeeFormDropZone from "../../../components/employee-form-drop-zone";

class EmployeeFileModal extends Component {
  state = {file: null, employee: null};

  open(employee, file = null) {
    this.setState({file, employee}, () => {
      this.formikRef && this.formikRef.resetForm();
      this.modal.open();
    });
  }

  handleSubmit = async (values) => {
    // submit thing to database
    const {filename, fileUpload, folder} = values;
    const {url} = fileUpload;
    const {employee, file} = this.state;
    const {syncState} = this.props;

    if (!file) {
      await request(`employees/file/${employee.ID}`, "POST", {
        FILE_NAME: filename,
        KEY: url,
        FOLDER_ID: folder,
      });
    }

    if (file) {
      await request(`employees/file/${file.URL}`, "PATCH", {
        FILE_NAME: filename,
        FOLDER_ID: folder,
      });
    }

    syncState();

    this.modal.close();
  };

  close() {
    this.modal.close();
  }

  deletePdf(file) {
    const {syncState} = this.props;
    request(`employees/file/${file.URL}`, "DELETE")
      .then(() => {
        syncState();
        this.modal.close();
      })
      .catch((err) => console.log(err));
  }

  render() {
    const {file} = this.state;
    const {folders} = this.props;

    return (
      <Modal
        tooltip={{
          data: `${
            file ? "Update a file for the employee" : "Add a new file for this employee"
          }`,
        }}
        buttonLabel={"Save"}
        label={file ? "Edit File" : "Add File"}
        ref={(e) => (this.modal = e)}
        deleteOnClick={() => this.deletePdf(file)}
        formikOnClick={() => this.formikRef}
        deleteLabel={file ? "Delete" : null}
        closeLabel={"Close"}
      >
        <Formik
          onSubmit={this.handleSubmit}
          innerRef={(e) => (this.formikRef = e)}
          initialValues={{
            fileUpload: file
              ? {
                  filename: file?.FILE_NAME,
                  url: file?.URL,
                }
              : null,
            filename: file?.FILE_NAME,
            folder: file?.FOLDER_ID,
          }}
          validationSchema={Yup.object().shape({
            fileUpload: Yup.object()
              .shape({
                filename: Yup.string().nullable().required("The file name is required."),
                url: Yup.string().nullable().required("The file url is required."),
              })
              .nullable()
              .required("Please select a file."),
            filename: Yup.string()
              .nullable()
              .test("length check", "File name is required", (val) => val?.length > 0)
              .when("fileUpload", {
                is: (e) => e,
                then: Yup.string()
                  .nullable()
                  .test({
                    name: "file name validation",
                    message: "File name is invalid.",
                    test: function (value) {
                      return value?.match(
                        /^[a-zA-Z0-9](?:[a-zA-Z0-9 ._-]*[a-zA-Z0-9])?\.[a-zA-Z0-9_-]+$/
                      );
                    },
                  })
                  .test({
                    name: "file extension validation",
                    message: "File extension must match the original file",
                    test: function (value) {
                      const {filename} = this.parent.fileUpload;
                      const filenameSplit = filename?.split(".");
                      const valueSplit = value?.split(".");

                      return (
                        valueSplit &&
                        filenameSplit &&
                        filenameSplit[filenameSplit.length - 1] ===
                          valueSplit[valueSplit.length - 1]
                      );
                    },
                  }),
              }),
          })}
        >
          {(formikOptions) => {
            const {handleSubmit, values, setFieldValue} = formikOptions;
            const {fileUpload} = values;

            return (
              <form onSubmit={handleSubmit}>
                <FormInput options={formikOptions} label="File Name" name="filename" />

                <FormSelect
                  options={formikOptions}
                  label="Tag"
                  name="folder"
                  hint={"Optional"}
                  data={folders?.map(({ID, NAME}) => ({label: NAME, value: ID} ?? []))}
                />

                {!fileUpload && (
                  <EmployeeFormDropZone
                    name={"fileUpload"}
                    onChangeSoft={(file) => {
                      setFieldValue("filename", file.name);
                      setFieldValue("fileUpload", file.fileDetails);
                    }}
                  />
                )}
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

EmployeeFileModal.propTypes = {
  onSave: PropTypes.func,
};

export default EmployeeFileModal;
