import {request} from "../../utils/request";
import {Formik} from "formik";
import React, {Component} from "react";
import ImageFormDropZone from "../../components/image-form-drop-zone";
import {FormSelect} from "@frostbyte-technologies/frostbyte-tailwind";
import {showErrorAlert} from "../../utils/alert-helper";
import {showSuccessNotification} from "../../utils/notification-helper";

class BarcodeImportPage extends Component {
  state = {locations: []};

  async componentDidMount() {
    const serverLocations = await request("employees/locations", "GET");

    this.setState({locations: serverLocations});
  }

  handleSubmit = async (values) => {
    const {location, barcodes} = values;

    try {
      await request("import/barcodes/", "POST", {
        ROWS: barcodes,
        LOCATION_ID: location,
      });
    } catch (err) {
      console.log("ERR", err);
    }

    showSuccessNotification("SUCCESS");
  };

  render() {
    const {locations} = this.state;

    const locationData = locations.map(({ID, NAME}) => ({
      value: ID,
      label: NAME,
    }));

    return (
      <div className={"mx-20"}>
        <Formik
          onSubmit={this.handleSubmit}
          innerRef={(e) => (this.formikRef = e)}
          enableReinitialize
          initialValues={{location: null}}
        >
          {(formikOptions) => {
            const {handleSubmit, values} = formikOptions;

            return (
              <form onSubmit={handleSubmit} className="mt-4">
                <FormSelect
                  options={formikOptions}
                  label="Select Location"
                  name="location"
                  data={locationData}
                />

                <ImageFormDropZone
                  name="barcodes"
                  type="CSV"
                  tooltip={{
                    label: "Import product barcodes",
                    data: "Must contain Product Name and Barcode columns",
                  }}
                  label="Import Barcodes by Product"
                  options={formikOptions}
                />

                <button
                  type="submit"
                  className="mt-4 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  Submit
                </button>
              </form>
            );
          }}
        </Formik>
      </div>
    );
  }
}

export default BarcodeImportPage;
