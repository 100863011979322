import React, {Component} from "react";
import PropTypes from "prop-types";
import {Table} from "@frostbyte-technologies/frostbyte-tailwind";
import moment from "moment/moment";
import {
  minutesToHours,
  toDollars,
} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import {setupReduxConnection} from "../../redux";
import DangerBadge from "../../components/badges/danger-badge";
import ScheduledTicketsLineItems from "../sales/tickets/ScheduledTicketsLineItems";
import TimeCardBreaks from "./time-card-breaks";
import TimeCardSummaryExpansionComponent from "../reporting/reports/time-card-summary-expansion-component";
import {fetchUnpaidBreaksTotalMinutes} from "../../utils/breaks-helper";
import TimesheetHistoryModal from "../../modals/timesheets/timesheet-history-modal";
import {saveAs} from "file-saver";
import {MILLI_MINUTE} from "../../utils/constants";

class TimeCardsTable extends Component {
  handleRefresh() {
    if (this.tableRef) {
      this.tableRef.refetch();
    }
  }

  renderClockImages(card) {
    const fileName = moment(card.DATE_START).format("M.D.YY_H.mmA_");

    return (
      <div className={"text-indigo-600 font-medium cursor-pointer"}>
        {card.CLOCK_IN_IMAGE && (
          <div
            className={"hover:text-indigo-900"}
            onClick={async (event) => {
              event.stopPropagation();
              return await saveAs(card.CLOCK_IN_IMAGE, fileName + "CLOCK_IN");
            }}
          >
            View Clock In
          </div>
        )}

        {card.CLOCK_OUT_IMAGE && (
          <div
            className={"hover:text-indigo-900"}
            onClick={async (event) => {
              event.stopPropagation();
              await saveAs(card.CLOCK_OUT_IMAGE, fileName + "CLOCK_OUT");
            }}
          >
            View Clock Out
          </div>
        )}
      </div>
    );
  }

  renderNoPayFlag(row) {
    const isNoPay = row.AMOUNT_PAYED === 0 && row.DATE_END && row.EMPLOYEE_TYPE !== "SAL";

    if (isNoPay) {
      return (
        <div>
          <DangerBadge yellow>No Pay Earned</DangerBadge>
        </div>
      );
    }

    return <></>;
  }

  renderAutoClockFlag(row) {
    const isAutoClock = moment(row.DATE_END).diff(row.DATE_START, "hours") >= 24;

    if (isAutoClock) {
      return (
        <div>
          <DangerBadge>Autoclocked Out</DangerBadge>
        </div>
      );
    }

    return <></>;
  }

  renderMobileFlag(row) {
    if (row.MOBILE === 1) {
      return (
        <div>
          <DangerBadge blue>Mobile</DangerBadge>
        </div>
      );
    }

    return <></>;
  }

  renderBreakFlag(over = false) {
    const typeVerbiage = over ? "Over" : "Under";

    return (
      <div>
        <DangerBadge>{`Break Flagged (${typeVerbiage})`}</DangerBadge>
      </div>
    );
  }

  renderBreakFlags(row) {
    const {settings} = this.props.shop;
    const {FLAG_BREAK_BEFORE, FLAG_BREAK_AFTER} = settings;
    const {BREAKS: breaks = []} = row;

    const beforeSetting = parseInt(FLAG_BREAK_BEFORE);
    const afterSetting = parseInt(FLAG_BREAK_AFTER);

    if (FLAG_BREAK_BEFORE && !isNaN(beforeSetting)) {
      const isFlagged = breaks.find(
        ({DATE_START, DATE_END, BREAK_OPTION_AMOUNT_MINUTES}) => {
          return (
            BREAK_OPTION_AMOUNT_MINUTES - (DATE_END - DATE_START) / MILLI_MINUTE >
            beforeSetting
          );
        }
      );

      if (isFlagged) {
        return this.renderBreakFlag(false);
      }
    }

    if (FLAG_BREAK_AFTER && !isNaN(afterSetting)) {
      const isFlagged = breaks.find(
        ({DATE_START, DATE_END, BREAK_OPTION_AMOUNT_MINUTES}) => {
          return (
            (DATE_END - DATE_START) / MILLI_MINUTE - BREAK_OPTION_AMOUNT_MINUTES >
            afterSetting
          );
        }
      );

      if (isFlagged) {
        return this.renderBreakFlag(true);
      }
    }

    return <></>;
  }

  render() {
    let {
      approval,
      className,
      breakOptions,
      actionButtons = null,
      employeeId = null,
    } = this.props;
    const {settings} = this.props.shop;
    const {permissions: userPermissions} = this.props.user;
    const canViewRates =
      userPermissions?.includes("ADMIN_FULL") ||
      userPermissions?.includes("PAY_RATES_FULL");
    let route = "team/time/dumb";

    if (!actionButtons) {
      actionButtons = [
        {
          label: "View Activity",
          onClick: (row, index, event) => {
            event.stopPropagation();
            return this.timesheetHistoryModal.open(row);
          },
        },
      ];
    }

    const columns = [
      {
        minWidth: 150,
        label: "Name",
        value: "FULL_NAME",
        format: (name, row) => (
          <div>
            <div className="text-sm font-medium text-gray-900">{name}</div>

            <div className="text-sm text-gray-500">{row.ROLE_NAME}</div>
          </div>
        ),
      },
      {
        width: 1,
        label: "Location Name",
        value: "LOCATION_NAME",
      },
      {
        width: 1,
        label: "Type",
        value: "TYPE",
        format: (name, row) => {
          return (
            <div className="flex-col">
              {this.renderNoPayFlag(row)}

              {this.renderAutoClockFlag(row)}

              {this.renderMobileFlag(row)}

              {this.renderBreakFlags(row)}
            </div>
          );
        },
      },
      {
        width: 1,
        value: "DATE_START",
        label: "Date",
        format: (start) => {
          return moment(start).format("ddd, MMM D");
        },
      },
      {
        width: 1,
        value: "DATE_START",
        label: "Time",
        format: (start, row) => {
          return `${moment(start).format("hh:mm A")} - ${
            row.DATE_END ? moment(row.DATE_END).format("hh:mm A") : "Clocked In"
          }`;
        },
      },
      {
        width: 1,
        value: "DATE_START",
        label: "Scheduled",
        format: (start, row) => {
          if (row.SHIFT_START) {
            let {SHIFT_START, SHIFT_END} = row;
            return `${moment(SHIFT_START).format("h:mm A")} - ${moment(SHIFT_END).format(
              "h:mm A"
            )}`;
          } else {
            return "No Shift";
          }
        },
      },
      {
        width: 1,
        label: "Hours Worked",
        value: "AMOUNT_MINUTES",
        format: (value, row) => (row.DATE_END ? minutesToHours(value ?? 0) : "-"),
        sortable: true,
        sortFunction: (a, b) => {
          return (a?.AMOUNT_MINUTES ?? 0) - (b?.AMOUNT_MINUTES ?? 0);
        },
      },
    ];
    if (canViewRates) {
      columns.splice(5, 0, {
        width: 1,
        label: "Pay Earned",
        value: "AMOUNT_PAYED",
        format: (value, row) => {
          if (row.EMPLOYEE_TYPE === "SAL") {
            return "Salaried";
          }

          return row.DATE_END ? toDollars(value, true) : "-";
        },
      });
    }

    if (breakOptions && breakOptions.length > 0) {
      columns.push(
        {
          width: 1,
          label: "Number Breaks",
          value: "BREAKS",
          format: (value, row) => (value ? value.length : 0),
        },
        {
          width: 1,
          label: "Unpaid Duration",
          value: "Unpaid_BREAK_DURATION",
          format: (value, row) => {
            return (
              <div>
                {`${
                  row.BREAKS && row.BREAKS.length > 0
                    ? fetchUnpaidBreaksTotalMinutes(row.BREAKS)
                    : 0
                } mins`}
              </div>
            );
          },
        }
      );
    }

    if (employeeId) {
      route = "team/time/dumb/" + employeeId;
    }

    if (settings.REQUIRE_TIME_CARD_PICTURE === "1") {
      columns.push({
        width: 1,
        label: "Clock Image",
        value: "CLOCK_IN_IMAGE",
        format: (value, row) => this.renderClockImages(row),
      });
    }

    return (
      <div>
        <TimesheetHistoryModal ref={(e) => (this.timesheetHistoryModal = e)} />

        <Table
          {...this.props}
          {...(!approval && {route})}
          {...(approval && {data: this.props.cards})}
          pagination
          className={className}
          onClick={this.props.onClick}
          columns={columns}
          actionButtons={actionButtons}
          rowsPerPage
          ref={(e) => (this.tableRef = e)}
        />
      </div>
    );
  }
}

TimeCardsTable.propTypes = {
  actionButtons: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default setupReduxConnection(["user", "shop"])(TimeCardsTable);
