import React, {useRef} from "react";
import {Handle, Position} from "reactflow";
import IngredientModal from "../../../../modals/operations/supply-chain/recipes/ingredient-modal";

function RecipeNode({data}) {
  const ingredientModal = useRef(null);

  return (
    <div className="px-4 py-2 shadow-md rounded-md bg-white">
      <IngredientModal ref={ingredientModal} syncState={() => data.syncState()} />

      <div className="flex" onClick={() => ingredientModal.current.open(data.ingredient)}>
        <div>
          <div className="text-md font-bold">{data.label}</div>
        </div>
      </div>

      <Handle type="target" position={Position.Top} className={"bg-indigo-600"} />

      <Handle type="source" position={Position.Bottom} className={"bg-indigo-600"} />
    </div>
  );
}

export default RecipeNode;
