import React, {Component} from "react";
import {PageHeadings} from "@frostbyte-technologies/frostbyte-tailwind";
import FixedColumnReport from "../../features/reporting/reports/fixed-column-report";
import {CELL_TYPES, LABOR_VS_SALES_COLUMNS} from "../../features/reporting/reports/reporting-constants";
import {setupReduxConnection} from "../../redux";
import {request} from "../../utils/request";
import FilterDropdown from "../../components/filter-dropdown";
import LoadingSpinner from "../../components/loading-spinner";

class LaborVsCostReportPage extends Component {
  state = {roleData: [], initialValues: [], selectedRoles: [], isLoading: true};

  componentDidMount() {
    this.syncData();
  }

  async syncData() {
    const serverRoles = await request("employeeroles/roles", "GET");
    const roleData = serverRoles.map((item) => {
      return {
        value: item.ID,
        name: item.NAME,
      };
    });
    const initialValues = serverRoles.map((item) => item.ID);
    this.setState({roleData, initialValues, isLoading: false, selectedRoles: initialValues});
  }

  convertDataToRows(reportData) {
    let rows = [];

    if (reportData) {
      let {BREAKDOWN, TOTALS} = reportData;

      rows = BREAKDOWN.map((item) => {
        return {
          label: item.TIME_LABEL,
          numbers: item,
        };
      });

      rows.push({
        label: TOTALS.TIME_LABEL,
        numbers: TOTALS,
        style: [CELL_TYPES.BOLD],
      });
    }

    return rows;
  }

  onRoleChange(values) {
    this.setState({selectedRoles: values});
  }

  render() {
    const {REPORTING_SHOW_LOCATION_CSV} = this.props.shop.settings;
    const {initialValues, roleData, selectedRoles, isLoading} = this.state;
    if (isLoading) {
      return <LoadingSpinner />;
    }
    return (
      <div>
        <div className="mb-5">
          <PageHeadings label={"Labor vs Sales Report"} />
        </div>

        <FixedColumnReport
          filenamePrefix="labor-vs-sales"
          endpoint="report/laborvssales"
          constantColumns={LABOR_VS_SALES_COLUMNS}
          convertDataToRows={this.convertDataToRows}
          hideTime={true}
          showLocationsInDownload={REPORTING_SHOW_LOCATION_CSV === "1"}
          customParams={{
            ROLES: selectedRoles,
          }}
          customFields={
            <div className={"flex-row flex"}>
              <FilterDropdown
                selectAll
                cannotBeEmpty
                initialValues={initialValues}
                enableReinitialize
                plural={"Roles"}
                initialText={"Roles"}
                data={roleData}
                defaultValue={initialValues}
                onChange={(roles) => this.onRoleChange(roles)}
              />
            </div>
          }
        />
      </div>
    );
  }
}

export default setupReduxConnection(["reporting", "shop"])(LaborVsCostReportPage);
