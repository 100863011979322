import {request} from "../utils/request";
import {VendorRequests} from "../utils/request-helpers/supply-chain/supply-chain-requests";

const defaultState = {
  ledgers: null,
  vendors: [],
};

const UPDATE_LEDGERS = "UPDATE_LEDGERS";
const UPDATE_VENDORS = "UPDATE_VENDORS";

export function updateLedgers(payload) {
  return {type: UPDATE_LEDGERS, payload};
}

export function updateVendors(payload) {
  return {type: UPDATE_VENDORS, payload};
}

export function loadLedgers() {
  return async (dispatch, getState) => {
    const [ledgers, vendors] = await Promise.all([
      request("accounting/ledgers", "GET"),
      VendorRequests.fetchVendors(),
    ]);

    dispatch(updateLedgers(ledgers));
    dispatch(updateVendors(vendors));
  };
}

export const accountingReducer = (state = defaultState, action) => {
  let {type, payload} = action;

  switch (type) {
    default:
      return state;
    case UPDATE_LEDGERS:
      return {...state, ledgers: payload};
    case UPDATE_VENDORS:
      return {...state, vendors: payload};
  }
};
