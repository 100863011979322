import React, {Component} from "react";
import {TwoColumnList} from "@frostbyte-technologies/frostbyte-tailwind";
import {setupReduxConnection} from "../../../redux";
import InvoicesTabModal from "../../../modals/shop/shop-settings/invoices-tab-modal";

class InvoicesTab extends Component {
  constructor(props) {
    super(props);
    this.invoicesTabModal = React.createRef(null);
  }

  render() {
    let {INVOICE_DEFAULT_NOTES, INVOICE_NUMBER} = this.props.shop.settings;

    return (
      <div>
        <InvoicesTabModal ref={this.invoicesTabModal} />
        <TwoColumnList
          label="Invoice Settings"
          description="Configure settings for your invoices"
          data={[
            {
              label: "Default Invoice Notes",
              value: INVOICE_DEFAULT_NOTES || "None",
              tooltip: "Autofill notes for new invoices",
            },
            {
              label: "Current Invoice Number",
              value: INVOICE_NUMBER || "0",
              tooltip: "The next invoice will be created with this number",
            },
          ]}
          buttons={[
            {
              label: "Edit",
              onClick: () => this.invoicesTabModal.current.open(),
            },
          ]}
        />
      </div>
    );
  }
}

export default setupReduxConnection(["shop"])(InvoicesTab);
