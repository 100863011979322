import React, {Component} from "react";
import {Layer, Rectangle, Sankey, Tooltip} from "recharts";
import PropTypes from "prop-types";
import {toDollars} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";

function SankeyNode({x, y, width, height, index, payload, containerWidth}) {
  const isOut = x + width + 6 > containerWidth;

  return (
    <Layer key={`CustomNode${index}`}>
      <Rectangle
        x={x}
        y={y}
        width={width}
        height={height}
        fill="rgba(78,70,229, 0.8)"
        fillOpacity="1"
      />

      <text
        textAnchor={isOut ? "end" : "start"}
        x={isOut ? x - 6 : x + width + 6}
        y={y + height / 2}
        fontSize="14"
        stroke="#333"
        strokeOpacity="0.8"
      >
        {payload.name}
      </text>

      <text
        textAnchor={isOut ? "end" : "start"}
        x={isOut ? x - 6 : x + width + 6}
        y={y + height / 2 + 13}
        fontSize="12"
        stroke="#999"
        strokeOpacity="0.5"
      >
        {toDollars(payload.value, true)}
      </text>
    </Layer>
  );
}

class SankeyLink extends Component {
  static displayName = "SankeyLinkDemo";

  static propTypes = {
    sourceX: PropTypes.number,
    targetX: PropTypes.number,
    sourceY: PropTypes.number,
    targetY: PropTypes.number,
    sourceControlX: PropTypes.number,
    targetControlX: PropTypes.number,
    sourceRelativeY: PropTypes.number,
    targetRelativeY: PropTypes.number,
    linkWidth: PropTypes.number,
    index: PropTypes.number,
  };

  state = {
    fill: "rgba(78,70,229, 0.2)",
  };

  render() {
    const {
      sourceX,
      targetX,
      sourceY,
      targetY,
      sourceControlX,
      targetControlX,
      sourceRelativeY,
      targetRelativeY,
      linkWidth,
      index,
    } = this.props;
    const {fill} = this.state;

    return (
      <Layer key={`CustomLink${index}`}>
        <path
          d={`
            M${sourceX},${sourceY + linkWidth / 2}
            C${sourceControlX},${sourceY + linkWidth / 2}
              ${targetControlX},${targetY + linkWidth / 2}
              ${targetX},${targetY + linkWidth / 2}
            L${targetX},${targetY - linkWidth / 2}
            C${targetControlX},${targetY - linkWidth / 2}
              ${sourceControlX},${sourceY - linkWidth / 2}
              ${sourceX},${sourceY - linkWidth / 2}
            Z
          `}
          fill={fill}
          strokeWidth="0"
          onMouseEnter={() => {
            this.setState({fill: "rgba(78,70,229, 0.6)"});
          }}
          onMouseLeave={() => {
            this.setState({fill: "rgba(78,70,229, 0.2)"});
          }}
        />
      </Layer>
    );
  }
}

class ReportingIngredientUsageGraph extends Component {
  render() {
    const {data = {}, tooltip} = this.props;

    return (
      <Sankey
        width={960}
        height={200}
        margin={{top: 20, bottom: 20}}
        data={data}
        nodeWidth={10}
        nodePadding={60}
        linkCurvature={0.61}
        iterations={64}
        link={<SankeyLink />}
        node={<SankeyNode containerWidth={960} />}
      >
        <Tooltip content={tooltip} isAnimationActive={false} />
      </Sankey>
    );
  }
}

export default ReportingIngredientUsageGraph;
