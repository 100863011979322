import React, {Component} from "react";
import {request} from "../../../../../utils/request";
import {Card, Table} from "@frostbyte-technologies/frostbyte-tailwind";
import {updateStateDelegator} from "../../../../../utils/util";
import ProductAvailabilityModal from "../../../../../modals/sales/product/product-availability-modal";
import moment from "moment";
import {ISO_DAYS} from "../../../../../utils/constants";

class ProductAvailabilityTab extends Component {
  state = {availabilities: []};

  componentDidMount() {
    this.syncState();
  }

  syncState() {
    const {product} = this.props;

    request(`items/availability`, "GET").then((availabilities) => {
      this.setState({
        availabilities: availabilities.filter((item) => {
          return item.PRODUCT_ID === product.ID;
        }),
      });
    });
  }

  render() {
    const {availabilities} = this.state;
    const {product} = this.props;

    return (
      <Card
        label="Availability"
        description="Set a period of time that this product is available"
        button={{
          label: "Add Availability",
          onClick: () => this.productAvailabilityModal.open(null),
        }}
      >
        <ProductAvailabilityModal
          product={product}
          ref={(e) => (this.productAvailabilityModal = e)}
          addState={(state) =>
            this.setState(
              {availabilities: [state, ...availabilities]},
              () => this.props.syncState && this.props.syncState()
            )
          }
          updateState={(id, state) => {
            this.setState(
              {
                availabilities: updateStateDelegator(availabilities, id, state),
              },
              () => this.props.syncState && this.props.syncState()
            );
          }}
        />

        <Table
          pagination
          data={availabilities}
          columns={[
            {
              value: "TYPE",
              label: "Type",
              format: (type) =>
                type === "RECUR" ? "Recurrence" : "Date Range",
            },
            {
              value: "ISO_DAY",
              label: "Day",
              format: (day) => ISO_DAYS[parseInt(day)],
            },
            {
              width: 1,
              value: "DATE_START",
              label: "Date Start",
              format: (item, rest) => {
                if (rest.ISO_DAY !== null) {
                  return moment()
                    .startOf("day")
                    .add(item, "milliseconds")
                    .format("hh:mm A");
                }

                return moment(item).format("ddd MMM Do YYYY");
              },
            },
            {
              width: 1,
              value: "DATE_END",
              label: "Date End",
              format: (item, rest) => {
                if (rest.ISO_DAY !== null) {
                  return moment()
                    .startOf("day")
                    .add(item, "milliseconds")
                    .format("hh:mm A");
                }

                return moment(item).format("ddd MMM Do YYYY");
              },
            },
          ]}
          actionButtons={[
            {
              label: "Edit",
              onClick: (item) => this.productAvailabilityModal.open(item),
            },
          ]}
        />
      </Card>
    );
  }
}

export default ProductAvailabilityTab;
