import React, {Component} from "react";
import InventoryCountTable from "../../../tables/operations/counts/inventory-count-table";
import {
    Card,
    Filter,
    FormInput,
    FormTextArea,
    Loading,
} from "@frostbyte-technologies/frostbyte-tailwind";
import {Formik} from "formik";
import * as Yup from "yup";
import moment from "moment";
import {
    showErrorNotification,
    showSuccessNotification,
} from "../../../utils/notification-helper";
import {withRouter} from "../../../utils/navigation";
import {showErrorAlert} from "../../../utils/alert-helper";
import {setupReduxConnection} from "../../../redux";
import {IngredientCountRequests} from "../../../utils/request-helpers/supply-chain/supply-chain-requests";

class InventoryCountPage extends Component {
    state = {
        counts: [],
        maxUnits: 0,
        vendorItemDict: {},
        uniqueVendors: [],
        isLoading: true,
        count: null,
    };

    componentDidMount() {
        const {UNIQUE_ID: uniqueId} = this.props.router.params;

        if (uniqueId === "new") {
            const {ingredients} = this.props.supply;

            const counts = ingredients.map((ingredient) => {
                return {...ingredient, COUNT: null};
            });

            const maxUnits = counts.reduce((accum, item) => {
                if (item.VENDOR_ITEMS.length > accum) {
                    return item.VENDOR_ITEMS.length;
                }

                return accum;
            }, 0);

            const vendorItemDict = counts.reduce((dict, count) => {
                for (const vendorItem of count.VENDOR_ITEMS) {
                    dict[vendorItem.ID] = vendorItem;
                }

                return dict;
            }, {});

            const uniqueVendors = counts.reduce((dict, count) => {
                for (const vendor of count.VENDOR_ITEMS) {
                    dict[vendor.VENDOR_ID] = {
                        id: vendor.VENDOR_ID,
                        label: vendor.VENDOR_NAME,
                    };
                }

                return dict;
            }, {});

            this.setState({
                counts,
                maxUnits,
                vendorItemDict,
                uniqueVendors: Object.values(uniqueVendors),
                isLoading: false,
            });
        } else {
            const {ingredients} = this.props.supply;

            IngredientCountRequests.fetchCount(uniqueId).then((count) => {
                const counts = ingredients.map((ingredient) => {
                    return {...ingredient, COUNT: null};
                });

                const maxUnits = counts.reduce((accum, item) => {
                    if (item.VENDOR_ITEMS.length > accum) {
                        return item.VENDOR_ITEMS.length;
                    }

                    return accum;
                }, 0);

                const vendorItemDict = counts.reduce((dict, count) => {
                    for (const vendorItem of count.VENDOR_ITEMS) {
                        dict[vendorItem.ID] = vendorItem;
                    }

                    return dict;
                }, {});

                const uniqueVendors = counts.reduce((dict, count) => {
                    for (const vendor of count.VENDOR_ITEMS) {
                        dict[vendor.VENDOR_ID] = {
                            id: vendor.VENDOR_ID,
                            label: vendor.VENDOR_NAME,
                        };
                    }

                    return dict;
                }, {});

                this.setState({
                    counts,
                    maxUnits,
                    count,
                    vendorItemDict,
                    uniqueVendors: Object.values(uniqueVendors),
                    isLoading: false,
                });
            });
        }
    }

    saveCount(values) {
        const {count} = this.state;
        const {name, executedOn, ingredients, description} = values;

        IngredientCountRequests.updateCount(count.ID, {
            NAME: name,
            EXECUTED_ON: executedOn,
            DESCRIPTION: description,
            STATUS: "DRAFT",
            TYPE: "STANDARD",
            INGREDIENTS: ingredients,
        })
            .then(() => {
                showSuccessNotification(
                    "Count Saved as a Draft",
                    name + " was successfully saved as a draft."
                );
            })
            .catch((e) => {
                showErrorNotification(
                    "An Error Occurred",
                    "An error occurred when saving this count as a draft."
                );
            });
    }

    createCount(values) {
        const {name, ingredients, description} = values;

        IngredientCountRequests.createCount({
            NAME: name,
            EXECUTED_ON: null,
            DESCRIPTION: description,
            STATUS: "DRAFT",
            TYPE: "STANDARD",
            INGREDIENTS: ingredients,
        })
            .then(() => {
                showSuccessNotification(
                    "Count Saved as a Draft",
                    name + " was successfully saved as a draft."
                );
            })
            .catch((e) => {
                showErrorNotification(
                    "An Error Occurred",
                    "An error occurred when saving this count as a draft."
                );
            });
    }

    publishCount(values) {
        const {name, ingredients, description} = values;
        const {count} = this.state;

        IngredientCountRequests.execute(count.ID, {
            INGREDIENTS: ingredients,
            EXECUTED_ON: Date.now(),
            DESCRIPTION: description,
            NAME: name,
        })
            .then(() => {
                showSuccessNotification(
                    "Count Published",
                    name + " was successfully published and ingredient counts have been updated."
                );
            })
            .catch((e) => {
                console.log("ERROR", e);

                if (e.extra) {
                    showErrorAlert(
                        "Invalid Item Quantity",
                        e.extra.NAME +
                        " quantity is listed incorrectly. The maximum quantity registered in the system is " +
                        e.extra.MAX_QUANTITY +
                        ". You entered " +
                        e.extra.QUANTITY +
                        ". If you have extra " +
                        e.extra.NAME +
                        " available, please enter them as a one time stock."
                    );
                } else {
                    showErrorNotification(
                        "An Error Occurred",
                        "An error occurred when saving this count as a draft."
                    );
                }
            });
    }

    render() {
        const {counts, maxUnits, vendorItemDict, uniqueVendors, isLoading, count} =
            this.state;

        if (isLoading) {
            return <Loading/>;
        }

        const buttons = [
            {
                label: "Save as Draft",
                onClick: () => {
                    return count
                        ? this.saveCount(this.formikRef.values)
                        : this.createCount(this.formikRef.values);
                },
            },
            {
                label: "Complete",
                onClick: () => {
                    return this.publishCount(this.formikRef.values);
                },
            }
        ];

        const ITEMS = count?.ITEMS || [];

        const ingredients = counts.reduce((accum, item) => {
            const existingItem = ITEMS.find(it => it.INGREDIENT_ID === item.ID)
            accum[item.ID] = existingItem?.QUANTITY ?? item.CURRENT_STOCK;
            return accum;
        }, {})

        return (
            <div>
                <Card label="Inventory Count" buttons={count?.STATUS !== 'EXECUTED' ? buttons : []}>
                    <Formik
                        onSubmit={() => null}
                        innerRef={(e) => (this.formikRef = e)}
                        initialValues={{
                            name: count
                                ? count.NAME
                                : "Inventory Count on " + moment().format("MM/DD/YYYY"),
                            executedOn: Date.now(),
                            ingredients,
                            description: count?.DESCRIPTION,
                        }}
                        validationSchema={Yup.object().shape({
                            name: Yup.string()
                                .nullable()
                                .required("Please enter a name for this inventory count."),
                            executedOn: Yup.number()
                                .nullable()
                                .required("Please enter a time for this count"),
                        })}
                    >
                        {(formikOptions) => {
                            return (
                                <div className="pb-4">
                                    <div className="mx-4 mb-4">
                                        <FormInput label="Name" name="name" options={formikOptions} flex/>

                                        <FormTextArea
                                            label="Description"
                                            name="description"
                                            options={formikOptions}
                                            hint="Optional"
                                            flex
                                        />
                                    </div>

                                    <Filter
                                        searchable
                                        className="border-none mx-4 mb-4"
                                        defaultFilters={[]}
                                        data={[
                                            {
                                                id: "vendor",
                                                label: "Vendor",
                                                options: uniqueVendors,
                                                onFilter: (options, data) => {
                                                    return data.filter((item) => {
                                                        return item.VENDOR_ITEMS.some((item) =>
                                                            options.includes(item.VENDOR_ID)
                                                        );
                                                    });
                                                },
                                            },
                                        ]}
                                    >
                                        {(filters, search) => {
                                            return (
                                                <InventoryCountTable
                                                    counts={counts}
                                                    maxUnits={maxUnits}
                                                    vendorItemDict={vendorItemDict}
                                                    filters={filters}
                                                    options={formikOptions}
                                                    search={search}
                                                />
                                            );
                                        }}
                                    </Filter>
                                </div>
                            );
                        }}
                    </Formik>
                </Card>
            </div>
        );
    }
}

export default setupReduxConnection(["supply"])(withRouter(InventoryCountPage));
