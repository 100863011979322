import React, {Component} from "react";
import {request} from "../../../../utils/request";
import {withRouter} from "../../../../utils/navigation";

import {
  Card,
  Loading,
  PageHeadings,
  Tab,
  Table,
  TwoColumnList,
} from "@frostbyte-technologies/frostbyte-tailwind";
import {withTranslation} from "react-i18next";
import ProductsTable from "../../../../tables/sales/products-table";
import {showLoadingConfirmAlert} from "../../../../utils/alert-helper";
import TaxModal from "../../../../modals/sales/tax/tax-modal";
import PresetProductModal from "../../../../modals/sales/modifiers/preset-product-modal";

class TaxPage extends Component {
  state = {
    tax: null,
    products: null,
    categories: null,
  };

  componentDidMount() {
    this.syncState();
  }

  syncState = () => {
    const {ID: id} = this.props.router.params;

    request("tax/" + id, "GET", {EXPAND: true}).then(async (tax) => {
      this.setState({tax, products: tax.PRODUCTS, categories: tax.CATEGORIES});
    });
  };

  delete = async (category) => {
    showLoadingConfirmAlert(
      "Delete Tax Rate",
      "Are you sure you want to delete this tax rate? This will remove the tax rate from all linked categories and products."
    ).then(async (close) => {
      await request("tax/" + category.ID, "DELETE", null);

      close();

      this.props.router.navigate("/taxes");
    });
  };

  render() {
    const {categories, products, tax} = this.state;

    if (tax === null) {
      return <Loading />;
    }

    return (
      <div>
        <TaxModal
          ref={(e) => (this.taxModal = e)}
          updateState={(_, tax) => this.setState({tax})}
        />

        <PresetProductModal
          ref={(e) => (this.productSelectModal = e)}
          exclude={products.map((p) => p.ID)}
          updateState={async ({products}, callback) => {
            await Promise.all(
              products.map((product) => {
                return request("tax/product", "POST", {
                  PRODUCT_ID: product,
                  TAX_ID: tax.ID,
                });
              })
            );

            this.syncState();
            callback();
          }}
        />

        <PageHeadings
          label={tax?.NAME}
          buttons={[
            {
              type: "dropdown",
              label: "Actions",
              sections: [
                {
                  items: [
                    {
                      label: "Delete Tax",
                      onClick: () => this.delete(tax),
                    },
                  ],
                },
              ],
            },
          ]}
          breadcrumbs={[
            {label: "Sales", url: "/"},
            {label: "Menu Page", url: "/menu"},
            {label: "Taxes Page", url: "/taxes"},
          ]}
        />

        <TwoColumnList
          buttons={[
            {
              label: "Edit Information",
              onClick: () => this.taxModal.open(tax),
            },
          ]}
          label={tax.NAME + " Information"}
          description="Information about this tax rate"
          data={[
            {label: "Name", value: tax.NAME},
            {label: "Type", value: tax.TYPE.capitalize()},
            {label: "Rate", value: tax.RATE + "%"},
            {label: "Is Inclusive", value: tax.IS_INCLUSIVE, type: "bool"},
            {label: "Is Default", value: tax.IS_DEFAULT, type: "bool"},
            {
              label: "Notes",
              value: tax.NOTES,
              span: true,
              hide: true,
            },
          ]}
        />

        <Tab
          className="mt-2"
          data={[
            {label: "Line Items", id: "lines"},
            {label: "Categories", id: "categories"},
            {label: "Products", id: "products"},
          ]}
        >
          {(id) => {
            if (id === "products") {
              return (
                <Card
                  label="Products"
                  description="Products that are attached to this tax rate."
                  buttons={[
                    {
                      label: "Add Product",
                      onClick: () => this.productSelectModal.open(),
                    },
                  ]}
                >
                  <ProductsTable
                    data={products}
                    pagination
                    updatePricing={() => this.syncState()}
                    updateProduct={(serverProduct) => {
                      const productIndex = products.findIndex((item) => {
                        return item.ID === serverProduct.ID;
                      });

                      if (productIndex !== -1) {
                        products.splice(productIndex, 1, {
                          ...products[productIndex],
                          ...serverProduct,
                        });
                      }

                      this.setState({products});
                    }}
                    updateInventory={({product, inventory}) => {
                      const productIndex = products.findIndex((item) => {
                        return item.ID === product.ID;
                      });

                      if (productIndex !== -1) {
                        products.splice(productIndex, 1, {
                          ...products[productIndex],
                          INVENTORY: inventory.QUANTITY,
                        });
                      }

                      this.setState({products});
                    }}
                  />
                </Card>
              );
            }

            if (id === "categories") {
              return (
                <Card
                  label="Categories"
                  description="Categories that have the tax rate attached to it"
                >
                  <Table
                    pagination
                    data={categories}
                    columns={[
                      {
                        value: "NAME",
                        label: "Name",
                      },
                    ]}
                    actionButtons={[
                      {
                        label: "View",
                        onClick: (row) =>
                          this.props.router.navigate("/category/" + row.UNIQUE_ID),
                      },
                    ]}
                  />
                </Card>
              );
            }

            if (id === "lines") {
              return (
                <Card
                  label="Line Items"
                  description="Line items that have the tax rate attached to it"
                >
                  <Table
                    pagination
                    route={"tax/" + tax.ID + "/dumb"}
                    columns={[
                      {
                        width: 1,
                        value: "ID",
                        label: "Ticket ID",
                        format: (_, row) => row?.TICKET?.ID,
                      },
                      {
                        value: "TICKET",
                        label: "Ticket Name",
                        format: (_, row) => row?.TICKET?.NAME,
                      },
                      {
                        value: "NUM",
                        label: "Ticket NUmber",
                        format: (_, row) => row?.TICKET?.TICKET_NUMBER,
                      },
                      {
                        value: "NAME",
                        label: "Item Name",
                        format: (_, row) => row?.LINE_ITEM?.NAME,
                      },
                      {
                        value: "AMOUNT",
                        label: "Tax Amount",
                        type: "dollars",
                      },
                    ]}
                    actionButtons={[
                      {
                        label: "View Ticket",
                        onClick: (row) =>
                          this.props.router.navigate("/ticket/" + row.TICKET.UNIQUE_ID),
                      },
                    ]}
                  />
                </Card>
              );
            }
          }}
        </Tab>
      </div>
    );
  }
}

export default withTranslation()(withRouter(TaxPage));
