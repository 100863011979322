import React, {Component} from "react";
import {
  Loading,
  PageHeadings,
  Tab,
  Table,
} from "@frostbyte-technologies/frostbyte-tailwind";
import LocationModal from "../../../modals/admin/records/location-modal";
import {request} from "../../../utils/request";
import {withRouter} from "../../../utils/navigation";
import CompanyModal from "../../../modals/admin/records/company-modal";
import FranchiseModal from "../../../modals/admin/records/franchise-modal";

class FranchisesPage extends Component {
  state = {franchises: null};

  componentDidMount() {
    request("admin/franchises", "GET").then((franchises) => {
      this.setState({franchises});
    });
  }

  render() {
    const {franchises} = this.state;

    if (franchises === null) {
      return <Loading />;
    }

    return (
      <>
        <FranchiseModal
          ref={(e) => (this.franchiseModal = e)}
          addState={(franchise) => {
            this.props.router.navigate(
              "/admin/franchise/" + franchise.UNIQUE_ID
            );
          }}
        />

        <PageHeadings
          label="Franchises"
          description="Create and manage franchises"
          button={{
            label: "Create Franchise",
            onClick: () => this.franchiseModal.open(),
          }}
        />

        <Table
          pagination
          data={franchises}
          className="mt-4"
          actionButtons={[
            {
              label: "View",
              onClick: (row) => {
                this.props.router.navigate("/admin/franchise/" + row.UNIQUE_ID);
              },
            },
          ]}
          columns={[
            {
              value: "NAME",
              label: "Name",
            },
          ]}
        />
      </>
    );
  }
}

export default withRouter(FranchisesPage);
