import React, {Component} from "react";
import {classNames} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import moment from "moment-timezone";
import {setupReduxConnection} from "../../../redux";

class ReportingGraphContainer extends Component {
  render() {
    let {className, label, secondaryLabel, stats, graph} = this.props;
    const {lastStartEpoch, lastEndEpoch} = this.props.reporting;

    const isMultiDay = !moment(lastStartEpoch).isSame(
      moment(lastEndEpoch),
      "day"
    );

    if (!label) {
      label = `${moment(lastStartEpoch).format("MMM D, YYYY")}${
        isMultiDay ? ` - ${moment(lastEndEpoch).format("MMM D, YYYY")}` : ""
      }`;
    }

    return (
      <div className={classNames(className, "bg-white p-4 shadow-sm")}>
        {stats && (
          <div>
            <div
              className={
                "flex flex-col lg:flex-row justify-between items-center pb-2"
              }
            >
              <div className={"font-bold text-2xl py-2"}>{label}</div>
              <div className={"pr-5 flex flex-row space-x-16"}>
                {stats.map((_stat) => (
                  <div className={"text-left font-semibold text-sm"}>
                    <div className={"font-semibold text-lg"}>{_stat.value}</div>
                    <div className={"text-sm"}>{_stat.label}</div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}

        {secondaryLabel && (
          <div
            style={{
              fontSize: "0.75rem",
              fontWeight: "bold",
              color: "#666666",
            }}
            className={"mb-2"}
          >
            {secondaryLabel}
          </div>
        )}
        {graph}
      </div>
    );
  }
}
export default setupReduxConnection(["reporting"])(ReportingGraphContainer);
