import React, {Component} from "react";
import {request} from "../../../utils/request";
import LoadingSpinner from "../../../components/loading-spinner";
import moment from "moment-timezone";
import FormDateTimeSelect from "../../../components/form-date-time-select";
import {Formik} from "formik";

class QuickbooksImportComponent extends Component {
  state = {url: null};

  async componentDidMount() {
    const url = await request("accounting/quickbooks/link", "GET");

    this.setState({url});
  }

  async handleClick() {
    return Boolean(await this.formikRef.submitForm());
  }

  handleSubmit = async ({start, end}) => {
    await request("accounting/quickbooks", "POST", {
      MIGRATION_START_DATE: moment(start).format("YYYY-MM-DD"),
      MIGRATION_END_DATE: moment(end).format("YYYY-MM-DD"),
    });

    return true;
  };

  render() {
    const {url} = this.state;

    return (
      <div style={{height: 500, padding: 16}}>
        <Formik
          onSubmit={this.handleSubmit}
          innerRef={(e) => (this.formikRef = e)}
          initialValues={{}}
          enableReinitialize={true}
        >
          {(formikOptions) => {
            const {setFieldValue} = formikOptions;

            return (
              <div>
                <FormDateTimeSelect
                  name={"start"}
                  label={"Start Date"}
                  tooltip={"Opening balances will be set at the start date"}
                  hideTime={true}
                  options={formikOptions}
                />

                <FormDateTimeSelect
                  name={"end"}
                  label={"End Date"}
                  tooltip={"Opening balances will be set for this date"}
                  hideTime={true}
                  options={formikOptions}
                />
              </div>
            );
          }}
        </Formik>
      </div>
    );
  }
}

export default QuickbooksImportComponent;
