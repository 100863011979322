import React, {Component} from "react";
import {Table} from "@frostbyte-technologies/frostbyte-tailwind";
import {
  parseIdDict,
  toDollars,
} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import moment from "moment-timezone";
import {ISO_DAYS} from "../../utils/constants";
import {getAssetUrl} from "@frostbyte-technologies/frostbyte-core/dist/helpers/asset-helper";

const FIELD_TYPES = {
  NAME: "STRING",
  DESCRIPTION: "STRING",
  INSTRUCTIONS: "STRING",
  LOGO: "IMAGE",
  LOGO_LOW: "IMAGE",
  PRICE: "PRICE",
  ENABLED: "BOOLEAN",
  VARIABLE: "BOOLEAN",
  ONE_CLICK: "BOOLEAN",
  ALCOHOLIC: "BOOLEAN",
  TAX_DISABLED: "BOOLEAN",
  NEXT_DAY: "BOOLEAN",
  PREPARE_TIME: "TIME",
  RATE_LIMIT: "NUMBER",
  AUTO_COMPLETE: "BOOLEAN",
  ADVANCED_TIME: "TIME",
  ICON_COLOR: "COLOR",
  WEIGHT: "NUMBER",
  POS_ENABLED: "BOOLEAN",
  THIRD_PARTY_ENABLED: "BOOLEAN",
  KIOSK_ENABLED: "BOOLEAN",
  WEIGHT_UNIT_COST: "PRICE",
  SEQ: "NUMBER",
};

export const FIELD_DISPLAYS = {
  POS_ENABLED: "Enabled on POS",
  KIOSK_ENABLED: "Enabled on Kiosk",
  THIRD_PARTY_ENABLED: "Enabled on Third Party",
  CUSTOMIZATIONS: "Modifiers",
  TAX_RATES: "Tax Rates",
  PRODUCT_DISCOUNTS: "Product Discounts",
  ICON_COLOR: "Icon Color",
};

class ProductLocationsTableExpandableComponent extends Component {
  renderOptionDifference(row, key) {
    const diffs = row["DIFFS"];
    const {product} = this.props;

    const options = product.CUSTOMIZATIONS.reduce(
      (_options, customization) => [..._options, ...customization.OPTIONS],
      []
    );

    const syncOptionDict = parseIdDict(options, "SYNC_ID");

    return (
      <div>
        {diffs.map((diff) => {
          if (!diff.FIELD || diff.FIELD?.startsWith("sync_")) {
            if (!diff[key]) {
              return <div>Does not exist</div>;
            }

            return <div className="font-bold mr-1">{diff[key].NAME}</div>;
          }

          return (
            <div className="flex flex-row">
              <div className="font-bold mr-1">
                {syncOptionDict[diff.ID].NAME}
                {"'s "}
                {diff.FIELD.substring(0, 1).toUpperCase() +
                  diff.FIELD.substring(1).toLowerCase()}
                :
              </div>

              <div>
                {diff.FIELD === "PRICE"
                  ? toDollars(diff[key], true)
                  : diff[key]}
              </div>
            </div>
          );
        })}
      </div>
    );
  }

  renderModifierDifference(row, key) {
    const diffs = row["DIFFS"];
    const {product} = this.props;
    const modifierDict = parseIdDict(product.CUSTOMIZATIONS, "SYNC_ID");

    return (
      <div>
        {diffs.map((diff) => {
          if (diff[key] === null) {
            const oppositeKey = key === "EXPECTED" ? "VALUE" : "EXPECTED";
            return <div>{diff[oppositeKey]?.NAME} does not exist</div>;
          }

          if (typeof diff[key] === "object") {
            return <div>{modifierDict[diff.ID]?.NAME ?? diff[key]?.NAME}</div>;
          }

          return (
            <div className="flex flex-row">
              <div className="font-bold mr-1">
                {modifierDict[diff.ID]?.NAME}
                {"'s "}
                {diff.FIELD.substring(0, 1).toUpperCase() +
                  diff.FIELD.substring(1).toLowerCase()}
                :
              </div>

              <div>
                {diff.FIELD === "PRICE"
                  ? toDollars(diff[key], true)
                  : diff[key]}
              </div>
            </div>
          );
        })}
      </div>
    );
  }

  renderAvailabilityDifference(row, key) {
    const availabilities = parseIdDict(row[key], "SYNC_ID");

    return (
      <div className="justify-start">
        {row.DIFFS.map((diff) => {
          const availability = availabilities[diff.ID];

          if (!availability) {
            return <div className="my-1">Does not exist</div>;
          }

          if (availability.TYPE === "RANGE") {
            const time =
              moment(availability.DATE_START).format("ddd MMM Do YYYY h:mm A") +
              " - " +
              moment(availability.DATE_END).format("ddd MMM Do YYYY h:mm A");

            return <div className="my-1">{time}</div>;
          }

          const time =
            ISO_DAYS[availability.ISO_DAY] +
            " " +
            moment()
              .startOf("day")
              .add(availability.DATE_START, "milliseconds")
              .format("h:mm A") +
            " - " +
            moment()
              .startOf("day")
              .add(availability.DATE_END, "milliseconds")
              .format("h:mm A");

          return <div className="my-1">{time}</div>;
        })}
      </div>
    );
  }

  renderTaxRateDifference(row, key) {
    return (
      <div className="justify-start">
        {row.DIFFS.map((diff) => {
          const diffValue = diff[key];

          if (!diffValue) {
            return <div className="my-1">Does not exist</div>;
          }

          return (
            <div className="my-1">
              {diffValue.NAME + " (" + diffValue.RATE + "%)"}
            </div>
          );
        })}
      </div>
    );
  }

  renderProductDiscountDifference(row, key) {
    return (
      <div className="justify-start">
        {row.DIFFS.map((diff) => {
          const diffValue = diff[key];

          if (!diffValue) {
            return <div className="my-1">Does not exist</div>;
          }

          return (
            <div className="my-1">
              {diffValue.NAME +
                " (" +
                (diffValue.TYPE === 0
                  ? toDollars(diffValue.CONTENT, true)
                  : diffValue.CONTENT + "%") +
                ")"}
            </div>
          );
        })}
      </div>
    );
  }

  renderLogoDifference(row, key) {
    return <img className="h-20 w-20 rounded-lg" src={getAssetUrl(row[key])} />;
  }

  renderIconColorDifference(row, key) {
    if (row[key] === null) {
      return <div>Does not exist</div>;
    }

    return (
      <div
        style={{backgroundColor: `#${row[key]}`}}
        className={"h-7 w-7 cursor-pointer m-2"}
      />
    );
  }

  formatValue(row, key) {
    const {product} = this.props;
    const {FIELD: field} = row;

    const allOptions = product.CUSTOMIZATIONS.reduce(
      (options, customization) => {
        options.push(...customization.OPTIONS);
        return options;
      },
      []
    );

    if (field === "DEFAULTS") {
      const optionDict = parseIdDict(allOptions);

      return row[key]
        .filter((def) => optionDict[def.OPTION_ID])
        .map((def) => optionDict[def.OPTION_ID].NAME)
        .join(", ");
    } else if (field === "OPTIONS") {
      return this.renderOptionDifference(row, key);
    } else if (field === "CUSTOMIZATIONS") {
      return this.renderModifierDifference(row, key);
    } else if (field === "AVAILABILITY") {
      return this.renderAvailabilityDifference(row, key);
    } else if (FIELD_TYPES[field] === "IMAGE") {
      return this.renderLogoDifference(row, key);
    } else if (FIELD_TYPES[field] === "PRICE") {
      return toDollars(row[key], true);
    } else if (FIELD_TYPES[field] === "BOOLEAN") {
      return row[key] ? "Yes" : "No";
    } else if (field === "TAX_RATES") {
      return this.renderTaxRateDifference(row, key);
    } else if (field === "PRODUCT_DISCOUNTS") {
      return this.renderProductDiscountDifference(row, key);
    } else if (field === "ICON_COLOR") {
      return this.renderIconColorDifference(row, key);
    }

    return row[key] ? row[key].toString().capitalize() : "Does not exist";
  }

  render() {
    const {product} = this.props;
    product.DISCREPANCIES = product.DISCREPANCIES ?? [];

    return (
      <div className="px-10 py-3 pb-10 text-sm">
        <Table
          hideBorder
          data={JSON.parse(
            JSON.stringify(
              product.DISCREPANCIES.filter((d) => d.FIELD !== "LOGO_LOW")
            )
          )}
          columns={[
            {
              label: "Field",
              value: "FIELD",
              format: (value) =>
                FIELD_DISPLAYS[value] ??
                value.substring(0, 1).toUpperCase() +
                  value.substring(1).toLowerCase(),
            },
            {
              label: "Last Sync",
              value: "EXPECTED",
              format: (_, row) => {
                return this.formatValue(row, "EXPECTED");
              },
            },
            {
              label: "Current",
              value: "VALUE",
              format: (_, row) => this.formatValue(row, "VALUE"),
            },
          ]}
        />
      </div>
    );
  }
}

export default ProductLocationsTableExpandableComponent;
