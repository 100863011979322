import React, {Component} from "react";
import {Modal} from "@frostbyte-technologies/frostbyte-tailwind";
import {request} from "../../../utils/request";
import InvoiceForm from "../../../forms/operations/invoices/invoice-form";
import PropTypes from "prop-types";

class InvoiceModal extends Component {
  state = {invoice: null};

  open(invoice = null) {
    this.setState({invoice}, () => {
      this.formikRef && this.formikRef.resetForm();
      this.modal.open();
    });
  }

  async saveInvoice(values) {
    const {
      contact,
      contactName,
      number,
      notes,
      due,
      service,
      tipEnabled,
      sendToKds,
    } = values;

    const {invoice} = this.state;

    let serverInvoice = await request("invoices/" + invoice.ID, "PATCH", {
      INVOICE_NUMBER: number,
      CONTACT_ID: contact,

      DATE_SERVICE: service,
      DATE_DUE: due,

      NOTES: notes,
      REQUEST_TIP: tipEnabled,
      SEND_TO_KDS: parseInt(sendToKds),
    });

    this.props.updateState(serverInvoice);
    this.modal.close();
  }

  render() {
    const {invoice} = this.state;

    return (
      <Modal
        buttonLabel="Save"
        label="Edit Invoice"
        ref={(e) => (this.modal = e)}
        formikOnClick={() => this.formikRef}
      >
        <InvoiceForm
          handleSubmit={this.saveInvoice.bind(this)}
          ref={(e) => (this.formikRef = e)}
          invoice={invoice}
        />
      </Modal>
    );
  }
}

InvoiceModal.propTypes = {
  updateState: PropTypes.func.isRequired,
};

export default InvoiceModal;
