import React, {Component} from "react";
import {request} from "../../../../utils/request";

class BusinessInformationComponent extends Component {
  openUnitLink = async () => {
    const res = await request("cash-account/groups/onboard", "GET");

    window.open(res, "_blank");
  };

  render() {
    return (
      <div className={"p-6"}>
        <button
          type="button"
          className="mt-4 inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          onClick={async () => {
            await this.openUnitLink();
          }}
        >
          Open Unit Onboarding
        </button>
      </div>
    );
  }
}

export default BusinessInformationComponent;
