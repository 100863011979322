import React, {Component} from "react";
import {
  Modal,
  FormInput,
  FormPhone,
  FormBoolean,
} from "@frostbyte-technologies/frostbyte-tailwind";
import {Formik} from "formik";
import {request} from "../../utils/request";
import {setupReduxConnection} from "../../redux";

class ContactPageModal extends Component {
  open() {
    this.formikRef && this.formikRef.resetForm();
    this.modal.open();
  }

  handleSubmit = async (values) => {
    await request("partner/settings/v2", "PATCH", {SETTINGS: values});

    let settings = await request("settings/shop", "GET");

    this.props.updateShop({settings});
    this.modal.close();
  };

  render() {
    const {SHOW_CONTACT_PAGE} = this.props.shop.settings;

    return (
      <Modal
        label="Customer Contact Page"
        buttonLabel="Save"
        formikOnClick={() => this.formikRef}
        ref={(e) => (this.modal = e)}
      >
        <Formik
          onSubmit={this.handleSubmit}
          enableReinitialize={true}
          initialValues={{
            SHOW_CONTACT_PAGE,
          }}
          innerRef={(e) => (this.formikRef = e)}
        >
          {(formikOptions) => {
            const {handleSubmit} = formikOptions;

            return (
              <form onSubmit={handleSubmit}>
                <FormBoolean
                  label="Show Contact Page on Order Website"
                  name="SHOW_CONTACT_PAGE"
                  options={formikOptions}
                />
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

export default setupReduxConnection(["shop"], null, {forwardRef: true})(
  ContactPageModal
);
