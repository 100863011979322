import {Component} from "react";
import {Modal} from "@frostbyte-technologies/frostbyte-tailwind";
import {request} from "../../../utils/request";

class ReinstateEmployeeModal extends Component {
  state = {employee: null};

  open(employee = null) {
    this.setState({employee}, () => {
      this.modal.open();
    });
  }

  async reinstate() {
    const {employee} = this.state;

    await request("reinstate/employee/" + employee.ID, "POST", null);

    this.props.updateState();
  }

  render() {
    return (
      <Modal
        label="Reinstate Employee"
        buttonLabel="Reinstate"
        ref={(e) => (this.modal = e)}
        buttonOnClick={() => {
          this.reinstate();
        }}
      >
        Would you like to reinstate this employee?
      </Modal>
    );
  }
}

export default ReinstateEmployeeModal;
