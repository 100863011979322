import React, {Component} from "react";
import {FormInput, Modal} from "@frostbyte-technologies/frostbyte-tailwind";
import {request} from "../../../utils/request";
import {Formik} from "formik";
import * as Yup from "yup";
import CompanyDropdown from "../../../dropdowns/admin/company-dropdown";
import {withRouter} from "../../../utils/navigation";
import PropTypes from "prop-types";

class FranchiseModal extends Component {
  state = {company: null, franchise: null};

  open(company = null, franchise = null) {
    this.setState({company, franchise}, () => {
      this.formikRef && this.formikRef.resetForm();
      this.modal.open();
    });
  }

  async createFranchise({name, company}) {
    const {addState} = this.props;

    const serverFranchise = await request("franchises", "POST", {
      COMPANY_ID: "" + company,
      NAME: name,
    });

    this.modal.close();

    addState && addState(serverFranchise);
  }

  async saveFranchise({first, last, phone, email, company, notes}) {
    const {contact} = this.state;

    if (phone) {
      phone = "1" + phone.replaceAll(" ", "");
    }

    let serverContact = await request("contacts/" + contact.ID, "PATCH", {
      CUSTOMER_GROUP_ID: null,
      ADDRESS_ID: null,

      FIRST_NAME: first,
      LAST_NAME: last,
      PHONE: phone,
      EMAIL: email,

      NOTES: notes,

      COMPANY: company,
    });

    this.props.updateState(serverContact);
    this.modal.close();
  }

  render() {
    const {franchise, company} = this.state;

    return (
      <Modal
        buttonLabel={franchise ? "Save" : "Add"}
        label={franchise ? "Edit Franchise" : "Create Franchise"}
        formikOnClick={() => this.formikRef}
        ref={(e) => (this.modal = e)}
      >
        <Formik
          onSubmit={
            franchise
              ? this.saveFranchise.bind(this)
              : this.createFranchise.bind(this)
          }
          innerRef={(e) => (this.formikRef = e)}
          enableReinitialize
          validationSchema={Yup.object({
            name: Yup.string().required("Name is required"),
            company: Yup.number()
              .typeError("Company is required")
              .required("Company is required"),
          })}
          initialValues={{
            name: franchise?.NAME ?? "",
            company: franchise?.COMPANY_ID ?? company,
          }}
        >
          {(formikOptions) => {
            const {handleSubmit} = formikOptions;

            return (
              <form onSubmit={handleSubmit}>
                <FormInput
                  options={formikOptions}
                  placeholder="Avery's Franchise"
                  label="Name"
                  name="name"
                />

                <CompanyDropdown
                  options={formikOptions}
                  label="Company"
                  name="company"
                />
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

FranchiseModal.propTypes = {
  addState: PropTypes.func.isRequired,
}

export default withRouter(FranchiseModal);
