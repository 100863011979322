import HubPayrollSideNav from "../../layouts/side-navs/hub-payroll-side-nav";
import AccountPaystubsPage from "../../pages/hub/payroll/account-paystubs-page";
import PayrollDashboardPage from "../../pages/hub/payroll/payroll-dashboard-page";
import PayrollOnboardPage from "../../pages/hub/payroll/payroll-employee-onboard-page";
import AccountDocumentsPage from "../../pages/hub/payroll/account-tax-documents-page";

export const HUB_PAGE_ROUTES = [
  {
    side: <HubPayrollSideNav />,
    permissions: [],
    routes: [
      {
        live: true,
        path: "/payroll",
        element: <PayrollDashboardPage />,
        permissions: [],
      },
      {
        live: true,
        path: "/payroll/paystubs",
        element: <AccountPaystubsPage />,
        permissions: [],
      },
      {
        live: true,
        path: "/payroll/tax-documents",
        element: <AccountDocumentsPage />,
      },
      {
        live: true,
        path: "/payroll/onboard/:UNIQUE_ID",
        element: <PayrollOnboardPage />,
        permissions: [],
      },
    ],
  },
];
