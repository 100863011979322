import {FormTextArea, Modal, FormInput} from "@frostbyte-technologies/frostbyte-tailwind";
import React, {Component} from "react";
import {Formik} from "formik";
import {setupReduxConnection} from "../../../redux";
import {request} from "../../../utils/request";
import * as Yup from "yup";

class InvoicesTabModal extends Component {
  constructor(props) {
    super(props);
    this.formikRef = React.createRef(null);
    this.modal = React.createRef(null);
  }

  open() {
    this.setState({}, () => {
      this.modal.current.open();
    });
  }

  async fetchMaxInvoiceNumber() {
    let invoices = await request("invoices", "GET", null);

    const maxInvoiceNumber = invoices.reduce((max, invoice) => {
      return parseInt(invoice.INVOICE_NUMBER) > max
        ? parseInt(invoice.INVOICE_NUMBER)
        : max;
    }, 0);

    return maxInvoiceNumber;
  }

  handleSubmit = async (values) => {
    let {invoiceDefaultNotes, invoiceNumber} = values;

    const maxInvoiceNumber = await this.fetchMaxInvoiceNumber();
    if (parseInt(invoiceNumber) <= maxInvoiceNumber) {
      if (this.formikRef?.current) {
        this.formikRef.current.setFieldError(
          "invoiceNumber",
          "There is an existing invoice number higher than this number: " +
            maxInvoiceNumber
        );
      }
      this.modal.current.stopLoading();
      return;
    }

    let payload = {
      INVOICE_DEFAULT_NOTES: invoiceDefaultNotes,
      INVOICE_NUMBER: invoiceNumber,
    };

    await request("partner/settings/v2", "PATCH", {SETTINGS: payload});

    let settings = await request("settings/shop", "GET");

    this.props.updateShop({settings});
    this.modal.current.close();
  };

  fetchInitialValues() {
    const {INVOICE_DEFAULT_NOTES, INVOICE_NUMBER} = this.props.shop.settings;
    return {
      invoiceDefaultNotes: INVOICE_DEFAULT_NOTES,
      invoiceNumber: INVOICE_NUMBER,
    };
  }

  renderForm() {
    const validationSchema = Yup.object().shape({
      invoiceDefaultNotes: Yup.string().nullable(),
      invoiceNumber: Yup.number()
        .typeError("Must be a number")
        .required("Invoice number is required"),
    });

    return (
      <Formik
        innerRef={this.formikRef}
        onSubmit={this.handleSubmit}
        initialValues={this.fetchInitialValues()}
        validationSchema={validationSchema}
      >
        {(formikOptions) => {
          const {handleSubmit} = formikOptions;

          return (
            <form onSubmit={handleSubmit}>
              <FormTextArea
                name="invoiceDefaultNotes"
                label="Invoice Default Notes"
                options={formikOptions}
              />

              <FormInput
                name="invoiceNumber"
                label="Invoice Number"
                options={formikOptions}
              />
            </form>
          );
        }}
      </Formik>
    );
  }

  render() {
    return (
      <Modal
        label="Invoice Settings"
        buttonLabel={"Save"}
        formikOnClick={() => this.formikRef.current}
        ref={this.modal}
      >
        {this.renderForm()}
      </Modal>
    );
  }
}

export default setupReduxConnection(["shop"], null, {forwardRef: true})(InvoicesTabModal);
