import React, {Component} from "react";
import {Formik} from "formik";
import {FormInput, FormSelect, Modal} from "@frostbyte-technologies/frostbyte-tailwind";
import FormDateTimeSelect from "../../components/form-date-time-select";
import moment from "moment-timezone";
import FormTimeSelect from "../../components/form-time-select";
import {request} from "../../utils/request";
import * as Yup from "yup";

class ClosingsModal extends Component {
  state = {closing: null};

  open = (closing) => {
    this.setState({closing});

    setTimeout(() => this.formikRef && this.formikRef.resetForm(), 250);
    this.modal.open();
  };

  closeModal = () => {
    this.setState({closing: null}, () => {
      this.formikRef && this.formikRef.resetForm();
      this.modal.close();
    });
  };

  handleSubmit = async (values) => {
    let {name, type, startDate, endDate, startTime, endTime} = values;
    let id = this.state.closing?.ID;

    let rawParams = {
      NAME: name,
      TYPE: type,
      START_DATE: startDate,
      END_DATE: endDate,
      START_TIME: startTime,
      END_TIME: endTime,
    };

    if (id) {
      await request("partner/closing/" + id, "PATCH", rawParams);
    } else {
      await request("partner/closing", "POST", rawParams);
    }

    await this.props.refresh();

    this.closeModal();
  };

  deleteClosing = async () => {
    let {ID} = this.state.closing;

    await request("partner/closing/" + ID, "DELETE");

    await this.props.refresh();

    this.closeModal();
  };

  render() {
    const {closing} = this.state;

    const label = closing ? "Edit Closing" : "New Closing";
    const deleteLabel = closing ? "Delete" : "";

    const schema = Yup.object({
      name: Yup.string().required(),
      startDate: Yup.number().required(),
      endDate: Yup.number()
        .required()
        .test(
          "valid dates",
          "End date must be after start date",
          (value, ctx) => ctx.parent.startDate <= ctx.parent.endDate
        ),
    });

    return (
      <Modal
        label={label}
        buttonLabel={"Save"}
        deleteLabel={deleteLabel}
        formikOnClick={() => this.formikRef}
        deleteOnClick={this.deleteClosing}
        ref={(e) => (this.modal = e)}
      >
        <Formik
          enableReinitialize={true}
          onSubmit={this.handleSubmit}
          initialValues={{
            name: closing?.NAME,
            type: closing?.TYPE ?? "FULL_DAY_CLOSING",
            startDate: closing?.START_DATE ?? moment().startOf("day"),
            endDate: closing?.END_DATE ?? Date.now() + 1,
            startTime: closing?.START_TIME,
            endTime: closing?.END_TIME,
          }}
          validationSchema={schema}
          innerRef={(e) => (this.formikRef = e)}
        >
          {(formikOptions) => {
            const {handleSubmit, values} = formikOptions;

            let {type} = values;

            return (
              <form onSubmit={handleSubmit}>
                <FormInput label="Name" name={"name"} options={formikOptions} />

                <FormDateTimeSelect
                  name="startDate"
                  label="Start Date"
                  buttonText={(epoch) => moment(epoch).format("M/D/YY")}
                  options={formikOptions}
                  hideTime
                />

                <FormDateTimeSelect
                  name="endDate"
                  label="End Date"
                  buttonText={(epoch) => moment(epoch).format("M/D/YY")}
                  options={formikOptions}
                  hideTime
                />

                <FormSelect
                  label={"Type"}
                  name={"type"}
                  data={[
                    {label: "Full Day Closing", value: "FULL_DAY_CLOSING"},
                    {label: "Special Hours", value: "SPECIAL_HOURS"},
                  ]}
                  options={formikOptions}
                />

                {type === "SPECIAL_HOURS" && (
                  <div>
                    <FormTimeSelect
                      name="startTime"
                      label="Open Time"
                      options={formikOptions}
                    />

                    <FormTimeSelect
                      name="endTime"
                      label="Close Time"
                      options={formikOptions}
                    />
                  </div>
                )}
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

export default ClosingsModal;
