import React, {Component} from "react";
import {FormDate, FormSelect, Modal} from "@frostbyte-technologies/frostbyte-tailwind";
import {FieldArray, Formik} from "formik";
import EmployeeDropdown from "../../dropdowns/team/employee-dropdown";
import {request} from "../../utils/request";
import PropTypes from "prop-types";
import moment from "moment-timezone";
import * as Yup from "yup";
import LocationsDropdown from "../../dropdowns/team/locations-dropdown";
import {setupReduxConnection} from "../../redux";
import CardAlert from "../../features/card-alert";
import {differenceInHours} from "../../utils/time-helper";
import {fetchUnpaidBreaksTotalMinutes} from "../../utils/breaks-helper";
import FormRow from "../../components/form-row";
import {minutesToHours} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import TipBreakdownModal from "../timesheets/tip-breakdown-modal";
import {MILLI_DAY} from "../../utils/constants";
import TipBreakdownModalNew from "../timesheets/tip-breakdown-modal-new";
import {showErrorNotification} from "../../utils/notification-helper";

class TimeCardModal extends Component {
  state = {card: null, roles: [], isNew: false, dummy: 0, timezoneOffset: 0};

  componentDidMount() {
    this.fetchBrowserTimezoneOffset();
  }

  fetchBrowserTimezoneOffset() {
    const browserTimezone = moment.tz.guess();

    const shopDate = moment();
    const browserDate = moment().tz(browserTimezone);

    const offset = browserDate.utcOffset() / 60 - shopDate.utcOffset() / 60;

    this.setState({timezoneOffset: offset});
  }

  open(card = null) {
    const {employee} = this.props;

    this.setState({card, isNew: card ? false : true}, () => {
      if (card) {
        this.fetchEmployeeRoles(card.EMPLOYEE_ID);
      } else if (employee) {
        this.fetchEmployeeRoles(employee.ID);
      }

      this.formikRef && this.formikRef.resetForm({});
      this.modal.open();
    });
  }

  async createTimeCard({location, date, to, from, employee, role, breaks}) {
    const {card} = this.state;

    if (!from && from !== 0) {
      this.modal.stopLoading();

      return showErrorNotification("", "start time is required.");
    }

    if (!to && Date.now() - from > MILLI_DAY) {
      this.modal.stopLoading();

      return showErrorNotification(
        "",
        "Please ensure the time card has an end date or is within the past day if clocked in"
      );
    }

    let timesheetPayload = {
      EMPLOYEE_ID: employee,
      LOCATION_ID: location,
      DATE_START: from,
      DATE_END: to ? to : null,
      ROLE_ID: role,
      BREAKS: breaks,
    };

    try {
      const serverTimeCard = await request("team/timesheet", "POST", timesheetPayload);

      this.props.onChange();
    } catch (err) {
      this.modal.stopLoading();

      return showErrorNotification(
        "",
        "Error creating time card. Please refresh the page and try again."
      );
    }

    this.modal.close();
  }

  async deleteCard() {
    const {card} = this.state;

    await request("team/timesheet/" + card.ID, "DELETE");

    if (this.props.onDelete) {
      this.props.onDelete(card);
    } else {
      this.props.onChange();
    }

    this.modal.close();
  }

  async saveTimeCard({location, date, to, from, employee, role, breaks}) {
    const {card} = this.state;

    if (!to && Date.now() - from > MILLI_DAY) {
      this.modal.stopLoading();

      return showErrorNotification(
        "",
        "Please ensure the time card has an end date or is within the past day if clocked in"
      );
    }

    if (!from && from !== 0) {
      this.modal.stopLoading();

      return showErrorNotification("", "Start time is required");
    }

    let timesheetPayload = {
      LOCATION_ID: location,
      EMPLOYEE_ID: employee,
      DATE_START: from,
      DATE_END: to ? to : null,
      ROLE_ID: role,
      BREAKS: breaks,
    };

    try {
      const serverTimeCard = await request(
        "team/timesheet/" + card.ID,
        "PATCH",
        timesheetPayload
      );

      this.props.onChange(serverTimeCard);
    } catch (err) {
      this.modal.stopLoading();

      return showErrorNotification(
        "",
        "Error saving timecard. This may occur if the desired timesheet range conflicts with existing breaks taken."
      );
    }

    this.modal.close();
  }

  addBreak() {
    const {card} = this.state;

    const breakPayload = {
      BREAK_OPTION_ID: "",
      DATE_START: "",
      DATE_END: "",
      EMPLOYEE_ID: this.formikRef.values.employee,
      TIMESHEET_ID: card?.ID,
      IS_PAID: null,
    };

    let {breaks} = this.formikRef.values;

    breaks.push(breakPayload);

    this.formikRef.setFieldValue("breaks", breaks);
  }

  removeBreak(index) {
    let {breaks} = this.formikRef.values;

    breaks.splice(index, 1);

    this.formikRef.setFieldValue("breaks", breaks);
  }

  isAfterTipDataCollectionDate(card) {
    //card tip breakdowns not backfilled for before July 1st, 2023
    return card.DATE_START >= 1688184000000;
  }

  async fetchEmployeeRoles(employee) {
    const roles = await request("employeeroles/employee/" + employee, "GET");
    const {values, setFieldValue} = this.formikRef;

    if (values.role === null && roles.length > 0) {
      setFieldValue("role", roles[0].Roles.ID);
    }

    this.setState({
      roles: roles.map(({Roles}) => {
        return {value: Roles.ID, label: Roles.NAME};
      }),
    });
  }

  fetchDateEndUsingBreakOption(row) {
    const {breakOptions} = this.props;

    const breakOption = breakOptions.find((item) => item.ID === row.BREAK_OPTION_ID);

    if (!breakOption) return "";

    return moment(row.DATE_START).add(breakOption.AMOUNT_MINUTES, "minutes").valueOf();
  }

  fetchIsPaidString(isPaid) {
    return isPaid ? "Paid" : "Unpaid";
  }

  renderBreaks() {
    const {card, timezoneOffset} = this.state;
    const {breakOptions, formikOptions} = this.props;

    let breakOptionSelectData = breakOptions
      .filter((_option) => _option.LOCATION_ID === this.formikRef?.values?.location)
      .map((item) => {
        return {
          value: item.ID,
          label: item.NAME + " (" + this.fetchIsPaidString(item.IS_PAID) + ")",
          metadata: item
        };
      });

    return (
      <div>
        {this.formikRef?.values?.breaks?.map((row, index) => (
          <div key={index}>
            <FormRow>
              <div className="flex flex-1 justify-between space-x-3">
                <FormDate
                  label={"Break Start"}
                  name={`breaks.${index}.start`}
                  style={{width: 170}}
                  showTime
                  timeIntervals={15}
                  value={row.DATE_START}
                  onChange={(selection) => {
                    const {breaks} = this.formikRef.values;

                    breaks[index].DATE_START = moment(selection)
                      .add(timezoneOffset, "hours")
                      .valueOf();

                    if (breaks[index].BREAK_OPTION_ID) {
                      breaks[index].DATE_END = this.fetchDateEndUsingBreakOption(row);
                    }

                    this.formikRef.setFieldValue("breaks", breaks);
                  }}
                  offset={timezoneOffset}
                />
                <FormDate
                  label={"Break End"}
                  name={`breaks.${index}.end`}
                  style={{width: 170}}
                  showTime
                  timeIntervals={15}
                  value={row.DATE_END}
                  onChange={(selection) => {
                    const {breaks} = this.formikRef.values;

                    breaks[index].DATE_END = moment(selection)
                      .add(timezoneOffset, "hours")
                      .valueOf();

                    this.formikRef.setFieldValue("breaks", breaks);
                  }}
                  offset={timezoneOffset}
                />
              </div>

              <div className="flex flex-1 flex-row max-w-sm">
                <FormSelect
                  data={breakOptionSelectData}
                  name={`breaks.${index}.option`}
                  label={"Option"}
                  style={{width: 330}}
                  value={row.BREAK_OPTION_ID}
                  onChangeSoft={(selection) => {
                    const {breaks} = this.formikRef.values;

                    breaks[index].BREAK_OPTION_ID = selection.value;
                    breaks[index].IS_PAID = selection.metadata.IS_PAID;

                    if (breaks[index].DATE_START) {
                      breaks[index].DATE_END = this.fetchDateEndUsingBreakOption(
                        breaks[index]
                      );
                    }

                    this.formikRef.setFieldValue("breaks", breaks);
                  }}
                />

                <div>
                  <FontAwesomeIcon
                    className="mt-10 ml-3 hover:cursor-pointer"
                    icon={"times"}
                    color={"red"}
                    size={"lg"}
                    onClick={() => {
                      this.removeBreak(index);
                    }}
                  />
                </div>
              </div>
            </FormRow>
          </div>
        ))}
      </div>
    );
  }

  renderTopBanner() {
    const dateEnd = this.formikRef?.values?.to;
    const dateStart = this.formikRef?.values?.from;

    const totalHours = dateEnd ? differenceInHours(dateStart, dateEnd) : 0;
    const unpaidHours = minutesToHours(
      fetchUnpaidBreaksTotalMinutes(this.formikRef?.values?.breaks)
    );
    const paidHours = Math.max(0, parseFloat(totalHours) - parseFloat(unpaidHours));

    return (
      <div className="grid gap-5 grid-cols-3">
        <CardAlert
          label="Total Hours"
          icon="clock"
          value={dateEnd ? totalHours : " - "}
          hideView
        />

        <CardAlert
          label="Paid Hours"
          icon="money-check-edit-alt"
          value={paidHours ? paidHours.toFixed(2) : "0"}
          hideView
        />

        <CardAlert
          label="Unpaid Hours"
          icon="money-check-edit"
          value={unpaidHours}
          hideView
        />
      </div>
    );
  }

  render() {
    const {location} = this.props.shop;
    const {card, roles, isNew, timezoneOffset} = this.state;
    const {employee, breakOptions} = this.props;

    const hasBreakOptions = breakOptions && breakOptions.length > 0;

    const schema = Yup.object({
      role: Yup.number().nullable().required("Role is required."),
      employee: Yup.string().nullable().required("Employee is required"),
      location: Yup.number().nullable().required("Location is required"),
      from: Yup.number()
        .typeError("Please set a valid date")
        .required("Please set start date"),
      to: Yup.number()
        .nullable()
        .test(
          "valid dates",
          "End date must be after start date and within 24 hours",
          (value, ctx) => {
            const diff = moment(ctx.parent.to).diff(moment(ctx.parent.from), "hours");
            return !ctx.parent.to || (diff >= 0 && diff < 24);
          }
        ), // breaks: Yup.array()
      //   .nullable()
      //   .of(
      //     Yup.object().shape({
      //       start: Yup.string()
      //         // .required("Break start time is a required field")
      //         .test(
      //           "break start time",
      //           "Break must fall within timecard range",
      //           (value, ctx) => {
      //             if (!value)
      //               return ctx.createError({message: "Required field"});
      //             const timesheetStart = ctx.from[1].value.from;
      //             console.log(timesheetStart);
      //             return value >= timesheetStart;
      //           }
      //         ),
      //       end: Yup.string()
      //         // .required("Break end time is a required field")
      //         .test(
      //           "break end time",
      //           "Break must fall within timecard range",
      //           (value, ctx) => {
      //             if (!value)
      //               return ctx.createError({message: "Required field"});
      //             console.log(ctx, value);
      //             const timesheetEnd = ctx.from[1].value.to;
      //             return timesheetEnd ? value <= timesheetEnd : true;
      //           }
      //         ),
      //       option: Yup.number().required("Break option is a required field"),
      //     })
      //   ),
    });

    return (
      <Modal
        large
        ref={(e) => (this.modal = e)}
        label={isNew ? "Create Time Card" : "Save Time Card"}
        buttonLabel={isNew ? "Create" : "Save"}
        formikOnClick={() => this.formikRef}
        deleteLabel={card && "Delete"}
        deleteOnClick={this.deleteCard.bind(this)}
      >
        <TipBreakdownModalNew ref={(e) => (this.tipBreakdownModal = e)} />
        <Formik
          enableReinitialize
          innerRef={(e) => (this.formikRef = e)}
          onSubmit={isNew ? this.createTimeCard.bind(this) : this.saveTimeCard.bind(this)}
          validationSchema={schema}
          initialValues={{
            role: card?.ROLE_ID,
            location: card?.LOCATION_ID ?? location?.ID,
            employee: card?.EMPLOYEE_ID ?? employee?.ID,
            date:
              moment(card?.DATE_START).startOf("day").valueOf() ??
              moment().startOf("day").valueOf(),
            from: card?.DATE_START ?? null,
            to: card?.DATE_END ?? null,
            breaks: card?.BREAKS ?? [],
          }}
        >
          {(formikOptions) => {
            const {setFieldValue, handleSubmit, values} = formikOptions;

            return (
              <form onSubmit={handleSubmit}>
                {hasBreakOptions && this.renderTopBanner()}

                {card?.DATE_END && this.isAfterTipDataCollectionDate(card) && (
                  <div
                    className={
                      "items-right cursor-pointer text-indigo-500 font-bold text-sm my-3 w-full"
                    }
                    onClick={() => {
                      this.tipBreakdownModal.open(card);
                    }}
                  >
                    View Tip Payout Breakdown
                  </div>
                )}

                <div className={`text-sm font-medium mt-4 ${hasBreakOptions && ""}`}>
                  Employee Details
                </div>

                <LocationsDropdown
                  options={formikOptions}
                  name="location"
                  hideIfSingle
                  flex
                />

                <EmployeeDropdown
                  flex
                  name="employee"
                  options={formikOptions}
                  onChangeSoft={(data) => {
                    setFieldValue("role", null);

                    this.fetchEmployeeRoles(data.id);
                  }}
                  disabled={!!employee}
                />

                <FormSelect
                  label="Role"
                  data={roles}
                  options={formikOptions}
                  name="role"
                  flex
                />

                <div className={"flex flex-row justify-between mt-6"}>
                  <div className="text-sm font-medium">Recorded Hours</div>
                  {breakOptions && breakOptions.length > 0 && (
                    <div
                      onClick={() => this.addBreak()}
                      className="text-indigo-600 text-sm font-medium cursor-pointer"
                    >
                      {"+ Add Break"}
                    </div>
                  )}
                </div>

                <FormRow>
                  <FormDate
                    label={"From"}
                    name="from"
                    placeholder={"Select a time"}
                    showTime
                    timeIntervals={15}
                    options={formikOptions}
                    style={{width: 350}}
                    offset={timezoneOffset}
                  />

                  <FormDate
                    label={"To"}
                    name="to"
                    placeholder={"Select a time"}
                    showTime
                    timeIntervals={15}
                    options={formikOptions}
                    style={{width: 350}}
                    offset={timezoneOffset}
                  />
                </FormRow>

                <FieldArray
                  name="breaks"
                  options={formikOptions}
                  render={() => (
                    <div>
                      {breakOptions && breakOptions.length > 0 && this.renderBreaks()}
                    </div>
                  )}
                />
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

TimeCardModal.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export default setupReduxConnection(["shop"])(TimeCardModal);
