import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import {toDollars} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import React, {Component} from "react";
import SingleReportingTooltip from "./tooltips/single-reporting-tooltip";

class ReportingDayOfWeekGraph extends Component {
  getLeftMargin = () => {
    const {data} = this.props;

    return (
      data.reduce(
        (mostCharacters, value) =>
          Math.max(mostCharacters, this.yTickFormatter(value).length),
        0
      ) * LEFT_MARGIN_MULTIPLIER
    );
  };

  yTickFormatter = (value) => toDollars(value, true);

  render() {
    const {data} = this.props;

    const graphData = data.map(({DAY, DAY_ABBREVIATION, GROSS_SALES, PAYMENT_COUNT}) => ({
      day: DAY,
      dayAbbreviation: DAY_ABBREVIATION,
      grossSales: GROSS_SALES,
      paymentCount: PAYMENT_COUNT,
    }));

    return (
      <div>
        <ResponsiveContainer width="100%" height={150}>
          <BarChart data={graphData} margin={{left: this.getLeftMargin(), top: 10}}>
            <CartesianGrid strokeDasharray="3 3" vertical={false} />

            <Bar dataKey="grossSales" fill="#4e46e5" barSize={20} />

            <XAxis
              dataKey="dayAbbreviation"
              axisLine={{stroke: "#cccccc"}}
              tickLine={false}
              tickMargin={10}
              tickLine={{stroke: "#cccccc"}}
              tick={{fontSize: "0.75rem", fontWeight: "bold"}}
            />

            <YAxis
              tick={{fontSize: "0.75rem", fontWeight: "bold"}}
              tickMargin={10}
              tickFormatter={this.yTickFormatter}
              axisLine={{stroke: "#cccccc"}}
              tickLine={{stroke: "#cccccc"}}
              tickCount={3}
            />

            <Tooltip
              cursor={false}
              content={
                <SingleReportingTooltip
                  formatLabel={(payload) => {
                    return payload.day;
                  }}
                  rows={[
                    {
                      label: "Gross Sales",
                      formatValue: (payload) => {
                        return toDollars(payload.grossSales, true);
                      },
                    },
                    {
                      label: "Tickets",
                      formatValue: (payload) => {
                        return payload.paymentCount;
                      },
                    },
                  ]}
                />
              }
            />
          </BarChart>
        </ResponsiveContainer>
      </div>
    );
  }
}

const LEFT_MARGIN_MULTIPLIER = 5;

export default ReportingDayOfWeekGraph;
