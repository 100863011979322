import React, {Component} from "react";
import {FormInput, Table} from "@frostbyte-technologies/frostbyte-tailwind";
import {parseIdDict, toDollars} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import {setupReduxConnection} from "../../../redux";
import {withRouter} from "../../../utils/navigation";

class InventoryCountTable extends Component {
    getColumns(options) {
        const {setFieldValue, values} = options;
        const {ingredients} = this.props.supply;
        const {units} = this.props.supply;
        const allUnitsDict = parseIdDict(units, "ID");

        return [
            {
                value: "NAME",
                label: "Name",
                width: 200,
            },
            {
                value: "_",
                label: "Waste Loss/Gain",
                tooltip: "This value is calculated based on the Vendor Item price multiplied by the difference in expected and current quantity.",
                width: 100,
                format: (_, ingredient) => {
                    const data = values.ingredients[ingredient.ID] ?? 0;
                    const ingredientUnit = allUnitsDict[ingredient.UNIT_ID];
                    const vendorItem = ingredient.VENDOR_ITEMS.find(item =>
                        item.UNIT_ID == ingredientUnit.ID
                    )
                    const vendorItemAlternative = ingredient.VENDOR_ITEMS.find(item =>
                        !!ingredientUnit?.CONVERSIONS.find(conversion => conversion.TO_UNIT === item.UNIT_ID)
                    )
                    const conversion = ingredientUnit?.CONVERSIONS.find(conversion => conversion.TO_UNIT === vendorItemAlternative?.UNIT_ID)
                    const pricePerCase = vendorItem?.PRICE_PER_CASE ?? ((vendorItemAlternative?.PRICE_PER_CASE ?? 0) * (conversion?.RATE ?? 0));
                    const total = (data - ingredient.DISPLAY_STOCK) * pricePerCase;
                    return toDollars(total, true);
                },
            },
            {
                value: "DISPLAY_STOCK",
                label: "Variance (%)",
                width: 150,
                format: (stock, ingredient) => {
                    const data = values.ingredients[ingredient.ID] ?? 0;
                    if(stock === '0.00') return 'Not Calculable' ;
                    return `${(data/stock)*100}`.substring(0,5) + '%';
                }
            },
            {
                value: "DISPLAY_STOCK",
                label: "Difference Quantity",
                width: 150,
                format: (stock, ingredient) => {
                    const data = values.ingredients[ingredient.ID] ?? 0;
                    return `${data-stock} ${ingredient.DISPLAY_UNIT.NAME}`
                }
            },
            {
                value: "DISPLAY_STOCK",
                label: "Expected Quantity",
                width: 150,
                format: (data, row) => {
                    return `${data} ${row.DISPLAY_UNIT.NAME}`
                }
            },
            {
                value: "_",
                label: "Counted Quantity",
                width: 150,
                format: (index, row) => {
                    const {UNIT, ID} = row;
                    const unit = UNIT;
                    return (
                        <div className="flex flex-row w-96 items-center">
                            <input
                                type="text"
                                id={row.ID}
                                value={values.ingredients[row.ID]}
                                data-event-off="mouseleave"
                                data-event="mouseenter click"
                                className="flex py-2 mr-3 w-24 text-sm focus:ring-2 border-neutral-border focus:border-0 focus:ring-indigo-500 rounded-md"
                                onChange={(e) => {
                                    options.setFieldValue("ingredients", {
                                        ...options.values.ingredients,
                                        [row.ID]: e.target.value,
                                    });
                                    // options.setFieldValue(column.ID, e.target.value);
                                }}
                                placeholder="Quantity"
                                onFocus={function autoSelect(e) {
                                    e.target.select();
                                }}
                            />

                            <div className="text-nowrap whitespace-nowrap mr-2">{unit.NAME}</div>
                        </div>
                    );
                },
            }
        ];
    }

    render() {
        const {counts = [], options} = this.props;

        return (
            <Table
                data={counts}
                columns={this.getColumns(options)}
                searchFields={["NAME"]}
                {...this.props}
            />
        );
    }
}

export default setupReduxConnection(["supply"])(withRouter(InventoryCountTable));
