import React, {Component} from "react";
import {Loading, PageHeadings, Tab, Table} from "@frostbyte-technologies/frostbyte-tailwind";
import {setupReduxConnection} from "../../../../redux";
import {updateStateDelegator} from "../../../../utils/util";
import moment from "moment/moment";
import SupplyChainUnitModal from "../../../../modals/operations/ingredients/supply-chain-unit-modal";
import {SupplyChainUnitRequests} from "../../../../utils/request-helpers/supply-chain/supply-chain-requests";
import {ConversionsTable} from "./conversion-view";
import {showErrorAlert} from "../../../../utils/alert-helper";

class SupplyChainUnitsPage extends Component {
    state = {
        isLoading: true,
        ingredients: [],
        units: [],
    };

    componentDidMount() {
        this.syncState();
    }

    syncState = async () => {
        const supplyChainUnits = await SupplyChainUnitRequests.fetchSupplyChainUnits()

        this.setState({isLoading: false, units: supplyChainUnits});

    };

    deleteUnit = async (row) => {
        try {
            await SupplyChainUnitRequests.deleteSupplyChainUnit(row.ID);
        } catch (e) {
            if(e.error === 'HAS_INGREDIENTS') {
                showErrorAlert(
                    "Cannot Delete Unit",
                    "Unit is used by ingredients so it cannot be deleted."
                )
            }
        }
        await this.syncState();
    };

    fetchSIColumns() {
        return [
            {
                value: "NAME",
                label: "Name",
            },
            {
                value: "ABBREVIATION",
                label: "Abbreviation",
                format: (value) => value ?? ''
            },
            {
                value: "CONVERSIONS",
                label: "Linked Units",
                format: (values) => values.map((it) => it.UNIT.NAME).join(', ')
            }
        ]
    }

    fetchColumns() {
        return [
            {
                value: "NAME",
                label: "Name",
            },
            {
                value: "ABBREVIATION",
                label: "Abbreviation",
                format: (value) => value ?? ''
            },
            {
                width: 1,
                label: "Created",
                value: "DATE_CREATED",
                format: (value) => moment(value).format("MM/DD/YY"),
            },
            {
                width: 1,
                label: "Updated",
                value: "DATE_UPDATED",
                format: (value) => moment(value).format("MM/DD/YY"),
            },
        ]
    }

    fetchActionButtons() {
        //TODO: Allow Editing
        return [
            // {
            //     label: "Edit",
            //     onClick: (row) => this.unitModal.open(row),
            // },
            {
                icon: "trash",
                onClick: (row) => this.deleteUnit(row),
            },
        ]
    }

    renderTable(data, actionButtons, columns) {

        return (<Table
            pagination
            className="mt-4"
            data={data}
            rowsPerPage
            ref={(e) => (this.tableRef = e)}
            actionButtons={actionButtons}
            columns={columns}
            expandable={(unit) => {
                return (<ConversionsTable unit={unit}/>)
            }}
        />)
    }

    render() {
        const {isLoading, units} = this.state;
        const {ingredients} = this.props.supply;

        if (isLoading) {
            return <Loading/>;
        }

        const customData = units.filter((it) => it.COMPANY_ID != null)
        const siData = units.filter((it) => it.COMPANY_ID == null)

        return (
            <div>
                <PageHeadings
                    label="Supply Chain Units"
                    description="View the default units of measurement for your items/ingredients and create custom units tied to default measurements."
                    button={{
                        label: "Create a Custom Unit",
                        onClick: () => this.unitModal.open(),
                    }}
                />

                <SupplyChainUnitModal
                    ref={(e) => (this.unitModal = e)}
                    units={units}
                    addState={(unit) => this.setState({units: [unit, ...units]})}
                    updateState={(unit) => {
                        this.setState({
                            units: updateStateDelegator(units, unit.ID, unit),
                        });
                    }}
                    syncState={this.syncState}
                />
                <Tab data={[{id: 2, label: "Standard Units"}, {id: 1, label: 'Custom Units'}]}>
                    {(id) => {
                        switch (id) {
                            case 1:
                                return this.renderTable(customData, this.fetchActionButtons(), this.fetchColumns())
                            case 2:
                                return this.renderTable(siData, [], this.fetchSIColumns())
                        }
                    }}
                </Tab>

            </div>
        );
    }
}

export default setupReduxConnection(["supply", "shop"])(SupplyChainUnitsPage);
