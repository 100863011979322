import {request} from "../../request";

export const TeamsRequests = {
  fetchBlackoutDates: async () => {
    return request("timeoff/blackout/", "GET");
  },
  fetchBreakOptions: async () => {
    return request("team/break-option/company", "GET");
  },
  Employees: {
    updateEmployeeById: async (employeeId, params) => {
      return request("employees/" + employeeId, "PUT", params);
    },
    replaceEmployeeDefaultRole: async (params) => {
      return request("employees/default-role/replacement", "PATCH", params);
    },
  },
  EmployeeRoles: {
    deleteEmployeeRoleById: async (employeeId, params) => {
      return request("employeeroles/employee/" + employeeId, "DELETE", params);
    },
    deleteRoleById: async (roleId, params) => {
      return request("employeeroles/roles/" + roleId, "DELETE", params);
    },
    addEmployeeRoleById: async (employeeId, params) => {
      return request("employeeroles/employee/" + employeeId, "POST", params);
    },
  },
};
