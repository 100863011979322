import React, {Component} from "react";
import {Modal} from "@frostbyte-technologies/frostbyte-tailwind";
import {Formik} from "formik";
import FormProductSelect from "../../../components/form-product-select";

class PresetProductModal extends Component {
  open() {
    this.formikRef && this.formikRef.resetForm();
    this.modal.open();
  }

  render() {
    const {label, description, dropdownLabel} = this.props;

    return (
      <Modal
        buttonLabel="Add"
        ref={(e) => (this.modal = e)}
        label={label ?? "Select Modifier Product"}
        description={description ?? "Select a product to attach this modifier."}
        formikOnClick={() => this.formikRef}
      >
        <Formik
          initialValues={{products: []}}
          innerRef={(e) => (this.formikRef = e)}
          onSubmit={(values) => {
            this.props.updateState(values, () => this.modal.close());
          }}
        >
          {(formikOptions) => {
            const {handleSubmit} = formikOptions;

            return (
              <form onSubmit={handleSubmit}>
                <FormProductSelect
                  exclude={this.props.exclude}
                  label={dropdownLabel ?? "Modifier Products"}
                  options={formikOptions}
                  multi
                  excludeType={5}
                  name="products"
                />
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

export default PresetProductModal;
