import React, {Component} from "react";
import {getObjectImage} from "@frostbyte-technologies/frostbyte-core/dist/helpers/asset-helper";
import {parseIdDict, toDollars} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import {Filter, PageHeadings, Tab, Table} from "@frostbyte-technologies/frostbyte-tailwind";
import LoadingSpinner from "../../../../components/loading-spinner";
import SetupProductIngredientsModal
    from "../../../../modals/operations/supply-chain/recipes/setup-product-ingredients/setup-product-ingredients-modal";
import {getStore, setupReduxConnection} from "../../../../redux";
import {withRouter} from "../../../../utils/navigation";
import {request} from "../../../../utils/request";
import {updateProducts} from "../../../../redux/menu";
import {ReadOnlyTable} from "../shared";
import {INGREDIENT_TYPE_DISPLAYS} from "../../../../modals/operations/supply-chain/recipes/ingredient-modal";
import RecipePricingTab from "./RecipePricingTab";
import MigrateMenuModal
    from "../../../../modals/operations/supply-chain/recipes/setup-product-ingredients/migrate-menu/migrate-menu-modal";

class SupplyChainRecipesPage extends Component {
    recipeModal = null
    state = {products: null}

    syncState = async () => {
        const products = await request("products/", "GET");
        this.setState({products})
    }

    componentDidMount() {
        this.syncState()
    }

    componentDidUpdate(prevProps) {
        if (this.props.menu.products.length !== prevProps.menu.products.length) {
            this.tableRef.resetTableData(this.props.menu.products);
        }
    }

    productColumns = () => ([
        {
            width: 1,
            value: "NAME",
            label: "Product Name",
            format: (value, row) => {
                return (
                    <div className={"flex flex-row justify-between"}>
                        <div className="flex items-center">
                            <div className="flex-shrink-0 h-10 w-10">
                                <img
                                    src={getObjectImage(row, "LOGO", "logo_mark-01.jpg")}
                                    className="object-cover h-10 w-10 rounded-full"
                                    alt=""
                                />
                            </div>

                            <div className="ml-4">
                                <div className="text-sm font-medium text-gray-900">
                                    {row?.NAME}
                                </div>

                                <div className="text-sm text-gray-500">
                                    {row?.CATEGORY_NAME}
                                </div>
                            </div>
                        </div>
                    </div>
                );
            },
        },
        {
            width: 1,
            value: "PRICE",
            label: "Price",
            onClick: this.props.updatePricing
                ? (product) => this.pricingModal.open(product)
                : undefined,
            format: (value, rest) => {
                const pricingModifier = rest?.CUSTOMIZATIONS?.find(
                    (item) => item.TYPE === 5 && item.OPTIONS.length > 0
                );

                return pricingModifier
                    ? toDollars(
                    Math.min(
                        ...pricingModifier?.OPTIONS.map((modifier) => modifier.PRICE)
                    ),
                    true
                ) + "+"
                    : toDollars(value, true);
            },
        },
        {
            width: 1,
            value: "RECIPES",
            onClick: (row) => {
                this.recipeModal.open(row);
            },
            format: (recipes) => {
                if (recipes.length !== 0) {
                    return "Edit Recipes"
                } else {
                    return "Create Recipe"
                }
            }
        }
    ])

    recipeColumns = (unitDict) => [
        {key: 'VARIANT_OPTION', label: 'Variant Name', format: (it) => it?.NAME ?? 'All Variants'},
        {key: 'OPTION', label: 'Modifier Option', format: (it) => it?.NAME ?? 'All Options'},
        {key: 'NAME', label: 'Item/Ingredient'},
        {key: 'TYPE', label: 'Type', format: it => INGREDIENT_TYPE_DISPLAYS[it]},
        {key: 'RECIPE_COST', label: 'Quantity'},
        {key: 'CURRENT_STOCK', label: 'Current Stock'},
        {key: 'UNIT_ID', label: 'Unit', format: it => unitDict[it].NAME},
    ]

    renderProductsTable(unitDict) {
        const {products} = this.props.menu;

        return (
            <Filter searchable searchParams data={[]}>
                {(_, search) => (
                    <Table
                        ref={(e) => {
                            this.tableRef = e
                        }}
                        columns={this.productColumns()}
                        data={products}
                        searchFields={["NAME"]}
                        search={search}
                        pagination={true}
                        sorting
                        rowsPerPage
                        expandable={(product) => {
                            return (
                                <Tab data={[{id: 2, label: 'Recipes'}, {id: 1, label: 'Pricing'}]}>
                                    {(id) => {
                                        switch (id) {
                                            case 1:
                                                return (<RecipePricingTab product={product}/>);
                                            case 2:
                                                return (<ReadOnlyTable columns={this.recipeColumns(unitDict)}
                                                                       data={product.RECIPES}/>)
                                        }
                                    }}
                                </Tab>
                            )
                        }}
                    />
                )}
            </Filter>
        )
    }

    fetchModifierRecipesButton() {
        return {
            label: "Modifier Recipes",
            onClick: () => this.migrateMenuModal.open(),
        };
    }

    fetchPageHeadingButtons() {
        return [
            this.fetchModifierRecipesButton(),
        ];
    }

    renderPageHeadings() {
        return (
            <PageHeadings
                label="Recipes"
                description="Create and Edit recipes for your products.
                You can also assign items for finished goods (i.e. one tumbler)."
                buttons={this.fetchPageHeadingButtons()}
            />
        );
    }

    render() {
        const {products} = this.props.menu;
        const {units} = this.props.supply;

        const unitDict = parseIdDict(units);

        if (products.length <= 0) {
            return <LoadingSpinner/>;
        }

        return (
            <div>
                <SetupProductIngredientsModal
                    ref={(e) => (this.recipeModal = e)}
                    handleRecipeSync={async () => {
                        getStore().dispatch(updateProducts())
                    }}
                    unitDict={unitDict}
                />
                <MigrateMenuModal ref={(e) => (this.migrateMenuModal = e)}/>
                {this.renderPageHeadings()}
                {this.renderProductsTable(unitDict)}
            </div>
        )

    }
}

export default setupReduxConnection(["menu", "supply"])(withRouter(SupplyChainRecipesPage));
