import React, {Component} from "react";
import {
  Card,
  PageHeadings,
  Table,
} from "@frostbyte-technologies/frostbyte-tailwind";
import {setupReduxConnection} from "../../../redux";
import {request} from "../../../utils/request";
import DangerBadge from "../../../components/badges/danger-badge";
import SuccessBadge from "../../../components/badges/success-badge";
import {withRouter} from "../../../utils/navigation";
import HubPayrollEmployeeModal from "../../../modals/hub/hub-payroll-employee-modal";

class PayrollDashboardPage extends Component {
  state = {upcomingPaydays: null, payrollEmployees: null};

  async componentDidMount() {
    const {UPCOMING_PAYDAYS, PAYROLL_EMPLOYEES} = await request(
      "hub/payroll",
      "GET"
    );

    this.setState({
      upcomingPaydays: UPCOMING_PAYDAYS,
      payrollEmployees: PAYROLL_EMPLOYEES,
    });
  }

  render() {
    const {payrollEmployees} = this.state;

    const incompleteEmployees = payrollEmployees?.filter(
      (_emp) => _emp.CHECK_EMPLOYEE_STATUS !== "completed"
    );

    return (
      <div>
        <HubPayrollEmployeeModal
          ref={(e) => (this.hubPayrollEmployeeModal = e)}
        />
        <PageHeadings label={`Payroll Dashboard`} description={""} />

        {incompleteEmployees?.length > 0 && (
          <div role="alert">
            <div className="mt-3 bg-red-500 text-white font-bold px-4 py-2">
              Incomplete
            </div>
            <div className="border border-t-0 border-red-400 bg-red-100 px-4 py-3 text-red-700">
              <p>
                Please fill out the required information in order to get paid!
              </p>
            </div>
          </div>
        )}

        <Card
          label={"Personal Information"}
          description={
            "Set your information, bank account, and tax withholding settings"
          }
          className={"mt-6"}
        >
          <Table
            columns={[
              {
                label: "Name",
                value: "COMPANY_NAME",
                format: (value, row) => {
                  return <div>{value}</div>;
                },
              },
              {
                label: "Status",
                value: "CHECK_EMPLOYEE_STATUS",
                format: (value, row) => {
                  return (
                    <div>
                      {(value === "blocking" ||
                        value === "not_started" ||
                        value === "needs_attention") && (
                        <DangerBadge className={"h-5"}>Incomplete</DangerBadge>
                      )}

                      {value === "completed" && (
                        <SuccessBadge className={"h-5"}>Complete</SuccessBadge>
                      )}
                    </div>
                  );
                },
              },
              {
                label: "",
                width: 1,
                value: "CHECK_EMPLOYEE_STATUS",
                format: (value, row) => {
                  const notStarted = value === "not_started";

                  return (
                    <button
                      type="button"
                      className="rounded bg-indigo-600 px-2 py-1 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                      onClick={() => {
                        if (!notStarted) {
                          this.hubPayrollEmployeeModal.open(
                            row.CHECK_EMPLOYEE_UNIQUE_ID
                          );
                        } else {
                          this.props.router.navigate(
                            `/hub/payroll/onboard/${row.CHECK_EMPLOYEE_UNIQUE_ID}`
                          );
                        }
                      }}
                    >
                      {!notStarted ? "Open" : "Start Onboarding"}
                    </button>
                  );
                },
              },
            ]}
            data={payrollEmployees}
          />
        </Card>
      </div>
    );
  }
}

export default setupReduxConnection(["user"])(withRouter(PayrollDashboardPage));
