import React, {Component} from "react";
import FixedColumnReport from "../../features/reporting/reports/fixed-column-report";
import {PLATFORM_SALES_COLUMNS} from "../../features/reporting/reports/reporting-constants";
import {PageHeadings} from "@frostbyte-technologies/frostbyte-tailwind";
import {AUDIT_PLATFORM_SALES_COLUMNS} from "../../features/reporting/reports/audit-reporting-constants";
import {setupReduxConnection} from "../../redux";

class OrderTypeSalesReport extends Component {
  convertDataToRows(reportData) {
    let rows = [];

    if (reportData) {
      rows = reportData.ORDER_TYPE_RECORDS.map((item) => {
        return {
          label: item.ORDER_TYPE_NAME,
          numbers: item,
        };
      });

      rows.sort((row1, row2) => {
        return row2.numbers.ORDER_COUNT - row1.numbers.ORDER_COUNT;
      });

      rows.push({
        label: "Total",
        numbers: reportData.TOTALS,
      });
    }

    return rows;
  }

  render() {
    let {REPORTING_MODE, REPORTING_SHOW_LOCATION_CSV, REPORTING_SHOW_DATE_CSV} =
      this.props.shop.settings;

    let columns = PLATFORM_SALES_COLUMNS;

    if (REPORTING_MODE === "AUDIT") {
      columns = AUDIT_PLATFORM_SALES_COLUMNS;
    }

    return (
      <div>
        <div className="mb-5">
          <PageHeadings label={"Ticket Type Sales Report"} />
        </div>

        <FixedColumnReport
          filenamePrefix="order-type-sales"
          endpoint="report/ordertypesales"
          constantColumns={columns}
          convertDataToRows={this.convertDataToRows}
          firstColLabel="Ticket Type"
          locationPicker={true}
          showLocationsInDownload={REPORTING_SHOW_LOCATION_CSV === "1"}
          showDateRangeInDownload={REPORTING_SHOW_DATE_CSV === "1"}
        />
      </div>
    );
  }
}

export default setupReduxConnection(["reporting", "shop"])(OrderTypeSalesReport);
