import React, {Component} from "react";
import {FormInput, Modal} from "@frostbyte-technologies/frostbyte-tailwind";
import FormDateTimeSelect from "../../components/form-date-time-select";
import {Formik} from "formik";
import {ChevronDownIcon, ChevronUpIcon} from "@heroicons/react/solid";
import LineEntriesInput from "../../features/accounting/line-entries-input";
import {toDollars} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import {setupReduxConnection} from "../../redux";
import {withRouter} from "../../utils/navigation";

class DailySalesModal extends Component {
  state = {journalEntry: null, expanded: false};

  open(journalEntry) {
    this.setState({journalEntry}, () => this.modal.open());
  }

  handleSubmit = () => {};

  render() {
    const {ledgers} = this.props.accounting;
    const {journalEntry, expanded} = this.state;

    const lineEntries = journalEntry?.LINE_ENTRIES.map(
      ({UNIQUE_ID, LEDGER_ID, DEBIT_CREDIT, AMOUNT}) => ({
        uniqueId: UNIQUE_ID,
        ledgerId: LEDGER_ID,
        debitAmount: DEBIT_CREDIT === "DEBIT" ? toDollars(AMOUNT) : null,
        creditAmount: DEBIT_CREDIT === "CREDIT" ? toDollars(AMOUNT) : null,
      })
    );

    return (
      <Modal
        label={`Daily Sales`}
        ref={(e) => (this.modal = e)}
        formikOnClick={() => this.formikRef}
        closeLabel={"Close"}
      >
        <Formik
          initialValues={{
            name: journalEntry?.NAME,
            date: journalEntry?.DATE_OCCURRED,
            lineEntries,
          }}
          innerRef={(e) => (this.formikRef = e)}
          onSubmit={this.handleSubmit}
        >
          {(formikOptions) => {
            return (
              <div>
                <div className={"flex flex-row justify-between space-x-2"}>
                  <FormInput
                    className={"flex-1"}
                    label={"Entry Name"}
                    name={"name"}
                    options={formikOptions}
                  />

                  <FormDateTimeSelect
                    label="Date"
                    name={"date"}
                    hideTime={true}
                    disabled={true}
                    options={formikOptions}
                  />
                </div>

                <div
                  className={
                    "font-semibold text-indigo-500 text-sm cursor-pointer mt-3"
                  }
                  onClick={() =>
                    window.open(
                      "https://dashboard.dripos.com/reports/sales",
                      "_blank"
                    )
                  }
                >
                  View Sales Summary Report
                </div>

                <div
                  className={
                    "font-semibold text-indigo-500 text-sm cursor-pointer mt-3 flex flex-row"
                  }
                  onClick={() =>
                    this.setState((prevState) => ({
                      expanded: !prevState.expanded,
                    }))
                  }
                >
                  {!expanded && (
                    <ChevronDownIcon className="h-5 w-5 cursor-pointer" />
                  )}
                  {!!expanded && (
                    <ChevronUpIcon className="h-5 w-5 cursor-pointer" />
                  )}
                  Line Entries
                </div>

                {!!expanded && (
                  <LineEntriesInput
                    options={formikOptions}
                    disabled={true}
                    ledgers={ledgers}
                  />
                )}
              </div>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

export default setupReduxConnection(["accounting"])(
  withRouter(DailySalesModal)
);
