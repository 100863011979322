import React, {Component} from "react";
import {
  FormBoolean,
  FormInput,
  FormSelect,
  Modal,
} from "@frostbyte-technologies/frostbyte-tailwind";
import {Formik} from "formik";
import {request} from "../../../utils/request";
import * as Yup from "yup";
import {SYNC_STATUS_DICT} from "../../../utils/constants";

class CategoryDetailsModal extends Component {
  state = {category: null, schedule: null};

  open(category = null, schedule = null) {
    this.setState({category, schedule}, () => {
      this.formikRef && this.formikRef.resetForm({});
      this.modal.open();
    });
  }

  async createCategory({
    name,
    third,
    mobile,
    kiosk,
    autoComplete,

    pos,
    type,
  }) {
    let categoryPayload = {
      NAME: name,
      TYPE: type,
      POS_ENABLED: pos === "1",
      APP_ENABLED: mobile === "1",
      KIOSK_ENABLED: kiosk === "1",
      THIRD_PARTY_ENABLED: third === "1",
      AUTO_COMPLETE: autoComplete === "1",
    };

    const serverCategory = await request("category", "POST", categoryPayload);
    this.props.addState(serverCategory);
  }

  async saveCategory({name, third, mobile, kiosk, autoComplete, pos, type}) {
    const {category} = this.state;

    const {syncStatus = null} = this.props;

    let categoryPayload = {
      NAME: name,
      TYPE: type,
      POS_ENABLED: pos === "1",
      APP_ENABLED: mobile === "1",
      KIOSK_ENABLED: kiosk === "1",
      AUTO_COMPLETE: autoComplete === "1",
      THIRD_PARTY_ENABLED: third === "1",
    };

    let serverCategory = await request("category/" + category.ID, "PUT", categoryPayload);

    this.props.updateState({
      category: serverCategory,
      syncStatus:
        syncStatus === SYNC_STATUS_DICT.OUT_OF_DATE
          ? SYNC_STATUS_DICT.OUT_OF_DATE
          : SYNC_STATUS_DICT.AHEAD,
    });

    this.modal.close();
  }

  render() {
    const {category, schedule} = this.state;

    return (
      <Modal
        buttonLabel={category ? "Save" : "Create"}
        label={category ? "Edit Category" : "Create Category"}
        formikOnClick={() => this.formikRef}
        ref={(e) => (this.modal = e)}
      >
        <Formik
          onSubmit={
            category ? this.saveCategory.bind(this) : this.createCategory.bind(this)
          }
          innerRef={(e) => (this.formikRef = e)}
          enableReinitialize
          initialValues={{
            name: category?.NAME ?? "",
            type: category?.TYPE ?? "DRIPOS",
            pos: "" + (category?.POS_ENABLED ?? "1"),
            mobile: "" + (category?.APP_ENABLED ?? "1"),
            kiosk: "" + (category?.KIOSK_ENABLED ?? "1"),
            third: "" + (category?.THIRD_PARTY_ENABLED ?? "1"),
            autoComplete: "" + (category?.AUTO_COMPLETE ?? "0"),
          }}
          validationSchema={Yup.object({
            name: Yup.string().required("Category name is required"),
          })}
        >
          {(formikOptions) => {
            const {handleSubmit, values} = formikOptions;

            return (
              <form onSubmit={handleSubmit}>
                <FormInput
                  placeholder="Hot Coffee"
                  label="Name"
                  name="name"
                  options={formikOptions}
                />

                <FormSelect
                  label="Category Type"
                  name="type"
                  options={formikOptions}
                  tooltip={{
                    data: [
                      {
                        label: "Category Type Overview",
                        data: "Categories can have different types that are dependent on the enabled order types",
                      },
                      {
                        label: "Store Order",
                        data: "A store order category is a category with generic products that will be bought and sold in the store",
                      },
                    ],
                  }}
                  data={[{label: "Store Order", value: "DRIPOS"}]}
                />

                <FormBoolean
                  label="Enable on Point of Sale"
                  name="pos"
                  tooltip="If enabled, this category will appear on the point of sale."
                  options={formikOptions}
                />

                <FormBoolean
                  label="Enable on Mobile"
                  name="mobile"
                  tooltip={
                    "If enabled, this category will appear on the mobile ordering app and order website."
                  }
                  options={formikOptions}
                />

                <FormBoolean
                  label="Enable on Kiosk"
                  name="kiosk"
                  tooltip="If enabled, this category will appear on kiosks."
                  options={formikOptions}
                />

                <FormBoolean
                  label="Enable on Third Party"
                  name="third"
                  tooltip="If enabled, this category will appear on third party ordering platforms."
                  options={formikOptions}
                />

                <FormBoolean
                  options={formikOptions}
                  label="Auto Complete Products"
                  name="autoComplete"
                  tooltip={{
                    data: [
                      {
                        label: "Auto-complete",
                        data: "When enabled, products in this category will not be displayed on the Ticket Screen. This only applies to orders placed on the POS.",
                      },
                      {
                        label: "Warning",
                        data: "If all products on a ticket are auto-completed then the ticket will not appear on the tickets screen on the POS since all items on the ticket were completed.",
                      },
                    ],
                  }}
                />
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

const TAX_RATE_TYPES = {
  DEFAULT: "DEFAULT",
  CUSTOM: "CUSTOM",
};

export default CategoryDetailsModal;
