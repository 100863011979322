import {Component} from "react";

class LoadingSpinner extends Component {
    render() {
        let {size, borderSize, color, loadingText} = this.props;

        size = size || 30;
        borderSize = borderSize || 2;
        color = color || "rgb(107 114 128)";

        const style = {
            width: `${size}px`,
            height: `${size}px`,
            borderWidth: `${borderSize}px`,
            borderColor: `${color} transparent transparent transparent`,
        };

        return (
            <div className="flex flex-col">
                <div className="flex justify-center items-center">
                <span
                    className="loader inline-block rounded-full border-solid animate-spin"
                    style={style}>
                </span>
                </div>
                {loadingText && <div className="flex justify-center items-center">{loadingText}</div>}
            </div>
        );
    }
}

export default LoadingSpinner;