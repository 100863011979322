import React, {Component} from "react";
import {Card, PageHeadings} from "@frostbyte-technologies/frostbyte-tailwind";
import {request} from "../../utils/request";

class AccountingSettingsPage extends Component {
  async componentDidMount() {
    console.log(await this.generateLink());
  }

  async generateLink() {
    return await request("accounting/quickbooks/link");
  }

  render() {
    return (
      <div>
        <PageHeadings label={"Accounting Settings"} />

        <Card label={"Quickbooks"}></Card>
      </div>
    );
  }
}

export default AccountingSettingsPage;
