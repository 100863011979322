import {request} from "../../request";

export const EmployeeRequests = {
  fetchEmployee: async (employeeId) => {
    return request("employees/" + employeeId, "GET", null);
  },
  fetchEmployeePayRates: async (employeeId) => {
    return request("pay/rates/employee/" + employeeId, "GET");
  },
  fetchEmployeeTimeOffPolicies: async (employeeId) => {
    return request("timeoff/policies/employee/" + employeeId, "GET");
  },
  fetchEmployeeRoles: async (employeeId) => {
    return request("employeeroles/employee/" + employeeId, "GET");
  },
  fetchEmployeeReports: async (employeeId) => {
    return request("employees/" + employeeId + "/reports", "GET");
  },
  fetchEmployeeSchedule: async (employeeId) => {
    return request("scheduling/schedule/employee/" + employeeId, "GET");
  },
  fetchEmployeeTermination: async (employeeId) => {
    return request("termination/employee/" + employeeId, "GET");
  },
  fetchEmployeeUnavailabilities: async (employeeId) => {
    return request("scheduling/preferences/v2/" + employeeId, "GET");
  },
  fetchEmployeeTimeOffRequests: async (employeeId) => {
    return request("timeoff/requests/employee/" + employeeId, "GET");
  },
  fetchEmployeeNotes: async (employeeId) => {
    return request("employees/notes/" + employeeId, "GET");
  },
  fetchEmployeeFiles: async (employeeId) => {
    return request("employees/file/" + employeeId, "GET");
  },
  fetchEmployeeFolders: async (employeeId) => {
    return request("employees/folder/" + employeeId, "GET");
  },
  fetchEmployeeFranchiseLocations: async () => {
    return request("partner/franchise/locations", "GET");
  },
  fetchAllEmployees: async () => {
    return request("employees", "GET");
  },
};
