import React, {Component} from "react";
import {FormInput, Modal} from "@frostbyte-technologies/frostbyte-tailwind";
import {Formik} from "formik";
import * as Yup from "yup";
import FormRow from "../../../../components/form-row";
import IngredientDropdown from "../../../../dropdowns/operations/recipes/ingredient-dropdown";
import {
    decimalToDollars,
    toDollars,
} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import UnitDropdown from "../../../../dropdowns/operations/recipes/unit-dropdown";
import {
    showErrorNotification,
    showSuccessNotification,
} from "../../../../utils/notification-helper";
import {VendorRequests} from "../../../../utils/request-helpers/supply-chain/supply-chain-requests";
import {getStore} from "../../../../redux";
import {updateVendor, upsertVendor} from "../../../../redux/supply";
import {upsert} from "../../../../utils/util";

class VendorItemModal extends Component {
    state = {item: null, ingredient: null, initialValues: {}, resolve: null};

    open(item = null, initialValues = {}) {
        return new Promise((resolve) => {
            this.setState({item, initialValues, resolve}, () => {
                this.formikRef && this.formikRef.resetForm();
                this.modal.open();
            });
        });
    }

    async createVendorItem({ingredient, name, sku, price, caseSize, unit}) {
        const {vendor, syncState} = this.props;
        const {resolve} = this.state;

        const payload = {
            NAME: name,
            PRODUCT_SKU: sku,
            INGREDIENT_ID: ingredient,
            VENDOR_ID: vendor.ID,
            PRICE_PER_CASE: decimalToDollars(price),
            UNIT_ID: unit,
            CASE_SIZE: caseSize,
        };

        const item = await VendorRequests.createVendorItem(vendor.ID, payload);
        getStore().dispatch(upsertVendor({...vendor, ITEMS: [item, ...vendor.ITEMS]}));

        resolve && resolve(item);
        syncState()
        this.modal.close();
    }

    async saveVendorItem({ingredient, name, sku, price, caseSize, unit}) {
        const {vendor, syncState} = this.props;
        const {item} = this.state;

        const payload = {
            NAME: name,
            PRODUCT_SKU: sku,
            INGREDIENT_ID: ingredient,
            VENDOR_ID: vendor.ID,
            PRICE_PER_CASE: decimalToDollars(price),
            UNIT_ID: unit,
            CASE_SIZE: caseSize,
        };

        const serverItem = await VendorRequests.updateVendorItem(item.VENDOR_ID, item.ID, payload);

        getStore().dispatch(
            upsertVendor({...vendor, ITEMS: upsert(vendor.ITEMS, serverItem)})
        );

        syncState()
        this.modal.close();
    }

    deleteVendorItem() {
        const {syncState} = this.props;
        const {item} = this.state;

        VendorRequests.deleteVendorItem(item.VENDOR_ID, item.UNIQUE_ID)
            .then(() => {
                showSuccessNotification("Vendor item deleted successfully.");
                return getStore().dispatch(updateVendor(item.VENDOR_ID));
            })

            .catch((err) => {
                showErrorNotification("Error deleting sub-ingredient,");
            })
            .finally(() => {
                syncState()
                this.modal && this.modal.close();
            });
    }

    render() {
        const {item, ingredient, initialValues} = this.state;

        return (
            <Modal
                buttonLabel={item ? "Save" : "Add"}
                label={item ? "Edit Vendor Item" : "Create Vendor Item"}
                deleteLabel={item ? "Delete" : ""}
                deleteOnClick={() => this.deleteVendorItem()}
                ref={(e) => (this.modal = e)}
                formikOnClick={() => this.formikRef}
                large
            >
                <Formik
                    onSubmit={
                        item ? this.saveVendorItem.bind(this) : this.createVendorItem.bind(this)
                    }
                    innerRef={(e) => (this.formikRef = e)}
                    enableReinitialize
                    validationSchema={Yup.object({
                        ingredient: Yup.number().nullable().required("Ingredient is required"),
                        name: Yup.string().nullable().required("Product Name is required"),
                        sku: Yup.string(),
                        price: Yup.number()
                            .nullable()
                            .required("Please enter how much this item costs."),
                        caseSize: Yup.number()
                            .nullable()
                            .required("Please input the number of units in one item."),
                        unit: Yup.number()
                            .nullable()
                            .required("Please select the unit in which you are measuring this item."),
                    })}
                    initialValues={{
                        ingredient: item?.INGREDIENT_ID ?? null,
                        name: item?.NAME ?? initialValues.NAME ?? "",
                        sku: item?.PRODUCT_SKU ?? initialValues.PRODUCT_SKU ?? "",
                        price: item ? toDollars(item.PRICE_PER_CASE) : "",
                        caseSize: item?.CASE_SIZE ?? "",
                        unit: item?.UNIT_ID,
                        ingredientUnit: null,
                    }}
                >
                    {(formikOptions) => {
                        const {handleSubmit, values, setFieldValue} = formikOptions;

                        return (
                            <form onSubmit={handleSubmit}>
                                <IngredientDropdown
                                    label="Attached Item/Ingredient"
                                    name="ingredient"
                                    options={formikOptions}
                                    onChangeSoft={({ingredient}) => {
                                        setFieldValue("unit", ingredient.UNIT_ID);
                                        this.setState({ingredient});
                                    }}
                                />

                                <FormRow>
                                    <FormInput
                                        options={formikOptions}
                                        placeholder={"12 Gallon Crate"}
                                        label="Vendor Item"
                                        name="name"
                                        tooltip={{
                                            label: "Listing Name",
                                            data: "The name that the vendor displays on their price list.",
                                        }}
                                        flex
                                    />

                                    <FormInput
                                        options={formikOptions}
                                        placeholder="61798"
                                        label="SKU#"
                                        name="sku"
                                        hint="Optional"
                                        tooltip={{
                                            label: "Product Code",
                                            data: "The name that the vendor displays on their price list",
                                        }}
                                        flex
                                    />
                                </FormRow>

                                <FormRow>
                                    <FormInput
                                        label="Vendor Item Price"
                                        name="price"
                                        options={formikOptions}
                                        tooltip="How much this item costs."
                                        flex
                                    />

                                    <FormInput
                                        label="Vendor Item Quantity"
                                        name="caseSize"
                                        options={formikOptions}
                                        tooltip="The number of units one of these items contains. For example, a shipment of 12 gallons of milk would have 12 as the quantity and gallons as the unit."
                                        flex
                                    />

                                    <UnitDropdown
                                        label="Vendor Item Unit"
                                        name="unit"
                                        unit={ingredient?.UNIT_ID}
                                        ingredient={ingredient}
                                        options={formikOptions}
                                        tooltip="The unit in which you are measuring the ingredient. For example, a shipment of 12 gallons of milk would have a unit of gallons."
                                    />
                                </FormRow>
                            </form>
                        );
                    }}
                </Formik>
            </Modal>
        );
    }
}

export default VendorItemModal;
