import {Component} from "react";
import {FormInput, Modal} from "@frostbyte-technologies/frostbyte-tailwind";
import {Formik} from "formik";
import {setupReduxConnection} from "../../../redux";
import * as Yup from "yup";
import {request} from "../../../utils/request";

const timeOffSchema = Yup.object({
  timeOffRequestNoticeDays: Yup.number()
    .required("Duration is required")
    .typeError("Days must be a valid number")
    .min(0, "Days must be at least 0"),
});

class TimeOffRequestNoticeModal extends Component {
  open() {
    this.setState({}, () => {
      this.formikRef && this.formikRef.resetForm();
      this.modal.open();
    });
  }

  handleSubmit = async (values) => {
    let {timeOffRequestNoticeDays} = values;

    let payload = {
      TIME_OFF_REQUEST_NOTICE: timeOffRequestNoticeDays ?? 0,
    };

    await request("partner/settings/v2", "PATCH", {SETTINGS: payload});

    let settings = await request("settings/shop", "GET");

    this.props.updateShop({settings});

    this.modal.close();
  };

  render() {
    const {TIME_OFF_REQUEST_NOTICE} = this.props.shop.settings;

    return (
      <Modal
        label="Time Off Request Notice"
        buttonLabel="Save"
        formikOnClick={() => this.formikRef}
        ref={(e) => (this.modal = e)}
      >
        <Formik
          onSubmit={this.handleSubmit}
          initialValues={{timeOffRequestNoticeDays: TIME_OFF_REQUEST_NOTICE ?? 0}}
          validationSchema={timeOffSchema}
          innerRef={(ref) => (this.formikRef = ref)}
        >
          {(formikOptions) => {
            const {handleSubmit} = formikOptions;

            return (
              <form onSubmit={handleSubmit}>
                <FormInput
                  name="timeOffRequestNoticeDays"
                  label="Number of Days"
                  type="number"
                  options={formikOptions}
                />
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

export default setupReduxConnection(["shop"], null, {forwardRef: true})(
  TimeOffRequestNoticeModal
);
