import React, {Component} from "react";
import {Outlet} from "react-router-dom";
import PageLayout from "../page/page-layout";
import SideNav from "../../components/side-nav";
import {setupReduxConnection} from "../../redux";
import {PERMISSIONS} from "../../utils/constants";
import {faUserGroup, faUserTag, faBuildingUser} from "@fortawesome/pro-light-svg-icons";
import GrayBadge from "../../components/badges/gray-badge";

export const TEAM_ROUTES = [
  {
    id: "approvals",
    name: "Approval Center",
    permissions: [
      PERMISSIONS.TEAM.APPROVALS_FULL.id,
      PERMISSIONS.TEAM.APPROVALS_TIME_CARDS.id,
      PERMISSIONS.TEAM.APPROVALS_TIME_OFF.id,
      PERMISSIONS.TEAM.APPROVALS_TRADE_REQUESTS.id,
      PERMISSIONS.TEAM.APPROVALS_OPEN_SHIFT_REQUESTS.id,
    ],
    href: "/approvals",
  },
  {
    id: "team",
    name: "My Team",
    href: "/team",
    permissions: [PERMISSIONS.TEAM.MY_TEAM_FULL.id],
    children: [
      {
        searchable: true,
        icon: faUserGroup,
        name: "Employees",
        href: "/employees",
        permissions: [],
        paths: ["/employees", "/employee"],
      },
      {
        searchable: true,
        icon: faUserTag,
        name: "Roles",
        href: "/roles",
        permissions: [],
        paths: ["/roles", "/role"],
      },
      {
        searchable: true,
        icon: faBuildingUser,
        name: "Departments",
        href: "/departments",
        permissions: [],
        paths: ["/departments", "/department"],
      },
    ],
  },
  {
    id: "scheduling",
    name: "Scheduling",
    permissions: [PERMISSIONS.TEAM.SCHEDULING_FULL.id],
    href: "/scheduling",
  },
  {
    id: "cards",
    name: "Time Cards",
    permissions: [PERMISSIONS.TEAM.TIME_FULL.id],
    href: "/cards",
  },
  {
    id: "breakOptions",
    name: "Breaks",
    permissions: [PERMISSIONS.TEAM.TIME_FULL.id],
    href: "/breaks",
  },
  {
    id: "cards",
    name: "Time Off Policies",
    permissions: [PERMISSIONS.TEAM.POLICIES_FULL.id],
    href: "/policies",
    paths: ["/policies", "/policy"],
    children: [
      {
        id: "blackoutDates",
        name: "Blackout Dates",
        href: "/blackout",
        permissions: [PERMISSIONS.TEAM.POLICIES_FULL.id],
        paths: ["/blackout"],
      },
    ],
  },
  {
    id: "memos",
    name: "Memos",
    href: "/memos",
    permissions: [PERMISSIONS.TEAM.MEMOS_FULL.id],
    paths: ["/memos", "/memo"],
  },
  {
    id: "onboarding",
    name: (
      <div className={"flex flex-row items-center"}>
        <div>Onboarding</div>

        <GrayBadge darkGrey className={"ml-3"}>
          Beta
        </GrayBadge>
      </div>
    ),
    href: "/onboarding/overview",
    permissions: [PERMISSIONS.TEAM.ONBOARDING_FULL.id],
    live: true,
    children: [
      {
        id: "overview",
        name: "Overview",
        href: "/onboarding/overview",
        permissions: [PERMISSIONS.TEAM.ONBOARDING_FULL.id],
        live: true,
      },
      {
        id: "processes",
        name: "Processes",
        href: "/onboarding/processes",
        permissions: [PERMISSIONS.TEAM.ONBOARDING_FULL.id],
        live: true,
      },
      {
        id: "company-documents",
        name: "Company Documents",
        href: "/onboarding/company-documents",
        permissions: [PERMISSIONS.TEAM.ONBOARDING_FULL.id],
        paths: ["/onboarding/company-documents", "/onboarding/company-document"],
        live: true,
      },
    ],
  },
];

class TeamSideNav extends Component {
  render() {
    return (
      <div className="p-2 md:p-6 height-screen">
        <PageLayout className="mt-0">
          <div className="lg:col-start-1 lg:col-span-2">
            <SideNav
              title="Team"
              permissions={[PERMISSIONS.TEAM.FULL.id]}
              navigation={TEAM_ROUTES}
            />
          </div>

          <div className="lg:col-start-3 lg:col-span-10">
            <Outlet />
          </div>
        </PageLayout>
      </div>
    );
  }
}

export default setupReduxConnection(["shop"])(TeamSideNav);
