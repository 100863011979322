import React, {Component} from "react";
import {Modal} from "@frostbyte-technologies/frostbyte-tailwind";
import {Formik} from "formik";
import {request} from "../../../utils/request";
import * as Yup from "yup";
import LocationsDropdown from "../../../dropdowns/team/locations-dropdown";
import {
  showConfirmAlert,
  showErrorAlert,
  showSuccessAlert,
} from "../../../utils/alert-helper";
import {
  showErrorNotification,
  showSuccessNotification,
} from "../../../utils/notification-helper";
import {setupReduxConnection} from "../../../redux";

class ProductSyncModal extends Component {
  state = {category: null, categories: null};

  open(category = null) {
    this.setState({category, categories: null}, () => {
      this.formikRef && this.formikRef.resetForm();
      this.modal.open();
    });
  }

  saveProduct = async ({locations}) => {
    const {category} = this.state;

    if (locations.length === 0) return;

    let categoryPayload = {
      LOCATIONS: locations.map((item) => ({LOCATION_ID: item})),
      CATEGORIES: [category.ID],
    };

    try {
      await showConfirmAlert(
        "Confirm Category Sync",
        "You cannot undo a category sync. This is a beta feature and the data it create or deletes cannot be undone."
      );

      showSuccessNotification(
        "Category Sync Starting",
        "Syncing your category will take a while. Don't leave this page or start another sync until you receive a success notification.",
        {timeout: 10}
      );

      await request("sync/categories", "POST", categoryPayload);
      await this.props.updateState();

      this.modal && this.modal.stopLoading();
      this.modal && this.modal.close();

      setTimeout(() => {
        showSuccessAlert(
          "Category Sync Complete",
          "The category sync has been completed successfully!"
        );
      }, 250);
    } catch (e) {
      this.modal.stopLoading();
      this.modal.close();

      if (e?.error === "MENU_SYNC_IN_PROGRESS") {
        setTimeout(() => {
          showErrorNotification(
            "Menu Sync in Progress.",
            "There is already a menu sync in progress for this company. Please wait for it to finish before starting another."
          );
        }, 250);

        return;
      }

      setTimeout(() => {
        showErrorAlert(
          "Category Sync Error",
          "There was an error during the category sync"
        );
      }, 250);
    }
  };

  render() {
    const {location} = this.props.shop;

    return (
      <Modal
        buttonLabel="Sync"
        label="Sync Category - Beta"
        description="Syncs a category to a different location. This will override any existing products and modifiers that have already been synced, will create products and modifiers that don't exist, and will delete products and modifiers that exist on the target location that don't match this location. This will also update each categories details as well."
        ref={(e) => (this.modal = e)}
        formikOnClick={() => this.formikRef}
      >
        <Formik
          innerRef={(e) => (this.formikRef = e)}
          enableReinitialize
          initialValues={{
            locations: [],
          }}
          validationSchema={Yup.object({})}
          onSubmit={this.saveProduct}
        >
          {(formikOptions) => {
            const {handleSubmit} = formikOptions;

            return (
              <form onSubmit={handleSubmit}>
                <LocationsDropdown
                  name="locations"
                  label="Sync Locations (Can Select Multiple)"
                  options={formikOptions}
                  exclude={[location.ID]}
                  isCompany
                  multi
                />
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

export default setupReduxConnection(["shop"])(ProductSyncModal);
