import React, {Component} from "react";
import {Card, PageHeadings} from "@frostbyte-technologies/frostbyte-tailwind";
import {request} from "../../../../utils/request";
import LoadingSpinner from "../../../../components/loading-spinner";
import {getPayrollCompanyOnboardingLink} from "../../../../utils/payroll-helper";
import DangerBadge from "../../../../components/badges/danger-badge";
import SuccessBadge from "../../../../components/badges/success-badge";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {showErrorAlert} from "../../../../utils/alert-helper";
import {setupReduxConnection} from "../../../../redux";

class PayrollOnboardingPage extends Component {
  state = {
    loading: true,
    loadingCheckComponent: false,
    isCompanyCompleted: false,
    submitted: false,
  };

  async componentDidMount() {
    const group = this.props.shop.payrollGroup;
    const {IS_COMPANY_COMPLETE} = await request("payroll/onboard");

    this.setState({
      loading: false,
      submitted: group.STATUS === "SUBMITTED",
      isCompanyCompleted: IS_COMPANY_COMPLETE,
    });
  }

  submitForReview = async () => {
    try {
      await request("payroll/groups/submit", "POST");

      this.setState({submitted: true});
    } catch (e) {
      showErrorAlert(
        "Incomplete",
        "Please finish the location information step before submitting.",
        "Ok"
      );
    }
  };

  openHosted = () => {
    let {ID} = this.props.user.account;

    this.setState({loadingCheckComponent: true});

    getPayrollCompanyOnboardingLink({ID}).then((res) => {
      const handler = window.Check.create({
        link: res.url,
      });
      handler.open();

      setTimeout(() => this.setState({loadingCheckComponent: false}), 3000);
    });
  };

  renderLocationBadge() {
    let {isCompanyCompleted} = this.state;

    if (isCompanyCompleted) {
      return <SuccessBadge>Completed</SuccessBadge>;
    }

    return <DangerBadge>Incomplete</DangerBadge>;
  }

  render() {
    let {loadingCheckComponent, submitted, loading} = this.state;

    return (
      <div>
        <PageHeadings
          label={"Payroll Onboarding"}
          description={"Follow the steps below to setup payroll with Dripos"}
        />

        {loading && <LoadingSpinner />}

        {!loading && (
          <div>
            {!!submitted && (
              <Card className={"p-10 text-center "}>
                <div className="text-2xl font-semibold">In Review</div>
                <div className="mt-5">
                  Hang tight, we'll let you know when you're approved and good
                  to go!
                </div>
              </Card>
            )}

            {!submitted && (
              <Card className={"p-10"}>
                <div className={"border-b pb-5 font-semibold"}>
                  Onboarding Steps
                </div>

                <div className={"pt-5 flex-row flex"}>
                  <FontAwesomeIcon
                    icon={"store"}
                    className="h-4.5 w-4.5 pt-1.5 mr-4"
                  />

                  <div>
                    <div className="flex flex-row items-center">
                      <div className="text-lg font-medium mr-4">
                        Location Information
                      </div>

                      {this.renderLocationBadge()}
                    </div>

                    <div className="pt-1">
                      Fill out all required information on the following pop up:
                    </div>

                    {loadingCheckComponent && <LoadingSpinner />}

                    {!loadingCheckComponent && (
                      <button
                        type="button"
                        className="mt-2 inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        onClick={() => this.openHosted()}
                      >
                        Open Tax & Authorization Settings
                      </button>
                    )}
                  </div>
                </div>

                <div className="mt-10 flex-row flex">
                  <FontAwesomeIcon
                    icon={"building-columns"}
                    className="h-4.5 w-4.5 pt-1.5 mr-4"
                  />

                  <div>
                    <div className="text-lg font-medium">Bank Statements</div>

                    <div>
                      In order to secure 2-day approval, our payroll provider
                      requires a bank statement for each of the last 3 months in
                      PDF form.
                    </div>

                    <div>These PDFs can be emailed to payroll@dripos.com</div>
                  </div>
                </div>

                <div className="mt-10 flex-row flex">
                  <FontAwesomeIcon
                    icon={"users"}
                    className="h-4.5 w-4.5 pt-1.5 mr-4"
                  />

                  <div>
                    <div className={"text-lg font-medium"}>
                      Active Employees Setup
                    </div>

                    <div>
                      We need your employee's personal and tax information in
                      order to file documents on their behalf.
                    </div>

                    <div className="mt-2">
                      <div className={"ml-2"}>
                        <div>
                          1. On the{" "}
                          <a
                            className="text-indigo-500"
                            href="https://dashboard.dripos.com/payroll/employees"
                            target={"_tab"}
                          >
                            payroll employees page
                          </a>
                          , click "Add" next to their name
                        </div>

                        <div>
                          2. Click the gear next to their name, then "Send link
                          via email" to have them complete their information
                        </div>

                        <div>
                          4. Remind your employees to complete their forms until
                          all active employees have a status of "Complete"
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div>
                  <div className="mt-10 flex-row flex">
                    <FontAwesomeIcon
                      icon={"users-slash"}
                      className="h-4.5 w-4.5 pt-1.5 mr-4"
                    />

                    <div>
                      <div className={"text-lg font-medium"}>
                        Inactive Employees Setup
                      </div>

                      <div>
                        We need your employee's personal and tax information in
                        order to file documents on their behalf.
                      </div>

                      <div className="mt-2">
                        <div className={"ml-2"}>
                          <div>
                            1. On the{" "}
                            <a
                              className="text-indigo-500"
                              href="https://dashboard.dripos.com/payroll/employees"
                              target={"_tab"}
                            >
                              payroll employees page
                            </a>
                            , click "Add" next to their name
                          </div>

                          <div>
                            2. Click the gear next to their name, then "Send
                            link via email" to have them complete their
                            information
                          </div>

                          <div>
                            4. Remind your employees to complete their forms
                            until all active employees have a status of
                            "Complete"
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="mt-10 flex-row flex">
                  <FontAwesomeIcon
                    icon={"file-spreadsheet"}
                    className="h-4.5 w-4.5 pt-1.5 mr-4"
                  />

                  <div>
                    <div className={"text-lg font-medium"}>
                      Historical Payrolls
                    </div>

                    <div>
                      We need your payroll exports for all of 2024 in order to
                      properly file earnings on your behalf. Specific
                      instructions can be found in your email.
                    </div>

                    <div>These files can be emailed to payroll@dripos.com</div>
                  </div>
                </div>

                <button
                  type="button"
                  className="m-auto mt-10 block items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  onClick={async () => {
                    await this.submitForReview();
                  }}
                >
                  Confirm You've Completed Above Steps & Submit
                </button>
              </Card>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default setupReduxConnection(["user", "shop"])(PayrollOnboardingPage);
