import React, {Component} from "react";

import {BrowserRouter, Route, Routes} from "react-router-dom";
import LayoutIndex from "../layouts";
import {setupReduxConnection} from "../redux";
import LoginPage from "./entry/login-page";
import FourZeroFour from "./status/404";
import Dashboard from "./dashboards/dashboard";
import ScrollToTop from "../layouts/wrappers/top-wrapper";
import {PAGE_ROUTES} from "../settings/routes";
import PermissionWrapper from "../layouts/wrappers/permission-wrapper";
import FourZeroThree from "./status/403";
import SearchPage from "./search-page";
import {REAL_HISTORY} from "../utils/navigation";
import OnboardingLandingPage from "./team/onboarding/onboarding-landing-page";
import PayrollContractorLandingPage from "./finance/payroll/contractor-dashboard/payroll-contractor-landing-page";
import OnboardingCompletePage from "./team/onboarding/landing-page-types/onboarding-complete-page";
import AdminLayout from "../layouts/admin";
import {ADMIN_PAGE_ROUTES} from "../settings/admin/admin-routes";
import AdminWrapper from "../layouts/admin/admin-wrapper";
import ReceiptEditor from "../features/sales/flow/receipt-editor";
import EmployeeLayout from "../layouts/hub";
import HubWrapper from "../layouts/hub/hub-wrapper";
import {HUB_PAGE_ROUTES} from "../settings/hub/hub-routes";
import AuthPage from "./entry/auth-page";
import AuthRedirectPage from "./entry/auth-redirect-test";

class PagesIndex extends Component {
  componentDidMount() {
    window.addEventListener("popstate", (event) => {
      REAL_HISTORY.pop();
    });
  }

  render() {
    return (
      <BrowserRouter>
        <ScrollToTop>
          <Routes>
            <Route path="/login" element={<LoginPage />} />
            <Route path="/authorize" element={<AuthPage />} />
            <Route path="/oauth/redirect" element={<AuthRedirectPage />} />
            <Route path="/test" element={<ReceiptEditor />} />

            <Route
              path="/admin/*"
              element={
                <AdminLayout>
                  <Routes>
                    {ADMIN_PAGE_ROUTES.map(({side, routes}) => {
                      return (
                        <Route element={side}>
                          {routes.map(({path, element, header}) => {
                            return (
                              <Route
                                path={path}
                                element={
                                  <AdminWrapper header={header}>
                                    <div>{element}</div>
                                  </AdminWrapper>
                                }
                              />
                            );
                          })}
                        </Route>
                      );
                    })}
                  </Routes>
                </AdminLayout>
              }
            />

            <Route
              path={"/hub/*"}
              element={
                <EmployeeLayout>
                  <Routes>
                    {HUB_PAGE_ROUTES.map(({side, routes}) => {
                      return (
                        <Route element={side}>
                          {routes.map(({path, element, header}) => {
                            return (
                              <Route
                                path={path}
                                element={
                                  <HubWrapper header={header}>
                                    <div>{element}</div>
                                  </HubWrapper>
                                }
                              />
                            );
                          })}
                        </Route>
                      );
                    })}
                  </Routes>
                </EmployeeLayout>
              }
            />

            <Route
              path={"/onboarding/complete/:ID"}
              element={<OnboardingLandingPage />}
            />

            <Route path={"/onboarding/competed"} element={<OnboardingCompletePage />} />

            <Route
              path={"contractor-payroll/landing/:ID"}
              element={<PayrollContractorLandingPage />}
            />

            <Route
              path={"/employee-onboarding/:ID"}
              element={<OnboardingLandingPage />}
            />

            <Route
              path="/*"
              element={
                <LayoutIndex>
                  <Routes>
                    <Route index element={<Dashboard />} />

                    {PAGE_ROUTES.map(({side, routes, permissions: parentPermissions}) => {
                      return (
                        <Route element={side}>
                          {routes.map(({path, element, permissions, live}) => {
                            return (
                              <Route
                                path={path}
                                element={
                                  <PermissionWrapper
                                    live={live}
                                    permissions={[...permissions, ...parentPermissions]}
                                  >
                                    <div>{element}</div>
                                  </PermissionWrapper>
                                }
                              />
                            );
                          })}
                        </Route>
                      );
                    })}

                    <Route path="/search" element={<SearchPage />} />
                    <Route path="/*" element={<FourZeroFour />} />
                  </Routes>
                </LayoutIndex>
              }
            />

            <Route path="/403" element={<FourZeroThree />} />
          </Routes>
        </ScrollToTop>
      </BrowserRouter>
    );
  }
}

export default setupReduxConnection(["user"])(PagesIndex);
