import React, {Component} from "react";
import {classNames} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import {ChevronDownIcon, ChevronRightIcon} from "@heroicons/react/solid";
import {Tooltip} from "@frostbyte-technologies/frostbyte-tailwind";

class ReportingSuperTable extends Component {
  state = {expanded: []};

  render() {
    let {columns, expandable, isExpandable, data, isRowClickable, onRowClick} =
      this.props;
    let {expanded} = this.state;

    return (
      <div className="overflow-x-auto">
        <table
          style={{
            width: "100%",
            tableLayout: "fixed",
          }}
          className="min-w-full divide-y divide-gray-200"
        >
          <thead className="bg-gray-50">
            <tr>
              {columns.map((item) => {
                const columnStyle = {};

                if (item.width) {
                  columnStyle.width = item.width;
                }

                if (item.flex) {
                  columnStyle.flex = 1;
                }

                return (
                  <th
                    scope="col"
                    // style={columnStyle}
                    className={classNames(
                      "px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider w-40",
                      item.sticky && "sticky left-0 z-1 bg-gray-50"
                    )}
                  >
                    {item.label}

                    {item.tooltip && (
                      <Tooltip className="ml-2 inline" label={item.tooltip} />
                    )}
                  </th>
                );
              })}
            </tr>
          </thead>

          <tbody className="bg-white divide-y divide-gray-200" id={"table_" + this.id}>
            {data.map((row, index) => {
              const expandIndex = expanded.indexOf(index);
              const isExpanded = expandIndex !== -1;

              const keyRow = row.id || row.ID;

              let rowsToMap = [row];

              if (
                expandable &&
                (!isExpandable || isExpandable(row)) &&
                isExpanded &&
                row.expandedRows
              ) {
                rowsToMap = [row, ...row.expandedRows(row)];
              }

              return rowsToMap.map((item) => {
                let row = item;

                let rowIsExpandable =
                  expandable && (!isExpandable || (isExpandable && isExpandable(row)));

                return (
                  <>
                    <tr
                      id={"table_" + this.id + "_row_" + keyRow}
                      key={keyRow}
                      className={classNames(
                        isRowClickable && isRowClickable(row) && "cursor-pointer"
                      )}
                      onClick={() => {
                        if (onRowClick) {
                          onRowClick(row);
                        }
                      }}
                    >
                      {columns.map((column) => {
                        const value = row[column.value];

                        let rowColComp = value;

                        if (column.format) {
                          rowColComp = column.format(value, row);
                        }

                        return (
                          <td
                            className={classNames(
                              "px-6 py-4 text-sm text-gray-500",
                              column.sticky && "sticky left-0 bg-white z-1",
                              column.sticky && columns.length !== 2 && "bg-right-border",
                              expandable && !row.footer && "cursor-pointer pl-3"
                            )}
                            onClick={() => {
                              if (row.footer) {
                                return;
                              }

                              if (expandable && !isExpanded) {
                                expanded.push(index);
                              } else if (expandable && isExpanded) {
                                expanded = expanded.filter((item) => item != index);
                              }

                              this.setState({expanded});
                            }}
                          >
                            <div
                              style={{
                                wordBreak: "break-word",
                              }}
                              className={classNames(
                                column.sticky && expandable && "flex flex-row"
                              )}
                            >
                              {column.sticky &&
                                rowIsExpandable &&
                                !isExpanded &&
                                !row.footer && (
                                  <ChevronRightIcon className="mr-1 h-5 w-5 cursor-pointer" />
                                )}

                              {column.sticky && rowIsExpandable && isExpanded && (
                                <ChevronDownIcon className="mr-1 h-5 w-5 cursor-pointer" />
                              )}

                              {expandable && !rowIsExpandable && (
                                <div className="w-5"></div>
                              )}

                              <div className={classNames(row.footer && "ml-2")}>
                                {rowColComp}
                              </div>
                            </div>
                          </td>
                        );
                      })}
                    </tr>

                    {isExpanded && row.expandCell && (
                      <tr>
                        <td colSpan={columns.length}>{row.expandCell(row)}</td>
                      </tr>
                    )}
                  </>
                );
              });
            })}
          </tbody>
        </table>
      </div>
    );
  }
}

export default ReportingSuperTable;
