import React, {Component} from "react";
import {withRouter} from "../../../../utils/navigation";
import {Card, PageHeadings, Table} from "@frostbyte-technologies/frostbyte-tailwind";
import {request} from "../../../../utils/request";
import LoadingSpinner from "../../../../components/loading-spinner";
import {Formik} from "formik";
import * as Yup from "yup";
import {showConfirmAlert} from "../../../../utils/alert-helper";
import {
  decimalToDollars,
  toDollars,
} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import {showErrorNotification} from "../../../../utils/notification-helper";
import OcrModal from "../../../../modals/operations/supply-chain/purchase-orders/ocr-modal";
import IngredientDropdown from "../../../../dropdowns/operations/recipes/ingredient-dropdown";
import UnitDropdown from "../../../../dropdowns/operations/recipes/unit-dropdown";

class ReceiveTransferPage extends Component {
  state = {
    transfer: null,
  };

  componentDidMount() {
    const {UNIQUE_ID: uniqueId} = this.props.router.params;

    request("inventory-transfers/" + uniqueId, "GET").then((transfer) => {
      return this.setState({transfer});
    });
  }

  async receiveTransfer(values) {
    const {transfer} = this.state;

    this.receiveButton && this.receiveButton.startLoading();

    const parsedItems = values.items.map((item) => {
      return {
        QUANTITY: item.RAW_QUANTITY ?? item.QUANTITY,
        TOTAL: item.TOTAL,
        INGREDIENT_ID: item.INGREDIENT_ID,
        NAME: item.NAME,
        PRODUCT_SKU: item.PRODUCT_SKU,
        UNIT_ID: item.UNIT_ID,
        ITEM_REQUEST_ID: item.ITEM_REQUEST_ID,
        INVENTORY_TRANSFER_ID: item.INVENTORY_TRANSFER_ID,
      };
    });

    try {
      await showConfirmAlert(
        "Receive Transfer?",
        "Are you sure you want to mark this transfer as received? " +
          "Make sure all of the prices and quantities are correct before marking as received."
      );
    } catch (e) {
      this.receiveButton && this.receiveButton.stopLoading();
      return;
    }

    try {
      await request("inventory-transfers/receive/" + transfer.UNIQUE_ID, "POST", {
        ITEMS: parsedItems,
      });

      this.props.router.navigate("/transfer/" + transfer.UNIQUE_ID);
    } catch (e) {
      showErrorNotification(
        "Error receiving transfer.",
        "There was an error receiving this transfer. Please try again."
      );
    }
  }

  removeItem(item, items, removeAll = false) {
    const newCart = [...items];

    const existingIdx = items.findIndex((_cartItem) => _cartItem.ID === item.ID);

    if (existingIdx !== -1) {
      const cartItem = newCart[existingIdx];

      if (cartItem.QUANTITY === 1 || removeAll) {
        newCart.splice(existingIdx, 1);
      } else {
        newCart[existingIdx].QUANTITY -= 1;
      }
    }

    return newCart;
  }

  addItem(item, items) {
    const newCart = [...items];

    const existingIdx = items.findIndex((_cartItem) => _cartItem.ID === item.ID);

    if (existingIdx !== -1) {
      newCart[existingIdx].QUANTITY += 1;
    } else {
      newCart.push({...item, QUANTITY: 1});
    }

    return newCart;
  }

  async scanInvoice() {
    const {vendorItems} = this.state;
    const invoiceData = await this.ocrModal.open();

    const lines = invoiceData.LINE_ITEMS.map((lineItem) => {
      const quantity = lineItem.QUANTITY?.value.replace(/[^0-9.]/g, "") ?? "1";

      const amount =
        lineItem.UNIT_PRICE?.value.replace(/[^0-9.]/g, "") ??
        toDollars(
          decimalToDollars(lineItem.PRICE?.value.replace(/[^0-9.]/g, "")) /
            parseFloat(quantity),
          true
        );

      const sku = lineItem.PRODUCT_CODE?.value;
      let objectId = null;
      let name = null;

      if (sku) {
        const vendorItem = vendorItems.find((vi) => vi.PRODUCT_SKU + "" === sku + "");
        objectId = vendorItem?.UNIQUE_ID;
        name = vendorItem?.NAME;
      }

      return {
        QUANTITY: parseFloat(quantity),
        AMOUNT: amount,
        NAME: name ?? lineItem.NAME?.value ?? lineItem.ITEM?.value,
        TOTAL: toDollars(decimalToDollars(amount) * parseFloat(quantity), true),
        AMOUNT_DISCOUNT: "$0.00",
        AMOUNT_FEES: "$0.00",
        AMOUNT_REFUNDED: "$0.00",
        AMOUNT_TAX: "$0.00",
        PRODUCT_SKU: sku,
        TYPE: "CUSTOM_ITEM",
        OBJECT_ID: objectId,
      };
    });

    this.formikRef.setFieldValue("items", lines);
  }

  getColumns() {
    let {transfer} = this.state;

    return [
      {
        label: "Name",
        value: "INGREDIENT_ID",
        format: (id, row) => {
          return (
            <IngredientDropdown
              fixed
              compact
              value={row.INGREDIENT_ID}
              onChange={(_, item) => {
                const {ingredient} = item;
                const idx = transfer.ITEMS.findIndex((_item) => _item.tid === row.tid);

                this.props.addItem(idx, {
                  NAME: ingredient.NAME,
                  INGREDIENT_ID: ingredient.ID,
                  INGREDIENT: ingredient,
                  UNIT_ID: ingredient.UNIT_ID,
                  QUANTITY: 1,
                  TOTAL: this.calculateCost({
                    NAME: ingredient.NAME,
                    INGREDIENT_ID: ingredient.ID,
                    INGREDIENT: ingredient,
                    UNIT_ID: ingredient.UNIT_ID,
                    QUANTITY: 1,
                  }),
                });
              }}
              ignoreMargin
              style={{
                marginTop: 0,
                minWidth: 100,
                flex: 1,
                borderWidth: 0,
              }}
              placeholder="Input an ingredient"
            />
          );
        },
      },
      {
        label: "Quantity",
        value: "QUANTITY",
        editable: true,
        onChange: (idx, val) => {
          this.props.addItem(idx, {
            QUANTITY: val ? parseFloat(val) : 0,
            RAW_QUANTITY: this.calculateQuantity({
              ...transfer.ITEMS[idx],
              QUANTITY: val ? parseFloat(val) : 0,
            }),
            TOTAL: this.calculateCost({
              ...transfer.ITEMS[idx],
              QUANTITY: val ? parseFloat(val) : 0,
            }),
          });
        },
      },
      {
        label: "Your Current Stock",
        value: "",
        format: (_, row) => {
          const unit = row.UNIT_ID;

          if (unit !== row.INGREDIENT?.UNIT_ID) {
            const conversion = row.INGREDIENT.UNIT.CONVERSIONS?.find((_conversion) => {
              return _conversion.TO_UNIT === unit;
            });

            return (row.INGREDIENT?.CURRENT_STOCK * conversion?.RATE).toFixed(2);
          }

          return row.INGREDIENT?.CURRENT_STOCK;
        },
      },
      {
        label: "Unit",
        value: "UNIT_ID",
        format: (id, row) => {
          return (
            <UnitDropdown
              fixed
              compact
              value={row.UNIT_ID}
              onChange={(_, item) => {
                const idx = transfer.ITEMS.findIndex((_item) => _item.tid === row.tid);

                this.props.addItem(idx, {
                  UNIT_ID: item.id,
                  UNIT: item.unit,
                  TOTAL: this.calculateCost({
                    ...transfer.ITEMS[idx],
                    UNIT_ID: item.id,
                    UNIT: item.unit,
                  }),
                });
              }}
              ignoreMargin
              ingredient={row.INGREDIENT}
              style={{
                marginTop: 0,
                minWidth: 100,
                flex: 1,
                borderWidth: 0,
              }}
              placeholder="Input a unit"
            />
          );
        },
      },
      {
        label: "Cost",
        value: "TOTAL",
        format: (val) => toDollars(val, true),
      },
    ];
  }

  renderForm() {
    const {transfer} = this.state;

    return (
      <Formik
        onSubmit={(values) => this.receiveTransfer(values)}
        innerRef={(e) => (this.formikRef = e)}
        validationSchema={Yup.object({
          items: Yup.array()
            .of(
              Yup.object({
                QUANTITY: Yup.number().nullable().required("Please enter in a quantity"),
                TOTAL: Yup.number().nullable().required("Please enter in the total"),
                INGREDIENT_ID: Yup.number()
                  .nullable()
                  .required("Please select an ingredient."),
                NAME: Yup.string().nullable().required("Please select an ingredient."),
                PRODUCT_SKU: Yup.string().nullable(),
                UNIT_ID: Yup.number().nullable().required("Please select a unit"),
                ITEM_REQUEST_ID: Yup.number().nullable(),
              })
            )
            .required("Lines are required"),
        })}
        initialValues={{
          items:
            transfer?.ITEMS.map((item) => ({
              ...item,
              UNIT_ID: item.INGREDIENT.UNIT_ID,
            })) ?? [],
        }}
      >
        {(formikOptions) => {
          const {handleSubmit, values, setFieldValue} = formikOptions;

          return (
            <form onSubmit={handleSubmit}>
              <OcrModal ref={(e) => (this.ocrModal = e)} />

              <Card
                label="Receive Transfer"
                buttons={[
                  {
                    label: "Scan",

                    onClick: () => this.scanInvoice(),
                  },
                  {
                    label: "Receive",
                    ref: (e) => (this.receiveButton = e),
                    onClick: handleSubmit,
                  },
                ]}
              >
                <Table
                  hideBorder
                  className="mt-1"
                  actionTexts={[
                    {
                      label: "Add Item",
                      onClick: () => {
                        values.items.push({
                          AMOUNT: "$0.00",
                          AMOUNT_DISCOUNT: "$0.00",
                          AMOUNT_FEES: "$0.00",
                          AMOUNT_REFUNDED: "$0.00",
                          AMOUNT_TAX: "$0.00",
                          TOTAL: "$0.00",
                          NAME: "",
                          PRODUCT_SKU: null,
                          QUANTITY: 1,
                          TYPE: "CUSTOM_ITEM",
                        });

                        setFieldValue("items", values.items);
                      },
                    },
                  ]}
                  data={values.items}
                  columns={this.getColumns()}
                  pagination
                />
              </Card>
            </form>
          );
        }}
      </Formik>
    );
  }
  render() {
    const {transfer} = this.state;

    if (!transfer) {
      return <LoadingSpinner />;
    }

    return (
      <div>
        <PageHeadings
          className="py-4"
          label="Receive Transfer"
          description="Confirm the amounts and prices of the transfer"
        />

        {this.renderForm()}
      </div>
    );
  }
}

export default withRouter(ReceiveTransferPage);
