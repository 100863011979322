import React, {Component} from "react";
import {FormSelect} from "@frostbyte-technologies/frostbyte-tailwind";
import {request} from "../../utils/request";

class ProductTagsDropdown extends Component {
  state = {data: []};

  componentDidMount() {
    this.syncData().catch((err) => {
      console.log("Problem fetching employee data", err);
    });
  }

  async syncData() {
    const serverTags = await request("tags", "GET");
    const data = serverTags
      .filter((tag) => tag.DATE_ARCHIVED === null)
      .map((item) => {
        return {
          value: item.ID,
          label: item.NAME,
        };
      });

    this.setState({data});
  }

  render() {
    const {data} = this.state;

    return <FormSelect {...this.props} data={data} multi />;
  }
}

export default ProductTagsDropdown;
