export const ROLE_COLORS = [
  "5CAABC",
  "438ABE",
  "B195CD",
  "75B673",
  "ADCF7A",
  "7FC19D",
  "EC9760",
  "E99235",
  "DA755E",
  "C25B7E",
  "F1BC52",
  "EBC149",
];

export const TIME_OFF_REQUEST_STATUSES = {
  PENDING: {
    CODE: "PENDING",
    LABEL: "Pending",
    COLOR: "#FFAC5D",
  },
  ACCEPTED: {
    CODE: "APPROVED",
    LABEL: "Approved",
    COLOR: "#1C9115",
  },
  DENIED: {
    CODE: "DENIED",
    LABEL: "Denied",
    COLOR: "#AA3939",
  },
};

export const TRADE_REQUEST_TYPES = {
  employee_pending: {
    label: "Employee Pending",
    value: "PND",
  },
  ready_for_review: {
    label: "Ready For Review",
    value: "RVW",
  },
  employee_rejected: {
    label: "Employee Rejected",
    value: "RJD",
  },
  reviewer_denied: {
    label: "Reviewer Denied",
    value: "DND",
  },
  reviewer_approved: {
    label: "Reviewer Approved",
    value: "ACP",
  },
};

export const COMPENSATION_TYPES = {
  HOUR: {
    LABEL: "Hourly",
    VALUE: "HOUR",
  },
  SAL: {
    LABEL: "Salaried",
    VALUE: "SAL",
  },
};

export const OPEN_SHIFT_APPROVAL_TYPES = {
  pending: {
    label: "Pending",
    value: "PND",
  },
  denied: {
    label: "Denied",
    value: "DND",
  },
  approved: {
    label: "Approved",
    value: "ACP",
  },
};

export const UNAVAILABILITY_TYPES = {
  PREFERENCE: 0,
  UNAVAILABILITY: 1,
};
