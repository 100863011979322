import React, {Component} from "react";
import {request} from "../../utils/request";
import {
  parseIdDict,
  toDollars,
} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import {setupReduxConnection} from "../../redux";
import {withRouter} from "../../utils/navigation";
import GrayBadge from "../../components/badges/gray-badge";
import {TIME_CARD_EVENTS} from "../../utils/constants";
import Error from "../../components/error";
import {showErrorNotification} from "../../utils/notification-helper";
import {Modal, Loading, Table, Card} from "@frostbyte-technologies/frostbyte-tailwind";
import moment from "moment";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

class TipBreakdownModalNew extends Component {
  state = {
    summary: {},
    employeesDict: {},
    isLoading: true,
    isError: false,
  };

  open(timesheet = null) {
    this.modal.open();

    this.syncState(timesheet);
  }

  close() {
    this.modal.close();
  }

  async syncState(timesheet) {
    this.setState({isLoading: true});
    let {locationId} = this.props;
    const {location} = this.props.shop;

    let serverSummary;
    let serverEmployees;

    try {
      serverSummary = await request("team/time/tip-summary/" + timesheet.ID, "GET");

      locationId = locationId ?? location.ID;

      if (locationId) {
        serverEmployees = await request(`employees/v3/${locationId}`, "GET");
      }
    } catch (error) {
      this.setState({isError: true});

      return showErrorNotification(
        "",
        "Error fetching data. Please close the modal and try again."
      );
    }
    const employeesDict = parseIdDict(serverEmployees ?? []);

    this.setState({
      summary: serverSummary ?? {},
      employeesDict: employeesDict ?? {},
      isLoading: false,
    });
  }

  renderError() {
    return <Error label={"Error loading data"} />;
  }

  renderLabel() {
    return (
      <div className="text-gray-700 text-md">
        Tip Payout Summary
        <GrayBadge className="ml-1">Beta</GrayBadge>
      </div>
    );
  }

  renderContent() {
    const {summary} = this.state;

    return (
      <div>
        {this.renderTipBreakdown(summary)}

        {this.renderCardHistory(summary)}

        {this.renderCreditDebits(summary)}

        {this.renderTotalPaid(summary)}
      </div>
    );
  }

  renderTotalPaid(summary) {
    let {
      CREDIT_DEBIT_SUMMARY: creditDebits = {},
      TOTAL: payoutTotal,
      TIP_SUMMARY = {},
    } = summary;
    const {REQUIRE_TIME_CARD_BLESSINGS: isTipPayoutLocation} = this.props.shop.settings;

    if (payoutTotal) {
      payoutTotal = parseInt(payoutTotal);
    }

    if (!creditDebits || !creditDebits.TIMESHEET || !payoutTotal) {
      return <></>;
    }

    const {TIMESHEET: timesheet} = creditDebits;

    const amountEarnedCurrent =
      this.getTotalTipsEarnedFromTicketsAndBuckets(Object.values(TIP_SUMMARY)) +
      this.getTotalCreditsAndDebits(creditDebits.PAYOUT_TIMESHEETS);

    const creditDebitsFromCard = amountEarnedCurrent - payoutTotal;

    const columns = [
      {
        label: "Name",
        value: "NAME",
        format: (val, _) => <div>{val ?? "Tip Payout"}</div>,
      },
      {
        label: "Date Blessed",
        value: "DATE_BLESSED",
        format: (val, _) => <div>{moment(val).format("M/D h:mm A")}</div>,
      },
      {
        label: "Amount",
        value: "AMOUNT",
        format: (val, _) => {
          const toRender = val ?? payoutTotal;

          return (
            <div className={toRender > 0 ? "text-green-500" : "text-red-500"}>
              {"$" + toDollars(Math.abs(toRender))}
            </div>
          );
        },
      },
    ];

    return (
      <Card
        label={"Total Paid Out"}
        description={"The tip amount paid out on this time card, if applicable"}
        tooltip={{
          data: "Green implies a credit (positive amount), red implies a debit (negative amount)",
        }}
      >
        <Table
          columns={columns}
          data={[timesheet, {NAME: "Credits / Debits", AMOUNT: creditDebitsFromCard}]}
        />
      </Card>
    );
  }

  getTotalCreditsAndDebits(payoutTimesheets) {
    if (!payoutTimesheets || payoutTimesheets.length === 0) {
      return 0;
    }

    return payoutTimesheets.reduce((accum, _entry) => {
      return accum + _entry.AMOUNT;
    }, 0);
  }

  getTotalTipsEarnedFromTicketsAndBuckets(tipSummary) {
    if (!tipSummary || tipSummary.length === 0) {
      return 0;
    }

    return tipSummary.reduce((accum, _entry) => {
      return accum + _entry.AMOUNT;
    }, 0);
  }

  renderTipBreakdown(summary) {
    const {TIP_SUMMARY} = summary;

    if (!TIP_SUMMARY) {
      return <></>;
    }

    const columns = [
      {
        label: "Ticket / Bucket",
        value: "TICKET",
        format: (_, row) => {
          const {SUM, TICKET, BUCKET} = row;

          if (SUM) {
            return <div className={"font-bold"}>Total Tips Earned (Current)</div>;
          }

          let ticketName = "";

          if (TICKET) {
            ticketName = TICKET.ID ? "Ticket #" + TICKET.TICKET_NUMBER : "Offline Ticket";
          }

          const externalIcon = TICKET ? (
            <FontAwesomeIcon
              className="ml-2 h-3 w-3"
              icon={"external-link"}
              color={"indigo-500"}
              light
            />
          ) : (
            ""
          );

          return (
            <div className={TICKET ? "text-indigo-500" : ""}>
              {TICKET
                ? ticketName
                : "Bucket " + moment(BUCKET.DATE_CREATED).format("M/D h:mm A")}

              {externalIcon}
            </div>
          );
        },
      },
      {
        label: "Tips Earned",
        value: "AMOUNT",
        format: (val, row) => {
          return <div className={row.SUM ? "font-bold" : ""}>{"$" + toDollars(val)}</div>;
        },
      },
    ];

    const totalTipsEarned = this.getTotalTipsEarnedFromTicketsAndBuckets(
      Object.values(TIP_SUMMARY)
    );

    return (
      <Card
        label={"Tip Breakdown"}
        description={
          "Understand which tickets and buckets contributed to this time card's tips earned"
        }
      >
        <Table
          onClick={(row) => {
            const {TICKET} = row;

            if (!TICKET) {
              return;
            }

            return window.open(
              `${window.location.origin}/ticket/${TICKET.UNIQUE_ID}`,
              "_blank"
            );
          }}
          columns={columns}
          data={[...Object.values(TIP_SUMMARY), {SUM: true, AMOUNT: totalTipsEarned}]}
        />
      </Card>
    );
  }

  renderCardHistory(summary) {
    const {employeesDict} = this.state;
    const {TIP_HISTORY} = summary;

    if (!TIP_HISTORY) {
      return <></>;
    }

    const columns = [
      {
        label: "Time Card Name",
        value: "CARD",
        format: (_, row) => {
          let {triggerTimesheet, timesheet} = row;

          if (!triggerTimesheet) {
            triggerTimesheet = timesheet;
          }

          const triggerCardEmployeeName =
            employeesDict[triggerTimesheet?.EMPLOYEE_ID]?.FULL_NAME ?? "Unknown Employee";

          return (
            <div className={"text-indigo-500"}>
              {triggerCardEmployeeName}

              <FontAwesomeIcon
                className="ml-2 h-3 w-3"
                icon={"external-link"}
                color={"indigo-500"}
                light
              />
            </div>
          );
        },
      },
      {
        label: "Date",
        value: "DATE",
        format: (_, row) => {
          let {triggerTimesheet, timesheet} = row;

          if (!triggerTimesheet) {
            triggerTimesheet = timesheet;
          }

          return (
            <div>
              {moment(triggerTimesheet?.DATE_START).format("M/D h:mm A") +
                " - " +
                (triggerTimesheet?.DATE_END
                  ? moment(triggerTimesheet?.DATE_END).format("M/D h:mm A")
                  : "Not Clocked Out")}
            </div>
          );
        },
      },
      {
        label: "Event",
        value: "type",
        format: (_, row) => {
          const {type} = row;

          return <div>{TIME_CARD_EVENTS[type]}</div>;
        },
      },
      {
        label: "Tips Earned",
        value: "TIPS",
        tooltip: "Tips earned by this card prior to the event",
        format: (_, row) => {
          const {timesheet} = row;
          const totalTips = timesheet?.AMOUNT_TIPS + timesheet?.AMOUNT_TIPS_FIXED;

          return <div>{"$" + toDollars(totalTips)}</div>;
        },
      },
    ];

    return (
      <Card
        label={"Time Card History"}
        description={
          "Understand how this time card's tips have changed over time. Each row represents a time card that caused a re-calculation in this card's tips"
        }
      >
        <Table
          onClick={(row) => {
            const {triggerId} = row;

            if (!triggerId) {
              return;
            }

            const queryString = new URLSearchParams({id: triggerId}).toString();

            return window.open(
              `${window.location.origin}/cards/?${queryString}`,
              "_blank"
            );
          }}
          columns={columns}
          data={TIP_HISTORY}
        />
      </Card>
    );
  }

  renderCreditDebits(summary) {
    const {employeesDict} = this.state;
    const {CREDIT_DEBIT_SUMMARY: creditDebits} = summary;

    if (!creditDebits || creditDebits?.PAYOUT_TIMESHEETS?.length === 0) {
      return <></>;
    }

    const columns = [
      {
        label: "Time Card Name",
        value: "CARD",
        format: (_, row) => {
          const {TIMESHEET: timesheet} = row;

          const triggerCardEmployeeName =
            employeesDict[timesheet?.EMPLOYEE_ID]?.FULL_NAME ?? "Unknown Employee";

          return (
            <div className={"text-indigo-500"}>
              {triggerCardEmployeeName}

              <FontAwesomeIcon
                className="ml-2 h-3 w-3"
                icon={"external-link"}
                color={"indigo-500"}
                light
              />
            </div>
          );
        },
      },
      {
        label: "Date",
        value: "DATE",
        format: (_, row) => {
          const {TIMESHEET: timesheet} = row;

          return (
            <div>
              {moment(timesheet?.DATE_START).format("M/D h:mm A") +
                " - " +
                moment(timesheet?.DATE_END).format("M/D h:mm A")}
            </div>
          );
        },
      },
      {
        label: "Amount Credited (Debited)",
        value: "AMOUNT",
        format: (_, row) => {
          const {AMOUNT: amount} = row;
          const color = amount > 0 ? "text-green-500" : "text-red-500";

          return <div className={color}>{"$" + toDollars(Math.abs(amount))}</div>;
        },
      },
    ];

    return (
      <Card
        label={"Credits and Debits"}
        description={
          "Understand if any other time cards contributed to the total tip payout on this time card."
        }
        tooltip={{
          data: "Green implies a credit (positive amount), red implies a debit (negative amount)",
        }}
      >
        <Table
          onClick={(row) => {
            row = row[0];
            const {TIMESHEET: timesheet} = row;

            if (!timesheet || !timesheet.ID) {
              return;
            }

            const queryString = new URLSearchParams({id: timesheet.ID}).toString();

            window.open(`${window.location.origin}/cards/?${queryString}`, "_blank");
          }}
          columns={columns}
          data={creditDebits.PAYOUT_TIMESHEETS}
        />
      </Card>
    );
  }

  render() {
    const {isLoading, isError, summary} = this.state;

    return (
      <div>
        <Modal
          buttonOnClick={() => this.close()}
          label={this.renderLabel()}
          description=""
          ref={(e) => (this.modal = e)}
          large
        >
          {!!isLoading && <Loading />}

          {!!isError && this.renderError()}

          {this.renderContent()}
        </Modal>
      </div>
    );
  }
}

export default setupReduxConnection(["shop"])(withRouter(TipBreakdownModalNew));
