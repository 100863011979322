import {
  classNames,
  toDollars,
} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import {Crosshair} from "react-vis";
import React, {Component} from "react";

class ReportingGraphCrosshair extends Component {
  render() {
    let {timeString, totalSales, ticketCount, isLeft} = this.props;

    return (
      <div
        className={classNames(
          isLeft ? "-ml-16" : "-mr-16",
          "bg-gray-600 shadow-md w-32 h-20 rounded-sm text-white pt-2 font-semibold text-center"
        )}
      >
        <h3 className="text-gray-100">{timeString}</h3>

        <h3 className="text-lg mt-1">{toDollars(totalSales, true)}</h3>

        <h3 className="text-gray-100">{ticketCount ?? 0} Tickets</h3>

        <div
          className={classNames(
            isLeft ? "align-triangle-left" : "align-triangle-right",
            "triangle mt-1"
          )}
        />
      </div>
    );
  }
}

export default ReportingGraphCrosshair;
