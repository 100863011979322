import React, {Component} from "react";
import {Loading, PageHeadings, Table} from "@frostbyte-technologies/frostbyte-tailwind";
import {withRouter} from "../../../../utils/navigation";
import moment from "moment-timezone";
import {toDollars} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import {WasteRequests} from "../../../../utils/request-helpers/supply-chain/supply-chain-requests";

class WastePage extends Component {
    state = {wasteType: null};

    componentDidMount() {
        const {UNIQUE_ID: id} = this.props.router.params;

        WasteRequests.fetchWasteType(id).then((wasteType) => {
            return this.setState({wasteType});
        });
    }

    render() {
        const {wasteType} = this.state;

        if (!wasteType) {
            return <Loading/>;
        }

        console.log(wasteType);

        return (
            <>
                <PageHeadings
                    label={wasteType.NAME}
                    description={wasteType.DESCRIPTION}
                    breadcrumbs={[
                        {label: "Supply Chain", url: "/supply"},
                        {label: "Wastes", url: "/wastes"},
                    ]}
                />

                <Table
                    className="mt-4"
                    data={wasteType.WASTES}
                    columns={[
                        {
                            label: "Timestamp",
                            value: "DATE_CREATED",
                            format: (val) => moment(val).format("hh:mma MM/DD/YYYY"),
                        },
                        {
                            label: "Item/Ingredient",
                            value: "INGREDIENT",
                            format: (val) => val.NAME,
                        },
                        {
                            label: "Employee",
                            value: "FULL_NAME",
                        },
                        {
                            label: "Waste Cost",
                            value: "COST",
                            format: (val) => toDollars(val, true),
                        },
                    ]}
                />
            </>
        );
    }
}

export default withRouter(WastePage);
