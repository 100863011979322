import React, {Component} from "react";
import {FieldArray} from "formik";
import {
  FormInput,
  FormSelect,
} from "@frostbyte-technologies/frostbyte-tailwind";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

class LineEntriesInput extends Component {
  render() {
    const {options, ledgers, disabled = false} = this.props;
    const {values, setFieldValue} = options;
    const {lineEntries} = values;

    return (
      <FieldArray name={"lineEntries"} options={options}>
        {({remove}) => (
          <div>
            {lineEntries.map((_l, index) => (
              <div className={"relative"}>
                <div className={"flex flex-row space-x-2 items-end w-full"}>
                  <FormSelect
                    style={{maxWidth: 194}}
                    className={"flex-1"}
                    label={"Account"}
                    name={`lineEntries.${index}.ledgerId`}
                    data={ledgers.map(({ID, NAME}) => ({
                      label: NAME,
                      value: ID,
                    }))}
                    value={_l.ledgerId}
                    options={options}
                    onChangeSoft={({value}) => {
                      lineEntries[index].ledgerId = value;

                      setFieldValue("lineEntries", lineEntries);
                    }}
                    disabled={disabled}
                  />

                  <FormInput
                    name={`lineEntries.${index}.debitAmount`}
                    style={{maxWidth: DEBIT_CREDIT_WIDTH}}
                    label={"Debit"}
                    options={options}
                    disabled={disabled}
                  />

                  <FormInput
                    name={`lineEntries.${index}.creditAmount`}
                    style={{maxWidth: DEBIT_CREDIT_WIDTH}}
                    label={"Credit"}
                    options={options}
                    disabled={disabled}
                  />
                </div>

                {lineEntries.length > 2 && !disabled && (
                  <FontAwesomeIcon
                    className={
                      "absolute text-red-500 text-sm cursor-pointer bottom-3 -right-4"
                    }
                    icon={"fa-x"}
                    onClick={() => remove(index)}
                  />
                )}
              </div>
            ))}

            {!disabled && (
              <div
                className={
                  "text-indigo-500 font-semibold text-sm mt-2 cursor-pointer"
                }
                onClick={() =>
                  setFieldValue("lineEntries", [...lineEntries, {}])
                }
              >
                + Add Account
              </div>
            )}
          </div>
        )}
      </FieldArray>
    );
  }
}

const DEBIT_CREDIT_WIDTH = 95;

export default LineEntriesInput;
