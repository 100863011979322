import {setupReduxConnection} from "../../redux";
import ComboBox from "../../components/combobox";
import React, {Component} from "react";

class LedgerDropdown extends Component {
  render() {
    const {inLine = false} = this.props;
    const {ledgers} = this.props.accounting;

    let extraProps = {};

    if (inLine) {
      extraProps = {
        fixed: true,
        compact: true,
        extraHeight: 0,
        ignoreMargin: true,
        style: {
          marginTop: 0,
          minWidth: 100,
          flex: 1,
          borderWidth: 0,
        },
      };
    }

    return (
      <ComboBox
        {...this.props}
        data={ledgers.map(({ID, NAME}) => ({
          label: NAME,
          id: ID,
        }))}
        {...extraProps}
      />
    );
  }
}

export default setupReduxConnection(["accounting"])(LedgerDropdown);
