import {FormBoolean, FormInput, Modal} from "@frostbyte-technologies/frostbyte-tailwind";
import React, {Component} from "react";
import {Formik} from "formik";
import {setupReduxConnection} from "../../../redux";
import {request} from "../../../utils/request";

class SchedulerHintModal extends Component {
  open() {
    this.setState({}, () => {
      this.formikRef && this.formikRef.resetForm();
      this.modal.open();
    });
  }

  handleSubmit = async (values) => {
    let {enabled, threshold, lateThreshold} = values;

    let payload = {
      SCHEDULER_HINTS: enabled,
      SCHEDULER_NO_SHOW_THRESHOLD: threshold,
      SCHEDULER_LATE_THRESHOLD: lateThreshold,
    };

    await request("partner/settings/v2", "PATCH", {SETTINGS: payload});

    let settings = await request("settings/shop", "GET");

    this.props.updateShop({settings});

    this.modal.close();
  };

  render() {
    let {SCHEDULER_HINTS, SCHEDULER_NO_SHOW_THRESHOLD, SCHEDULER_LATE_THRESHOLD} =
      this.props.shop.settings;

    return (
      <Modal
        label="Scheduler Hint Settings"
        tooltip={{
          data: "Configure scheduler settings for the currently signed-in location.",
        }}
        buttonLabel={"Save"}
        formikOnClick={() => this.formikRef}
        ref={(e) => (this.modal = e)}
      >
        <Formik
          innerRef={(e) => (this.formikRef = e)}
          onSubmit={this.handleSubmit}
          initialValues={{
            enabled: SCHEDULER_HINTS,
            threshold: SCHEDULER_NO_SHOW_THRESHOLD,
            lateThreshold: SCHEDULER_LATE_THRESHOLD,
          }}
        >
          {(formikOptions) => {
            const {handleSubmit} = formikOptions;

            return (
              <form onSubmit={handleSubmit}>
                <FormBoolean
                  name="enabled"
                  label="Enable Scheduler Hints"
                  options={formikOptions}
                  tooltip={
                    "When enabled, scheduler shifts will display hints for late clock ins and no shows. Hover over the icon in the bottom right corner of the scheduler event to see the status."
                  }
                />

                <FormInput
                  options={formikOptions}
                  label="Late Threshold (Minutes)"
                  name="lateThreshold"
                  placeholder={"5 Minutes (Default)"}
                  tooltip={
                    "Threshold after which a shift will be considered late. This setting is also used for clock in notifications."
                  }
                />

                <FormInput
                  name="threshold"
                  label="No Show Threshold (Minutes)"
                  options={formikOptions}
                  placeholder={"5 Minutes (Default)"}
                  tooltip={"Threshold after which a shift will be considered a no show"}
                />
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

export default setupReduxConnection(["shop"], null, {forwardRef: true})(
  SchedulerHintModal
);
