import {
  Card,
  Filter,
  PageHeadings,
  Table,
} from "@frostbyte-technologies/frostbyte-tailwind";
import {useEffect, useRef, useState} from "react";
import {withRouter} from "../../../../utils/navigation";
import CreateBrandedApp from "../../../../modals/admin/tools/create-branded-app-modal";
import {setupReduxConnection} from "../../../../redux";
import BrandedAppBadge from "../../../../features/admin/branded/branded-app-badge";

function BrandedAppsPage(props) {
  const {brandedApps} = props.admin;
  let createBandedModal = useRef(null);

  useEffect(() => {
    props.loadBrandedApps();
  }, []);

  return (
    <div className={"p-6"}>
      <CreateBrandedApp ref={(e) => (createBandedModal = e)} />

      <PageHeadings label={"Branded Apps"} />

      <Card
        label={"Apps"}
        button={{
          label: "+ Create",
          onClick: () => createBandedModal.open(),
        }}
      >
        <Filter
          defaultFilters={["DRAFT", "BUILDING", "READY_FOR_SUBMIT", "FAILED"].map(status => ({filter: "status", id:status}))}
          data={[
            {
              id: "status",
              label: "Status",
              onFilter: (options, data) => {
                return data.filter((item) => options.includes(item.STATUS));
              },
              options: [
                {label: "Draft", id: "DRAFT"},
                {label: "Building", id: "BUILDING"},
                {label: "Ready to Submit", id: "READY_FOR_SUBMIT"},
                {label: "Failed", id: "FAILED"},
                {label: "Live", id: "LIVE"},
              ],
            },
          ]}
        >
          {(filters) => {
            return (
              <Table
                  filters={filters}
                data={brandedApps}
                actionButtons={[
                  {
                    label: "View",
                    onClick: (row) => {
                      props.router.navigate(row.UNIQUE_ID);
                    },
                  },
                ]}
                columns={[
                  {
                    label: "Franchise Name",
                    value: "NAME",
                  },
                  {
                    label: "Status",
                    value: "STATUS",
                    format: (value) => <BrandedAppBadge status={value} />,
                  },
                ]}
              />
            );
          }}
        </Filter>
      </Card>
    </div>
  );
}

export default setupReduxConnection(["admin"])(withRouter(BrandedAppsPage));
