import React, {Component} from "react";
import {request} from "../../../utils/request";
import {Formik} from "formik";
import PropTypes from "prop-types";
import * as Yup from "yup";
import {Modal, FormInput} from "@frostbyte-technologies/frostbyte-tailwind";

class InvoiceModal extends Component {
  state = {payment: null};

  constructor(props) {
    super(props);
    this.formikRef = React.createRef(null);
    this.modal = React.createRef(null);
  }

  open(payment = null) {
    this.setState({payment}, () => {
      this.modal.current.open();
    });
  }

  async editPayment() {
    const {checkNumber} = this.formikRef.current.values;
    const {payment} = this.state;

    await request("payments/check-number/" + payment.ID, "PATCH", {
      CHECK_NUMBER: checkNumber,
    });

    this.props.resyncPage();
    this.modal.current.close();
  }

  fetchInitialValues() {
    const {payment} = this.state;

    return {
      checkNumber: payment?.CONTENT || null,
    };
  }

  renderForm() {
    const {payment} = this.state;

    const validationSchema = Yup.object({
      checkNumber: Yup.number().typeError("Please enter a valid check number").nullable(),
    });

    return (
      <Formik
        onSubmit={this.editPayment.bind(this)}
        innerRef={this.formikRef}
        initialValues={this.fetchInitialValues()}
        validationSchema={validationSchema}
      >
        {(formikOptions) => {
          const {handleSubmit} = formikOptions;

          return (
            <form onSubmit={handleSubmit}>
              {payment.TYPE === "CHECK" && (
                <FormInput
                  options={formikOptions}
                  label="Check Number"
                  name="checkNumber"
                />
              )}
            </form>
          );
        }}
      </Formik>
    );
  }

  render() {
    return (
      <Modal
        buttonLabel="Save"
        label="Edit Payment"
        ref={this.modal}
        formikOnClick={() => this.formikRef.current}
      >
        {this.renderForm()}
      </Modal>
    );
  }
}

InvoiceModal.propTypes = {
  updateState: PropTypes.func.isRequired,
};

export default InvoiceModal;
