import React, {Component} from "react";
import {Card, TwoColumnList} from "@frostbyte-technologies/frostbyte-tailwind";
import ContactModal from "../../../modals/shop/contact-modal";
import ContactPageModal from "../../../modals/shop/contact-page-modal";
import {setupReduxConnection} from "../../../redux";

class ContactTab extends Component {
  render() {
    let {EMAIL, FACEBOOK, TWITTER, INSTAGRAM, WEBSITE, PHONE} = this.props.info;
    const {SHOW_CONTACT_PAGE} = this.props.shop.settings;

    return (
      <div>
        <Card>
          <ContactModal
            info={this.props.info}
            ref={(e) => (this.contactModal = e)}
            updateInfo={this.props.updateInfo}
          />

          <ContactPageModal
            info={this.props.info}
            ref={(e) => (this.contactPageModal = e)}
            updateInfo={this.props.updateInfo}
          />

          <TwoColumnList
            buttons={[
              {
                label: "Edit",
                onClick: () => this.contactModal.open(),
              },
            ]}
            label="Contact"
            description="Edit your location's contact information"
            data={[
              {label: "Website", value: WEBSITE},
              {label: "Phone", value: PHONE ? PHONE : "None"},
              {label: "Email", value: EMAIL ? EMAIL : "None"},
              {label: "Facebook", value: FACEBOOK ? FACEBOOK : "None"},
              {label: "Twitter", value: TWITTER ? TWITTER : "None"},
              {label: "Instagram", value: INSTAGRAM ? INSTAGRAM : "None"},
            ]}
          />
        </Card>

        <TwoColumnList
          label={"Contact Page"}
          buttons={[
            {
              label: "Edit",
              onClick: () => this.contactPageModal.open(),
            },
          ]}
          data={[
            {
              label: "Show Contact Page on Order Website",
              value: SHOW_CONTACT_PAGE,
              type: "bool",
            },
          ]}
        />
      </div>
    );
  }
}
export default setupReduxConnection(["shop"])(ContactTab);
