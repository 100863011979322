import React, {Component} from "react";
import {Outlet} from "react-router-dom";
import PageLayout from "../page/page-layout";
import SideNav from "../../components/side-nav";

class HubPayrollSideNav extends Component {
  render() {
    return (
      <div className="p-2 md:p-6 height-screen">
        <PageLayout className="mt-0">
          <div className="lg:col-start-1 lg:col-span-2">
            <SideNav
              title={"Payroll"}
              permissions={[]}
              navigation={[
                {
                  live: true,
                  id: "shop",
                  name: "Dashboard",
                  href: "/hub/payroll",
                  permissions: [],
                  children: [
                    {
                      live: true,
                      name: "Paystubs",
                      href: "/hub/payroll/paystubs",
                      paths: ["/hub/payroll/paystubs"],
                      permissions: [],
                    },
                    {
                      live: true,
                      name: "Tax Documents",
                      href: "/hub/payroll/tax-documents",
                      paths: ["/hub/payroll/tax-documents"],
                      permissions: [],
                    },
                  ],
                },
              ]}
            />
          </div>

          <div className="lg:col-start-3 lg:col-span-10">
            <Outlet />
          </div>
        </PageLayout>
      </div>
    );
  }
}

export default HubPayrollSideNav;
