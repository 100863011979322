import React, {Component} from "react";
import {setupReduxConnection} from "../../redux";
import {PAYROLL_ONBOARDING_STATUSES} from "../../utils/payroll-constants";

class PayrollWhitelistWrapper extends Component {
  componentDidMount() {
    const {payrollGroup} = this.props.shop;

    if (
      PAYROLL_ONBOARDING_STATUSES[payrollGroup?.STATUS] <
      PAYROLL_ONBOARDING_STATUSES.WORKPLACES_CREATED
    ) {
      this.props.router.navigate("/payroll");
    }
  }

  render() {
    const {children} = this.props;

    return <div>{children}</div>;
  }
}

export default setupReduxConnection(["shop"])(PayrollWhitelistWrapper);
