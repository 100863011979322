import React, {Component} from "react";
import {
  Loading,
  PageHeadings,
  Tab,
} from "@frostbyte-technologies/frostbyte-tailwind";
import {withRouter} from "../../../utils/navigation";
import TimeCardsTab from "./tabs/time-cards-tab";
import TimeOffTab from "./tabs/time-off-tab";
import TradeRequestTab from "./tabs/trade-request-tab";
import {request} from "../../../utils/request";
import OpenShiftApprovalTab from "./tabs/open-shift-approval-tab";
import {setupReduxConnection} from "../../../redux";
import {PERMISSIONS} from "../../../utils/constants";

class ApprovalCenterPage extends Component {
  state = {
    cards: null,
    requests: null,
    trades: null,
    locations: null,
    openShifts: null,
    breakOptions: null,
    viewTime: false,
    viewTimeOff: false,
    viewTrades: false,
    viewOpenShifts: false,
    isLoading: true,
  };

  componentDidMount() {
    this.syncState();
  }

  async syncState() {
    this.setState({isLoading: true});

    const {permissions} = this.props.user;

    const hasFullPermission =
      permissions.includes(PERMISSIONS.ADMIN.FULL.id) ||
      permissions.includes(PERMISSIONS.TEAM.FULL.id);

    const hasTimeCardPermissions =
      permissions.includes(PERMISSIONS.TEAM.APPROVALS_TIME_CARDS.id) ||
      hasFullPermission;

    const hasTimeOffPermissions =
      permissions.includes(PERMISSIONS.TEAM.APPROVALS_TIME_OFF.id) ||
      hasFullPermission;

    const hasTradeRequestPermissions =
      permissions.includes(PERMISSIONS.TEAM.APPROVALS_TRADE_REQUESTS.id) ||
      hasFullPermission;

    const hasOpenShiftRequestPermissions =
      permissions.includes(PERMISSIONS.TEAM.APPROVALS_OPEN_SHIFT_REQUESTS.id) ||
      hasFullPermission;

    let [
      locations,
      cards,
      breakOptions,
      timeOffRequests,
      tradeRequests,
      openShiftRequests,
    ] = await Promise.all([
      request("partner/franchise/locations", "GET"),
      this.fetchTimeCards(hasTimeCardPermissions),
      this.fetchBreakOptions(hasTimeCardPermissions),
      this.fetchTimeOffRequests(hasTimeOffPermissions),
      this.fetchTradeRequests(hasTradeRequestPermissions),
      this.fetchOpenShiftRequests(hasOpenShiftRequestPermissions),
    ]);
    console.log(tradeRequests);

    if (locations && locations.length > 0) {
      locations = locations.map((item) => {
        return {id: item.ID, label: item.NAME};
      });
    }

    this.setState({
      locations,
      cards,
      requests: timeOffRequests,
      trades: tradeRequests,
      openShifts: openShiftRequests,
      breakOptions,
      viewTime: hasTimeCardPermissions,
      viewTimeOff: hasTimeOffPermissions,
      viewTrades: hasTradeRequestPermissions,
      viewOpenShifts: hasOpenShiftRequestPermissions,
      isLoading: false,
    });
  }

  async fetchTimeCards(permission) {
    if (permission) {
      return request("team/timesheet-unapproved", "GET");
    } else {
      return Promise.resolve([]);
    }
  }

  async fetchBreakOptions(permission) {
    if (permission) {
      return request("team/break-option/company", "GET");
    } else {
      return Promise.resolve([]);
    }
  }

  async fetchTimeOffRequests(permission) {
    if (permission) {
      return request("timeoff/requests", "GET");
    } else {
      return Promise.resolve([]);
    }
  }

  async fetchTradeRequests(permission) {
    console.log(permission)
    if (permission) {
      return request("scheduling/request", "GET");
    } else {
      return Promise.resolve([]);
    }
  }

  async fetchOpenShiftRequests(permission) {
    if (permission) {
      return request("scheduling/open-shifts", "GET");
    } else {
      return Promise.resolve([]);
    }
  }

  render() {
    const {
      trades,
      requests,
      cards,
      openShifts,
      breakOptions,
      viewTime,
      viewTrades,
      viewTimeOff,
      viewOpenShifts,
      isLoading = false,
    } = this.state;

    const {SCHEDULER_OPEN_SHIFT_APPROVAL} = this.props.shop.settings;
    let {locations} = this.state;

    let numRequests = requests?.reduce((sum, item) => {
      if (item.STATUS === "PENDING") {
        sum += 1;
      }

      return sum;
    }, 0);

    let numOpenShiftRequests = openShifts?.reduce((sum, item) => {
      if (item.STATUS === "PND") {
        sum += 1;
      }

      return sum;
    }, 0);

    let numTradesForReview = trades?.reduce((sum, item) => {
      if (item.STATUS === "RVW") {
        sum += 1;
      }

      return sum;
    }, 0);

    if (isLoading) {
      return <Loading />;
    }

    return (
      <div>
        <PageHeadings
          label={"Approval Center"}
          description={
            "Approve Time Cards, Time Off Requests and Trade Requests"
          }
        />
        <Tab
          data={[
            {
              id: "cards",
              label: "Time Cards",
              badge: cards?.length > 0 ? "" + cards.length : "",
            },
            {
              id: "timeOff",
              label: "Time Off Requests",
              badge: numRequests > 0 ? "" + numRequests : "",
            },
            {
              id: "trade",
              label: "Trade Requests",
              badge: numTradesForReview > 0 ? "" + numTradesForReview : "",
            },
            SCHEDULER_OPEN_SHIFT_APPROVAL === "1" && {
              id: "openShift",
              label: "Open Shifts",
              badge: numOpenShiftRequests > 0 ? "" + numOpenShiftRequests : "",
            },
          ]}
        >
          {(tab) => {
            if (tab === "cards") {
              return (
                <TimeCardsTab
                  cards={cards}
                  hasPermissions={viewTime}
                  updateCards={(cards) => this.setState({cards})}
                  breakOptions={breakOptions}
                />
              );
            }

            if (tab === "timeOff") {
              return (
                <TimeOffTab
                  requests={requests}
                  hasPermissions={viewTimeOff}
                  locations={locations}
                  updateRequests={(requests) =>
                    this.setState({requests: [...requests]})
                  }
                  {...this.props}
                />
              );
            }

            if (tab === "trade") {
              return (
                <TradeRequestTab
                  trades={trades}
                  hasPermissions={viewTrades}
                  locations={locations}
                  updateTrades={(trades) =>
                    this.setState({trades: [...trades]})
                  }
                  {...this.props}
                />
              );
            }

            if (tab === "openShift") {
              return (
                <OpenShiftApprovalTab
                  openShifts={openShifts}
                  hasPermissions={viewOpenShifts}
                  locations={locations}
                  update={(openShifts) =>
                    this.setState({openShifts: [...openShifts]})
                  }
                  {...this.props}
                />
              );
            }
          }}
        </Tab>
      </div>
    );
  }
}

export default setupReduxConnection(["shop", "user"])(
  withRouter(ApprovalCenterPage)
);
