import {request} from "../../request";

export const InvoiceRequests = {
  deleteInvoice: async (invoiceId) => {
    return request("invoices/" + invoiceId, "DELETE");
  },
  sendInvoice: async (invoiceId) => {
    return request("invoices/" + invoiceId + "/send", "POST", {});
  },
  remindInvoice: async (invoiceId) => {
    return request("invoices/" + invoiceId + "/reminder", "POST", {});
  },
  updateInvoice: async (invoiceId, payload) => {
    return request("invoices/" + invoiceId, "PATCH", payload);
  },
  payInvoice: async (invoiceId, payload) => {
    return request("invoices/" + invoiceId + "/pay", "POST", payload);
  },
  payInvoiceMethod: async (invoiceId, payload) => {
    return request("invoices/" + invoiceId + "/pay/method", "POST", payload);
  },
};
