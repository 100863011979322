import React, {Component} from "react";
import moment from "moment-timezone";
import {toDollars} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import ReportingSalesGraph, {
  REPORTING_GRAPH_TYPES,
} from "../graphs/reporting-sales-graph";
import PercentageBadge from "../percentage-badge";
import CompareReportingTooltip from "../graphs/tooltips/compare-reporting-tooltip";

class CompareGraph extends Component {
  getTimeString(startEpoch, endEpoch) {
    if (
      moment(startEpoch).startOf("day").valueOf() ===
      moment(endEpoch).startOf("day").valueOf()
    ) {
      return moment(startEpoch).format("MMM DD, YYYY");
    }

    return `${moment(startEpoch).format("MMM DD, YYYY")} - ${moment(endEpoch).format(
      "MMM DD, YYYY"
    )}`;
  }

  render() {
    const {
      baseSalesSummary,
      compareSalesSummary,
      toDateCompareSalesSummary,
      baseStartEpoch,
      baseEndEpoch,
      compareStartEpoch,
      compareEndEpoch,
    } = this.props;

    const datasets = [
      {
        id: "base",
        rawData: baseSalesSummary,
        stroke: "#4e46e5",
        strokeWidth: 2,
        dataKey: "GROSS_SALES",
        stopNow: true,
      },
      {
        id: "compare",
        rawData: compareSalesSummary,
        dataKey: "GROSS_SALES",
        stroke: "#9ca3af",
        stopNow: false,
      },
    ];

    return (
      <div>
        <div className={"space-y-2"}>
          <div className={"flex flex-row space-x-2 items-center"}>
            <div className={"text-black font-semibold text-md"}>Gross Sales</div>

            {
              <PercentageBadge
                current={baseSalesSummary.TIMESPAN[0].GROSS_SALES}
                past={toDateCompareSalesSummary.TIMESPAN[0].GROSS_SALES}
              />
            }
          </div>

          <div className={"flex flex-row space-x-4"}>
            <div className={"flex flex-row items-center space-x-2"}>
              <div className={"h-3 w-3 bg-indigo-500"} />

              <div className={"text-sm font-semibold text-gray-700"}>
                {this.getTimeString(baseStartEpoch, baseEndEpoch)}
              </div>

              <div className={"text-sm font-semibold text-black"}>
                {toDollars(baseSalesSummary.TIMESPAN[0].GROSS_SALES, true)}
              </div>
            </div>

            <div className={"flex flex-row items-center space-x-2"}>
              <div className={"h-3 w-3 bg-gray-400"} />

              <div className={"text-sm font-semibold text-gray-700"}>
                {this.getTimeString(compareStartEpoch, compareEndEpoch)}
              </div>

              <div className={"text-sm font-semibold"}>
                {toDollars(compareSalesSummary.TIMESPAN[0].GROSS_SALES, true)}
              </div>
            </div>
          </div>

          <div className={"text-sm text-gray-700 font-semibold"}>
            {moment().format("h:mm A")}
          </div>
        </div>

        <ReportingSalesGraph
          height={300}
          displayAsDollars={true}
          datasets={datasets}
          type={REPORTING_GRAPH_TYPES.LINE.id}
          tooltipLabel={"Gross Sales"}
          tooltip={
            <CompareReportingTooltip
              header={"Gross Sales"}
              row={{label2: "Hi!", key: "Value"}}
              datasets={datasets}
            />
          }
        />
      </div>
    );
  }
}

export default CompareGraph;
