import React, {Component} from "react";
import {request} from "../../../utils/request";
class QuickbooksLinkComponent extends Component {
  state = {url: null};

  async componentDidMount() {
    const url = await request("accounting/quickbooks/link", "GET");

    this.setState({url});
  }

  render() {
    const {url} = this.state;

    return (
      <div style={{height: 500, padding: 16}}>
        <button
          type="button"
          className="m-auto space-x-4 inline-flex items-center px-4 py-2 border border-transparent text-sm font-bold rounded-md shadow-sm text-white bg-indigo-600"
          onClick={() => (window.location.href = url)}
        >
          Link my Quickbooks
        </button>
      </div>
    );
  }
}
export default QuickbooksLinkComponent;
