import {Component} from "react";
import FormDropZone from "./form-drop-zone";

class EmployeeFormDropZone extends Component {
  render() {
    const {options, name, onChange, onChangeSoft, endpoint} = this.props;

    return (
      <FormDropZone
        options={options}
        type={"PDF"}
        endpoint={endpoint ?? "employees/file/"}
        name={name}
        label={"Upload a File"}
        onChange={onChange}
        onChangeSoft={onChangeSoft}
      />
    );
  }
}

export default EmployeeFormDropZone;
