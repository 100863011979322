import React from "react";

export default function FormPartialHours({label, children}) {
  return (
    <div className={"flex flex-col items-start"}>
      <span className="block text-xs font-medium text-gray-700">{label}</span>
      {children}
    </div>
  );
}
