import React, {Component} from "react";
import {Modal} from "@frostbyte-technologies/frostbyte-tailwind";
import {withRouter} from "../../../utils/navigation";
import {showAlert} from "../../../utils/alert-helper";
import {InvoiceRequests} from "../../../utils/request-helpers/invoices/invoice-requests";

class BatchPublishInvoiceModal extends Component {
  state = {invoices: null};

  constructor(props) {
    super(props);
    this.modalRef = React.createRef(null);
    this.buttonRef = React.createRef(null);
  }

  open(invoices = null) {
    this.setState({invoices: invoices}, () => {
      this.modalRef.current.open();
    });
  }

  async publishInvoices(button) {
    const {invoices} = this.state;

    button && button.startLoading();

    const filteredInvoices = invoices.filter((invoice) => invoice.STATUS === "DRAFT");

    const requests = filteredInvoices.map((invoice) =>
      InvoiceRequests.sendInvoice(invoice.ID)
    );

    try {
      await Promise.all(requests);
      this.props.syncState();
    } catch (error) {
      showAlert({
        label: "Error",
        description: "Failed to publish all invoices",
        buttonLabel: "Close",
        type: "error",
        icon: "error",
      });
    }
    this.modalRef?.current && this.modalRef.current.close();
  }

  render() {
    const {invoices} = this.state;

    return (
      <Modal
        hideClose
        deleteOnClick={() => this.modalRef.current.close()}
        deleteType="gray"
        deleteLabel="Cancel"
        buttons={[
          {
            label: "Publish",
            ref: this.buttonRef,
            onClick: () => this.publishInvoices(this.buttonRef.current),
          },
        ]}
        label="Publish Invoices"
        ref={this.modalRef}
      >
        <div className="font-medium">
          Publish
          <span className="font-bold">
            {" "}
            {invoices?.length || 0} invoice{invoices?.length > 1 && "s"}
          </span>
        </div>

        <div className="mt-4 text-sm text-gray-700">
          Are you sure you want to publish these invoices and notify the recipient? Only
          draft invoices will be published.
        </div>
      </Modal>
    );
  }
}

export default withRouter(BatchPublishInvoiceModal);
