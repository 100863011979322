import React, {Component} from "react";
import {Formik} from "formik";
import {FormSelect} from "@frostbyte-technologies/frostbyte-tailwind";
import {request} from "../../../utils/request";
import FormDateTimeSelect from "../../../components/form-date-time-select";
import moment from "moment-timezone";
import {setupReduxConnection} from "../../../redux";
import {setAccountingGroupPrevProvider} from "../../../redux/shop";

class SettingsComponent extends Component {
  async handleClick() {
    return Boolean(await this.formikRef.submitForm());
  }

  handleSubmit = async ({prevProvider, type, entriesStart}) => {
    const payload = {
      PREV_PROVIDER: prevProvider,
      TYPE: type,
      ENTRIES_START_DATE: moment(entriesStart).format("YYYY-MM-DD"),
    };

    return await request("accounting/groups", "PATCH", payload);
  };

  render() {
    return (
      <div className="flex justify-center">
        <Formik
          onSubmit={this.handleSubmit}
          initialValues={{
            prevProvider: "NONE",
            type: "CASH",
            entriesStart: moment().startOf("day").valueOf(),
          }}
          innerRef={(e) => (this.formikRef = e)}
          enableReinitialize={true}
        >
          {(formikOptions) => {
            const {setFieldValue} = formikOptions;

            return (
              <div className="w-full px-6 md:w-1/2 md:px-6 py-10" style={{height: 600}}>
                <form>
                  <FormSelect
                    name="prevProvider"
                    data={[
                      {
                        label: "None",
                        value: "NONE",
                      },
                      {
                        label: "Quickbooks",
                        value: "QUICKBOOKS",
                      },
                    ]}
                    label={"Previous Provider"}
                    options={formikOptions}
                    onChangeSoft={({value}) => {
                      this.props.setAccountingGroupPrevProvider(value);
                    }}
                  />

                  <FormSelect
                    name={"type"}
                    label={"Method"}
                    data={[
                      {
                        label: "Cash",
                        value: "CASH",
                      },
                      {
                        label: "Accrual",
                        value: "ACCRUAL",
                      },
                    ]}
                    options={formikOptions}
                  />

                  <FormDateTimeSelect
                    name={"entriesStart"}
                    label={"Entries Start Date"}
                    tooltip={"Opening balances will be set for this date"}
                    hideTime={true}
                    options={formikOptions}
                  />
                </form>
              </div>
            );
          }}
        </Formik>
      </div>
    );
  }
}

export default setupReduxConnection(["shop"])(SettingsComponent);
