import React, {Component} from "react";
import {setupReduxConnection} from "../../../redux";
import {
  PageHeadings,
  TwoColumnList,
} from "@frostbyte-technologies/frostbyte-tailwind";
import ThirdPartySetupPage from "./third-party-setup-page";
import {request} from "../../../utils/request";
import {showSuccessAlert} from "../../../utils/alert-helper";
import ThirdPartyModal from "../../../modals/sales/third/third-party-modal";

class ThirdPartyPage extends Component {
  async upsertMenu() {
    this.syncRef.startLoading();

    await request("chowly/menu", "POST", {});

    this.syncRef.stopLoading();

    showSuccessAlert(
      "Menu Updated",
      "Menu can sometimes take 1-5 minutes to sync on third party platforms."
    );
  }

  render() {
    const {location, settings} = this.props.shop;
    const {
      THIRD_PARTY_MARK_UP,
      THIRD_PARTY_PRODUCT_ROUND_UP,
      THIRD_PARTY_MODIFIER_ROUND_UP,
    } = settings;

    if (location.CHOWLY_ID === null && !location.STREAM_ID) {
      return <ThirdPartySetupPage />;
    }

    return (
      <div>
        <ThirdPartyModal ref={(e) => (this.settingsModal = e)} />

        <PageHeadings
          label="Third Party Delivery"
          description="Manage your third party delivery options and platforms"
          buttons={[
            {
              ref: (e) => (this.syncRef = e),
              onClick: () => this.upsertMenu(),
              label: "Manual Sync",
            },
          ]}
        />

        <TwoColumnList
          label="Menu Markup Settings"
          description="How should we mark up the menu for delivery"
          button={{
            label: "Edit Settings",
            onClick: () => this.settingsModal.open(),
          }}
          data={[
            {
              label: "Menu Percent Markup",
              value: THIRD_PARTY_MARK_UP + "%",
              tooltip:
                "Percentage we want to add to the products and modifiers",
            },
            {
              label: "Product Round Up",
              value: THIRD_PARTY_PRODUCT_ROUND_UP + " cents",
              tooltip: "Cent amount we want to round to for products",
            },
            {
              label: "Modifier Round Up",
              value: THIRD_PARTY_MODIFIER_ROUND_UP + " cent",
              tooltip: "Cent amount we want to round to for modifiers",
            },
          ]}
        />
      </div>
    );
  }
}

export default setupReduxConnection(["shop"])(ThirdPartyPage);
