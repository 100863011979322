import React, {Component} from "react";
import LoadingSpinner from "../../../components/loading-spinner";
import {request} from "../../../utils/request";
import {setupReduxConnection} from "../../../redux";
import {withRouter} from "../../../utils/navigation";

class GenerateAccountingComponent extends Component {
  async componentDidMount() {
    const accountingGroup = await request("accounting/groups/populate", "POST");

    setTimeout(
      () =>
        (window.location = `${
          window.location.protocol + "//" + window.location.host
        }/accounting`),
      4000
    );
  }

  render() {
    return (
      <div
        style={{height: 600}}
        className="py-20 flex flex-col justify-center items-center space-y-4"
      >
        <div className={"space-y-2 text-center"}>
          <div className={"font-semibold"}>We're setting everything up for you!</div>

          <div className={"text-xs text-gray-600 font-semibold"}>
            You'll be redirected to Dripos Accounting when finished.
          </div>
        </div>
        <LoadingSpinner />
      </div>
    );
  }
}

export default setupReduxConnection(["shop"])(withRouter(GenerateAccountingComponent));
