import {request} from "../../request";

export const MapRequests = {
    /**
     *
     * @returns {Promise<[{
     *           NAME,
     *           LOGO,
     *           ICON,
     *           ADDRESS,
     *           CITY,
     *           ZIPCODE,
     *           STATE,
     *           LATITUDE,
     *           LONGITUDE,
     * }]>}
     */
    fetchLocations: async () => {
        return request("locations/points", "GET");
    },
};
