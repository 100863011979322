import {
  faCoffee,
  faDollarSign,
  faMap,
  faBriefcase,
} from "@fortawesome/pro-light-svg-icons";
import {PERMISSIONS} from "../../utils/constants";

export const ADMIN_HEADER_MENU = [
  {
    label: "Records",
    routes: [
      {
        live: true,
        name: "Shops",
        description: "Locations.",
        url: "/admin/locations",
        icon: faCoffee,
      },
      {
        live: true,
        name: "Accounts",
        description: "Locations.",
        url: "/admin/accounts",
        icon: faCoffee,
      },
      {
        live: true,
        name: "Employees",
        description: "Companies.",
        url: "/admin/employees",
        icon: faCoffee,
      },
      {
        live: true,
        name: "Tickets",
        description: "Companies.",
        url: "/admin/tickets",
        icon: faCoffee,
      },
    ],
  },
  {
    label: "Tracking",
    routes: [
      {live: true, url: "/admin/track/map", name: "Locations Map", icon: faMap},
      {
        live: true,
        name: "Product Tracker",
        description: "",
        url: "/admin/track/product",
        icon: faDollarSign,
      },
      {
        live: true,
        name: "Success Tracker",
        description: "Understand trends in your sales over time.",
        url: "/admin/track/success",
        icon: faDollarSign,
      },
      {
        live: true,
        name: "Payroll Tracker",
        description: "Understand trends in your sales over time.",
        url: "/admin/track/payroll",
        icon: faDollarSign,
      },
      {
        live: true,
        name: "Product Bug Tracker",
        description: "Understand trends in your sales over time.",
        url: "/admin/product/bugs",
        icon: faDollarSign,
      },
      {
        live: true,
        name: "Success Goal Tracker",
        description: "Understand trends in your sales over time.",
        url: "/admin/success/goal",
        icon: faDollarSign,
      },
    ],
  },
  {
    label: "Tools",
    routes: [
      {
        live: true,
        name: "Payroll Actions",
        description: "Understand trends in your sales over time.",
        url: "/admin/success/payroll",
        icon: faDollarSign,
      },
      {
        live: true,
        name: "Hardware Purchasing",
        description: "Understand trends in your sales over time.",
        url: "/admin/hardware",
        icon: faDollarSign,
      },
      {
        live: true,
        name: "Reader Apps",
        description: "Deploying reader apps to different customers",
        url: "/admin/reader/versions",
        icon: faDollarSign,
      },
      {
        live: true,
        name: "Barcode Import",
        description: "Deploying reader apps to different customers",
        url: "/admin/barcode/import",
        icon: faDollarSign,
      },
      {
        live: true,
        name: "Tip Payouts",
        description: "CMC is on the line again. They want their tips back",
        url: "/admin/tip-payouts",
        icon: faDollarSign,
      },
      {
        live: true,
        name: "Branded Apps",
        description: "Manage customers' white labeled apps",
        url: "/admin/branded",
        icon: faDollarSign,
      },
      {
        live: true,
        name: "Onboarding",
        description: "Onboard and manage Dripos employees",
        url: "/admin/onboarding",
        icon: faBriefcase,
      },
    ],
  },
  {
    label: "Support",
    routes: [
      {
        live: true,
        name: "Success Mode",
        description: "Understand trends in your sales over time.",
        url: "/reports/sales",
        icon: faDollarSign,
      },
      {
        live: true,
        name: "Token Requests",
        description: "Understand trends in your sales over time.",
        url: "/admin/tore",
        icon: faDollarSign,
      },
    ],
  },
  {
    label: "Reports",
    permissions: [PERMISSIONS.REPORTS.FULL.id],
    routes: [
      {
        live: true,
        name: "Sales",
        description: "Understand trends in your sales over time.",
        url: "/reports/sales",
        icon: faDollarSign,
        permissions: [PERMISSIONS.REPORTS.SALES_REPORT.id],
      },
    ],
  },
];
