import React, {Component} from "react";
import {Formik} from "formik";
import PropTypes from "prop-types";
import PayRateModal from "../../../modals/team/pay-rate-modal";
import {updateStateDelegator} from "../../../utils/util";
import PayRatesTable from "../../../tables/team/pay-rates-table";

class RoleRatesForm extends Component {
  submitForm() {
    this.formikRef.submitForm();
  }

  fetchFormData({rates}) {
    return {rates};
  }

  async validateForm() {
    const val = await this.formikRef.validateForm();

    if (Object.keys(val).length === 0) {
      await this.formikRef.submitForm();

      return true;
    }

    for (let item of Object.keys(this.formikRef.values)) {
      this.formikRef.setFieldTouched(item, true);
    }

    return false;
  }

  render() {
    const {handleSubmit, rates = []} = this.props;

    return (
      <Formik
        onSubmit={handleSubmit}
        innerRef={(e) => (this.formikRef = e)}
        enableReinitialize
        initialValues={{
          rates,
        }}
      >
        {(formikOptions) => {
          const {setFieldValue, values} = formikOptions;

          return (
            <div className="mt-4">
              <PayRateModal
                soft
                addState={(rate) => {
                  setFieldValue("rates", [...values.rates, rate]);
                }}
                updateState={(id, rate) => {
                  console.log(
                    id,
                    rate,
                    updateStateDelegator(values.rates, id, rate)
                  );
                  setFieldValue(
                    "rates",
                    updateStateDelegator(values.rates, id, rate)
                  );
                }}
                rates={rates}
                ref={(e) => (this.payRateModal = e)}
              />

              <PayRatesTable
                addState={() => this.payRateModal.open()}
                updateState={(rate) => this.payRateModal.open(rate)}
                rates={values.rates}
                soft
              />
            </div>
          );
        }}
      </Formik>
    );
  }
}

RoleRatesForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  rates: PropTypes.object,
};

export default RoleRatesForm;
