import React, {Component} from "react";
import {
  FormBoolean,
  Modal,
  FormTextArea,
} from "@frostbyte-technologies/frostbyte-tailwind";
import {Formik} from "formik";
import {request} from "../../../utils/request";
import PropTypes from "prop-types";
import * as Yup from "yup";

class TicketTypeDetailsModal extends Component {
  state = {type: null, settings: null};

  open(type, settings) {
    this.setState({settings, type});
    this.formikRef && this.formikRef.resetForm();
    this.modal.open();
  }

  async saveTicketType({notifyOnPlacement, notifyOnCompletion, placementMessageContent, completionMessageContent}) {
    const {type} = this.state;

    const serverTicketType = {
      NOTIFY_ON_PLACEMENT: notifyOnPlacement,
      NOTIFY_ON_COMPLETION: notifyOnCompletion,
      PLACEMENT_MESSAGE_CONTENT: placementMessageContent,
      COMPLETION_MESSAGE_CONTENT: completionMessageContent,
    };

    //types/:ID/settings
    await request(
      "types/" + type.ID + "/settings",
      "PATCH",
      serverTicketType
    );

    await this.props.handleUpdate();
    this.modal.close();
  }

  getInitialValues(settings) {
    return {
      notifyOnPlacement: settings?.NOTIFY_ON_PLACEMENT,
      notifyOnCompletion: settings?.NOTIFY_ON_COMPLETION,
      placementMessageContent: settings?.PLACEMENT_MESSAGE_CONTENT || "",
      completionMessageContent: settings?.COMPLETION_MESSAGE_CONTENT || "",
    };
  }

  renderNotifyOnPlacement(formikOptions, orderPlacementTooltip, contentTooltip) {
    return (
      <>
        <FormBoolean
          label="Enable Notifications on Order Placement"
          options={formikOptions}
          tooltip={orderPlacementTooltip}
          name="notifyOnPlacement"
          flex
        />

        {formikOptions.values.notifyOnPlacement === "1" && (
          <FormTextArea
            label="Message Content Sent on Order Placement"
            options={formikOptions}
            tooltip={contentTooltip}
            name="placementMessageContent"
            flex
          />
        )}
      </>
    );
  }

  renderNotifyOnCompletion(formikOptions, orderCompletionTooltip, contentTooltip) {
    return (
      <>
        <FormBoolean
          label="Enable Notifications on Order Completion"
          options={formikOptions}
          tooltip={orderCompletionTooltip}
          name="notifyOnCompletion"
          flex
        />

        {formikOptions.values.notifyOnCompletion === "1" && (
          <FormTextArea
            label="Message Content Sent on Order Completed"
            options={formikOptions}
            tooltip={contentTooltip}
            name="completionMessageContent"
            flex
          />
        )}
      </>
    );
  }

  render() {
    const { settings } = this.state;

    const placementMessageContent = Yup.string().when('notifyOnPlacement', {
      is: "1",
      then: Yup.string().required("Please provide an order creation text"),
      otherwise: Yup.string().notRequired(),
    });
    const completionMessageContent = Yup.string().when('notifyOnCompletion', {
      is: "1",
      then: Yup.string().required("Please provide an order completion text"),
      otherwise: Yup.string().notRequired(),
    });

    const validationSchema = Yup.object().shape({
      placementMessageContent: placementMessageContent,
      completionMessageContent: completionMessageContent,
    });

    const orderPlacementTooltip = "Notify the customer when they place an order";
    const orderCompletionTooltip = "Notify the customer when their order is completed";
    const contentTooltip = "Input the message to send to the customer. Use {{NAME}}, {{LOCATION}}, or {{RECEIPT}} to prefill the message";

    return (
      <Modal
        buttonLabel="Save"
        label="Edit Ticket Notifications"
        ref={(e) => (this.modal = e)}
        formikOnClick={() => this.formikRef}
      >
        <Formik
          onSubmit={this.saveTicketType.bind(this)}
          innerRef={(e) => (this.formikRef = e)}
          enableReinitialize
          initialValues={this.getInitialValues(settings)}
          validationSchema={validationSchema}
        >
          {(formikOptions) => {
            const {handleSubmit} = formikOptions;

            return (
              <form onSubmit={handleSubmit}>
                <div>
                  {this.renderNotifyOnPlacement(formikOptions, orderPlacementTooltip, contentTooltip)}
                  {this.renderNotifyOnCompletion(formikOptions, orderCompletionTooltip, contentTooltip)}
                </div>
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

TicketTypeDetailsModal.propTypes = {
  updateState: PropTypes.func.isRequired,
};

export default TicketTypeDetailsModal;
