import React, {Component} from "react";
import {setupReduxConnection} from "../../../../redux";
import {Loading, PageHeadings, Tab} from "@frostbyte-technologies/frostbyte-tailwind";
import {withRouter} from "../../../../utils/navigation";
import {request} from "../../../../utils/request";
import CouponsTable from "../../../../tables/marketing/deals/coupons-table";

class PromotionsPage extends Component {
  render() {
    return (
      <div>
        <PageHeadings
          label="Coupons"
          description="Coupons that your customers can redeem"
          button={{
            label: "Create Coupon",
            onClick: () => this.props.router.navigate("/coupon"),
          }}
        />

        <Tab
          data={[
            {
              id: "active",
              label: "Manually Created Coupons",
            },
            {
              id: "text",
              label: "Text Campaign Coupons",
            },
            {
              id: "inactive",
              label: "Inactive Coupons",
            },
          ]}
        >
          {(id) => <CouponsTable className="mt-4" currentTab={id} />}
        </Tab>
      </div>
    );
  }
}

export default setupReduxConnection(["menu"])(withRouter(PromotionsPage));
