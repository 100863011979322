import {request} from "../../request";

export const Shifts = {
  create: async (params) => {
    return request("scheduling/v2/shifts", "POST", params);
  },
  update: async (id, params) => {
    return request("scheduling/v2/shift/" + id, "PATCH", params);
  },
  delete: async (id) => {
    return request("scheduling/locations/shift/" + id, "DELETE");
  },
};

export const ShiftTags = {
  fetch: async () => {
    return request("team/shift-tags", "GET");
  },
};
