import React, {Component} from "react";
import {Card} from "@frostbyte-technologies/frostbyte-tailwind";
import ProductsTable from "../../../../../tables/sales/products-table";
import PresetProductModal from "../../../../../modals/sales/modifiers/preset-product-modal";
import {request} from "../../../../../utils/request";
import {showLoadingConfirmAlert} from "../../../../../utils/alert-helper";

class ModifierProductsTab extends Component {
  render() {
    const {modifier} = this.props;

    return (
      <>
        <PresetProductModal
          ref={(e) => (this.presetProductModal = e)}
          exclude={modifier.PRESETS.map((preset) => preset.ID)}
          updateState={async ({products}, callback) => {
            await request("v2/preset/" + modifier.ID + "/products", "PUT", {
              PRODUCTS: products,
            });

            const serverProducts = await Promise.all(
              products.map((p) => {
                return request("product/" + p, "GET");
              })
            );

            modifier.PRESETS.push(...serverProducts);
            this.props.updateState({modifier});
            callback();
          }}
        />

        <Card
          label="Modifiers"
          buttons={[
            {
              theme: "primary",
              label: "Add Product",
              icon: "plus",
              onClick: () => {
                this.presetProductModal.open();
              },
            },
          ]}
        >
          <ProductsTable
            data={modifier.PRESET ? modifier.PRESETS : modifier.PRODUCTS}
            pagination
            onDelete={(row) => {
              showLoadingConfirmAlert(
                "Delete Preset",
                "Are you sure you want to delete this preset?"
              )
                .then((close) => {
                  request(
                    "preset/" +
                      parseInt(modifier.ID) +
                      "/" +
                      parseInt(row.PRODUCT_ID) +
                      "/remove",
                    "POST"
                  );

                  const modifierIndex = modifier.PRESETS.findIndex(
                    (preset) => preset.ID === row.ID
                  );

                  if (modifierIndex !== -1) {
                    modifier.PRESETS.splice(modifierIndex, 1);
                  }

                  close();
                  this.props.updateState({modifier});
                })
                .catch((e) => {});
            }}
          />
        </Card>
      </>
    );
  }
}

export default ModifierProductsTab;
