import {toDollars} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import {Bar, BarChart, ResponsiveContainer, XAxis, YAxis} from "recharts";
import React, {Component} from "react";
import LoadingSpinner from "../../../components/loading-spinner";
import TimespanDropdown from "../timespan-dropdown";
import {request} from "../../../utils/request";
import {withRouter} from "../../../utils/navigation";
import moment from "moment/moment";

class ProfitLossBlock extends Component {
  state = {report: null, period: TIME_PERIODS.THIRTY_DAYS.value};

  async componentDidMount() {
    this.syncState();
  }

  syncState = async () => {
    const {period} = this.state;

    const {startEpoch, endEpoch} = TIME_PERIODS[period].convertTimeRange();

    const report = await request("accounting/reports/profitability", "POST", {
      START_DATE: startEpoch,
      END_DATE: endEpoch,
    });

    this.setState({report});
  };

  renderNetProfitBarChart(data, xMax, color) {
    const containerProps = {
      height: 30,
    };

    if (xMax === 0) {
      containerProps.width = 75;
    }

    return (
      <div className={"space-y-1"}>
        <div className={"font-semibold text-gray-800"}>
          <div>{toDollars(data[0].total, true)}</div>
        </div>

        <div className={"flex flex-row items-end"}>
          <div className={"w-24"}>
            <div className={"text-sm text-gray-600"}>{data[0].name}</div>
          </div>

          <ResponsiveContainer {...containerProps}>
            <BarChart
              data={data}
              layout="vertical"
              barCategoryGap={1}
              margin={{top: 0, right: 50, left: 0, bottom: 0}}
            >
              <pattern
                id="pattern-stripe"
                width="8"
                height="8"
                patternUnits="userSpaceOnUse"
                patternTransform="rotate(45)"
              >
                <rect width="3" height="8" transform="translate(0,0)" fill="white"></rect>
              </pattern>

              <mask id="mask-stripe">
                <rect
                  x="0"
                  y="0"
                  width="100%"
                  height="100%"
                  fill="url(#pattern-stripe)"
                />
              </mask>

              <XAxis type="number" hide domain={[1, xMax]} />
              <YAxis hide type="category" dataKey="name" />

              <Bar
                dataKey="reviewed"
                fill={color}
                stackId={"a"}
                isAnimationActive={false}
              />

              <Bar
                // label={<ReviewLabel />}
                dataKey="unreviewed"
                fill={color}
                stackId={"a"}
                isAnimationActive={false}
                shape={<StripedMask fill={color} />}
              />

              {/*<Tooltip content={<ProfitLossTooltip />} />*/}
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>
    );
  }

  render() {
    const {report, period} = this.state;

    if (!report) {
      return <LoadingSpinner />;
    }

    const {unreviewedTotals, unreviewedTransactionCount} = report;

    let {NET_PROFIT, INCOME, EXPENSE} = report.TOTALS;

    const xMax = Math.max(INCOME, EXPENSE);

    const categorizeStr = unreviewedTransactionCount
      ? `Categorize ${unreviewedTransactionCount} transactions`
      : "All Transactions have been reviewed";

    return (
      <div className={"p-5 bg-white shadow"}>
        <div className={"flex flex-row justify-between"}>
          <div className={"font-semibold pb-4"}>Profit & Loss</div>

          <TimespanDropdown
            value={period}
            onChange={(value) => this.setState({period: value}, () => this.syncState())}
          />
        </div>

        <div className={"space-y-6"}>
          <div>
            <div className={"text-gray-500 text-sm font-semibold"}>
              Net profit for {TIME_PERIODS[period].convertLabel()}
            </div>

            <div className={"font-bold"}>{toDollars(NET_PROFIT, true)}</div>
          </div>

          {this.renderNetProfitBarChart(
            [
              {
                name: "Income",
                total: INCOME,
                reviewed: INCOME - (unreviewedTotals["REVENUE"] ?? 0),
                unreviewed: unreviewedTotals["REVENUE"],
              },
            ],
            xMax,
            "#6366f1"
          )}

          {this.renderNetProfitBarChart(
            [
              {
                name: "Expenses",
                total: EXPENSE,
                reviewed: EXPENSE - (unreviewedTotals["EXPENSE"] ?? 0),
                unreviewed: unreviewedTotals["EXPENSE"],
              },
            ],
            xMax,
            "#b4b4b4"
          )}

          <div
            className={"text-indigo-500 text-sm font-semibold cursor-pointer"}
            onClick={() => this.props.router.navigate("transactions")}
          >
            {categorizeStr}
          </div>
        </div>
      </div>
    );
  }
}

export const StripedMask = (props) => {
  const {x: oX, y: oY, width: oWidth, height: oHeight, value, fill} = props;

  let x = oX;
  let y = oHeight < 0 ? oY + oHeight : oY;
  let width = oWidth;
  let height = Math.abs(oHeight);

  return (
    <rect
      fill={fill}
      mask="url(#mask-stripe)"
      x={x}
      y={y}
      width={width}
      height={height}
    />
  );
};

export const TIME_PERIODS = {
  THIRTY_DAYS: {
    label: "Last 30 days",
    value: "THIRTY_DAYS",
    convertLabel: () => "the last 30 days",
    convertTimeRange: () => {
      return {
        startEpoch: moment().startOf("day").subtract(30, "days").format("YYYY-MM-DD"),
        endEpoch: moment().endOf("day").format("YYYY-MM-DD"),
      };
    },
  },
  THIS_MONTH: {
    label: "This month",
    value: "THIS_MONTH",
    convertLabel: () => moment().format("MMM, YYYY"),
    convertTimeRange: () => {
      return {
        startEpoch: moment().startOf("month").format("YYYY-MM-DD"),
        endEpoch: moment().endOf("month").format("YYYY-MM-DD"),
      };
    },
  },
  THIS_MONTH_TO_DATE: {
    label: "This month to date",
    value: "THIS_MONTH_TO_DATE",
    convertLabel: () => `${moment().format("MMM, YYYY")} to date`,

    convertTimeRange: () => {
      return {
        startEpoch: moment().startOf("month").format("YYYY-MM-DD"),
        endEpoch: moment().endOf("day").format("YYYY-MM-DD"),
      };
    },
  },
  THIS_QUARTER: {
    label: "This quarter",
    value: "THIS_QUARTER",
    convertLabel: () => `Q${moment().format("Q, YYYY")}`,
    convertTimeRange: () => {
      return {
        startEpoch: moment().startOf("quarter").format("YYYY-MM-DD"),
        endEpoch: moment().endOf("quarter").format("YYYY-MM-DD"),
      };
    },
  },
  THIS_QUARTER_TO_DATE: {
    label: "This quarter to date",
    value: "THIS_QUARTER_TO_DATE",
    convertLabel: () => `Q${moment().format("Q, YYYY")} to date`,
    convertTimeRange: () => {
      return {
        startEpoch: moment().startOf("quarter").format("YYYY-MM-DD"),
        endEpoch: moment().endOf("day").format("YYYY-MM-DD"),
      };
    },
  },
  LAST_MONTH: {
    label: "Last month",
    value: "LAST_MONTH",
    convertLabel: () => `${moment().subtract(1, "month").format("MMM, YYYY")}`,
    convertTimeRange: () => {
      return {
        startEpoch: moment().subtract(1, "month").startOf("month").format("YYYY-MM-DD"),
        endEpoch: moment().subtract(1, "month").endOf("month").format("YYYY-MM-DD"),
      };
    },
  },
  LAST_QUARTER: {
    label: "Last quarter",
    value: "LAST_QUARTER",
    convertLabel: () => `Q${moment().subtract(1, "quarter").format("Q, YYYY")}`,
    convertTimeRange: () => {
      return {
        startEpoch: moment()
          .subtract(1, "quarter")
          .startOf("quarter")
          .format("YYYY-MM-DD"),
        endEpoch: moment().subtract(1, "quarter").endOf("quarter").format("YYYY-MM-DD"),
      };
    },
  },
  LAST_YEAR: {
    label: "Last year",
    value: "LAST_YEAR",
    convertLabel: () => `${moment().subtract(1, "year").format("YYYY")}`,
    convertTimeRange: () => {
      return {
        startEpoch: moment().subtract(1, "year").startOf("year").format("YYYY-MM-DD"),
        endEpoch: moment().subtract(1, "year").endOf("year").format("YYYY-MM-DD"),
      };
    },
  },
};

export default withRouter(ProfitLossBlock);
