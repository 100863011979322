import React, {Component} from "react";
import {
  Card,
  FormBoolean,
  FormInput,
  FormTextArea,
  PageHeadings,
} from "@frostbyte-technologies/frostbyte-tailwind";
import {Formik} from "formik";
import {setupReduxConnection} from "../../../redux";
import QRCode from "qrcode.react";
import {Buffer} from "buffer";
import CategoryDropdown from "../../../dropdowns/sales/category-dropdown";

class OrderWebsiteQRCodesPage extends Component {
  state = {...DEFAULT_STATE};

  orderWebsiteNameParse = (str) => {
    return str.replaceAll("-", "+").replaceAll(" ", "-");
  };

  handleGenerateCode = (formikValues) => {
    const {location} = this.props.shop;
    const {customerNotes, internalNotes, category, signup} = formikValues;

    const baseUrl = "https://order.dripos.com/";

    let locationUrl =
      baseUrl +
      (location.SLUG
        ? location.SLUG
        : this.orderWebsiteNameParse(location.NAME));

    if (signup === "1") {
      this.setState({
        generatedUrl: locationUrl + "/signup",
        ...formikValues,
      });
      return;
    }

    if (customerNotes || internalNotes || category) {
      locationUrl += "?";

      if (customerNotes) {
        locationUrl += `cNote=${new Buffer(customerNotes).toString("base64")}`;

        if (internalNotes) {
          locationUrl += "&";
        }
      }

      if (internalNotes) {
        locationUrl += `iNote=${new Buffer(internalNotes).toString("base64")}`;

        if (category) {
          locationUrl += "&";
        }
      }

      if (category) {
        locationUrl += `category=${category}`;
      }
    }

    this.setState({
      generatedUrl: locationUrl,
      ...formikValues,
    });
  };

  downloadQrCode = () => {
    const canvas = document.querySelector(".HpQrcode > canvas");

    let a = document.createElement("a"); //Create <a>

    a.href = canvas.toDataURL();
    a.download = this.state.fileName
      ? `${this.state.fileName}.png`
      : "qr-code.png";

    a.click();
  };

  handleEditForm = () => {
    this.setState({
      generatedUrl: null,
    });
  };

  handleResetForm = () => {
    this.setState({
      ...DEFAULT_STATE,
    });
  };

  render() {
    const {
      signup,
      generatedUrl,
      customerNotes,
      internalNotes,
      size,
      backgroundColor,
      foregroundColor,
      fileName,
      category,
    } = this.state;

    return (
      <div>
        <PageHeadings
          label="QR Codes"
          description={
            "Create links to your ordering website with customizable notes that attach to the orders"
          }
        />

        <Card label={"Create Order Website Code"}>
          <div className={"flex flex-col md:flex-row p-6"}>
            <div className={"w-96"}>
              <Formik
                initialValues={{
                  signup,
                  customerNotes,
                  internalNotes,
                  size,
                  backgroundColor,
                  foregroundColor,
                  fileName,
                  category,
                }}
                enableReinitialize={true}
                onSubmit={this.handleGenerateCode}
              >
                {(formikOptions) => {
                  let {handleSubmit, values} = formikOptions;

                  return (
                    <form onSubmit={handleSubmit}>
                      <FormBoolean
                        label={"Generate Signup Link"}
                        name={"signup"}
                        options={formikOptions}
                        tooltip={
                          "If set to yes, the QR code will link to your patron signup page instead of your order website's homepage."
                        }
                      />

                      {values.signup === "0" && (
                        <FormTextArea
                          label="Patron Facing Notes"
                          name="customerNotes"
                          placeholder={
                            "Notes that your patron will see at checkout."
                          }
                          options={formikOptions}
                          disabled={generatedUrl}
                        />
                      )}

                      {values.signup === "0" && (
                        <FormTextArea
                          label="Internal Facing Notes"
                          name="internalNotes"
                          placeholder={
                            "Notes that your employees will see on the ticket."
                          }
                          options={formikOptions}
                          disabled={generatedUrl}
                        />
                      )}

                      {values.signup === "0" && (
                        <CategoryDropdown
                          unique
                          name="category"
                          label="Category to focus"
                          tooltip="Which category will be front and center when clicking on the QR Code"
                          placeholder="Click to select"
                          options={formikOptions}
                          disabled={generatedUrl}
                        />
                      )}

                      <FormInput
                        label="Download File Name"
                        name="fileName"
                        placeholder={"qr-code"}
                        options={formikOptions}
                        disabled={generatedUrl}
                      />

                      <FormInput
                        label="QR Code Image Size (px)"
                        name="size"
                        options={formikOptions}
                        disabled={generatedUrl}
                        type={"number"}
                      />

                      {!generatedUrl && (
                        <div style={{display: "flex"}}>
                          <div style={{marginRight: 52}}>
                            <FormInput
                              label={"Background"}
                              name={"backgroundColor"}
                              type={"color"}
                              options={formikOptions}
                              color={values.backgroundColor}
                            />
                          </div>

                          <FormInput
                            label={"Foreground"}
                            name={"foregroundColor"}
                            type={"color"}
                            options={formikOptions}
                          />
                        </div>
                      )}

                      <div className="mt-5 sm:mb-10 md:mb-0">
                        {!generatedUrl && (
                          <button
                            type="submit"
                            className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                          >
                            Generate QR Code
                          </button>
                        )}

                        {generatedUrl && (
                          <div className="flex justify-around">
                            <button
                              type="button"
                              className="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                              onClick={() => {
                                this.handleEditForm();
                              }}
                            >
                              Create New with Existing
                            </button>

                            <button
                              type="button"
                              className="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                              onClick={() => {
                                this.handleResetForm();
                              }}
                            >
                              Reset
                            </button>
                          </div>
                        )}
                      </div>
                    </form>
                  );
                }}
              </Formik>
            </div>

            <div className={"flex-1 m-auto"}>
              {generatedUrl && (
                <div className="flex justify-center text-center align-center flex-col space-y-3">
                  <div className="HpQrcode m-auto">
                    <QRCode
                      value={generatedUrl}
                      size={size / 2}
                      includeMargin={false}
                      bgColor={backgroundColor}
                      fgColor={foregroundColor}
                    />
                  </div>

                  <div
                    className="font-semibold text-indigo-500 text-lg cursor-pointer"
                    onClick={() => this.downloadQrCode()}
                  >
                    Download Image
                  </div>

                  <div
                    className="text-xs font-semibold text-indigo-500 cursor-pointer"
                    onClick={() => navigator.clipboard.writeText(generatedUrl)}
                  >
                    Copy Link to Clipboard
                  </div>
                </div>
              )}
            </div>
          </div>
        </Card>
      </div>
    );
  }
}

const DEFAULT_STATE = {
  signup: "0",
  customerNotes: "",
  internalNotes: "",
  size: 250,
  backgroundColor: "#FFFFFF",
  foregroundColor: "#000000",
  fileName: "qr-code",
  generatedUrl: null,
};

export default setupReduxConnection(["shop"])(OrderWebsiteQRCodesPage);
