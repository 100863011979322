import React, {Component} from "react";
import {
  AdvancedFilter,
  PageHeadings,
  Tab,
  Table,
} from "@frostbyte-technologies/frostbyte-tailwind";
import {request} from "../../utils/request";
import LoadingSpinner from "../../components/loading-spinner";
import TransactionModal from "../../modals/accounting/transaction-modal";
import {toDollars} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import CategoryDropdown from "../../features/accounting/category-dropdown";
import moment from "moment-timezone";
import {classNames} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {setupReduxConnection} from "../../redux";
import {loadLedgers} from "../../redux/accounting";

class AccountingTransactionsPage extends Component {
  state = {bankConnections: null, selectedIndex: 0, confirmingTransactions: []};

  async componentDidMount() {
    this.props.loadLedgers();
    const bankConnections = await request("accounting/ledgers/bank-connections", "GET");

    this.setState({
      bankConnections,
    });
  }

  syncState = async () => {
    await this.tableRef.refresh(true);
  };

  confirmTransaction = async (row) => {
    const {confirmingTransactions, bankConnections} = this.state;

    const ledgerIndex = bankConnections.findIndex(({ID}) => ID === row.LEDGER_ID);
    bankConnections[ledgerIndex].UNREVIEWED_TRANSACTION_COUNT--;

    this.setState({
      confirmingTransactions: [...confirmingTransactions, row.ID],
      bankConnections,
    });

    await request(`accounting/transactions/${row.UNIQUE_ID}/review`, "POST");

    this.tableRef.refresh(true);
  };

  getColumns() {
    const {confirmingTransactions} = this.state;

    return [
      {
        label: "Date",
        value: "DATE_TRANSACTION",
        format: (value) => (
          <div className={"items-center flex flex-row"} style={{minHeight: 30}}>
            {moment.utc(value).format("MMM D, YYYY")}
          </div>
        ),
        width: 1,
      },
      {
        label: "Description",
        value: "DESCRIPTION",
        format: (value, row) => {
          return (
            <div
              onClick={() => this.transactionModal.open(row)}
              className={" items-center"}
            >
              <div className={"font-semibold text-black"}>{value}</div>
            </div>
          );
        },
      },
      {
        label: "From/To",
        value: "VENDOR_NAME",
        width: 1,
      },
      {
        label: "Amount",
        value: "AMOUNT",
        format: (value) => <div>{toDollars(value, true)}</div>,
        width: 1,
      },
      {
        label: "Category",
        value: "CATEGORIES",
        format: (value, row) => this.renderCategory(row),
        width: 1,
      },
      {
        label: "",
        value: "REVIEW_STATUS",
        format: (value, row) => {
          if (value === TRANSACTION_REVIEW_STATUSES.REVIEWED) {
            return <div></div>;
          }

          if (confirmingTransactions.includes(row.ID)) {
            return (
              <LoadingSpinner borderSize={2.5} size={23} color={"rgb(63, 81, 181)"} />
            );
          }

          return (
            <button
              type="button"
              className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm py-1 bg-indigo-500 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
              onClick={() => this.confirmTransaction(row)}
            >
              <FontAwesomeIcon icon={"check"} />
            </button>
          );
        },
        width: 1,
      },
    ];
  }

  renderCategory(row) {
    if (row.JOURNAL_ENTRY_TYPE === "PAYOUT") {
      return <div className={"text-black font-medium"}>Dripos Payout</div>;
    }

    return (
      <CategoryDropdown
        transaction={row}
        onClick={() => this.transactionModal.open(row)}
        // onOtherClick={() => {
        //   this.transactionModal.open(row);
        // }}
        syncState={() => this.tableRef.refresh(true)}
      />
    );
  }

  renderLinkedLedger(
    {ID, NAME, BANK_BALANCE, UNREVIEWED_TRANSACTION_COUNT},
    index,
    isSelected
  ) {
    return (
      <div
        className={classNames(
          "bg-white p-3.5 rounded-sm shadow cursor-pointer space-y-2",
          isSelected && "shadow-[0_0_0_3px_rgba(99,102,241,1)]"
        )}
        onClick={() => {
          this.setState({selectedIndex: index}, () => this.tableRef.refresh());
        }}
      >
        <div className={"flex flex-row items-center text-center space-x-3"}>
          <div className={"font-semibold text-sm"}>{NAME}</div>

          {!UNREVIEWED_TRANSACTION_COUNT && (
            <FontAwesomeIcon
              icon="check"
              className={
                "mr-2 text-xs rounded-full bg-green-500 p-1 text-white h-2 w-2 font-medium"
              }
            />
          )}

          {!!UNREVIEWED_TRANSACTION_COUNT && (
            <div className={"rounded-full bg-indigo-500 text-center items-center px-1"}>
              <div className={"text-xs font-semibold text-white"}>
                {UNREVIEWED_TRANSACTION_COUNT}
              </div>
            </div>
          )}
        </div>
        <div className={"text-sm"}>{toDollars(BANK_BALANCE, true)}</div>

        {/*<div*/}
        {/*  className={*/}
        {/*    "flex flex-row text-center items-center text-gray-500 text-xs"*/}
        {/*  }*/}
        {/*>*/}
        {/*  <FontAwesomeIcon icon={"arrows-rotate"} className="h-3 w-3 mr-2" />*/}

        {/*  <div>19 hours ago</div>*/}
        {/*</div>*/}
      </div>
    );
  }

  render() {
    const {bankConnections, selectedIndex} = this.state;
    const {ledgers} = this.props.accounting;

    return (
      <div id={"category-wrapper"}>
        <PageHeadings label={"Transactions"} />

        <TransactionModal
          ref={(e) => (this.transactionModal = e)}
          syncState={this.syncState}
        />

        {(!bankConnections || !ledgers) && <LoadingSpinner />}

        {bankConnections && bankConnections.length === 0 && (
          <div className={"mt-10"}>Link a ledger on the bank connections page!</div>
        )}

        {bankConnections?.length > 0 && ledgers && (
          <div>
            <div className={"flex flex-row space-x-2 mt-5 mb-2"}>
              {bankConnections.map((_linkedLedger, index) =>
                this.renderLinkedLedger(_linkedLedger, index, selectedIndex === index)
              )}
            </div>

            <Tab
              className="mt-2"
              data={[
                {
                  label: "Unreviewed",
                  id: "unreviewed",
                  badge: bankConnections[selectedIndex]?.UNREVIEWED_TRANSACTION_COUNT,
                },
                {label: "Categorized", id: "categorized"},
              ]}
            >
              {(id) => (
                <div className={"bg-white"}>
                  <AdvancedFilter
                    data={[
                      {
                        label: "Search by Name",
                        placeholder: "Search",
                        type: "search",
                        id: "search",
                      },
                      {label: "Start Date", type: "datetime", id: "start"},
                      {label: "End Date", type: "datetime", id: "end"},
                    ]}
                  >
                    {(filters, search) => {
                      return (
                        <Table
                          ref={(e) => (this.tableRef = e)}
                          route={`accounting/transactions/${bankConnections[selectedIndex]?.UNIQUE_ID}/dumb`}
                          filters={[...filters, {filter: "reviewStatus", data: [id]}]}
                          columns={this.getColumns()}
                          pagination
                        />
                      );
                    }}
                  </AdvancedFilter>
                </div>
              )}
            </Tab>
          </div>
        )}
      </div>
    );
  }
}

export default setupReduxConnection(["accounting"])(AccountingTransactionsPage);

const TRANSACTION_REVIEW_STATUSES = {
  REVIEWED: "REVIEWED",
  UNREVIEWED: "UNREVIEWED",
};
