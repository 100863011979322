import React, {Component} from "react";
import Wizard from "../../../components/wizard";
import {withRouter} from "../../../utils/navigation";
import {request} from "../../../utils/request";
import {
  Loading,
  Table,
  Card,
  TwoColumnList,
} from "@frostbyte-technologies/frostbyte-tailwind";
import TimeOffCard from "../../../features/team/time-off-card";
import {getObjectImage} from "@frostbyte-technologies/frostbyte-core/dist/helpers/asset-helper";
import {toDecimalPlaces} from "../../../utils/decimal-helper";

class RunPolicyPage extends Component {
  state = {policy: null, employees: null};

  componentDidMount() {
    this.syncState();
  }

  async syncState() {
    const {ID: id} = this.props.router.params;

    const policy = await request("timeoff/policies/" + id, "GET");
    const employees = await request("timeoff/policies/" + id + "/run", "GET");

    this.setState({policy, employees});
  }

  async submitProduct() {
    const {policy} = this.state;

    await request("timeoff/policies/" + policy.ID + "/run", "POST", {});

    this.props.router.navigate("/policy/" + policy.UNIQUE_ID);
  }

  render() {
    const {employees, policy} = this.state;

    if (employees === null) {
      return <Loading />;
    }

    return (
      <>
        <Wizard
          ref={(e) => (this.wizardRef = e)}
          onQuit={() => this.props.router.navigate("/policy/" + policy.UNIQUE_ID)}
          onSubmit={this.submitProduct.bind(this)}
          steps={[
            {
              id: 0,
              name: "Employee policies",
              description: "Employees enrolled in this policy",
              render: ({values}, {handleSubmit, setupRef}) => {
                return (
                  <Card label="Employees">
                    <Table
                      data={employees}
                      columns={[
                        {
                          value: "employee",
                          label: "Employee Name",
                          format: (value, {employee: row}) => {
                            return (
                              <div className="flex items-center">
                                <div className="h-10 w-10 flex-shrink-0">
                                  <img
                                    className="h-10 w-10 rounded-full"
                                    src={getObjectImage(row, "LOGO", "appicon.png")}
                                    alt=""
                                  />
                                </div>

                                <div className="ml-4">
                                  <div className="text-sm font-medium text-gray-900">
                                    {row.FULL_NAME}
                                  </div>

                                  <div className="text-sm text-gray-500">
                                    {row.ROLES?.map((item) => item.ROLE_NAME).join(", ")}
                                  </div>
                                </div>
                              </div>
                            );
                          },
                        },
                        {
                          value: "BALANCE",
                          label: "Hours Worked",
                          format: (_, {log}) => toDecimalPlaces(log.HOURS, 2),
                        },
                        {
                          value: "BALANCE",
                          label: "Hours Earned",
                          format: (_, {log}) => toDecimalPlaces(log.AMOUNT, 2),
                        },
                        {
                          value: "BALANCE",
                          label: "Hours Balance",
                          format: (_, {log}) => toDecimalPlaces(log.BALANCE, 2),
                        },
                      ]}
                    />
                  </Card>
                );
              },
            },
            {
              id: 1,
              name: "Preview",
              description: "Confirm the details of this policy run",
              render: ({values}) => {
                const {employees} = this.state;

                const totalHours = employees.reduce((accum, item) => {
                  return accum + item.log.HOURS;
                }, 0);

                const totalEarned = employees.reduce((accum, item) => {
                  return accum + item.log.AMOUNT;
                }, 0);

                return (
                  <>
                    <TwoColumnList
                      label="Time Off Review"
                      description="Information"
                      data={[
                        {
                          label: "Hours Tracked",
                          value: toDecimalPlaces(totalHours, 2),
                        },
                        {
                          label: "Total Earned",
                          value: toDecimalPlaces(totalEarned, 2),
                        },
                      ]}
                    />
                  </>
                );
              },
            },
          ]}
        />
      </>
    );
  }
}

export default withRouter(RunPolicyPage);
