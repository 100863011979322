import React, {Component} from "react";
import {TwoColumnList} from "@frostbyte-technologies/frostbyte-tailwind";
import PropTypes from "prop-types";
import moment from "moment";

class InvoiceCard extends Component {
  render() {
    const {handleEdit, hideEdit, invoice} = this.props;

    return (
      <TwoColumnList
        label="Invoice Details"
        description="Information about the invoice"
        data={[
          {
            label: "Contact",
            link: invoice?.CONTACT?.UNIQUE_ID
              ? "/contact/" + invoice?.CONTACT?.UNIQUE_ID
              : null,
            value:
              invoice?.CONTACT?.FIRST_NAME + " " + invoice?.CONTACT?.LAST_NAME,
          },
          {label: "Invoice Status", value: invoice.STATUS.capitalize()},
          {label: "Invoice Number", value: invoice.INVOICE_NUMBER},
          {
            label: "Service Date",
            value: moment(invoice.DATE_SERVICE).format("M/DD hh:mm A"),
          },
          {
            label: "Tips Enabled",
            value: invoice.REQUEST_TIP ? "Yes" : "No",
          },
          {
            label: "Send to KDS",
            value: invoice?.SEND_TO_KDS ? "Yes" : "No",
          },
          {
            label: "Date Due",
            value: invoice.DATE_DUE,
            type: "date",
            hide: true,
          },
          {
            label: "Notes",
            value: (
              <div className="whitespace-pre">
                {invoice?.TICKET?.NOTES ?? invoice.NOTES}
              </div>
            ),
            span: true,
            hide: true,
          },
        ]}
        buttons={
          hideEdit
            ? []
            : [
                {
                  label: "Edit Information",
                  onClick: handleEdit,
                },
              ]
        }
      />
    );
  }
}

InvoiceCard.propTypes = {
  invoice: PropTypes.object.isRequired,
  handleEdit: PropTypes.func.isRequired,
};

export default InvoiceCard;
