import React, {Component} from "react";
import {FormSelect, Modal} from "@frostbyte-technologies/frostbyte-tailwind";
import {Formik} from "formik";
import {request} from "../../../utils/request";
import {showSuccessAlert} from "../../../utils/alert-helper";
import FormDateTimeSelect from "../../../components/form-date-time-select";
import {CSVLink} from "react-csv";
import {toDollars} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import moment from "moment";
import FormRow from "../../../components/form-row";
import * as Yup from "yup";

class TicketReceiptModal extends Component {
  state = {downloadData: []};

  open() {
    this.setState({downloadData: []}, () => {
      this.formikRef && this.formikRef.resetForm();
      this.modal.open();
    });
  }

  async sendTicketReceipt({start, end}) {
    if (start > end) {
      this.modal.stopLoading();
      return this.formikRef.setFieldError(
        "start",
        "Start has to be before end"
      );
    }

    if (end - start > 1000 * 60 * 60 * 24 * 31) {
      this.modal.stopLoading();
      return this.formikRef.setFieldError(
        "start",
        "Exports can only be 31 days at a time"
      );
    }

    const ticketData = await request("tickets/download", "POST", {
      DATE_START: start,
      DATE_END: end,
    });

    this.setState({downloadData: ticketData}, () => {
      setTimeout(() => {
        this.csvRef.link.click();

        this.setState({downloadData: []}, () => {
          this.modal.close();

          showSuccessAlert(
            "Download Complete",
            "Your ticket export completed processing and was downloaded!"
          );
        });
      }, 1);
    });
  }

  render() {
    const {downloadData} = this.state;

    return (
      <Modal
        buttonLabel="Download"
        label="Download Ticket Data"
        description="You can only download 30 days of ticket data at a time. This export includes unscrubbed ticket data, please use our reports for more accurate sales numbers."
        ref={(e) => (this.modal = e)}
        formikOnClick={() => this.formikRef}
      >
        <Formik
          onSubmit={this.sendTicketReceipt.bind(this)}
          innerRef={(e) => (this.formikRef = e)}
          enableReinitialize
          validationSchema={Yup.object({
            start: Yup.number()
              .typeError("Start date is required")
              .required("Start date is required"),
            end: Yup.number()
              .typeError("End date is required")
              .required("End date is required"),
          })}
          initialValues={{
            start: null,
            end: null,
          }}
        >
          {(formikOptions) => {
            const {handleSubmit, values} = formikOptions;

            return (
              <form onSubmit={handleSubmit}>
                <CSVLink
                  ref={(e) => (this.csvRef = e)}
                  data={downloadData.reduce((accum, ticket) => {
                    const ticketPayload = {...ticket};

                    let amount = 0;
                    let amountTip = 0;
                    let amountTax = 0;
                    let amountFees = 0;
                    let amountDiscount = 0;
                    let amountRefund = 0;
                    let amountProcessing = 0;
                    let total = 0;

                    for (let item of ticketPayload.PAYMENT_INTENT.TRANSACTIONS.filter(
                      (item) => {
                        return item.TYPE === "PAYMENT";
                      }
                    )) {
                      amount += item.AMOUNT;
                      amountTip += item.AMOUNT_TIP;
                      amountTax += item.AMOUNT_TAX;
                      amountFees += item.AMOUNT_FEES;
                      amountDiscount += item.AMOUNT_DISCOUNT;
                      amountRefund += item.AMOUNT_REFUNDED;
                      amountProcessing += item.AMOUNT_PROCESSING;
                      total += item.TOTAL;
                    }

                    amountRefund =
                      ticketPayload.PAYMENT_INTENT.TRANSACTIONS.filter(
                        ({TYPE}) => TYPE === "REFUND"
                      ).reduce(
                        (total, {AMOUNT_REFUNDED}) => total + AMOUNT_REFUNDED,
                        0
                      );

                    ticketPayload.AMOUNT = "$" + toDollars(amount);
                    ticketPayload.AMOUNT_TIP = "$" + toDollars(amountTip);
                    ticketPayload.AMOUNT_TAX = "$" + toDollars(amountTax);
                    ticketPayload.AMOUNT_FEES = "$" + toDollars(amountFees);
                    ticketPayload.AMOUNT_DISCOUNT =
                      "$" + toDollars(amountDiscount);
                    ticketPayload.AMOUNT_REFUNDED =
                      "$" + toDollars(amountRefund);
                    ticketPayload.AMOUNT_PROCESSING =
                      "$" + toDollars(amountProcessing);
                    ticketPayload.TOTAL = "$" + toDollars(total);

                    ticketPayload.DATE_CREATED = ticket.DATE_CREATED
                      ? moment(ticket.DATE_CREATED).format("hh:mma MM/DD/YY")
                      : "";

                    ticketPayload.DATE_STARTED = ticket.DATE_STARTED
                      ? moment(ticket.DATE_STARTED).format("hh:mma MM/DD/YY")
                      : "";

                    ticketPayload.DATE_COMPLETED = ticket.DATE_COMPLETED
                      ? moment(ticket.DATE_COMPLETED).format("hh:mma MM/DD/YY")
                      : "";

                    ticketPayload.DATE_SENT = ticket.DATE_SENT
                      ? moment(ticket.DATE_SENT).format("hh:mma MM/DD/YY")
                      : "";

                    ticketPayload.DATE_REFUNDED = ticket.PAYMENT_INTENT
                      .DATE_REFUNDED
                      ? moment(ticket.PAYMENT_INTENT.DATE_REFUNDED).format(
                          "hh:mma MM/DD/YY"
                        )
                      : "";

                    ticketPayload.DATE_VOIDED = ticket.PAYMENT_INTENT
                      .DATE_VOIDED
                      ? moment(ticket.PAYMENT_INTENT.DATE_VOIDED).format(
                          "hh:mma MM/DD/YY"
                        )
                      : "";

                    ticketPayload.ITEMS = ticket.ITEMS.length;

                    accum.push(ticketPayload);

                    for (let item of ticket.ITEMS) {
                      accum.push({
                        ITEM_NAME: item.NAME.replaceAll('"', ""),
                        ITEM_AMOUNT: toDollars(item.AMOUNT),
                        ITEM_AMOUNT_TAX: toDollars(item.AMOUNT_TAX),
                        ITEM_TOTAL: toDollars(item.TOTAL),
                        ITEM_QUANTITY: item.QUANTITY,
                      });

                      for (let selection of item.SELECTIONS) {
                        accum.push({
                          MODIFIER_NAME: selection.MODIFIER_NAME.replaceAll(
                            '"',
                            ""
                          ),
                          OPTION_NAME: selection.OPTION_NAME.replaceAll(
                            '"',
                            ""
                          ),
                          SELECTION_AMOUNT: selection.AMOUNT,
                          SELECTION_AMOUNT_TAX: selection.AMOUNT_TAX,
                          SELECTION_TOTAL: selection.TOTAL,
                          SELECTION_QUANTITY: selection.QUANTITY,
                        });
                      }
                    }

                    return accum;
                  }, [])}
                  filename={`tickets-${moment(values.start).format(
                    "MM/DD/YY"
                  )}-${moment(values.end).format("MM/DD/YY")}.csv`}
                  headers={[
                    {label: "Ticket Number", key: "TICKET_NUMBER"},
                    {label: "Name", key: "NAME"},
                    {label: "Phone", key: "PHONE"},
                    {label: "Email", key: "EMAIL"},
                    {label: "Ticket Type", key: "TICKET_TYPE_NAME"},
                    {label: "Platform", key: "PLATFORM"},
                    {label: "Employee Name", key: "EMPLOYEE_NAME"},
                    {label: "Number of Items", key: "ITEMS"},
                    {label: "Subtotal", key: "AMOUNT"},
                    {label: "Tip", key: "AMOUNT_TIP"},
                    {label: "Tax", key: "AMOUNT_TAX"},
                    {label: "Fees", key: "AMOUNT_FEES"},
                    {label: "Discounted", key: "AMOUNT_DISCOUNT"},
                    {label: "Refunded", key: "AMOUNT_REFUNDED"},
                    {label: "Total", key: "TOTAL"},
                    {label: "Created", key: "DATE_CREATED"},
                    {label: "Sent to KDS", key: "DATE_SENT"},
                    {label: "Started on KDS", key: "DATE_STARTED"},
                    {label: "Completed on KDS", key: "DATE_COMPLETED"},
                    {label: "Date Refunded", key: "DATE_REFUNDED"},
                    {label: "Date Voided", key: "DATE_VOIDED"},
                    {label: "Device Name", key: "DEVICE_NAME"},
                    {label: "Notes", key: "NOTES"},
                    {label: "Internal Notes", key: "INTERNAL_NOTES"},
                    {label: "Dripos ID", key: "ID"},
                    {label: "Dripos Unique", key: "UNIQUE_ID"},
                    {label: "Item Quantity", key: "ITEM_QUANTITY"},
                    {label: "Item Name", key: "ITEM_NAME"},
                    {label: "Item Total", key: "ITEM_TOTAL"},
                    {label: "Selection Quantity", key: "SELECTION_QUANTITY"},
                    {label: "Modifier Name", key: "MODIFIER_NAME"},
                    {label: "Option Name", key: "OPTION_NAME"},
                  ]}
                />

                <FormRow>
                  <FormDateTimeSelect
                    hideTime
                    label="Start"
                    options={formikOptions}
                    name="start"
                    flex
                  />

                  <FormDateTimeSelect
                    hideTime
                    label="End"
                    options={formikOptions}
                    name="end"
                    flex
                  />
                </FormRow>

                <FormSelect
                  label="Preset Ranges"
                  onChange={(value) => {
                    if (value === "week") {
                      formikOptions.setFieldValue(
                        "start",
                        moment().startOf("week").valueOf()
                      );

                      formikOptions.setFieldValue(
                        "end",
                        moment().endOf("week").valueOf()
                      );
                    }

                    if (value === "lastWeek") {
                      formikOptions.setFieldValue(
                        "start",
                        moment().subtract(1, "week").startOf("week").valueOf()
                      );

                      formikOptions.setFieldValue(
                        "end",
                        moment().subtract(1, "week").endOf("week").valueOf()
                      );
                    }

                    if (value === "lastMonth") {
                      formikOptions.setFieldValue(
                        "start",
                        moment().subtract(1, "month").startOf("month").valueOf()
                      );

                      formikOptions.setFieldValue(
                        "end",
                        moment().subtract(1, "month").endOf("month").valueOf()
                      );
                    }

                    if (value === "month") {
                      formikOptions.setFieldValue(
                        "start",
                        moment().startOf("month").valueOf()
                      );

                      formikOptions.setFieldValue(
                        "end",
                        moment().endOf("month").valueOf()
                      );
                    }
                  }}
                  data={[
                    {label: "Last Week", value: "lastWeek"},
                    {label: "Week to Date", value: "week"},
                    {label: "Last Month", value: "lastMonth"},
                    {label: "Month to Date", value: "month"},
                  ]}
                />
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

export default TicketReceiptModal;
