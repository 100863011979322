import {Filter, Table} from "@frostbyte-technologies/frostbyte-tailwind";
import {getFormattedPhone} from "@frostbyte-technologies/frostbyte-core/dist/helpers/phone-helper";
import {Component} from "react";
import PropTypes from "prop-types";
import {
  fullNameColumn,
  getNameColumn,
  getPhoneColumn,
} from "../../tables/columns/column-helper";

class SubscriptionListTable extends Component {
  formatNameColumn(value, row) {
    return (
      <div>
        <div className="text-sm font-medium text-gray-900">{value || "-"}</div>
        <div className="text-sm text-gray-500">{row.EMAIL}</div>
      </div>
    );
  }

  formatPhone(value) {
    return getFormattedPhone(value);
  }

  render() {
    const {data, list} = this.props;

    const nameColumn = getNameColumn({format: this.formatNameColumn});

    const phoneColumn = getPhoneColumn({format: this.formatPhone});

    return (
      <Filter className="ml-4" searchable noBorder>
        {(_, search) => {
          const filters = [
            {
              KEY: "EMAIL_ONLY",
              VALUE: list.EMAIL_ONLY,
            },
          ];
          return (
            <Table
              {...this.props}
              search={search}
              searchFields={["FULL_NAME", "PHONE"]}
              route={data ? undefined : "text/marketing/list/" + list.ID + "/dumb"}
              pagination
              rowsPerPage
              ref={(e) => (this.tableRef = e)}
              filters={filters}
              columns={[nameColumn, phoneColumn]}
            />
          );
        }}
      </Filter>
    );
  }
}

SubscriptionListTable.propTypes = {
  list: PropTypes.object.isRequired,
};

export default SubscriptionListTable;
