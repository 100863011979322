import React, {Component} from "react";
import {request} from "../../utils/request";
import LoadingSpinner from "../../components/loading-spinner";
import {PageHeadings, Tab, Table} from "@frostbyte-technologies/frostbyte-tailwind";
import {ACCOUNT_SUB_TYPES, ACCOUNT_TYPES} from "../../utils/accounting-constants";
import {withRouter} from "../../utils/navigation";
import JournalEntryModal from "../../modals/accounting/journal-entry-modal";
import AccountModal from "../../modals/accounting/account-modal";
import {toDollars} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import LedgerDescription from "../../features/accounting/ledger-description";
import {setupReduxConnection} from "../../redux";
import ReportingSearchBar from "../../features/reporting/reports/reporting-search-bar";
import Fuse from "fuse.js";

class AccountsPage extends Component {
  state = {search: "", ledgers: null};

  async componentDidMount() {
    this.props.loadLedgers();

    await this.syncState();
  }

  syncState = async () => {
    const ledgers = await request("accounting/ledgers");

    this.setState({ledgers});
  };

  render() {
    let {search, ledgers} = this.state;

    const pageButtons = [
      {
        type: "dropdown",
        label: "Actions",
        sections: [
          {
            items: [
              {
                label: "New Account",
                onClick: () => this.accountModal.openNew(),
              },
              {
                label: "New Journal Entry",
                onClick: () => this.journalEntryModal.open(),
              },
            ],
          },
        ],
      },
    ];

    if (search.length) {
      ledgers = new Fuse(ledgers, {
        keys: ["NAME"],
        useExtendedSearch: true,
        threshold: 0,
      })
        .search(search)
        .map(({item}) => item);
    }

    return (
      <div>
        <AccountModal
          ref={(e) => (this.accountModal = e)}
          ledgers={ledgers}
          syncState={this.syncState}
        />

        <JournalEntryModal
          ref={(e) => (this.journalEntryModal = e)}
          ledgers={ledgers}
          syncState={this.syncState}
        />

        <PageHeadings label={"Chart of Accounts"} buttons={pageButtons} />
        {!ledgers && <LoadingSpinner />}

        {ledgers && (
          <div>
            <ReportingSearchBar
              placeholder="Search by name"
              className={"mt-5"}
              onChange={(search) => this.setState({search})}
            />

            <Tab
              data={[
                {label: "All", id: "all"},
                ...Object.keys(ACCOUNT_TYPES).map((_key) => ({
                  label: ACCOUNT_TYPES[_key].label,
                  id: _key,
                })),
              ]}
            >
              {(id) => {
                return (
                  <Table
                    actionButtons={[
                      {
                        label: "View",
                        onClick: (row) =>
                          this.props.router.navigate(
                            `/accounting/accounts/${row.UNIQUE_ID}`
                          ),
                      },
                    ]}
                    data={ledgers
                      .filter((_acc) => id === "all" || id === _acc.TYPE)
                      .sort((a, b) => {
                        const aIndex = ACCOUNT_SUB_TYPES[a.SUB_TYPE].index;
                        const bIndex = ACCOUNT_SUB_TYPES[b.SUB_TYPE].index;

                        if (aIndex === bIndex) {
                          return a.NAME.localeCompare(b.NAME);
                        }

                        return aIndex - bIndex;
                      })}
                    columns={[
                      {
                        label: "Name",
                        value: "NAME",
                        format: (value, row) => (
                          <div>
                            <div className={"text-black"}>{value}</div>
                            {row.PARENT_NAME && <div>{row.PARENT_NAME}</div>}
                          </div>
                        ),
                      },
                      {
                        label: "Type",
                        value: "TYPE",
                        format: (value, row) => (
                          <div>
                            <div className={"font-semibold text-black"}>
                              {ACCOUNT_TYPES[value]?.label}
                            </div>

                            <LedgerDescription ledger={row} />
                          </div>
                        ),
                      },
                      {
                        label: "Balance",
                        value: "BALANCE",
                        format: (value) => toDollars(value, true),
                      },
                    ]}
                  />
                );
              }}
            </Tab>
          </div>
        )}
      </div>
    );
  }
}

export default setupReduxConnection(["accounting"])(withRouter(AccountsPage));
