import moment from "moment-timezone";
import {minutesToHours} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import {MILLI_MINUTE} from "./constants";

export function checkDSTConversion(date, from, to) {
  const startOfDayEnd = moment(date).add(to, "minutes").startOf("day");

  let dateStart = moment(date).add(from, "minutes");
  let dateEnd =
    to < from
      ? moment(date).add(1, "day").add(to, "minutes")
      : moment(date).add(to, "minutes");

  if (moment(date).isDST() !== dateStart.isDST()) {
    //from DST to non-DST implies add one hour
    if (moment(date).isDST()) {
      dateStart.add(60, "minutes");
    } else {
      dateStart.subtract(60, "minutes");
    }
  }

  //dateEnd could start on a diff day (overnight shifts), need another if check
  if (startOfDayEnd.isDST() !== dateEnd.isDST()) {
    if (startOfDayEnd.isDST()) {
      dateEnd.add(60, "minutes");
    } else {
      dateEnd.subtract(60, "minutes");
    }
  }

  return {dateStart: dateStart, dateEnd: dateEnd};
}

export function fromToDSTConversion(dateStart, dateEnd) {
  if (!dateStart || !dateEnd) {
    return {from: null, to: null};
  }

  const startOfDayStart = moment(dateStart).startOf("day");
  const startOfDayEnd = moment(dateEnd).startOf("day");

  if (startOfDayStart.isDST() !== moment(dateStart).isDST()) {
    if (startOfDayStart.isDST()) {
      dateStart = moment(dateStart).subtract(60, "minutes");
    } else {
      dateStart = moment(dateStart).add(60, "minutes");
    }
  }

  if (startOfDayEnd.isDST() !== moment(dateEnd).isDST()) {
    if (startOfDayEnd.isDST()) {
      dateEnd = moment(dateEnd).subtract(60, "minutes");
    } else {
      dateEnd = moment(dateEnd).add(60, "minutes");
    }
  }

  return {
    from: moment(dateStart).diff(startOfDayStart, "minutes"),
    to: moment(dateEnd).diff(startOfDayEnd, "minutes"),
  };
}

export function differenceInHours(start, end) {
  const diffInMinutes = moment(end)
    .startOf("seconds")
    .diff(moment(start).startOf("seconds"), "minutes");

  return minutesToHours(diffInMinutes);
}

export function getTimezone(location, timezone) {
  if (location.STATE === "AZ") {
    return "America/Phoenix";
  } else if (location.STATE === "HI") {
    return "Pacific/Honolulu";
  } else {
    return timezone;
  }
}

export function calculateTimeOffset(time) {
  if (!time) {
    return;
  }

  const [hours, minutes] = time.split(":").map(Number);

  const date = new Date();
  date.setHours(hours, minutes, 0, 0);

  const startOfDay = new Date();
  startOfDay.setHours(0, 0, 0, 0);

  return (date - startOfDay) / MILLI_MINUTE;
}

export function calculateTimeDuration(from, to, date) {
  if (!from || !to) {
    return;
  }

  let [hours, minutes] = from.split(":").map(Number);

  const dateStart = new Date();
  dateStart.setHours(hours, minutes, 0, 0);

  [hours, minutes] = to.split(":").map(Number);

  const dateEnd = new Date();
  dateEnd.setHours(hours, minutes, 0, 0);

  return (dateEnd - dateStart) / MILLI_MINUTE;
}

export function getHoursPastMidnight(offset) {
  return moment(1699851600000).startOf("day").add(offset, "minutes");
}

export function getFormattedDate(
  epoch,
  emptyStr = "Invalid Date",
  momentFormat = "M/D/YY, h:mm A"
) {
  return moment(epoch).isValid() ? moment(epoch).format(momentFormat) : emptyStr;
}
