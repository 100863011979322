import React, {Component} from "react";
import {
  classNames,
  decimalToDollars,
  toDollars,
} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import {Loading, Modal} from "@frostbyte-technologies/frostbyte-tailwind";
import ModifierCard from "./../../../features/product/modifier-card";
import {
  fetchLineItem,
  fetchLineItemDiscount,
  fetchLineItemSelection,
} from "@frostbyte-technologies/frostbyte-tickets/dist/helpers/cart-helper";
import {fetchDefaultProductSelections} from "../../../utils/product-helper";
import {setupReduxConnection} from "../../../redux";
import {cloneDeep} from "lodash";
import {CUSTOMIZATION_TYPES} from "@frostbyte-technologies/frostbyte-tickets/dist/constants/product-constants";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

class ProductSelectModal extends Component {
  state = {
    selections: [],
    taxRates: [],
    discounts: [],
    product: null,
    quantity: 1,
    resolve: null,
  };

  open(product, item = null) {
    let selections = fetchDefaultProductSelections(product);
    let taxRates = product.TAX_RATES;
    let quantity = 1;
    let notes = "";
    const {taxRates: allTaxRates} = this.props.shop;

    if (item) {
      selections = item.SELECTIONS.filter(
        (s) => s.MODIFIER_TYPE !== CUSTOMIZATION_TYPES.AUTOMATIC_VARIANT
      ).map((selection) => {
        const customization = product.CUSTOMIZATIONS.find(
          (custom) => custom.ID === selection.MODIFIER_ID
        );

        const option = customization.OPTIONS.find(
          (option) => option.ID === selection.OPTION_ID
        );

        return fetchLineItemSelection({
          modifier: customization,
          option,
          quantity: selection.QUANTITY ?? 1,
          rates: taxRates,
        });
      });

      quantity = item.QUANTITY;

      taxRates = item.TAX_RATES.map((rate) =>
        allTaxRates.find((_rate) => _rate.ID === rate.TAX_ID)
      );

      taxRates = taxRates.filter((rate) => !!rate);
      notes = item.NOTES ?? "";
    }

    return new Promise((resolve) => {
      this.setState(
        {
          product,
          resolve,
          selections,
          taxRates,
          quantity,
          price: undefined,
          notes,
        },
        () => this.modal.open()
      );
    });
  }

  calculatePrice() {
    const {selections, product} = this.state;

    return selections
      .filter(
        (selection) => selection.MODIFIER_TYPE !== CUSTOMIZATION_TYPES.AUTOMATIC_VARIANT
      )
      .reduce((accum, selection) => accum + selection.AMOUNT, product.PRICE);
  }

  renderCustomization(customization) {
    const {selections, product} = this.state;

    return (
      <ModifierCard
        modifier={customization}
        selections={selections}
        product={product}
        updateSelections={(selections) => this.setState({selections})}
      />
    );
  }

  renderVariablePricing() {
    const {product} = this.state;

    return (
      <div className={"border-b mb-6 pb-1"}>
        <div className={"text-md font-bold px-5 mb-2"}>Variable Pricing</div>

        <input
          onChange={(e) =>
            this.setState((prevState) => {
              const {
                product: prevProduct,
                taxRates: prevTaxRates,
                quantity: prevQuantity,
                selections: prevSelections,
                discounts: prevDiscounts,
              } = prevState;

              prevProduct.PRICE = decimalToDollars(e.target.value);

              const lineItem = fetchLineItem({
                product: prevProduct,
                discounts: prevDiscounts,
                rates: prevTaxRates,
                selections: prevSelections.filter((s) => s.MODIFIER_NAME !== "Price"),
                quantity: prevQuantity,
              });

              return {selections: lineItem.SELECTIONS};
            })
          }
          className={
            "flex w-full py-2 px-5 rounded-md shadow-sm text-sm focus:border-indigo-500 mb-1 "
          }
          placeholder={"0.00"}
          disabled={!product.VARIABLE}
        />
      </div>
    );
  }

  handleTaxRateToggle(rate) {
    this.setState((prevState) => {
      const {selections: prevSelections, taxRates: prevTaxRates} = prevState;

      const taxRateIndex = prevTaxRates.findIndex(
        (_rate) => _rate.ID === rate.ID || _rate.TAX_ID === rate.ID
      );

      if (taxRateIndex !== -1) {
        prevTaxRates.splice(taxRateIndex, 1);
      } else {
        prevTaxRates.push(rate);
      }

      const newTaxRates = cloneDeep(prevTaxRates);

      const newSelections = prevSelections.map((selection) =>
        fetchLineItemSelection({
          rates: newTaxRates,
          modifier: {
            ID: selection.MODIFIER_ID,
            TYPE: selection.MODIFIER_TYPE,
            NAME: selection.MODIFIER_NAME,
            SEQ: selection.SEQ,
          },
          quantity: selection.QUANTITY,
          option: {
            ID: selection.OPTION_ID,
            NAME: selection.OPTION_NAME,
            PRICE: selection.AMOUNT + selection.AMOUNT_DISCOUNT,
          },
        })
      );

      return {selections: newSelections, taxRates: newTaxRates};
    });
  }

  handleDiscountToggle(discount) {
    this.setState((prevState) => {
      const {
        selections: prevSelections,
        discounts: prevDiscounts,
        taxRates: prevTaxRates,
        product: prevProduct,
        quantity: prevQuantity,
      } = prevState;

      const discountIndex = prevDiscounts.findIndex(
        (_discount) =>
          _discount.DISCOUNT_ID === discount.DISCOUNT_ID ||
          _discount.DISCOUNT_ID === discount.ID
      );

      if (discountIndex !== -1) {
        prevDiscounts.splice(discountIndex, 1);
      } else {
        const lineItemDiscount = fetchLineItemDiscount({
          discount: {
            ...discount,
            ID: discount.DISCOUNT_ID,
          },
        });

        prevDiscounts.push(lineItemDiscount);
      }

      const newDiscounts = cloneDeep(prevDiscounts);

      const newLineItem = fetchLineItem({
        product: prevProduct,
        discounts: cloneDeep(newDiscounts),
        rates: prevTaxRates,
        selections: prevSelections.filter((s) => s.MODIFIER_NAME !== "Price"),
        quantity: prevQuantity,
      });

      return {discounts: newDiscounts, selections: newLineItem.SELECTIONS};
    });
  }

  handleSubmit() {
    const {selections, quantity, notes, resolve, taxRates, discounts} = this.state;

    resolve({
      selections: cloneDeep(selections),
      rates: cloneDeep(taxRates),
      quantity: cloneDeep(quantity),
      notes: cloneDeep(notes),
      discounts: cloneDeep(discounts),
    });
    this.modal.close();
  }

  renderCustomizations() {
    const {product} = this.state;

    if (!product.CUSTOMIZATIONS || product.CUSTOMIZATIONS.length === 0) {
      return <div />;
    }

    return (
      <div className="shadow-md">
        <div className="text-md font-bold px-5">Modifiers</div>

        {product.CUSTOMIZATIONS.map((customization) =>
          this.renderCustomization(customization)
        )}
      </div>
    );
  }

  renderTaxRate(rate) {
    const {taxRates = []} = this.state;

    const active = taxRates.find(
      (_rate) => _rate.TAX_ID === rate.ID || _rate.ID === rate.ID
    );

    return (
      <div
        className={
          "flex flex-row items-center justify-between flex-1 ml-5 py-2 cursor-pointer"
        }
        onClick={() => this.handleTaxRateToggle(rate)}
      >
        <FontAwesomeIcon
          icon={active ? "fa-solid fa-check-square" : "fa-regular fa-square"}
          solid={active}
        />

        <div className={"flex flex-row justify-between border-b w-full mx-3 py-2"}>
          <div className={"text-sm font-semibold text-black mr-1"}>{rate.NAME}</div>

          <div className={"text-sm font-semibold text-gray-500"}>{rate.RATE}%</div>
        </div>
      </div>
    );
  }

  renderTaxRates() {
    const {taxRates = []} = this.props.shop;

    return (
      <div>
        <div className={"text-md font-bold px-5 mb-2 mt-6"}>Tax Rates</div>

        <div className={"bg-white rounded-md "}>
          {taxRates.map((rate) => this.renderTaxRate(rate))}
        </div>
      </div>
    );
  }

  renderDiscount(discount) {
    const {discounts} = this.state;

    const active = discounts.find(
      (_discount) =>
        _discount.ID === discount.ID || _discount.DISCOUNT_ID === discount.DISCOUNT_ID
    );

    return (
      <div className={"flex flex-row items-center justify-between flex-1 ml-3 py-2"}>
        <input
          key={discount.ID}
          aria-describedby={`${discount.NAME}-description`}
          type={"checkbox"}
          checked={active}
          onChange={() => this.handleDiscountToggle(discount)}
          className={classNames(
            "flex focus:ring-transparent h-4 w-4 text-indigo-600 ring-transparent border-black rounded-sm border-2",
            active ? "bg-black" : "bg-white"
          )}
        />

        <div
          className={
            "flex flex-row justify-between border-b flex flex-row w-full mx-3 py-2"
          }
        >
          <div className={"text-sm font-semibold text-black mr-1"}>{discount.NAME}</div>

          <div className={"text-sm font-semibold text-gray-500"}>
            {discount.TYPE === 1
              ? discount.CONTENT + "%"
              : toDollars(discount.CONTENT, true)}
          </div>
        </div>
      </div>
    );
  }

  renderDiscounts() {
    const {product} = this.state;
    const {PRODUCT_DISCOUNTS: discounts = []} = product;

    if (discounts.length === 0) {
      return <div />;
    }

    return (
      <div>
        <div className={"text-md font-bold px-5 mb-2 mt-6"}>Discounts</div>

        <div className={"bg-white rounded-md"}>
          {discounts.map((_discount) => this.renderDiscount(_discount))}
        </div>
      </div>
    );
  }

  render() {
    const {product, notes, price} = this.state;

    return (
      <Modal
        large
        className={"bg-neutral-bg bg-opacity-20 overflow-y-hidden "}
        buttonLabel={"Save"}
        ref={(e) => (this.modal = e)}
        buttonOnClick={() => this.handleSubmit()}
      >
        {!product && <Loading />}

        {!!product && (
          <div>
            <div className={"py-2 border-b mb-6 overflow-none overflow-clip"}>
              <div className={"flex flex-row justify-center font-medium text-md my-2"}>
                {product.NAME}
              </div>

              <div
                className={
                  "flex flex-row justify-center font-bold text-3xl text-indigo-500"
                }
              >
                {toDollars(this.calculatePrice(), true)}
              </div>

              <div className={"mb-2"}>
                <div className={"text-md font-bold px-5 mb-2"}>Notes</div>

                <input
                  className={
                    "flex w-full py-2 px-5 rounded-md shadow-sm text-sm focus:border-indigo-500"
                  }
                  value={notes}
                  onChange={(e) => this.setState({notes: e.target.value})}
                  placeholder="Add Special Instructions"
                />
              </div>
            </div>

            {!!product.VARIABLE && this.renderVariablePricing()}

            {!!product.CUSTOMIZATIONS && product.CUSTOMIZATIONS.length > 0 && (
              <div className={"border-b pb-4"}>{this.renderCustomizations()}</div>
            )}

            <div className={"border-b pb-4"}>{this.renderTaxRates()}</div>

            {!!product.PRODUCT_DISCOUNTS && product.PRODUCT_DISCOUNTS.length > 0 && (
              <div className={"border-b pb-4"}>{this.renderDiscounts()}</div>
            )}
          </div>
        )}
      </Modal>
    );
  }
}

export default setupReduxConnection(["shop"])(ProductSelectModal);
